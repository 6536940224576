import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../components/Button';
import { Grid } from '@material-ui/core';
import * as callApiUtil from '../utils/callApiUtil';
import { Helmet } from 'react-helmet';
import { Fragment } from 'react';
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      menuList: [],
      notice: null,
    };
  }
  componentDidMount = () => {
    this.setState({ isLoading: true });
    //取得首頁通知內容
    callApiUtil.callApi('/ECPAP/API/IndexController/initIndex.action').then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState(() => ({
          notice: response.notice,
        }));
        document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
      }
      this.setState(() => ({
        isLoading: false,
      }));
    });
    //從session取得menu清單
    let sysMenu = window.localStorage.getItem('menuList');
    if (sysMenu != null) {
      let filist = JSON.parse(sysMenu);
      filist.menuList.map((option, index) => {
        switch (option.menuId) {
          case 'M01':
            option.pageList = ['帳號基本資料異動、子帳號維護。'];
            break;
          case 'M02':
            option.pageList = ['產品查詢作業、產品線上服務。'];
            break;
          case 'M03':
            option.pageList = ['帳務資訊查詢、通話明細、線上繳款、MVPN線上服務。'];
            break;
          case 'M04':
            option.pageList = ['線上即時進行障礙報修、障礙排除FAQ。'];
            break;
          case 'M05':
            option.pageList = ['帳號的登入及登出紀錄查詢、產品服務異動申請進件紀錄查詢。'];
            break;
          case 'M06':
            option.pageList = ['各式表單下載、各地區服務主機。'];
            break;
          default:
            break;
        }
      });
      this.setState({
        menuList: filist.menuList,
      });
      window.localStorage.setItem('menuInit', 'success');
    } else {
      //header若未取到 Menu清單，則重新更新Header
      this.forceUpdate();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>企業用戶專區</title>
        </Helmet>
        <div className='bg-img'>
          <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
          <div className='container'>
            <div className='row'>
              <div className='container bg-white shadow pl-4 pr-4 rulebox w-100'>
                <div className='title pt-2 mt-5 ml-3'>企業用戶專區</div>
                <div className='p-2 ml-4 mr-4'>
                  <div className='mb-4 '>親愛的遠傳企業用戶專區（ECP）用戶您好：</div>
                  {this.state.notice != null ? (
                    <div dangerouslySetInnerHTML={{ __html: this.state.notice }}></div>
                  ) : null}
                  <div className='h5 mt-5 mb-4 ml-2 is-text-bold darkBlue'>
                    歡迎使用遠傳電信企業用戶專區服務，我們提供的服務內容如下說明，
                    <br />
                    您可
                    <span className='red'>點選上方功能列表或點選以下服務說明之文字連結</span>
                    ，進行後續作業，謝謝您對本公司服務的支持
                  </div>
                  <div className='row'>
                    {this.state.menuList.length > 0
                      ? this.state.menuList.map((option, index) => (
                          <div
                            className='fui-card card-3 m-2'
                            key={'indexPage' + index}
                            onClick={() => (window.location.href = option.uri)}>
                            <div className='fui-card-caption'>
                              <div className='fui-card-content'>
                                <div className='fui-card-title'>{option.menuName}</div>
                                <div className='mt-3 mb-3 ml-2'>{option.pageList}</div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Index);
