import React from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class LabelInput extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      submitted: false,
      isInvalid: false,
      required: this.props.required || false,
      placeholder: this.props.placeholder || '',
      maxLength: this.props.maxLength || 200,
    };

    this.resetInput = this.resetInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.input.current.value = this.props.getValue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getValue() !== this.props.getValue()) {
      this.input.current.value = this.props.getValue();
    }
    if (this.props.isFormSubmitted() !== this.state.submitted) {
      this.setState({
        submitted: this.props.isFormSubmitted(),
        isInvalid: this.props.showRequired() || this.props.showError(),
      });
    }
  }

  resetInput() {
    this.input.current.value = '';
    this.props.setValue(null);
    this.props.onChange(this.props.name, this.input.current.value);
  }

  handleChange(event) {
    this.props.setValue(this.input.current.value);
    this.setState({
      submitted: this.props.isFormSubmitted(),
      isInvalid: this.props.showRequired() || this.props.showError(),
    });
    this.props.onChange(this.props.name, this.input.current.value);
  }

  render() {
    const errorMessage = this.props.getErrorMessage();

    return (
      <div
        className={`${this.props.className ? this.props.className : ''} form-group ${
          this.state.isInvalid || this.props.isInvalid ? 'is-invalid' : ''
        }`}>
        <div className='row ai-baseline'>
          {!!this.props.label ? (
            <label
              htmlFor={`input-${this.props.name}`}
              className={`h5 mb-3 ${this.state.required ? 'is-required' : ''}`}>
              {this.props.label}
              &nbsp;
              {/* 如果有英文標題 */}
              {this.props.labelEng ? <span className='black-6E6E6E h6 font-bold'>{this.props.labelEng}</span> : null}
            </label>
          ) : (
            ''
          )}
          {!!this.props.hintText ? <span className={`ml-3 red`}>{this.props.hintText}</span> : ''}
        </div>
        <div className='text-input'>
          {/* 判斷如果是帳號密碼維護頁面，則使用特定css，輸入框長度有另外設定 */}
          {this.props.inputType === 'maintainPwdPage' ? (
            <input
              className='text-maintainPwdPage-input'
              onBlur={this.props.onBlur}
              id={`input-${this.props.name}`}
              type={this.props.type != null ? this.props.type : 'text'}
              ref={this.input}
              onChange={this.handleChange}
              onKeyUp={this.handleChange}
              placeholder={this.props.placeholder}
              value={this.props.getValue() || ''}
              maxLength={this.props.maxLength}
              disabled={this.props.disabled}
            />
          ) : (
            <input
              onBlur={this.props.onBlur}
              id={`input-${this.props.name}`}
              type={this.props.type != null ? this.props.type : 'text'}
              ref={this.input}
              onChange={this.handleChange}
              onKeyUp={this.handleChange}
              placeholder={this.props.placeholder}
              value={this.props.getValue() || ''}
              maxLength={this.props.maxLength}
              disabled={this.props.disabled}
            />
          )}
          {this.props.getValue() && !this.props.disabled ? (
            <div className='reset' onMouseDown={this.resetInput}>
              <i className='icon-close'></i>
            </div>
          ) : (
            ''
          )}
        </div>
        {this.state.isInvalid || this.props.failResultMsg != null ? (
          <span className='error-message'>
            {errorMessage}
            {this.props.failResultMsg}
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }
}

LabelInput.propTypes = {
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labelEng: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  failResultMsg: PropTypes.string,
  disabled: PropTypes.bool, // default false
  hintText: PropTypes.string,
  inputType: PropTypes.string,
};

export default withFormsy(LabelInput);
