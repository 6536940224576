import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
import * as callApiUtil from '../../utils/callApiUtil';
import * as emptyUtil from '../../utils/emptyUtil';

class TrusteeInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      step: 1,
      isLoading: false,
      form: {
        authPersonName: { value: '王*明', text: '' },
        personId: { value: 'A12345*789', text: '' },
        birthDate: { value: '1999/11/28', text: '' },
        mobile: { value: '09881**789', text: '' },
        telephone: { value: '0223****789', text: '' },
      },
      mobileNetTrusteeInfo: [], //行網
      landlineTrusteeInfo: [], //固網
      ecpTrusteeInfo: [], //ECP
      //分頁
      headerList: ['姓名', '身分證字號', '生日', '行動電話', '聯絡電話'],
      currentPage: 1,
      maxlength: 10, //一頁幾筆資料
      showMax: 10, //分頁欄顯示頁數
    };
  }
  componentDidMount = () => {
    let url = new URL(window.location.href);
    let encryptedParam = url.searchParams.get('encryptedParam');
    if (!encryptedParam) {
      this.props.history.push(
        {
          pathname: '/basicResultPage',
        },
        {
          type: 'fail',
          pageName: '受任人資料',
          title: '查詢受任人資料失敗',
          description: '查詢資料錯誤，請重新操作',
        }
      );
    } else {
      let vin = { encryptCode: encryptedParam };
      this.setState({ isLoading: true });
      callApiUtil
        .callApi('/ECPAP/WSAPI/ECPTrusteeInfoController/getTrusteeInfo.action', vin, '/ECP/loginPage')
        .then((response) => {
          if (response != null) {
            if (response.resultMessage.message == 's') {
              this.setState({ isLoading: false });
              this.setState({
                mobileNetTrusteeInfo: response.mobileNetTrusteeInfoList,
                ecpTrusteeInfo: response.ecpTrusteeList,
                landlineTrusteeInfo: response.landlineTrusteeInfoList,
              });
            } else {
              //API回傳失敗
              this.props.history.push(
                {
                  pathname: '/basicResultPage',
                },
                {
                  type: 'fail',
                  pageName: '受任人資料',
                  title: '查詢受任人資料失敗',
                  description: response.resultMessage.message,
                }
              );
            }
          } else {
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: 'fail',
                pageName: '受任人資料',
                title: '查詢受任人資料失敗',
              }
            );
          }
        });
    }
  };

  getTitle = (item) => {
    // 先取得最上面說明列
    let result = [];
    for (const [key, value] of Object.entries(item)) {
      result.push(
        <th key={key + value} className='p-3 border-gray keep-all'>
          {value}
        </th>
      );
    }
    return result;
  };

  getItemInfo = (item) => {
    let result = [];
    for (const [key, value] of Object.entries(item)) {
      result.push(
        <td className='p-3 border-gray' key={key + value}>
          {emptyUtil.checkVal(value)}
        </td>
      );

      result.push();
    }

    return result;
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>受任人基本資料</title>
        </Helmet>
        <div className='bg-img pt-10'>
          <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
          <div className='container'>
            <div className='row'>
              <div className='container bg-white col-9 shadow pl-4 pr-4 pb-4'>
                <div className='title pt-2 mt-4 ml-3'>受任人基本資料</div>
                <ScrollContainer className='mb-4 scroll-container' hideScrollbars='false'>
                  {/* 行網受任人 */}
                  <div className='sub-title mb-3 mt-5 is-text-bold'>行網受任人</div>
                  <Table responsive className=' text-center '>
                    <tr className='bg-white'>
                      {/* 先取header */}
                      {this.state.mobileNetTrusteeInfo != null && this.state.mobileNetTrusteeInfo.length > 0 ? (
                        this.getTitle(this.state.headerList)
                      ) : (
                        <td className='p-3 border-gray is-text-bold red'>查無基本資料</td>
                      )}
                    </tr>
                    {this.state.mobileNetTrusteeInfo != null && this.state.mobileNetTrusteeInfo.length > 0
                      ? this.state.mobileNetTrusteeInfo
                          .slice(
                            (this.state.currentPage - 1) * this.state.maxlength,
                            this.state.currentPage * this.state.maxlength
                          )
                          .map((item, idx) => (
                            <tr className='bg-wehit' key={'item' + idx}>
                              {item != null ? this.getItemInfo(item) : null}
                            </tr>
                          ))
                      : null}
                  </Table>

                  {/* 固網受任人 */}
                  <div className='sub-title mb-3 mt-5 is-text-bold'>固網受任人</div>
                  <Table responsive className='text-center '>
                    <tr className='bg-white'>
                      {/* 先取header */}
                      {this.state.landlineTrusteeInfo != null && this.state.landlineTrusteeInfo.length > 0 ? (
                        this.getTitle(this.state.headerList)
                      ) : (
                        <td className='p-3 border-gray is-text-bold red'>查無基本資料</td>
                      )}
                    </tr>
                    {this.state.landlineTrusteeInfo != null && this.state.landlineTrusteeInfo.length > 0
                      ? this.state.landlineTrusteeInfo
                          .slice(
                            (this.state.currentPage - 1) * this.state.maxlength,
                            this.state.currentPage * this.state.maxlength
                          )
                          .map((item, idx) => (
                            <tr className='bg-wehit' key={'item' + idx}>
                              {item != null ? this.getItemInfo(item) : null}
                            </tr>
                          ))
                      : null}
                  </Table>
                  {/* ECP受任人 */}
                  <div className='sub-title mb-3 mt-5 is-text-bold'>ECP受任人</div>
                  <Table responsive className='text-center'>
                    <tr className='bg-white'>
                      {/* 先取header */}
                      {this.state.ecpTrusteeInfo != null && this.state.ecpTrusteeInfo.length > 0 ? (
                        this.getTitle(this.state.headerList)
                      ) : (
                        <td className='p-3 border-gray is-text-bold red'>查無基本資料</td>
                      )}
                    </tr>
                    {this.state.ecpTrusteeInfo != null && this.state.ecpTrusteeInfo.length > 0
                      ? this.state.ecpTrusteeInfo
                          .slice(
                            (this.state.currentPage - 1) * this.state.maxlength,
                            this.state.currentPage * this.state.maxlength
                          )
                          .map((item, idx) => (
                            <tr className='bg-wehit' key={'item' + idx}>
                              {item != null ? this.getItemInfo(item) : null}
                            </tr>
                          ))
                      : null}
                  </Table>
                </ScrollContainer>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TrusteeInfoPage);
