import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Helmet from 'react-helmet';
import { Navigation } from 'react-minimal-side-navigation';
import * as callApiUtil from '../../utils/callApiUtil';
import * as emptyUtil from '../../utils/emptyUtil';

class CustInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.state = {
      // 顯示頁面
      isLoading: 'true',
      page: '',
      custInfo: {
        userNo: '',
        compName: '',
        userId: '',
        ctactName: '',
        ctactEmail: '',
        addrText: '',
        ctactPhoneNo: '',
        ctactFaxNo: '',
      },
      salesInfo: {
        salesName: '',
        phoneNo: '',
        email: '',
      },
      categoryName: '',
    };
  }
  componentDidMount = () => {
    let QueryProdServiceCustInfoVin = {
      userNo: new URLSearchParams(this.props.location.search).get('userNo'),
      fx: new URLSearchParams(this.props.location.search).get('fx'),
    };
    let categoryName = new URLSearchParams(this.props.location.search).get('categoryName');
    // 打API撈取資料
    callApiUtil
      .callApi('/ECPAP/API/ProductController/queryProductServiceCustInfo.action', QueryProdServiceCustInfoVin)
      .then((response) => {
        // 判斷回傳資料
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({
            custInfo: {
              userNo: emptyUtil.checkVal(response.odsAccountInfoBean.userNo),
              compName: emptyUtil.checkVal(response.odsAccountInfoBean.compName),
              userId: emptyUtil.checkVal(response.odsAccountInfoBean.userId),
              ctactName: emptyUtil.checkVal(response.odsAccountInfoBean.ctactName),
              ctactEmail: emptyUtil.checkVal(response.odsAccountInfoBean.ctactEmail),
              addrText: emptyUtil.checkVal(response.odsAccountInfoBean.addrText),
              ctactPhoneNo: emptyUtil.checkVal(response.odsAccountInfoBean.ctactPhoneNo),
              ctactFaxNo: emptyUtil.checkVal(response.odsAccountInfoBean.ctactFaxNo),
            },
            salesInfo: {
              salesName: emptyUtil.checkVal(response.salesInfoBean.salesName),
              phoneNo: emptyUtil.checkVal(response.salesInfoBean.salesPhone),
              email: emptyUtil.checkVal(response.salesInfoBean.salesEmail),
            },
            categoryName: categoryName,
          });
          this.setState({ isLoading: false });
        } else {
          //API回傳失敗
          this.setState({ isLoading: false });
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '產品服務作業查詢',
              title: '查詢失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });
    //將頁面高度傳至iframe鑲嵌頁面
    this.handleClick();
  };

  handleClick = () => {
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  };
  render() {
    return (
      <div className='w-100 p-3'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <div className='title mt-4'>客戶基本資料</div>
        <table className='w-100'>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>產品別</td>
            <td className='w-60 p-3 border-gray'>{this.state.categoryName}</td>
          </tr>
          <tr className='bg-gray'>
            <td colSpan={2}>
              <div className='h2 sub-title mt-5 mb-4 ml-2'>用戶服務基本資料</div>
            </td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>客戶編號/線路編號/CID</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.userNo}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>用戶名稱</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.compName}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>公司統一編號/身分證</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.userId}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>申請人</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.ctactName}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>Email</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.ctactEmail}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>聯絡地址</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.addrText}</td>
          </tr>
          <tr className='bg-gray'>
            <td colSpan={2}>
              <div className='sub-title mt-5 mb-4 ml-2'>帳務基本資料</div>
            </td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>發票抬頭</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.compName}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>發票統一編號</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.userId}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>帳單/發票寄送地址</td>
            <td className='w-60 p-3 border-gray'>{this.state.custInfo.addrText}</td>
          </tr>
          <tr className='bg-gray'>
            <td colSpan={2}>
              <div className='sub-title mt-5 mb-4 ml-2'>業務代表聯絡資料</div>
            </td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>業務代表姓名</td>
            <td className='w-60 p-3 border-gray'>{this.state.salesInfo.salesName}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>聯絡電話</td>
            <td className='w-60 p-3 border-gray'>{this.state.salesInfo.phoneNo}</td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>Email</td>
            <td className='w-60 p-3 border-gray'>{this.state.salesInfo.email}</td>
          </tr>
        </table>
      </div>
    );
  }
}

export default withRouter(CustInfoPage);
