import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';

class AccountManagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      itemList: [],
    };
  }

  componentDidMount() {
    //從session取得menu清單
    let menuInit = window.localStorage.getItem('menuList');
    if (menuInit != null) {
      let menuList = JSON.parse(menuInit);
      let itemList = [];
      menuList.menuList.map((option, index) => {
        if (option.menuId == 'M01') {
          itemList = option.pageList;
          itemList.map((item, index) => {
            switch (item.itemId) {
              case 'I006':
                item.pageList = ['修改或查詢帳號使用者基本資料。'];
                break;
              case 'I011':
                item.pageList = ['變更帳號使用者密碼。'];
                break;
              case 'I016':
                item.pageList = [
                  '設定第二代理人(子帳號)可使用功能，如帳務類及非帳務類的使用者帳號(僅受理受任人、第一代理人進行此項異動申請)。',
                ];
                break;
              case 'I021':
                item.pageList = ['變更公司基本資料申請(僅受理受理受任人、第一代理人進行此項異動申請。'];
                break;
              case 'I031':
                item.pageList = ['申請公司第一代理人變更及基本資料異動(僅受理受任人、第一代理人進行此項異動申請)。'];
                break;
              case 'I071':
                item.pageList = ['提供用戶異動繳費通知設定。'];
                break;
              case 'I026':
                item.pageList = ['申請公司受任人變更及基本資料異動(僅受理受任人進行此項異動申請)。'];
                break;

              case 'I081':
                item.pageList = ['提供受任人上傳服務異動申請書。'];
              default:
                break;
            }
          });
          this.setState({
            itemList: itemList,
          });
        }
      });

      // console.log('testtt' + menuInit);
      // console.log('item' + JSON.stringify(this.state.itemList));
    } else {
      //header若未取到 Menu清單，則重新更新Header
      this.forceUpdate();
    }
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>企業用戶專區</title>
        </Helmet>
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='帳號管理' history={this.props.history}></Sidebar>
              <div className='col-9 pl-4'>
                <div className='row w-100'>
                  {this.state.itemList.length > 0
                    ? this.state.itemList.map((option, index) => (
                        <div className='fui-card w-45 m-2' onClick={() => (window.location.href = option.uri)}>
                          <div className='fui-card-caption shadow'>
                            <div className='fui-card-content'>
                              <div className='fui-card-title'>{option.itemName}</div>
                            </div>
                            <div className='fui-card-extra mt-2'>
                              <div>{option.pageList}</div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AccountManagePage);
