import React from 'react';
import PropTypes from 'prop-types';

//AA Code
import sentAA from '../../../service/WebTrackingService';
import * as IndexService from '../../../service/IndexService';

class Collapse extends React.Component {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.state = {
      contentHeight: 0,
      open: this.props.open || false,
    };

    this.collapseOpen = this.collapseOpen.bind(this);
    this.setContentHeight = this.setContentHeight.bind(this);
  }

  setContentHeight() {
    this.setState({
      contentHeight: this.body.current.clientHeight,
    });
  }

  componentDidMount() {}

  componentDidUpdate(nextProps) {
    if (this.props.onChange && (nextProps.open !== this.state.open || this.props.open !== this.state.open)) {
      this.setState({
        open: this.props.open,
      });
    }
  }

  collapseOpen(e) {
    this.setContentHeight();
    if (this.props.onChange) {
      this.props.onChange(!this.state.open);
    } else {
      this.setState({
        open: !this.state.open,
      });
    }

    // AA code
    sentAA(e);
    this.forceUpdate();
  }

  render() {
    return (
      <div className={`fui-collapse ${this.state.open ? 'is-open' : ''}`}>
        {this.props.date ? <div dangerouslySetInnerHTML={{ __html: this.props.date }}></div> : null}
        <div
          role='button'
          onClick={(e) => this.collapseOpen(e)}
          className={`collapse-header h3`}
          fetkey={this.props.fetkey != null ? IndexService.getFetkeyVariablePrefix() + this.props.fetkey : undefined}
          data-aa-tracking-id={this.props.dataTrackingId}
          dangerouslySetInnerHTML={{ __html: this.props.title }}
        />
        <div
          className='collapse-body'
          style={{
            height: this.state.open ? this.state.contentHeight : 0,
          }}>
          {this.props.content ? (
            <article ref={this.body} dangerouslySetInnerHTML={{ __html: this.props.content }}></article>
          ) : null}
          {this.props.children ? <article ref={this.body}>{this.props.children}</article> : null}
        </div>
      </div>
    );
  }
}

Collapse.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node,
  date: PropTypes.string,
  open: PropTypes.bool,
};

export default Collapse;
