import React from 'react';
import { withRouter } from 'react-router-dom';
import Banner from '../../components/partials/banner/Banner';
import Helmet from 'react-helmet';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import SectionCollapseInfo from '../.././components/partials/collapse/SectionCollapseInfo';
import * as validation from '../../utils/validation';
import * as callApiUtil from '../../utils/callApiUtil';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidate: false,
      userIDErrMsg: '',
      loginIDErrMsg: '',
      emailErrMsg: '',
      form: {
        // 忘記密碼需要輸入的驗證資料
        userId: { value: '', text: '' }, // 統編
        loginID: { value: '', text: '' }, // 帳戶編號
        email: { value: '', text: '' }, // 電子郵件
        validate: { value: '', text: '' }, // 圖形驗證
      },
      isLoading: false,
      captcha: '', // 圖形驗證
      captchaErrmsg: '', // 圖形驗證錯誤訊息
      timer: '',
    };
  }
  componentDidMount() {
    document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
    // 產生圖形驗證
    this.setState({ isLoading: true });
    let GenCaptchaVin = {};
    callApiUtil.callApi('/ECPAP/API/CaptchaController/genCaptcha.action', GenCaptchaVin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState({ captcha: 'data:image/jpg;base64,' + response.captchaImage });
      }
      let timer = setInterval(this.refreshCaptcha, 600000);
      this.setState({ timer: timer, isLoading: false });
    });
  }
  // 將表單輸入的資料加回form參數中
  onChange = (name, value) => {
    let isValid =
      !validation.notEmpty(this.state.form.email.value) &&
      !validation.notEmpty(this.state.form.userId.value) &&
      !validation.notEmpty(this.state.form.loginID.value);
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({ form: newForm, isValidate: isValid });
  };

  // 送出資料到後端申請新密碼
  submit = () => {
    this.setState({ isLoading: true });
    // 驗證資料
    let isValid = this.enableButton();

    // 組vin
    let vin = {
      userID: this.state.form.userId.value,
      loginID: this.state.form.loginID.value,
      email: this.state.form.email.value,
      validateCode: this.state.form.validate.value,
    };

    if (isValid) {
      // call API 重設密碼並寄發認證信
      let message;
      let isSuccess;
      let title;
      callApiUtil.callApi('/ECPAP/API/ResetPwdController/resetPwd.action', vin).then((data) => {
        // 先判斷是否為驗證碼錯誤，若為驗證碼錯誤則不導頁，留在原頁即可
        if (data.resultMessage.code === 'MF_001_020') {
          this.setState({ captchaErrmsg: '驗證碼錯誤 verification code fail' });
          this.setState({ isLoading: false });
        } else {
          if (
            data != null &&
            data != undefined &&
            data.resultMessage != null &&
            data.resultMessage != undefined &&
            data.resultMessage.message == 's'
          ) {
            //API回傳成功
            message =
              `您的新密碼已送至${data.email}，可至「帳號管理-密碼維護」進行密碼修改!` +
              '<br/ >Your new password has sent to your email, please go to 帳號管理-密碼維護」reset your personal password';
            isSuccess = true;
            title = '重設成功' + '<br/ >Reset Done';
          } else {
            message = data.resultMessage.message;
            if (data.resultMessage.code == 'MF_001_002') {
              message = '您輸入的資料錯誤！' + '<br/ >Your input text error';
            }
            //API回傳失敗
            isSuccess = false;
            title = '重設失敗' + '<br/ >Reset fail';
          }
          clearInterval(this.state.timer);
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: isSuccess ? 'successs' : 'fail',
              pageName: '忘記密碼',
              title: title,
              description: message,
              actions: [
                {
                  text: '返回登入頁',
                  link: '/loginPage',
                  btnStyle: 'primary',
                },
              ],
            }
          );
        }
      });
    }
  };

  // 重置輸入的資料
  resetData = () => {
    let newForm = Object.assign(this.state.form);
    newForm = {
      userId: { value: '', text: '' }, // 統編
      loginID: { value: '', text: '' }, // 帳戶編號
      email: { value: '', text: '' }, // 電子郵件
    };
    this.setState({ form: newForm, isValidate: false });
  };

  // 驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;
    let userIDErrMsg = '';
    let loginIDErrMsg = '';
    let emailErrMsg = '';
    let captchaErrmsg = '';
    if (!validation.notEmpty(this.state.form.userId.value)) {
      userIDErrMsg = '請輸入正確的統一編號/其他編號 Please enter Company tax ID / VAT number ';
      isValid = false;
    }

    if (!validation.notEmpty(this.state.form.loginID.value)) {
      loginIDErrMsg = '請輸入正確的帳戶編號 Please enter user id';
      isValid = false;
    }

    if (!validation.email(this.state.form.email.value)) {
      emailErrMsg = '請輸入正確的註冊Email Please enter registered Email';
      isValid = false;
    }

    if (validation.isEmpty(this.state.form.validate.value)) {
      isValid = false;
      captchaErrmsg = '驗證碼不得為空 Please enter validate code';
    }

    this.setState(() => ({
      userIDErrMsg: userIDErrMsg,
      loginIDErrMsg: loginIDErrMsg,
      emailErrMsg: emailErrMsg,
      isValidate: isValid,
      captchaErrmsg: captchaErrmsg,
    }));

    return isValid;
  };

  /** 使用Captcha圖形驗證*/
  refreshCaptcha = () => {
    let GenCaptchaVin = {};
    callApiUtil.callApi('/ECPAP/API/CaptchaController/genCaptcha.action', GenCaptchaVin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState({ captcha: 'data:image/jpg;base64,' + response.captchaImage });
      } else {
        //API回傳失敗 不知道要回傳什麼
      }
    });
  };

  render() {
    const sectionCollapseContent =
      '<div className="row mt-4"><div className="col-6"><span>付費市話專線：02-4499-365</span></div><div className="col-6"><span>電話：02-7715-1661</span></div></div>' +
      '<div className="row mt-4"><div className="col-6"><span>付費國外直播：886-2-7746-5214</span></div><div className="col-6"><span>傳真：02-7715-1661</span></div></div>';

    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>忘記密碼</title>
        </Helmet>
        <Banner title='忘記密碼 Forgot password' />
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <span className='h4 is-text-bold'>
                請輸入帳號所註冊的Email及帳號使用者的行動電話，以確認身份，系統將自動產生一組密碼送至您的Email。
              </span>
              <br />
              <span className='black-6E6E6E h5 font-bold'>
                Please enter the registered Email address of the account and the user's cell phone number to confirm the
                identity. System will automatically generate a password and send it to your Email.
              </span>
            </div>
            <div className='bg-white pb-3'>
              <Formsy onValid={this.enableButton}>
                <Grid container spacing={6} className='mt-md-3'>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      className='mt-3'
                      name='userId'
                      value={this.state.form.userId.value}
                      required={true}
                      placeholder='請輸入統一編號/其他 Please enter Company tax ID / VAT number'
                      label='統一編號/其他'
                      labelEng='Company tax ID / VAT number'
                      onChange={this.onChange}
                      failResultMsg={this.state.userIDErrMsg}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} className='mt-md-3'>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      name='loginID'
                      value={this.state.form.loginID.value}
                      required={true}
                      placeholder='請輸入用戶代號 Please enter user id'
                      label='用戶代號'
                      labelEng='User ID'
                      onChange={this.onChange}
                      failResultMsg={this.state.loginIDErrMsg}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} className='mt-md-3'>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      name='email'
                      value={this.state.form.email.value}
                      required={true}
                      placeholder='請輸入註冊Email Please enter registered email'
                      label='註冊Email'
                      labelEng='Registered Email'
                      onChange={this.onChange}
                      failResultMsg={this.state.emailErrMsg}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} className='mt-md-3'>
                  <Grid item xs={12} md={8}>
                    <div className='no-border ml-5'>
                      <img src={this.state.captcha} className='pl-2' alt='validate'></img>
                      <span className='pl-2 small '>
                        更換驗證碼 Replace verification code
                        <img
                          src={process.env.PUBLIC_URL + '/resources/common/images/icon_reload.png'}
                          className='ml-1 pointer'
                          onClick={() => this.refreshCaptcha()}
                          alt='refresh'></img>
                      </span>
                    </div>
                    <LabelInput
                      className='ml-5 mt-3 mb-2'
                      name='validate'
                      value={this.state.form.validate.value}
                      required={true}
                      // label='密碼'
                      placeholder='請輸入上方驗證碼 Please enter verification code'
                      onChange={this.onChange}
                      failResultMsg={this.state.captchaErrmsg}
                    />
                  </Grid>
                </Grid>
              </Formsy>
              <div className='button-group mt-4 mb-4'>
                <div className='row two-buttons'>
                  <Button
                    className='col-2 ml-5  mt-3'
                    btnStyle='primary'
                    size='large'
                    onClick={this.submit}
                    disabled={!this.state.isValidate}>
                    送出 Send
                  </Button>
                  <Button className='col-1 mt-3' btnStyle='secondary' size='large' onClick={this.resetData}>
                    重設 Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SectionCollapseInfo
          title='聯絡我們'
          content={sectionCollapseContent}
          children=''
          dataTrackingId=''
          fetkey=''
        />
      </React.Fragment>
    );
  }
}

export default withRouter(PasswordReset);
