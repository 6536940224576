import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';

class LandlineVoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeObstaclesContent = (name, value) => {
    this.props.changeObstaclesContent(name, value);
    if (name == 'noc0004') {
      window.addEventListener('mouseOver', this.test);
    }
  };

  changeSelect = (name, data) => {
    this.props.changeSelect(name, data);
  };

  componentDidMount = () => {};

  changeDate = (name, value) => {
    this.props.changeDate('FOC', name, value);
  };

  isValid = () => {
    this.props.validInputData();
  };

  render() {
    return (
      <div>
        <React.Fragment>
          {this.props.showFocForm ? (
            <div>
              {this.props.showForm.foc0001 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='foc0001'
                      value={this.props.obstaclesContent.foc0001}
                      required={true}
                      placeholder='請輸入發話方號碼'
                      label='發話方號碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.foc0001}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.foc0002 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='foc0002'
                      value={this.props.obstaclesContent.foc0002}
                      required={true}
                      placeholder='請輸入受話方號碼'
                      label='受話方號碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.foc0002}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.foc0003 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>撥號時間</label>
                    <div className='row h5 is-text-bold align-items-center'>
                      <Dropdown
                        name='year'
                        className='is-button col-3 pl-0'
                        list={this.props.obstaclesTime.yearList}
                        label={this.props.obstaclesTime.year ? this.props.obstaclesTime.year : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('year', e.value)}
                      />
                      年
                      <Dropdown
                        className='is-button col-3 pl-2'
                        name='month'
                        list={this.props.obstaclesTime.monthList}
                        label={this.props.obstaclesTime.month ? this.props.obstaclesTime.month : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('month', e.value)}
                      />
                      月
                      <Dropdown
                        className='is-button col-3 pl-2'
                        name='day'
                        list={this.props.obstaclesTime.dayList}
                        label={this.props.obstaclesTime.day ? this.props.obstaclesTime.day : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('day', e.value)}
                      />
                      日
                    </div>
                    <div className='row h5 is-text-bold align-items-center'>
                      <Dropdown
                        name='hours'
                        className='is-button col-3 pl-0'
                        list={this.props.obstaclesTime.hoursList}
                        label={this.props.obstaclesTime.hours ? this.props.obstaclesTime.hours : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('hours', e.value)}
                      />
                      時
                      <Dropdown
                        className='is-button col-3 pl-2'
                        name='mins'
                        list={this.props.obstaclesTime.minsList}
                        label={this.props.obstaclesTime.mins ? this.props.obstaclesTime.mins : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('mins', e.value)}
                      />
                      分<span className='error-message'>{this.props.errMsg.foc0003}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.foc0004 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='foc0004'
                      value={this.props.obstaclesContent.foc0004}
                      required={true}
                      placeholder='請輸入有無撥通過'
                      label='有無撥通過'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.foc0004}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.foc0006 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>網路是否可正常連線</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.networkNormalList}
                        label={this.props.obstaclesContent.foc0006 ? this.props.obstaclesContent.foc0006 : '請選擇'}
                        arrow={true}
                        name='foc0006'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('foc0006', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.foc0006}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.foc0007 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='foc0007'
                      value={this.props.obstaclesContent.foc0007}
                      required={true}
                      placeholder='請輸入070電話號碼'
                      label='070電話號碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.foc0007}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.foc0008 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='foc0008'
                      value={this.props.obstaclesContent.foc0008}
                      required={true}
                      placeholder='請輸入登入密碼'
                      label='登入密碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.foc0008}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.foc0009 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='foc0009'
                      value={this.props.obstaclesContent.foc0009}
                      required={true}
                      placeholder='請輸入耳麥/Web Cam設備是否正常'
                      label='耳麥/Web Cam設備是否正常'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.foc0009}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.foc0005 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>障礙狀況/其他補充</label>
                    <div className='row'>
                      <textarea
                        onKeyUp={this.isValid}
                        name='foc0005'
                        className='text-input col-11'
                        defaultValue={this.props.obstaclesContent.foc0005}
                        onChange={(event) => this.changeObstaclesContent(event.target.name, event.target.value)}
                        style={{ resize: 'none', height: '200px ' }}
                      />
                      <span className='error-message'>{this.props.errMsg.foc0005}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          ) : null}
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(LandlineVoiceForm);
