import React from 'react';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import * as validation from '../../../utils/validation';

class ServiceNoSPage extends React.Component {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.state = {
      // 顯示頁面
      prodInfo: [],
      title: '',
    };
  }
  componentDidMount = () => {};

  getItemInfo = (item) => {
    let result = [];
    for (const [key, value] of Object.entries(item)) {
      result.push(
        <React.Fragment>
          <tr className='bg-white' key={key + value}>
            <td className='w-40 text-center border-gray'>{key}</td>
            <td className='w-60 p-3 border-gray'>{value}</td>
          </tr>
        </React.Fragment>
      );
    }
    return result;
  };

  handleClick = () => {
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  };

  render() {
    return (
      <React.Fragment>
        <table className='w-100'>
        <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>線路編號與客戶名稱</td>
            <td className='w-60 p-3 border-gray'>
              {!validation.isEmpty(this.props.title) ? this.props.title : <span>N/A</span>}
            </td>
          </tr>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>MRTG備註</td>
            <td className='w-60 p-3 border-gray'>
              {!validation.isEmpty(this.props.billNotes) ? this.props.billNotes : <span>N/A</span>}
            </td>
          </tr>
        </table>
        {this.props.prodInfo != null && this.props.prodInfo.length > 0 ? (
          this.props.prodInfo.map((item, idx) => (
            <div key={'item' + idx}>
              <div className='title-blue mt-5 h4 is-text-bold'>產品資料</div>
              <Table className='w-100 mt-4'>{item != null ? this.getItemInfo(item) : null}</Table>
            </div>
          ))
        ) : (
          <div>
            <div className='title-blue mt-5 h4 is-text-bold'>產品資料</div>
            <Table responsive className='w-100 mt-4 text-center'>
              <tr className='bg-white'>
                <td className='p-3 border-gray is-text-bold red'>查無產品基本資料，請洽客服人員!</td>
              </tr>
            </Table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ServiceNoSPage);
