import React from 'react';
import PropTypes from 'prop-types';
import * as validation from '../../../utils/validation';
import * as GetFuncNameUtil from '../../../utils/getFunctionNameUtil';

class CheckboxCollapse extends React.PureComponent {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.state = {
      contentHeight: 0,
      open: this.props.open || false,
      title: false,
    };
    this.check = this.check.bind(this);
    this.checkAll = this.checkAll.bind(this);
  }

  componentDidUpdate = () => {
    if (this.body != null && this.body.current != null) {
      this.setState({
        contentHeight: this.body.current.clientHeight,
      });
    }
  };

  collapseOpen = (e) => {
    this.setState(() => ({
      open: !this.state.open,
    }));
  };

  check = (idx) => {
    if (this.props.type == 'billAccount' || this.props.type == 'billFunction') {
      this.props.content[idx].selected = !this.props.content[idx].selected;
    }
    if (this.props.type == 'prod') {
      this.props.content.products[idx].selected = !this.props.content.products[idx].selected;
    }
    this.props.onchange();
  };

  checkAll = () => {
    this.setState({ title: !this.state.title });
    if (this.props.type == 'billAccount' || this.props.type == 'billFunction') {
      for (let index in this.props.content) {
        this.props.content[index].selected = !this.state.title;
      }
    }
    if (this.props.type == 'prod') {
      for (let index in this.props.content.products) {
        this.props.content.products[index].selected = !this.state.title;
      }
    }
    this.props.onchange();
  };

  initCheck = (object) => {
    let result = false;
    if (this.props.type == 'billFunction' || this.props.type == 'billAccount') {
      for (let i in object) {
        if (object[i].selected) {
          result = true;
          break;
        }
      }
    } else if (this.props.type == 'prod') {
      for (let i in object.products) {
        if (object.products[i].selected) {
          result = true;
          break;
        }
      }
    }
    return result;
  };

  render() {
    return (
      <div className={`fui-collapse ${this.state.open ? 'is-open' : ''}`}>
        <div className='row'>
          <div className='col-9'>
            <input type='checkbox' checked={this.initCheck(this.props.content)} onChange={() => this.checkAll()} />
            {' ' + this.props.title}
          </div>
          <div role='button' onClick={(e) => this.collapseOpen(e)} className='collapse-header col-3' />
        </div>
        {this.state.open ? (
          <div
            className='collapse-body'
            style={{
              height: this.state.open ? this.state.contentHeight : 0,
            }}>
            <article ref={this.body}>
              {this.props.type == 'billFunction'
                ? this.props.content.map((object, idx) => (
                    <div key={idx + object.billFunction}>
                      <input
                        type='checkbox'
                        className={`ml-4 ${idx == this.props.content.length - 1 ? '' : 'mb-2'}`}
                        checked={object.selected}
                        onChange={() => this.check(idx)}
                      />
                      {' ' + GetFuncNameUtil.getFunctionName(object.billFunction)}
                      {idx == this.props.content.length - 1 ? null : <br />}
                    </div>
                  ))
                : null}
              {this.props.type == 'billAccount'
                ? this.props.content.map((object, idx) => (
                    <div key={idx + object.billAccountId}>
                      <input
                        type='checkbox'
                        className={`ml-4 ${idx == this.props.content.length - 1 ? '' : 'mb-2'}`}
                        checked={object.selected}
                        onChange={() => this.check(idx)}
                      />
                      {' ' + object.billAccountId}
                      {idx == this.props.content.length - 1 ? null : <br />}
                    </div>
                  ))
                : null}

              {this.props.type == 'prod'
                ? this.props.content.products.map((object, idx) => (
                    <div key={idx + object.userNo}>
                      <input
                        type='checkbox'
                        className={`ml-4 ${idx == this.props.content.products.length - 1 ? '' : 'mb-2'}`}
                        checked={object.selected}
                        onChange={() => this.check(idx)}
                      />
                      {` ${validation.isEmpty(object.userNo) ? '' : object.userNo} (${
                        validation.isEmpty(object.nickName) ? object.name : object.nickName
                      })`}
                      {idx == this.props.content.products.length - 1 ? null : <br />}
                    </div>
                  ))
                : null}
            </article>
          </div>
        ) : null}
      </div>
    );
  }
}

CheckboxCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  open: PropTypes.bool,
};

export default CheckboxCollapse;
