import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Banner from '../../../components/partials/banner/Banner';
import Formsy from 'formsy-react';
import LabelInput from '../../../components/form/LabelInput';
import * as callApiUtil from '../../../utils/callApiUtil';
import * as validation from '../../../utils/validation';
import CommonServiceSidebar from './CommonServiceSidebar';

class EInvoiceService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: '',
      form: {
        accountNo: { value: '', text: '' }, // 用戶帳號
        custId: { value: '', text: '' }, // 統編
        eInvoiceValidate: { value: '', text: '' }, // 圖形驗證碼
      },
      userIdErrmsg: '',
      isValidate: true,
      accountNoErrmsg: '',
      custIdErrmsg: '',
      captchaErrmsg: '',
      hasInvoiceInfo: false,
      invoiceRecordList: [],
      tokenForMore: '',
      infoErrmsg: '',
    };
  }

  //產生驗證碼
  componentDidMount = () => {
    this.setState({ isLoading: true });
    this.setState({ hasInvoiceInfo: false });
    this.setState({ tokenForMore: '' });
    let GenCaptchaVin = {};
    callApiUtil.callApi('/ECPAP/API/CaptchaController/genCaptchaInvoice.action', GenCaptchaVin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState({ captcha: 'data:image/jpg;base64,' + response.captchaImage });
      }
      let timer = setInterval(this.refreshCaptcha, 600000);
      this.setState({ timer: timer, isLoading: false });
    });
    // 紀錄點擊查詢次數
    this.qyeryReceipt();
  };

  //每個欄位在onChange時把value放入form裡面
  onChange = (n, v) => {
    let form = Object.assign(this.state.form);
    form[n].value = v;
    this.setState({
      form: form,
    });
    this.forceUpdate();
    //資料到後端登入失敗後，必須修改前端欄位才能再按登入
    this.setState({ isValidate: true });
  };
  onSubmit = () => {
    //清空後端errorMsg
    this.setState({ infoErrmsg: '' });
    //驗證資料
    let isValid = true;
    let accountNoErrmsg = '';
    let custIdErrmsg = '';
    let captchaErrmsg = '';

    //檢查所有欄位
    if (validation.isEmpty(this.state.form.accountNo.value)) {
      isValid = false;
      accountNoErrmsg = '用戶帳號不得為空';
    }
    if (validation.isEmpty(this.state.form.custId.value)) {
      isValid = false;
      custIdErrmsg = '統編不得為空';
    }
    if (validation.isEmpty(this.state.form.eInvoiceValidate.value)) {
      isValid = false;
      captchaErrmsg = '驗證碼不得為空';
    }
    this.setState(() => ({
      accountNoErrmsg: accountNoErrmsg,
      custIdErrmsg: custIdErrmsg,
      captchaErrmsg: captchaErrmsg,
    }));
    if (isValid) {
      this.setState({ isLoading: true });

      //組成API input Value
      let InvoicePageVin = {
        accountNo: this.state.form.accountNo.value,
        custId: this.state.form.custId.value,
        validateCode: this.state.form.eInvoiceValidate.value,
        beforeDay: 60,
      };
      if (!validation.isEmpty(this.state.tokenForMore)) {
        InvoicePageVin = {
          accountNo: this.state.form.accountNo.value,
          custId: this.state.form.custId.value,
          validateCode: this.state.tokenForMore,
          beforeDay: 180,
        };
      }
      callApiUtil
        .callApi('/ECPAP/API/InvoiceController/eInvoiceServicePage.action', InvoicePageVin)
        .then((response) => {
          if (response.resultMessage.code === 's') {
            //API回傳成功
            //1.將發票清單結果呈現在畫面上
            let invoiceList = response.invoiceData.map((data) => {
              if (data.saleStatusAllowanceList) {
                // console.log(data.saleStatusAllowanceList);
              }
              return {
                invoiceDate: data.invoiceDate,
                invoiceNumber: data.invoiceNumber,
                win: data.win,
                winEndDate: data.winEndDate,
                carrierId: data.carrierId,
                carrierNo: data.carrierNo,
                saleStatusAllowanceList: data.saleStatusAllowanceList,
                // eInvoiceDownloadPdf: 'print online',
                eInvoiceDownloadPdf: '線上列印',
                companyUn: data.companyUn,
                invoiceNumberDownloadDate: data.invoiceNumberDownloadDate,
              };
            });
            console.log(invoiceList);
            this.setState({ hasInvoiceInfo: true });
            //API回傳成功，將資料顯示在頁面上
            this.setState({ invoiceRecordList: invoiceList });
            this.setState({ tokenForMore: response.tokenForMore });
          } else {
            if (response.resultMessage.code == 'QR_002_003') {
              //輸入欄位資訊驗證錯誤or登入資訊錯誤
              this.setState({ infoErrmsg: '查詢資訊有誤' });
            } else if (response.resultMessage.code == 'MF_002_006') {
              this.setState({ infoErrmsg: '請輸入正確的驗證碼' });
            } else {
              this.setState({ infoErrmsg: '發票資訊查詢錯誤' });
            }
            //API回傳失敗
            this.setState({ isLoading: false });
            this.componentDidMount();
          }
        });
    }
  };
  clickToPop = (e, uri) => {
    var content = '';
    //組成API input Value
    let InvoiceContentUEVin = {
      invoiceNumber: e,
    };
    callApiUtil.callApi('/ECPAP/API/InvoiceController/' + uri + '.action', InvoiceContentUEVin).then((response) => {
      if (response.resultMessage.code === 's') {
        //API回傳成功
        //1.將發票清單結果呈現在畫面上
        content = response.content;
        //API回傳成功，將html資料顯示在頁面上
        var pwin = window.open('', '', 'left=0,top=0,height=800,width=1000,toolbar=0,scrollbars=1,status=0');
        pwin.document.write(content);
        pwin.document.close();
        pwin.focus();
      } else {
        //API回傳失敗
        this.setState({ isLoading: false });
      }
    });
  };

  printInvoicePdf = (companyUn, invoiceNumber) => {
    console.log(companyUn, invoiceNumber);
    //組成API input Value
    let InvoiceInfoAndPringPdfVin = {
      companyUn: companyUn,
      invoiceNumber: invoiceNumber,
    };
    console.log(InvoiceInfoAndPringPdfVin);
    callApiUtil
      .callApi('/ECPAP/API/InvoiceController/getInvoiceInfoAndPringPdf.action', InvoiceInfoAndPringPdfVin)
      .then((response) => {
        if (response.resultMessage.code === 's') {
          //API回傳成功
          //1.將發票清單結果呈現在畫面上
          console.log(response.fileName);
          // API回傳成功，將PDF下載到CLIENT端
          let a = document.createElement('a');
          a.href = 'data:application/octet-stream;base64,' + response.pdfString;
          a.download = response.fileName;
          a.click();
        } else {
          //API回傳失敗
          this.setState({ isLoading: false });
        }
      });
  };

  /**判斷環境決定要拿測試/正式環境的Url */
  qyeryReceipt = () => {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/RecordClickController/queryReceiptUrl.action', {}).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        this.setState({ isLoading: false });
        //打開視窗
        // window.open(response.url, '_blank');
        //新增點擊紀錄
        this.clickQueryReceiptRecord();
      } else {
        //API回傳失敗
        console.log(response.resultMessage.message);
        // this.toErrPage(response.resultMessage.message);
      }
    });
  };

  clickQueryReceiptRecord = () => {
    callApiUtil.callApi('/ECPAP/API/RecordClickController/clickQueryReceiptRecord.action', null);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>企業用戶免登入查發票</title>
        </Helmet>
        <Banner title='企業用戶專區' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <CommonServiceSidebar
                className='col-3'
                title='常用服務'
                history={this.props.history}></CommonServiceSidebar>
              <div className='bg-white col-9 shadow p-2 pb-5'>
                <div className='title mt-5 ml-4 mb-2'>企業用戶免登入查發票</div>
                {!this.state.hasInvoiceInfo ? (
                  <Formsy className='pl-2 pr-2 pb-1 pt-4 bg-white shadow'>
                    <div className='row'>
                      {/* 發票查詢 */}
                      <div className='col-5 ml-3 mr-3 mt-0 mb-5 pl-3'>
                        <LabelInput
                          className='ml-1 mb-2 mt-2'
                          name='accountNo'
                          value={this.state.form.accountNo.value}
                          required={true}
                          // label='帳號'
                          placeholder='請輸入用戶帳號'
                          onChange={this.onChange}
                          failResultMsg={this.state.accountNoErrmsg}
                          maxLength='11'
                        />
                        <LabelInput
                          className='ml-1 mb-2 mt-2'
                          name='custId'
                          value={this.state.form.custId.value}
                          required={true}
                          // label='統一編號:'
                          placeholder='請輸入統一編號'
                          onChange={this.onChange}
                          failResultMsg={this.state.custIdErrmsg}
                        />
                        <div className='no-border mt-2'>
                          <img src={this.state.captcha} className='pl-2' alt='eInvoiceValidate'></img>
                          <span className='pl-2 small '>
                            更換驗證碼
                            <img
                              src={process.env.PUBLIC_URL + '/resources/common/images/icon_reload.png'}
                              className='ml-1 pointer'
                              onClick={() => this.componentDidMount()}
                              alt='refresh'></img>
                          </span>
                        </div>
                        <LabelInput
                          className='ml-1 mt-3 mb-2'
                          name='eInvoiceValidate'
                          value={this.state.form.eInvoiceValidate.value}
                          required={true}
                          // label='密碼'
                          placeholder='請輸入上方驗證碼'
                          onChange={this.onChange}
                          failResultMsg={this.state.infoErrmsg == '' ? this.state.captchaErrmsg : this.state.infoErrmsg}
                        />
                        <div className='ml-1 mt-4'>
                          <button
                            className='is-yellow fui-button is-large align-middle w-68'
                            onClick={() => this.onSubmit()}
                            disabled={!this.state.isValidate}>
                            查詢發票
                          </button>
                        </div>
                      </div>
                    </div>
                  </Formsy>
                ) : (
                  <div className='sub-title mt-3 ml-4 p-3 mb-2 shadow'>
                    <table className='ml-1 w-90 text-center'>
                      <tbody>
                        <tr className='bg-white'>
                          <td className='w-10 border-gray gray-block'>發票日期</td>
                          <td className='w-10 border-gray gray-block'>發票號碼</td>
                          <td className='w-10 border-gray gray-block'>中獎註記</td>
                          <td className='w-10 border-gray gray-block'>兌獎期限</td>
                          <td className='w-20 border-gray gray-block'>載具號碼</td>
                          <td className='w-20 border-gray gray-block'>折讓單號</td>
                          <td className='w-20 border-gray gray-block'>電子發票補印</td>
                        </tr>
                        {this.state.invoiceRecordList.map((record, i) => (
                          <tr className='bg-white' key={'invoice' + i}>
                            <td className='w-10 p-3 text-center border-gray'>{record.invoiceDate}</td>
                            <td className='w-10 p-3 text-center border-gray'>
                              <a href='#' onClick={() => this.clickToPop(record.invoiceNumber, 'getInvoiceContentUE')}>
                                {record.invoiceNumber}
                              </a>
                            </td>
                            <td className='w-10 p-3 text-center border-gray'> {record.win}</td>
                            <td className='w-10 p-3 text-center border-gray'>{record.winEndDate}</td>
                            <td className='w-20 p-3 text-center border-gray' title={record.carrierId}>
                              {record.carrierNo}
                            </td>
                            <td className='w-20 p-3 text-center border-gray'>
                              {!validation.isEmpty(record.saleStatusAllowanceList)
                                ? record.saleStatusAllowanceList.map((allowance, j) => (
                                    <a
                                      href='#'
                                      key={'allowance' + j}
                                      onClick={() =>
                                        this.clickToPop(allowance.allowanceNumber, 'getAllowanceContentUE')
                                      }>
                                      {allowance.allowanceNumber}
                                    </a>
                                  ))
                                : ''}
                            </td>
                            <td className='w-20 p-3 text-center border-gray'>
                              <a href='#' onClick={() => this.printInvoicePdf(record.companyUn, record.invoiceNumber)}>
                                {record.eInvoiceDownloadPdf}
                              </a>
                              {record.invoiceNumberDownloadDate}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className={`ml-auto text-italic`}>
                      {validation.isEmpty(this.state.invoiceRecordList) ? <pre className='h5'>查無資料</pre> : ''}

                      {!validation.isEmpty(this.state.tokenForMore) ? (
                        <a className='more-less-icon pointer text_size_12' onClick={() => this.onSubmit()}>
                          <i class='fa fa-angle-double-right' aria-hidden='true'></i>
                          <u>繼續顯示近半年發票資料</u>
                        </a>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className='ml-1 mt-4 mb-5'>
                      <button
                        className='is-yellow fui-button is-large align-middle w-68'
                        onClick={() => this.componentDidMount()}
                        disabled={!this.state.isValidate}>
                        上一步
                      </button>
                    </div>
                  </div>
                )}
                {!this.state.hasInvoiceInfo ? (
                  <div className='mb-2 ml-4 mt-2 font-weight-light text_size_15'>
                    歡迎您使用線上查詢發票服務。
                    <br />
                    公司戶(有統編)：請於『用戶帳號』欄位，輸入用戶(客戶)帳號，說明如下：
                    <br />
                    (1) 行網/固網/雲端服務：請輸入數字(前9碼) 。
                    <br />
                    (2) 寬頻服務：請輸入統編或英數(8碼)-流水編號(2碼)）共計11碼。
                    <br />
                    公司戶(無統編)：查詢發票資訊，須請移至「登入」頁面，完成登入後至帳務資訊進行查詢。
                  </div>
                ) : (
                  <div className='mb-2 ml-4 mt-2 green font-weight-light text_size_15'>
                    ※ 貼心叮嚀：
                    <br />
                    1. 據財政部令 台財資字第1012201146號
                    令之「電子發票實施作業要點」，於遠傳消費開立之統一發票將由財稅中心託管方式，不主動寄送，相關細節請參考電子發票說明FAQ。
                    <br />
                    2. 公司用戶營業稅申報不須附帳單和電子發票紙本，申報方式請見官網說明。
                    <br />
                    3.
                    個人用戶中獎通知：當期發票開獎後，未索取紙本的電子發票證明聯，本公司系統將自動對獎，於開獎日翌日起10天內，「以簡訊、電子郵件或紙本方式通知中獎訊息，請持該期帳單載具號碼至7-11、全家、萊爾富、OK四大超商的多媒體機台列印中獎發票。」
                    <br />
                    4. 電子發票證明聯補印僅供參考，無法用於兌領獎使用。
                    <br />
                    5. 折讓單：
                    <a
                      href={process.env.PUBLIC_URL + '/resources/common/pdf/DebitNoteFile.pdf'}
                      target='_blank'
                      rel='noreferrer'>
                      下載
                    </a>
                    。
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(EInvoiceService);
