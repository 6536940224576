import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Banner from '../../../components/partials/banner/Banner';
import CommonServiceSidebar from './CommonServiceSidebar';

class TestSpeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>線上測速</title>
        </Helmet>
        <Banner title='企業用戶專區' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <CommonServiceSidebar
                className='col-3'
                title='常用服務'
                history={this.props.history}></CommonServiceSidebar>
              <div className='bg-white col-9 shadow p-2 pb-5'>
                <div className='title mt-5 ml-4'>線上測速</div>
                <div className='sub-title ml-4 pl-3 font-weight-bold'>測試連線速率</div>
                <div className='ml-3 mt-3'>
                  <ol className='m-auto p-2 w-90'>
                    <div className='mb-3 ml-1'>說明及注意事項：</div>
                    <li className='ml-4 p-2'>本測速網頁測試速率最大可提供至寬頻 上行1MB 下行12MB。 </li>
                    <li className='ml-4 p-2'>請在單台電腦連線的狀況下進行速率測試，多台電腦上網將造成測試數值誤差。</li>
                    <li className='ml-4 p-2'>
                      測試速率因本網站當時造訪人次、網路壅塞程度以及個人電腦執行網路應用程式的多寡而有所誤差，建議
                      您先關閉電腦中其他網路相關應用程式，再進行速率測試。
                    </li>
                    <li className='ml-4 p-2'>本網頁限用IE執行，目前尚未支援其他瀏覽器測試速率，敬請見諒。</li>
                    <li className='ml-4 p-2'>
                      本網頁所測出之連線速率單位為kbps，若需換算為KB，請將測試結果數值除以8。
                    </li>
                    <li className='ml-4 p-2'>各速率理想參考值：</li>
                    <table className='w-100 ml-2 mt-2 mb-5'>
                      <tr>
                        <td className='text-center border-gray gray-block'>56K撥接</td>
                        <td className='text-center border-gray'>33.6kbps ~ 50kbps</td>
                      </tr>
                      <tr>
                        <td className='text-center border-gray gray-block'>256寬頻</td>
                        <td className='text-center border-gray'>170kbps ~ 210kbps</td>
                      </tr>
                      <tr>
                        <td className='text-center border-gray gray-block'>512寬頻</td>
                        <td className='text-center border-gray'>300kbps ~ 463kbps</td>
                      </tr>
                      <tr>
                        <td className='text-center border-gray gray-block'>1M寬頻</td>
                        <td className='text-center border-gray'>600kbps ~ 927kbps</td>
                      </tr>
                      <tr>
                        <td className='text-center border-gray gray-block'>2M寬頻</td>
                        <td className='text-center border-gray'>1200kbps ~ 1854kbps</td>
                      </tr>
                      <tr>
                        <td className='text-center border-gray gray-block'>3M寬頻</td>
                        <td className='text-center border-gray'>1600kbps ~ 2782kbps</td>
                      </tr>
                      <tr>
                        <td className='text-center border-gray gray-block'>8M寬頻</td>
                        <td className='text-center border-gray'>1200kbps ~ 6400kbps</td>
                      </tr>
                      <tr>
                        <td className='text-center border-gray gray-block'>12M寬頻</td>
                        <td className='text-center border-gray'>1200kbps ~ 9600kbps</td>
                      </tr>
                    </table>
                    <li className='mt-5 ml-4 p-2'>
                      因ADSL服務受距離與環境限制，所有速率均不保證頻寬。EX：少數1M或2M的客戶因上述限制，最高僅可
                      達512K或1.5M。此外，因8M/640K及12M/1M，目前所知是以實際可傳送之最大速率提供服務，下行最高
                      傳輸速率介於2M~8M及2M~12M之間。
                    </li>
                    <li className='ml-4 p-2'>
                      直接下載檔案方式進行速率測試，所測出之連線速率單位即為KB， 若您欲利用各速率理想參考值進行比
                      對，請將測試結果之數值乘以8即可換算為kbps。
                    </li>
                  </ol>
                  <div className='text-center p-5'>
                    <button
                      className='is-primary fui-button is-large align-middle'
                      onClick={() => {
                        window.open(
                          'http://my.seed.net.tw/seedapps/speed_test.shtml',
                          '_blank',
                          'height=800, width=800, resizable=0'
                        );
                      }}>
                      測試上傳速率
                    </button>
                    <button
                      className='is-primary fui-button is-large align-middle'
                      onClick={() => {
                        window.open('http://pftest3.seed.net.tw/', '_blank', 'height=800, width=800, resizable=0');
                      }}>
                      測試下載速率
                    </button>
                  </div>
                  <div className='ml-5 mb-3 p-2'>
                    以FTP方式測試速率
                    <a
                      className='pointer'
                      onClick={() => {
                        window.open(
                          'http://rock.seed.net.tw/service/speedTest2.htm',
                          '_blank',
                          'height=800, width=1000, resizable=0'
                        );
                      }}
                      href='#'
                      rel='noreferrer'>
                      (請按此)
                    </a>
                  </div>
                  <div className='ml-5 p-2 mb-3'>
                    備註：本網頁提供您目前連線速率的參考值，您可選直接下載檔案做更精確的測試。
                  </div>
                  <ul className='p-2 ml-4 mb-1'>
                    <li className='ml-5 p-3'>
                      <a
                        className='pointer'
                        onClick={() => {
                          window.open(
                            'ftp://pftest3.seed.net.tw/20m.dat',
                            '_blank',
                            'height=800, width=800, resizable=0'
                          );
                        }}
                        href='#'>
                        <u>下載測試檔案(檔案大小 20MB)</u>
                      </a>
                    </li>
                    <li className='ml-5 p-3'>
                      <a
                        className='pointer'
                        onClick={() => {
                          window.open(
                            'ftp://pftest3.seed.net.tw/40m.dat',
                            '_blank',
                            'height=800, width=800, resizable=0'
                          );
                        }}
                        href='#'>
                        <u>下載測試檔案(檔案大小 40MB)</u>
                      </a>
                    </li>
                    <li className='ml-5 p-3'>
                      <a
                        className='pointer'
                        onClick={() => {
                          window.open(
                            'ftp://pftest3.seed.net.tw/100m.dat',
                            '_blank',
                            'height=800, width=800, resizable=0'
                          );
                        }}
                        href='#'>
                        <u>下載測試檔案(檔案大小 100MB)</u>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TestSpeed);
