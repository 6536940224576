import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid, ThemeProvider } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';

class DataNetworkForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeObstaclesContent = (name, value) => {
    this.props.changeObstaclesContent(name, value);
  };

  changeSelect = (name, data) => {
    this.props.changeSelect(name, data);
  };

  validUserNo = () => {
    this.props.validUserNo();
  };

  changeDate = (name, value) => {
    this.props.changeDate('NOC', name, value);
  };

  isValid = () => {
    this.props.validInputData();
  };

  render() {
    return (
      <div>
        <React.Fragment>
          {this.props.showNocForm ? (
            <div>
              {this.props.showForm.noc0006 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0006'
                      value={this.props.obstaclesContent.noc0006}
                      required={true}
                      placeholder='請輸入線路號碼'
                      label='線路號碼(Seednet客戶請填客戶編號)'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0006}
                      onBlur={this.validUserNo}
                    />
                    <span className='pb-3'>
                      {' '}
                      不知線路編號/電話號碼；請直接聯絡企業客服中心Tel：4499-365(手機撥打請加02)
                    </span>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0001 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0001'
                      value={this.props.obstaclesContent.noc0001}
                      required={true}
                      placeholder='請輸入IP位址及裝機地址'
                      label='IP位址及裝機地址'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0001}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0002 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0002'
                      value={this.props.obstaclesContent.noc0002}
                      required={true}
                      placeholder='請輸入數據機燈號'
                      label='數據機燈號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0002}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0004 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0004'
                      value={this.props.obstaclesContent.noc0004}
                      required={true}
                      placeholder='請輸入專線編號'
                      label='專線編號'
                      onChange={this.changeObstaclesContent}
                      onBlur={this.validUserNo}
                      failResultMsg={this.props.errMsg.noc0004}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0005 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0005'
                      value={this.props.obstaclesContent.noc0005}
                      required={true}
                      placeholder='請輸入裝機地址'
                      label='裝機地址'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0005}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0003 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>障礙發生時間</label>
                    <div className='row h5 is-text-bold align-items-center'>
                      <Dropdown
                        name='year'
                        className='is-button col-3 pl-0'
                        list={this.props.obstaclesTime.yearList}
                        label={this.props.obstaclesTime.year ? this.props.obstaclesTime.year : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('year', e.value)}
                      />
                      年
                      <Dropdown
                        className='is-button col-3 pl-2'
                        name='month'
                        list={this.props.obstaclesTime.monthList}
                        label={this.props.obstaclesTime.month ? this.props.obstaclesTime.month : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('month', e.value)}
                      />
                      月
                      <Dropdown
                        className='is-button col-3 pl-2'
                        name='day'
                        list={this.props.obstaclesTime.dayList}
                        label={this.props.obstaclesTime.day ? this.props.obstaclesTime.day : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('day', e.value)}
                      />
                      日
                    </div>
                    <div className='row h5 is-text-bold align-items-center'>
                      <Dropdown
                        name='hours'
                        className='is-button col-3 pl-0'
                        list={this.props.obstaclesTime.hoursList}
                        label={this.props.obstaclesTime.hours ? this.props.obstaclesTime.hours : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('hours', e.value)}
                      />
                      時
                      <Dropdown
                        className='is-button col-3 pl-2'
                        name='mins'
                        list={this.props.obstaclesTime.minsList}
                        label={this.props.obstaclesTime.mins ? this.props.obstaclesTime.mins : '--'}
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeDate('mins', e.value)}
                      />
                      分<span className='error-message'>{this.props.errMsg.noc0003}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0007 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0007'
                      value={this.props.obstaclesContent.noc0007}
                      required={true}
                      placeholder='請輸入卡機錯誤訊息'
                      label='卡機錯誤訊息'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0007}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0008 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0008'
                      value={this.props.obstaclesContent.noc0008}
                      required={true}
                      placeholder='請輸入登入網址/帳號/密碼'
                      label='登入網址/帳號/密碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0008}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0009 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0009'
                      value={this.props.obstaclesContent.noc0009}
                      required={true}
                      placeholder='請輸入錯誤訊息'
                      label='錯誤訊息'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0009}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0010 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0010'
                      value={this.props.obstaclesContent.noc0010}
                      required={true}
                      placeholder='請輸入所在機房'
                      label='所在機房'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0010}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0011 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0011'
                      value={this.props.obstaclesContent.noc0011}
                      required={true}
                      placeholder='請輸入發信帳號'
                      label='發信帳號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0011}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0012 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0012'
                      value={this.props.obstaclesContent.noc0012}
                      required={true}
                      placeholder='請輸入收信帳號'
                      label='收信帳號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0012}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0013 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0013'
                      value={this.props.obstaclesContent.noc0013}
                      required={true}
                      placeholder='請輸入錯誤訊息'
                      label='錯誤訊息'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0013}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0014 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0014'
                      value={this.props.obstaclesContent.noc0014}
                      required={true}
                      placeholder='請輸入POP3及SMTP似服器設定'
                      label='POP3及SMTP似服器設定'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0014}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0015 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0015'
                      value={this.props.obstaclesContent.noc0015}
                      required={true}
                      placeholder='請輸入域名帳號'
                      label='域名帳號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0015}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0016 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0016'
                      value={this.props.obstaclesContent.noc0016}
                      required={true}
                      placeholder='請輸入域名帳號/網址'
                      label='域名帳號/網址'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0016}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0017 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0017'
                      value={this.props.obstaclesContent.noc0017}
                      required={true}
                      placeholder='請輸入網頁錯誤訊息'
                      label='網頁錯誤訊息'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0017}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0019 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='noc0019'
                      value={this.props.obstaclesContent.noc0019}
                      required={true}
                      placeholder='請輸入裝機地址'
                      label='裝機地址'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.noc0019}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.noc0018 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>障礙狀況/其他補充</label>
                    <div className='row'>
                      <textarea
                        onKeyUp={this.isValid}
                        name='noc0018'
                        className='text-input col-11'
                        defaultValue={this.props.obstaclesContent.noc0018}
                        onChange={(event) => this.changeObstaclesContent(event.target.name, event.target.value)}
                        style={{ resize: 'none', height: '200px ' }}
                      />
                      <span className='error-message'>{this.props.errMsg.noc0018}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          ) : null}
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(DataNetworkForm);
