import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faPeopleArrows, faUserMinus } from '@fortawesome/free-solid-svg-icons';

class MaintainSubCustAccountInfoPage extends React.Component {
  render() {
    const items = [
      {
        title: '基本資料維護',
        itemId: '/maintainAccountInfoPage',
      },
      {
        title: '帳號密碼維護',
        itemId: '/maintainPasswordPage',
      },
      {
        title: '第二代理人(子帳號)權限管理',
        itemId: '/maintainSubCustAccountInfoPage',
      },
      {
        title: '公司基本資料異動申請',
        itemId: '/maintainCorpInfoPage',
      },
      {
        title: '第一代理人(管理者)變更及基本資料異動申請',
        itemId: '/maintainFirstAgentPage',
      },
    ];
    return (
      <React.Fragment>
        <Helmet>
          <title>第二代理人(子帳號)權限管理</title>
        </Helmet>
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' items={items} title='帳號管理' history={this.props.history}></Sidebar>
              <div className='col-9 pl-4'>
                <div className='row w-100'>
                  <div
                    className='fui-card w-100 m-2'
                    onClick={() => {
                      this.props.history.push('/maintainSubCustAccountInfoPage/subCustAccountCreatePage');
                    }}>
                    <div className='fui-card-caption shadow'>
                      <div className='fui-card-content d-flex align-items-center mb-4'>
                        <div className='icon-circle col-2 ml-4'>
                          <FontAwesomeIcon icon={faUserPlus} size={'3x'} color='#5f7b80' />
                        </div>
                        <div className='col-10'>
                          <div className='row fui-card-title ml-5 mt-3'>新增第二代理人(子帳號)</div>
                          <div className='row fui-card-extra ml-4 mt-3'>新增第二代理人帳戶設定。</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row w-100'>
                  <div
                    className='fui-card w-100 m-2'
                    onClick={() => {
                      this.props.history.push('/maintainSubCustAccountInfoPage/subCustAccountUpdatePage');
                    }}>
                    <div className='fui-card-caption shadow'>
                      <div className='fui-card-content d-flex align-items-center mb-4'>
                        <div className='icon-circle col-2 ml-4'>
                          <FontAwesomeIcon icon={faPeopleArrows} size={'3x'} color='#5f7b80' />
                        </div>
                        <div className='col-10'>
                          <div className='row fui-card-title ml-5 mt-3'>第二代理人(子帳號)權限管理</div>
                          <div className='row fui-card-extra ml-4 mt-3'>
                            設定第二代理人(子帳號)的類別/權限管理，及基本資料。
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row w-100'>
                  <div
                    className='fui-card w-100 m-2'
                    onClick={() => {
                      this.props.history.push('/maintainSubCustAccountInfoPage/subCustAccountSuspendPage');
                    }}>
                    <div className='fui-card-caption shadow'>
                      <div className='fui-card-content d-flex align-items-center mb-4'>
                        <div className='icon-circle col-2 ml-4'>
                          <FontAwesomeIcon icon={faUserMinus} size={'3x'} color='#5f7b80' />
                        </div>
                        <div className='col-10'>
                          <div className='row fui-card-title ml-5 mt-3'>停用第二代理人(子帳號)</div>
                          <div className='row fui-card-extra ml-4 mt-3'>停用第二代理人帳戶設定</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MaintainSubCustAccountInfoPage);
