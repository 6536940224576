import React from 'react';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid, Dialog, DialogActions, DialogContent, Checkbox } from '@material-ui/core';
import * as validation from '../../utils/validation';
import * as callApiUtil from '../../utils/callApiUtil';
class ClfyMonitorContactInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Clarify response.result sucess
      RESULT_SUCCESS: 'S',
      // 顯示頁面
      isLoading: false,
      step: 1, //2:異動監控聯絡人 , 3:新增監控聯絡人, 4:異動監控設定
      monitorList: [], //init.撈資料放這
      selectedMonitor: {}, //select了哪筆資料
      hasSelectMonitor: false, //有選擇資料才能click異動button
      isPopOut: false, //確認是否刪除資料的提示視窗
      isValidate: false, //驗證資料, 控制送出按鈕開關
      isResultPopOut: false, //結果視窗
      successPrefix: null, //成功小視窗 前綴字
      // option與錯誤訊息
      hrList: [],
      minList: [],
      noMonitorInfoMsg: '', //若查無主動式監控資料，顯示在畫面上的訊息
      nameErrMsg: '',
      phoneErrMsg: '', //市內電話錯誤提示訊息
      mobileErrMsg: '', //手機電話錯誤提示訊息
      emailErrMsg: '',
      locationErrMsg: '',
      sWarningErrMsg: '', //檢查是否告警
      noticeErrMsg: '', //檢查告警方式
      monitorTimeErrMsg: '',

      result: null, // StoredProcedure 回傳的結果
      description: null, // StoredProcedure 回傳的錯誤訊息

      isTrustee: null, //登入帳號是否是受任人
      sLevel: null, //查詢可知主動式監控的階層: SI, E2E
      sMonitorID: null,

      //新增或修改要填寫的資料
      newForm: {
        //監控設定
        sWarning: null, //告警通知
        sTeleWarning: null,
        sSMSWarning: null,
        sEmailWarning: null,
        //周六
        sStartHour: null,
        sStartMin: null,
        sStopHour: null,
        sStopMin: null,
        //周日
        hStartHour: null,
        hStartMin: null,
        hStopHour: null,
        hStopMin: null,
        //非假日
        wStartHour: null,
        wStartMin: null,
        wStopHour: null,
        wStopMin: null,

        sLocation: null,
        sName: null, //新姓名
        sMonitorIP: null, //監控IP
        sPhone: null, //新市內電話
        sMobile: null, //新手機電話
        sEmail: null, //新信箱
        sRemark: null, //新備註
      },
    };
  }
  onChange = (name, value) => {
    let setValue = true; //是否更新 onChange(name, value) 接到的值
    let newForm = Object.assign(this.state.newForm);
    let monitorTimeErrMsg = '';

    if (name === 'sWarning' && value === '0') {
      // 取消監控告警，重置監控設定
      this.state.newForm.sWarning = '0';
      this.state.newForm.sTeleWarning = false;
      this.state.newForm.sSMSWarning = false;
      this.state.newForm.sEmailWarning = false;
      this.state.newForm.sStartHour = null;
      this.state.newForm.sStartMin = null;
      this.state.newForm.sStopHour = null;
      this.state.newForm.sStopMin = null;
      this.state.newForm.hStartHour = null;
      this.state.newForm.hStartMin = null;
      this.state.newForm.hStopHour = null;
      this.state.newForm.hStopMin = null;
      this.state.newForm.wStartHour = null;
      this.state.newForm.wStartMin = null;
      this.state.newForm.wStopHour = null;
      this.state.newForm.wStopMin = null;
      this.state.newForm.sMonitorIP = null;

      this.setState({
        isValidate: false,
        nameErrMsg: '',
        phoneErrMsg: '',
        mobileErrMsg: '',
        emailErrMsg: '',
        monitorTimeErrMsg: '',
        noticeErrMsg: '',
      });

      setValue = false; //此時已重置資料，故不重給資料
    } else if (name === 'sWarning' && value === '1') {
      // 若為要告警，至少需選擇一種通知方式，因此通知方式給初值 false
      // SI 層告警方式 電話與Email通知僅能設為不告警，又選擇要告警，但不選擇告警方式會使得要告警沒意義，故預設簡訊告警為true
      this.state.newForm.sTeleWarning = false;
      this.state.newForm.sSMSWarning = this.state.sLevel === 'SI' ? true : false;
      this.state.newForm.sEmailWarning = false;
    }

    //若清除監控時間設定的起始時，整列時間重置
    if (this.state.newForm.sStartHour && name === 'sStartHour' && !value) {
      this.state.newForm.sStartMin = null;
      this.state.newForm.sStopHour = null;
      this.state.newForm.sStopMin = null;
    } else if (this.state.newForm.hStartHour && name === 'hStartHour' && !value) {
      this.state.newForm.hStartMin = null;
      this.state.newForm.hStopHour = null;
      this.state.newForm.hStopMin = null;
    } else if (this.state.newForm.wStartHour && name === 'wStartHour' && !value) {
      this.state.newForm.wStartMin = null;
      this.state.newForm.wStopHour = null;
      this.state.newForm.wStopMin = null;
    }

    if (setValue) {
      newForm[name] = value.trim();
      this.setState({ newForm: newForm });
    }

    this.enableButton();
  };

  checkBoxChange = (name) => {
    let newForm = Object.assign(this.state.newForm);
    newForm[name] = !this.state.newForm[name];
    this.enableButton();
    this.setState({ form: newForm });
  };

  selecteMonitor = (monitorInfo) => {
    this.setState({
      selectedMonitor: monitorInfo,
      hasSelectMonitor: true,
    });
  };

  resetNewForm = () => {
    document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
    let newForm = {
      sWarning: null, //告警通知,不告警: 0, 告警: 1
      sTeleWarning: null, // 打後端時轉換為 不告警: '0', 告警: '1'
      sSMSWarning: null,
      sEmailWarning: null,
      //周六
      sStartHour: null,
      sStartMin: null,
      sStopHour: null,
      sStopMin: null,
      //周日
      hStartHour: null,
      hStartMin: null,
      hStopHour: null,
      hStopMin: null,
      //非假日
      wStartHour: null,
      wStartMin: null,
      wStopHour: null,
      wStopMin: null,

      sLocation: null,
      sName: null, //新姓名
      sMonitorIP: null, //監控IP
      sMcontactObject: null, //objId
      sPhone: null, //新市內電話
      sMobile: null, //新手機電話
      sEmail: null, //新信箱
      sRemark: null,
    };

    this.setState({
      newForm: newForm,
      isValidate: false,
      nameErrMsg: '',
      phoneErrMsg: '',
      mobileErrMsg: '',
      emailErrMsg: '',
      sWarningErrMsg: '',
      noticeErrMsg: '',
      monitorTimeErrMsg: '',
    });
  };

  //將後端回應的監控設定轉會成前台需要的格式
  parseClarifyMonitorInfoList = (monitorInfoList) => {
    let monitorList = [];
    let sLevel = '';

    for (const key in monitorInfoList) {
      let monitor = monitorInfoList[key];
      sLevel = monitor.sLevel;
      let newMonitor = {
        sLevel: monitor.sLevel,
        sLocation: monitor.sLocation,
        sMonitorIP: monitor.sMonitorIP,
        sWarning: monitor.sWarning,
        sTeleWarning: monitor.sTeleWarning === '1' ? true : false,
        sSMSWarning: monitor.sSMSWarning === '1' ? true : false,
        sEmailWarning: monitor.sEmailWarning === '1' ? true : false,
        sSaturdayBegin: monitor.sSaturdayBegin,
        sSaturdayEnd: monitor.sSaturdayEnd,
        sHolidayBegin: monitor.sHolidayBegin,
        sHolidayEnd: monitor.sHolidayEnd,
        sWorkingdayBegin: monitor.sWorkingdayBegin,
        sWorkingdayEnd: monitor.sWorkingdayEnd,
        objId: monitor.objId,
        sName: monitor.sName,
        sPriority: monitor.sPriority,
        sSMSWarningFlag: monitor.sSMSWarningFlag,
        sPhone: monitor.sPhone,
        sMobile: monitor.sMobile,
        sEmail: monitor.sEmail,
        sRemark: monitor.sRemark,
      };
      monitorList.push(newMonitor);
    }
    //若為SI層，只有一筆聯絡人，故預先帶入 selectedMonitor, hasSelectMonitor
    this.setState({
      selectedMonitor: sLevel === 'SI' ? monitorList[0] : null,
      hasSelectMonitor: sLevel === 'SI' ? true : false,
      monitorList: monitorList,
    });
  };

  //查詢 Clarify 監控資訊
  queryClarifyMonitor = (sMonitorID) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/getClarifyMonitor.action', {
        sMonitorID: sMonitorID,
        sLevel: this.state.sLevel,
      })
      .then((response) => {
        // console.log('getClarifyMonitor response', response);
        // 查詢過程成功
        if (
          response != null &&
          response !== undefined &&
          response.resultMessage != null &&
          response.resultMessage !== undefined &&
          response.resultMessage.success
        ) {
          this.setState({ isTrustee: response.isTrustee });

          let noMonitorInfoMsg = ''; //若查無資料，要顯示的訊息

          // 判斷 Clarify 回傳的資訊
          if (response.result === this.state.RESULT_SUCCESS && response.sLevel && response.clfyMonitorList) {
            // console.log('response.clfyMonitorList.length', response.clfyMonitorList.length);

            if (response.clfyMonitorList.length === 0) {
              noMonitorInfoMsg = response.isTrustee
                ? '目前無主動監控資料，請點擊新增按鈕進行新增資料'
                : '目前無主動監控資料，僅受任人可以新增主動監控資料';
            }
            this.setState({
              sLevel: response.sLevel,
              noMonitorInfoMsg: noMonitorInfoMsg,
            });
            this.parseClarifyMonitorInfoList(response.clfyMonitorList);
          } else {
            // 畫面顯示CLFY回傳的訊息
            noMonitorInfoMsg = response.description;

            this.setState({
              isResultPopOut: true,
              result: response.result,
              description: response.description,
              noMonitorInfoMsg: noMonitorInfoMsg,
            });
          }
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '主動式監控',
              title: '提示訊息',
              description: response.resultMessage.message,
            }
          );
        }
        this.setState({ isLoading: false });
        this.resetNewForm();
      });
  };

  //新增監控資訊
  insertClarifyMonitor = () => {
    this.setState({ isLoading: true, clickInsertOrUpdate: 'insert' });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/insertClarifyMonitor.action', {
        sMonitorID: this.state.sMonitorID,
        sLevel: this.state.sLevel,
        sMonitorIP: this.state.newForm.sMonitorIP,
        sWarning: this.state.newForm.sWarning,
        //轉換為 Clarify 需要的文字參數
        sTeleWarning: this.state.newForm.sTeleWarning ? '1' : '0',
        sSMSWarning: this.state.newForm.sSMSWarning ? '1' : '0',
        sEmailWarning: this.state.newForm.sEmailWarning ? '1' : '0',
        //將時與分組合，若無選擇監控時間，則使用預設 '00:00'
        sSaturdayBegin: this.state.newForm.sStartHour
          ? this.state.newForm.sStartHour + ':' + this.state.newForm.sStartMin
          : '00:00',
        sSaturdayEnd: this.state.newForm.sStopHour
          ? this.state.newForm.sStopHour + ':' + this.state.newForm.sStopMin
          : '00:00',
        sHolidayBegin: this.state.newForm.hStartHour
          ? this.state.newForm.hStartHour + ':' + this.state.newForm.hStartMin
          : '00:00',
        sHolidayEnd: this.state.newForm.hStopHour
          ? this.state.newForm.hStopHour + ':' + this.state.newForm.hStopMin
          : '00:00',
        sWorkingdayBegin: this.state.newForm.wStartHour
          ? this.state.newForm.wStartHour + ':' + this.state.newForm.wStartMin
          : '00:00',
        sWorkingdayEnd: this.state.newForm.wStopHour
          ? this.state.newForm.wStopHour + ':' + this.state.newForm.wStopMin
          : '00:00',
        sLocation: this.state.newForm.sLocation,
        sName: this.state.newForm.sName,
        sPhone: this.state.newForm.sPhone,
        sMobile: this.state.newForm.sMobile,
        sEmail: this.state.newForm.sEmail,
        sRemark: this.state.newForm.sRemark,
      })
      .then((response) => {
        // console.log('ins response', response);
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          if (response.result === this.state.RESULT_SUCCESS) {
            this.queryClarifyMonitor(this.state.sMonitorID);
            this.setState({ step: 1, hasSelectMonitor: false, successPrefix: '新增' });
          }
          this.setState({ isResultPopOut: true, result: response.result, description: response.description });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '主動式監控',
              title: '新增失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };
  //更新監控資訊
  updateClarifyMonitor = () => {
    this.setState({ isLoading: true });

    callApiUtil
      .callApi('/ECPAP/API/ProductController/updateClarifyMonitor.action', {
        sMonitorID: this.state.sMonitorID,
        sLevel: this.state.sLevel,
        sMonitorIP: this.state.newForm.sMonitorIP ? this.state.newForm.sMonitorIP : null,
        // 若為null表示無修改，給null,若有有給值表示有修改，則轉換 Clarify 需要的文字參數
        sWarning: this.state.newForm.sWarning === null ? null : this.state.newForm.sWarning,
        sTeleWarning: this.state.newForm.sTeleWarning === null ? null : this.state.newForm.sTeleWarning ? '1' : '0',
        sSMSWarning: this.state.newForm.sSMSWarning === null ? null : this.state.newForm.sSMSWarning ? '1' : '0',
        sEmailWarning: this.state.newForm.sEmailWarning === null ? null : this.state.newForm.sEmailWarning ? '1' : '0',
        //將時與分組合，若無選擇監控時間，則為不修改，改給null
        sSaturdayBegin: this.state.newForm.sStartHour
          ? this.state.newForm.sStartHour + ':' + this.state.newForm.sStartMin
          : null,
        sSaturdayEnd: this.state.newForm.sStopHour
          ? this.state.newForm.sStopHour + ':' + this.state.newForm.sStopMin
          : null,
        sHolidayBegin: this.state.newForm.hStartHour
          ? this.state.newForm.hStartHour + ':' + this.state.newForm.hStartMin
          : null,
        sHolidayEnd: this.state.newForm.hStopHour
          ? this.state.newForm.hStopHour + ':' + this.state.newForm.hStopMin
          : null,
        sWorkingdayBegin: this.state.newForm.wStartHour
          ? this.state.newForm.wStartHour + ':' + this.state.newForm.wStartMin
          : null,
        sWorkingdayEnd: this.state.newForm.wStopHour
          ? this.state.newForm.wStopHour + ':' + this.state.newForm.wStopMin
          : null,
        sName: this.state.newForm.sName ? this.state.newForm.sName : null,
        // mcontactObject 與 查詢得到的 objId 為相同資訊，依文件:「ECP_Phase2_Clarify與ECP介接API_v3」中的欄位名稱命名變數
        sMcontactObject: this.state.selectedMonitor.objId,
        sPhone: this.state.newForm.sPhone ? this.state.newForm.sPhone : null,
        sMobile: this.state.newForm.sMobile ? this.state.newForm.sMobile : null,
        sEmail: this.state.newForm.sEmail ? this.state.newForm.sEmail : null,
      })
      .then((response) => {
        // console.log('upd response', response);

        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          if (response.result === this.state.RESULT_SUCCESS) {
            this.queryClarifyMonitor(this.state.sMonitorID);
            this.setState({ step: 1, hasSelectMonitor: false, successPrefix: '更新' });
          }
          this.setState({ isResultPopOut: true, result: response.result, description: response.description });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '主動式監控',
              title: '更新失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });
    this.setState({ isLoading: false });
  };

  //刪除Clarify監控聯絡人
  deleteClarifyMonitor = () => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/deleteClarifyMonitor.action', {
        sMonitorID: this.state.sMonitorID,
        sLevel: this.state.sLevel,
        sMcontactObject: this.state.selectedMonitor.objId,
      })
      .then((response) => {
        // console.log('del response', response);

        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          if (response.result === this.state.RESULT_SUCCESS) {
            this.queryClarifyMonitor(this.state.sMonitorID);
            this.setState({ step: 1, hasSelectMonitor: false, successPrefix: '刪除' });
          }
          this.setState({ isResultPopOut: true, result: response.result, description: response.description });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '主動式監控',
              title: '刪除失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };

  //驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;

    let nameErrMsg = '';
    let phoneErrMsg = '';
    let mobileErrMsg = '';
    let emailErrMsg = '';
    let locationErrMsg = '';
    let sWarningErrMsg = '';
    let noticeErrMsg = '';
    let monitorTimeErrMsg = '';

    if (this.state.step === 2) {
      //至少需異動一項資料，只驗證是否有填寫資料，資料格式交由 Clarify驗證
      if (
        validation.isEmpty(this.state.newForm.sName) &&
        validation.isEmpty(this.state.newForm.sPhone) &&
        validation.isEmpty(this.state.newForm.sMobile) &&
        validation.isEmpty(this.state.newForm.sEmail) &&
        validation.isEmpty(this.state.newForm.sMonitorIP) &&
        this.state.newForm.sWarning === null &&
        !this.state.newForm.sStartHour &&
        !this.state.newForm.hStartHour &&
        !this.state.newForm.wStartHour
      ) {
        nameErrMsg = '若要修改，請至少異動一項資料';
        isValid = false;
      }

      // 若有選擇告警，檢查是否有勾選告警方式
      if (
        this.state.newForm.sWarning === '1' &&
        !this.state.newForm.sTeleWarning &&
        !this.state.newForm.sSMSWarning &&
        !this.state.newForm.sEmailWarning
      ) {
        noticeErrMsg = '請至少選擇一種告警方式';
        monitorTimeErrMsg = '請設定告警時段，如要全天監控請依週六/假日/非假日設定在00:00~24:00';

        isValid = false;
      }

      // 若有選擇告警，需填寫監控時間
      if (this.state.newForm.sWarning === '1') {
        // 判斷若無選擇監控時間，則顯示提醒訊息
        if (
          !(
            this.state.newForm.sStartHour &&
            this.state.newForm.sStartMin &&
            this.state.newForm.sStopHour &&
            this.state.newForm.sStopMin
          ) &&
          !(
            this.state.newForm.hStartHour &&
            this.state.newForm.hStartMin &&
            this.state.newForm.hStopHour &&
            this.state.newForm.hStopMin
          ) &&
          !(
            this.state.newForm.wStartHour &&
            this.state.newForm.wStartMin &&
            this.state.newForm.wStopHour &&
            this.state.newForm.wStopMin
          )
        ) {
          monitorTimeErrMsg = '請設定告警時段，如要全天監控請依週六/假日/非假日設定在00:00~24:00';
          isValid = false;
        } else {
          monitorTimeErrMsg = '';
        }
      }

      //若有填寫手機號碼，驗證格式是否只有數字
      if (this.state.newForm.sMobile && !validation.digit(this.state.newForm.sMobile)) {
        mobileErrMsg = '手機號碼僅能輸入數字';
      }

      // 若有選擇告警方式，先檢查相應聯絡方式是否已有資料，若無再檢查是否有填寫資料
      if (
        this.state.newForm.sTeleWarning === true &&
        validation.isEmpty(this.state.selectedMonitor.sPhone) &&
        validation.isEmpty(this.state.newForm.sPhone)
      ) {
        phoneErrMsg = '已選擇電話告警，請輸入電話';
        isValid = false;
      }
      if (
        this.state.newForm.sSMSWarning === true &&
        validation.isEmpty(this.state.selectedMonitor.sMobile) &&
        validation.isEmpty(this.state.newForm.sMobile)
      ) {
        mobileErrMsg = '已選擇簡訊告警，請輸入手機號碼';
        isValid = false;
      }
      if (
        this.state.newForm.sEmailWarning === true &&
        validation.isEmpty(this.state.selectedMonitor.sEmail) &&
        validation.isEmpty(this.state.newForm.sEmail)
      ) {
        emailErrMsg = '已選擇 E-mail 告警，請輸入 E-mail';
        isValid = false;
      }
    }

    // 驗證新增主動式監控
    if (this.state.step === 3) {
      if (validation.isEmpty(this.state.newForm.sName)) {
        nameErrMsg = '請輸入姓名';
        isValid = false;
      }
      // 2022/09/19 IT通知據點名稱不須讓客戶填寫，故註解此欄位
      // if (this.state.sLevel === 'E2E' && validation.isEmpty(this.state.newForm.sLocation)) {
      //   locationErrMsg = '請輸入據點名稱';
      //   isValid = false;
      // }
      if (!this.state.newForm.sWarning) {
        sWarningErrMsg = '告警方式為必填';
        isValid = false;
      }
      //若有填寫手機號碼，驗證格式是否只有數字
      if (this.state.newForm.sMobile && !validation.digit(this.state.newForm.sMobile)) {
        mobileErrMsg = '手機號碼僅能輸入數字';
      }

      //若有設定告警，至少選一種方式告警與監控時間
      if (this.state.newForm.sWarning === '1') {
        if (!this.state.newForm.sTeleWarning && !this.state.newForm.sSMSWarning && !this.state.newForm.sEmailWarning) {
          // SI 僅能選擇簡訊告警
          noticeErrMsg = '請至少選擇一種告警方式';
          isValid = false;
        }
        // 判斷若無選擇監控時間，則顯示提醒訊息
        if (
          !(
            this.state.newForm.sStartHour &&
            this.state.newForm.sStartMin &&
            this.state.newForm.sStopHour &&
            this.state.newForm.sStopMin
          ) &&
          !(
            this.state.newForm.hStartHour &&
            this.state.newForm.hStartMin &&
            this.state.newForm.hStopHour &&
            this.state.newForm.hStopMin
          ) &&
          !(
            this.state.newForm.wStartHour &&
            this.state.newForm.wStartMin &&
            this.state.newForm.wStopHour &&
            this.state.newForm.wStopMin
          )
        ) {
          monitorTimeErrMsg = '請設定告警時段，如要全天監控請依週六/假日/非假日設定在00:00~24:00';
          isValid = false;
        } else {
          monitorTimeErrMsg = '';
        }
      }

      // 若有選擇告警方式，相應聯絡方式為必填
      if (this.state.newForm.sTeleWarning === true && validation.isEmpty(this.state.newForm.sPhone)) {
        phoneErrMsg = '已選擇電話告警，請輸入電話';
        isValid = false;
      }
      if (this.state.newForm.sSMSWarning === true && validation.isEmpty(this.state.newForm.sMobile)) {
        mobileErrMsg = '已選擇簡訊告警，請輸入手機號碼';
        isValid = false;
      }
      if (this.state.newForm.sEmailWarning === true && validation.isEmpty(this.state.newForm.sEmail)) {
        emailErrMsg = '已選擇 E-mail 告警，請輸入 E-mail';
        isValid = false;
      }
    }

    // 週六
    //若有選擇時間，檢查是否填寫完整
    if (
      (this.state.newForm.sStartHour ||
        this.state.newForm.sStartMin ||
        this.state.newForm.sStopHour ||
        this.state.newForm.sStopMin) &&
      !(
        this.state.newForm.sStartHour &&
        this.state.newForm.sStartMin &&
        this.state.newForm.sStopHour &&
        this.state.newForm.sStopMin
      )
    ) {
      monitorTimeErrMsg = '請完整填寫周六的監控時間';
      isValid = false;
    } else {
      // 檢查是否結束時間早於起始時間
      let sStartHour = parseInt(this.state.newForm.sStartHour);
      let sStartMin = parseInt(this.state.newForm.sStartMin);
      let sStopHour = parseInt(this.state.newForm.sStopHour);
      let sStopMin = parseInt(this.state.newForm.sStopMin);

      if (sStartHour > sStopHour || (sStartHour == sStopHour && sStartMin >= sStopMin)) {
        monitorTimeErrMsg = '周六監控的開始時間請不要晚於結束時間';
        isValid = false;
      }
    }
    // 週日
    //若有選擇時間，檢查是否填寫完整
    if (
      (this.state.newForm.hStartHour ||
        this.state.newForm.hStartMin ||
        this.state.newForm.hStopHour ||
        this.state.newForm.hStopMin) &&
      !(
        this.state.newForm.hStartHour &&
        this.state.newForm.hStartMin &&
        this.state.newForm.hStopHour &&
        this.state.newForm.hStopMin
      )
    ) {
      monitorTimeErrMsg = '請完整填寫周日的監控時間';
      isValid = false;
    } else {
      // 檢查是否結束時間早於起始時間
      let hStartHour = parseInt(this.state.newForm.hStartHour);
      let hStartMin = parseInt(this.state.newForm.hStartMin);
      let hSopHour = parseInt(this.state.newForm.hStopHour);
      let hStopMin = parseInt(this.state.newForm.hStopMin);

      if (hStartHour > hSopHour || (hStartHour == hSopHour && hStartMin >= hStopMin)) {
        monitorTimeErrMsg = '周日監控的開始時間請不要晚於結束時間';
        isValid = false;
      }
    }
    // 非假日
    //若有選擇時間，檢查是否填寫完整
    if (
      (this.state.newForm.wStartHour ||
        this.state.newForm.wStartMin ||
        this.state.newForm.wStopHour ||
        this.state.newForm.wStopMin) &&
      !(
        this.state.newForm.wStartHour &&
        this.state.newForm.wStartMin &&
        this.state.newForm.wStopHour &&
        this.state.newForm.wStopMin
      )
    ) {
      monitorTimeErrMsg = '請完整填寫非假日的監控時間';
      isValid = false;
    } else {
      // 檢查是否結束時間早於起始時間
      let wStartHour = parseInt(this.state.newForm.wStartHour);
      let wStartMin = parseInt(this.state.newForm.wStartMin);
      let wStopHour = parseInt(this.state.newForm.wStopHour);
      let wStopMin = parseInt(this.state.newForm.wStopMin);
      if (wStartHour > wStopHour || (wStartHour == wStopHour && wStartMin >= wStopMin)) {
        monitorTimeErrMsg = '非假日監控的開始時間請不要晚於結束時間';
        isValid = false;
      }
    }

    this.setState(() => ({
      nameErrMsg: nameErrMsg,
      phoneErrMsg: phoneErrMsg,
      mobileErrMsg: mobileErrMsg,
      emailErrMsg: emailErrMsg,
      locationErrMsg: locationErrMsg,
      sWarningErrMsg: sWarningErrMsg,
      noticeErrMsg: noticeErrMsg,
      monitorTimeErrMsg: monitorTimeErrMsg,
      isValidate: isValid,
    }));
  };

  componentDidUpdate() {
    // console.log('this.state.newForm:>>', this.state.newForm);
    // console.log('this.state.selectedMonitor:>>', this.state.selectedMonitor);
  }
  componentDidMount() {
    // 指定該頁面背景顏色為白色
    document.body.style.backgroundColor = 'white';
    // 將頁面高度傳至iframe鑲嵌頁面
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
    //取得userNo
    let userNo = new URLSearchParams(this.props.location.search).get('userNo');
    this.setState({
      sMonitorID: userNo, //監控線路
    });

    this.queryClarifyMonitor(userNo);

    //產生監控時間option
    let hrList = [];
    hrList.push('--');
    for (let i = 0; i <= 24; i++) {
      i = i < 10 ? '0' + i : i;
      hrList.push(i);
    }
    let minList = ['--', '00', '30'];

    this.setState({
      hrList: hrList,
      minList: minList,
    });
  }

  render() {
    return (
      <div className='w-100 p-3'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <React.Fragment>
          <div>
            {this.state.step === 1 ? (
              <div className='w-100 pl-3 pt-3'>
                <div>
                  {/* SI, E2E 皆無設定 */}
                  {!this.state.sLevel ? (
                    <div>
                      <div className='title mt-4 mb-5'>主動監控</div>
                      <div className='sub-title ml-3 mt-4 mb-5'>
                        <span className='red'>*</span>
                        {this.state.noMonitorInfoMsg}
                      </div>
                    </div>
                  ) : null}
                  {/* SI層 */}
                  {this.state.sLevel === 'SI' ? (
                    <div>
                      <div className='title mt-4 mb-5'>主動監控</div>
                      {this.state.monitorList.length === 0 ? (
                        <div className='sub-title ml-3 mt-4 mb-5'>
                          <span className='red'>*</span>
                          {this.state.noMonitorInfoMsg}
                        </div>
                      ) : (
                        <div className='ml-2'>
                          <div className='sub-title mt-4 mb-3'>監控聯絡人</div>

                          <table className='w-100'>
                            <tbody>
                              <tr className='bg-white'>
                                <td className='w-15 p-2 text-center border-gray'>姓名</td>
                                <td className='w-15 p-2 text-center border-gray'>市內電話</td>
                                <td className='w-15 p-2 text-center border-gray'>手機號碼</td>
                                <td className='w-35 p-2 text-center border-gray'>Email</td>
                                <td className='w-20 p-2 text-center border-gray'>備註</td>
                              </tr>
                              {this.state.monitorList.map((info, index) => {
                                return (
                                  <tr className='bg-white' key={'info' + index}>
                                    <td className='w-15 p-2 text-center border-gray'>{info.sName}</td>
                                    <td className='w-15 p-2 text-center border-gray'>
                                      {info.sPhone ? info.sPhone : 'N/A'}
                                    </td>
                                    <td className='w-15 p-2 text-center border-gray'>
                                      {info.sMobile ? info.sMobile : 'N/A'}
                                    </td>
                                    <td className='w-35 p-2 text-center border-gray'>
                                      {info.sEmail ? info.sEmail : 'N/A'}
                                    </td>
                                    <td className='w-20 p-2 text-center border-gray'>
                                      {info.sRemark ? info.sRemark : 'N/A'}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div className='sub-title mt-4 mb-3'>監控設定</div>

                          <table className='w-100'>
                            <tbody>
                              <tr className='bg-white'>
                                <td className='w-25 p-2 text-center border-gray'>據點名稱</td>
                                <td className='w-25 p-2 text-center border-gray'>告警通知</td>
                                <td className='w-25 p-2 text-center border-gray'>監控時段</td>
                                <td className='w-25 p-2 text-center border-gray'>監控IP</td>
                              </tr>
                            </tbody>

                            {this.state.monitorList.map((info, index) => {
                              return (
                                <tbody>
                                  <tr className='bg-white' key={'info' + index}>
                                    <td className='w-25 p-2 text-center border-gray'>
                                      {info.sLocation ? info.sLocation : 'N/A'}
                                    </td>
                                    <td className='w-25 p-2 text-center border-gray'>
                                      {/* SI層要告警，僅有簡訊告警 */}
                                      <div className='mb-2'>{info.sWarning === '1' ? '要告警' : '無告警'}</div>
                                      <div className='mb-2'>{info.sWarning === '1' ? '簡訊告警' : null}</div>
                                    </td>
                                    <td className='w-25 p-2 text-center border-gray'>
                                      <div>
                                        {info.sSaturdayBegin ? (
                                          <div className='mb-2'>
                                            週六:{info.sSaturdayBegin}~{info.sSaturdayEnd}
                                          </div>
                                        ) : null}
                                        {info.sHolidayBegin ? (
                                          <div className='mb-2'>
                                            週日:{info.sHolidayBegin}~{info.sHolidayEnd}
                                          </div>
                                        ) : null}
                                        {info.sWorkingdayBegin ? (
                                          <div>
                                            非假日:{info.sWorkingdayBegin}~{info.sWorkingdayEnd}
                                          </div>
                                        ) : null}
                                      </div>
                                    </td>
                                    <td className='w-25 p-2 text-center border-gray'>
                                      {info.sMonitorIP ? info.sMonitorIP : 'N/A'}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </table>
                        </div>
                      )}
                      <div className='button-group mb-4'>
                        {/* 只有受任人能做新增、修改、刪除等動作 */}
                        {this.state.isTrustee ? (
                          <div className='mt-5'>
                            {this.state.monitorList && this.state.monitorList.length === 0 ? (
                              <Button
                                btnStyle='primary'
                                size='large'
                                fetkey=''
                                onClick={() => {
                                  this.resetNewForm();
                                  this.setState({
                                    step: 3,
                                  });
                                }}>
                                新增
                              </Button>
                            ) : null}
                            {this.state.monitorList && this.state.monitorList.length !== 0 ? (
                              <Button
                                btnStyle='primary'
                                size='large'
                                fetkey=''
                                disabled={!this.state.hasSelectMonitor}
                                onClick={() =>
                                  this.setState({
                                    step: 2,
                                  })
                                }>
                                異動
                              </Button>
                            ) : null}
                            {this.state.monitorList && this.state.monitorList.length !== 0 ? (
                              <Button
                                btnStyle='primary'
                                size='large'
                                disabled={!this.state.hasSelectMonitor}
                                fetkey=''
                                onClick={() =>
                                  this.setState({
                                    isPopOut: true,
                                  })
                                }>
                                刪除
                              </Button>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {/* E2E層 */}
                  {this.state.sLevel === 'E2E' ? (
                    <div>
                      <div className='title mt-4 mb-5'>主動監控</div>
                      {this.state.monitorList.length === 0 ? (
                        <div className='sub-title mt-4 mb-5'>
                          <span className='red'>*</span>目前無監控聯絡人資料，請點擊新增按鈕來新增監控聯絡人資料
                        </div>
                      ) : (
                        <div className='w-100 pl-2'>
                          <div className='sub-title mt-4 mb-3'>監控聯絡人</div>

                          <table className='w-100'>
                            <tbody>
                              <tr className='bg-white'>
                                {/* 只有受任人能做新增、修改、刪除等動作 */}
                                {this.state.isTrustee ? <td className='w-5 p-2 text-center border-gray'></td> : null}
                                <td className='w-8 p-2 text-center border-gray'>
                                  <div>通報</div>
                                  <div>順序</div>
                                </td>
                                <td className='w-15 p-2 text-center border-gray'>姓名</td>
                                <td className='w-17 p-2 text-center border-gray'>市內電話</td>
                                <td className='w-15 p-2 text-center border-gray'>手機號碼</td>
                                <td className='w-27 p-2 text-center border-gray'>Email</td>
                                <td className='w-15 p-2 text-center border-gray'>備註</td>
                              </tr>
                              {this.state.monitorList.map((info, index) => {
                                return (
                                  <tr className='bg-white' key={'info' + index}>
                                    {this.state.isTrustee ? (
                                      <td className='w-5 text-center border-gray'>
                                        <input
                                          type='radio'
                                          name='contact'
                                          id={info.objId}
                                          onClick={() => this.selecteMonitor(info)}
                                        />
                                      </td>
                                    ) : null}
                                    <td className='w-8 p-2 text-center border-gray'>{index + 1}</td>
                                    <td className='w-15 p-2 text-center border-gray'>{info.sName}</td>
                                    <td className='w-17 p-2 text-center border-gray'>
                                      {info.sPhone ? info.sPhone : 'N/A'}
                                    </td>
                                    <td className='w-15 p-2 text-center border-gray'>
                                      {info.sMobile ? info.sMobile : 'N/A'}
                                    </td>
                                    <td className='w-27 p-2 text-center border-gray'>
                                      {info.sEmail ? info.sEmail : 'N/A'}
                                    </td>
                                    <td className='w-15 p-2 text-center border-gray'>
                                      {info.sRemark ? info.sRemark : 'N/A'}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div className='sub-title mt-4 mb-3'>監控設定</div>

                          <table className='w-100'>
                            <tbody>
                              <tr className='bg-white'>
                                <td className='w-25 p-2 text-center border-gray'>據點名稱</td>
                                <td className='w-25 p-2 text-center border-gray'>告警通知</td>
                                <td className='w-25 p-2 text-center border-gray'>監控時段</td>
                                <td className='w-25 p-2 text-center border-gray'>監控IP</td>
                              </tr>

                              <tr className='bg-white'>
                                <td className='w-25 p-2 text-center border-gray'>
                                  {this.state.monitorList[0].sLocation ? this.state.monitorList[0].sLocation : 'N/A'}
                                </td>
                                <td className='w-25 p-2 text-center border-gray'>
                                  <div className='mb-2'>
                                    {this.state.monitorList[0].sWarning === '1' ? '要告警' : '無告警'}
                                  </div>
                                  <div className='mb-1'>
                                    {this.state.monitorList[0].sWarning === '1' &&
                                    this.state.monitorList[0].sTeleWarning
                                      ? '電話告警'
                                      : null}
                                  </div>
                                  <div className='mb-1'>
                                    {this.state.monitorList[0].sWarning === '1' && this.state.monitorList[0].sSMSWarning
                                      ? '簡訊告警'
                                      : null}
                                  </div>
                                  <div className='mb-1'>
                                    {this.state.monitorList[0].sWarning === '1' &&
                                    this.state.monitorList[0].sEmailWarning
                                      ? 'Email告警'
                                      : null}
                                  </div>
                                </td>
                                <td className='w-25 p-2 text-center border-gray'>
                                  <div>
                                    {this.state.monitorList[0].sSaturdayBegin ? (
                                      <div className='mb-2'>
                                        週六:{this.state.monitorList[0].sSaturdayBegin}~
                                        {this.state.monitorList[0].sSaturdayEnd}
                                      </div>
                                    ) : null}
                                    {this.state.monitorList[0].sHolidayBegin ? (
                                      <div className='mb-2'>
                                        週日:{this.state.monitorList[0].sHolidayBegin}~
                                        {this.state.monitorList[0].sHolidayEnd}
                                      </div>
                                    ) : null}
                                    {this.state.monitorList[0].sWorkingdayBegin ? (
                                      <div>
                                        非假日:{this.state.monitorList[0].sWorkingdayBegin}~
                                        {this.state.monitorList[0].sWorkingdayEnd}
                                      </div>
                                    ) : null}
                                  </div>
                                </td>
                                <td className='w-25 p-2 text-center border-gray'>
                                  {this.state.monitorList[0].sMonitorIP ? this.state.monitorList[0].sMonitorIP : 'N/A'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      <div className='button-group mb-4'>
                        {/* 只有受任人能做新增、修改、刪除等動作 */}
                        {this.state.isTrustee ? (
                          <div className='mt-5'>
                            <Button
                              btnStyle='primary'
                              size='large'
                              fetkey=''
                              onClick={() => {
                                this.resetNewForm();
                                this.setState({
                                  step: 3,
                                });
                              }}>
                              新增
                            </Button>
                            <Button
                              btnStyle='primary'
                              size='large'
                              disabled={!this.state.hasSelectMonitor}
                              fetkey=''
                              onClick={() =>
                                this.setState({
                                  step: 2,
                                })
                              }>
                              異動
                            </Button>
                            <Button
                              btnStyle='primary'
                              size='large'
                              disabled={!this.state.hasSelectMonitor}
                              fetkey=''
                              onClick={() =>
                                this.setState({
                                  isPopOut: true,
                                })
                              }>
                              刪除
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {/* 2:異動監控聯絡人 */}
            {this.state.step === 2 ? (
              <div>
                <div className='title mb-5 ml-3 mt-5 is-text-bold'>異動主動監控資料</div>
                {/* SI層 */}
                {this.state.sLevel === 'SI' ? (
                  <Formsy>
                    <div className='button-group p-3'>
                      <table className='w-100'>
                        <tbody>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              姓名
                            </td>
                            <td className='w-60 p-3 border-gray'>{this.state.selectedMonitor.sName}</td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3 is-required'
                                name='sName'
                                value={this.state.newForm.sName}
                                maxLength={20}
                                placeholder='請輸入欲更改的姓名'
                                label=''
                                failResultMsg={this.state.nameErrMsg}
                                onChange={this.onChange}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              市內電話(範例:xx-xxxxxxxx)
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sPhone ? this.state.selectedMonitor.sPhone : 'N/A'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='sPhone'
                                value={this.state.newForm.sPhone}
                                maxLength={20}
                                placeholder='請輸入欲更改的市內電話'
                                label=''
                                onChange={this.onChange}
                                failResultMsg={this.state.phoneErrMsg}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              手機號碼
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sMobile ? this.state.selectedMonitor.sMobile : 'N/A'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='sMobile'
                                maxLength={12}
                                value={this.state.newForm.sMobile}
                                placeholder='請輸入欲更改的手機號碼'
                                label=''
                                onChange={this.onChange}
                                failResultMsg={this.state.mobileErrMsg}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              Email
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sEmail ? this.state.selectedMonitor.sEmail : 'N/A'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='sEmail'
                                maxLength={40}
                                value={this.state.newForm.sEmail}
                                placeholder='請輸入欲更改的Email地址'
                                failResultMsg={this.state.emailErrMsg}
                                label=''
                                onChange={this.onChange}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-20 text-center border-gray backgroundColor-gray'>
                              告警通知
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sWarning === '1' ? '要告警' : '無告警'}
                            </td>
                          </tr>
                          <tr>
                            <td className='w-60 p-3 border-gray'>
                              請選擇欲修改的告警設定
                              <br />
                              <div className='ml-4 mt-3'>
                                <input
                                  type='radio'
                                  className='radio-button'
                                  name='sWarning'
                                  id='noWaring'
                                  value='0'
                                  checked={this.state.newForm.sWarning === '0'}
                                  onChange={(e) => this.onChange('sWarning', e.target.value)}></input>
                                <label htmlFor='noWaring' className='h6'>
                                  不告警
                                </label>
                                <br></br>
                                <input
                                  type='radio'
                                  className='radio-button'
                                  name='sWarning'
                                  value='1'
                                  id='warning'
                                  checked={this.state.newForm.sWarning === '1'}
                                  onChange={(e) => this.onChange('sWarning', e.target.value)}></input>
                                <label htmlFor='warning' className='h6'>
                                  要告警
                                </label>
                                <label className='h6 ml-2'>
                                  <input
                                    disabled={true}
                                    type='checkbox'
                                    name='sSMSWarning'
                                    className='ml-2'
                                    id='sSMSWarning'
                                    checked={this.state.newForm.sSMSWarning}
                                    onClick={() => this.checkBoxChange('sSMSWarning')}
                                  />
                                  簡訊告警
                                </label>
                                {/* <div className='ml-1 error-message'>{this.state.sWarningErrMsg}</div> */}
                                <div className='ml-1 error-message'>{this.state.noticeErrMsg}</div>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='3' className='w-20 text-center border-gray backgroundColor-gray'>
                              監控時段
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              <div className='mt-2'>
                                {/* selectedMonitor */}
                                {this.state.selectedMonitor.sSaturdayBegin ? (
                                  <div className='mt-2'>
                                    週六:{this.state.selectedMonitor.sSaturdayBegin}~
                                    {this.state.selectedMonitor.sSaturdayEnd}
                                  </div>
                                ) : null}
                                {this.state.selectedMonitor.sHolidayBegin ? (
                                  <div className='mt-2'>
                                    週日:{this.state.selectedMonitor.sHolidayBegin}~
                                    {this.state.selectedMonitor.sHolidayEnd}
                                  </div>
                                ) : null}
                                {this.state.selectedMonitor.sWorkingdayBegin ? (
                                  <div className='mt-2'>
                                    非假日:{this.state.selectedMonitor.sWorkingdayBegin}~
                                    {this.state.selectedMonitor.sWorkingdayEnd}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                          <tr></tr>
                          <tr className='bg-white'>
                            <td className='w-60 p-3 border-gray'>
                              請選擇欲修改的監控時段
                              <br />
                              <span className='red'>*全天監控請設定在00:00~24:00</span>
                              <div className='ml-4'>
                                <div className='mt-3 d-flex'>
                                  <div className='col-2 mt-1'>週六</div>
                                  <div className='col-10'>
                                    <select
                                      onClick={(e) => this.onChange('sStartHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.sStartHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('sStartMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.sStartMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='ml-1'>分 ~ </span>
                                    <select
                                      onClick={(e) => this.onChange('sStopHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.sStopHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('sStopMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.sStopMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>分</span>
                                  </div>
                                </div>
                                <div className='d-flex mt-3'>
                                  <div className='col-2 mt-1'>假日</div>
                                  <div className='col-10'>
                                    <select
                                      onClick={(e) => this.onChange('hStartHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.hStartHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('hStartMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.hStartMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='ml-1'>分 ~ </span>
                                    <select
                                      onClick={(e) => this.onChange('hStopHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.hStopHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('hStopMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.hStopMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>分</span>
                                  </div>
                                </div>
                                <div className='d-flex mt-3'>
                                  <div className='col-2 mt-1'>非假日</div>
                                  <div className='col-10'>
                                    <select
                                      onClick={(e) => this.onChange('wStartHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.wStartHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('wStartMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.wStartMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='ml-1'>分 ~ </span>
                                    <select
                                      onClick={(e) => this.onChange('wStopHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.wStopHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('wStopMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.wStopMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>分</span>
                                  </div>
                                </div>
                                <div className='ml-1 error-message'>{this.state.monitorTimeErrMsg}</div>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              監控IP
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sMonitorIP ? this.state.selectedMonitor.sMonitorIP : 'N/A'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3 is-required'
                                name='sMonitorIP'
                                value={this.state.newForm.sMonitorIP}
                                maxLength={15}
                                label=''
                                placeholder='請輸入欲修改的監控IP'
                                onChange={this.onChange}
                                failResultMsg={this.state.monitorIPErrMsg}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Formsy>
                ) : (
                  // E2E層
                  <Formsy>
                    <div className='button-group p-3'>
                      <table className='w-100'>
                        <tbody>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              姓名
                            </td>
                            <td className='w-60 p-3 border-gray'>{this.state.selectedMonitor.sName}</td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3 is-required'
                                name='sName'
                                value={this.state.newForm.sName}
                                maxLength={20}
                                placeholder='請輸入欲更改的姓名'
                                label=''
                                failResultMsg={this.state.nameErrMsg}
                                onChange={this.onChange}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              市內電話(範例:xx-xxxxxxxx)
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sPhone ? this.state.selectedMonitor.sPhone : 'N/A'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='sPhone'
                                value={this.state.newForm.sPhone}
                                maxLength={20}
                                placeholder='請輸入欲更改的市內電話'
                                label=''
                                onChange={this.onChange}
                                failResultMsg={this.state.phoneErrMsg}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              手機號碼
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sMobile ? this.state.selectedMonitor.sMobile : 'N/A'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='sMobile'
                                maxLength={12}
                                value={this.state.newForm.sMobile}
                                placeholder='請輸入欲更改的手機號碼'
                                label=''
                                onChange={this.onChange}
                                failResultMsg={this.state.mobileErrMsg}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              Email
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sEmail ? this.state.selectedMonitor.sEmail : 'N/A'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='sEmail'
                                maxLength={40}
                                value={this.state.newForm.sEmail}
                                placeholder='請輸入欲更改的Email地址'
                                failResultMsg={this.state.emailErrMsg}
                                label=''
                                onChange={this.onChange}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-20 text-center border-gray backgroundColor-gray'>
                              告警通知
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sWarning === '1' ? '要告警' : '無告警'}
                              {this.state.selectedMonitor.sWarning === '1' ? (
                                <div className='mt-3'>
                                  告警方式:
                                  <label className='h6 ml-2'>
                                    <input
                                      disabled={true}
                                      type='checkbox'
                                      className='ml-1'
                                      checked={this.state.selectedMonitor.sTeleWarning}
                                    />
                                    電話告警
                                  </label>
                                  <label className='h6'>
                                    <input
                                      disabled={true}
                                      type='checkbox'
                                      className='ml-2'
                                      checked={this.state.selectedMonitor.sSMSWarning}
                                    />
                                    簡訊告警
                                  </label>
                                  <label className='h6'>
                                    <input
                                      disabled={true}
                                      type='checkbox'
                                      className='ml-2'
                                      checked={this.state.selectedMonitor.sEmailWarning}
                                    />
                                    Email告警
                                  </label>
                                </div>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td className='w-60 p-3 border-gray'>
                              請選擇欲修改的告警設定
                              <br />
                              <div className='ml-4 mt-3'>
                                <input
                                  type='radio'
                                  className='radio-button'
                                  name='sWarning'
                                  id='noWaring'
                                  value='0'
                                  checked={this.state.newForm.sWarning === '0'}
                                  onChange={(e) => this.onChange('sWarning', e.target.value)}></input>
                                <label htmlFor='noWaring' className='h6'>
                                  不告警
                                </label>
                                <br></br>
                                <input
                                  type='radio'
                                  className='radio-button'
                                  name='sWarning'
                                  value='1'
                                  id='warning'
                                  checked={this.state.newForm.sWarning === '1'}
                                  onChange={(e) => this.onChange('sWarning', e.target.value)}></input>
                                {/* <label className='h6'>要告警</label> */}
                                <label className='h6'>
                                  要告警 設定告警方式<span className='red'>(可複選)</span>
                                </label>
                                <div className='ml-4'>
                                  <label className='h6'>
                                    <input
                                      disabled={!this.state.newForm.sWarning || this.state.newForm.sWarning === '0'}
                                      type='checkbox'
                                      name='sTeleWarning'
                                      className='ml-1'
                                      id='sTeleWarning'
                                      value={this.state.newForm.sTeleWarning}
                                      checked={this.state.newForm.sTeleWarning}
                                      onClick={() => this.checkBoxChange('sTeleWarning')}
                                    />
                                    電話告警
                                  </label>
                                  <label className='h6'>
                                    <input
                                      disabled={!this.state.newForm.sWarning || this.state.newForm.sWarning === '0'}
                                      type='checkbox'
                                      name='sSMSWarning'
                                      className='ml-2'
                                      id='sSMSWarning'
                                      checked={this.state.newForm.sSMSWarning}
                                      onClick={() => this.checkBoxChange('sSMSWarning')}
                                    />
                                    簡訊告警
                                  </label>
                                  <label className='h6'>
                                    <input
                                      disabled={!this.state.newForm.sWarning || this.state.newForm.sWarning === '0'}
                                      type='checkbox'
                                      name='sEmailWarning'
                                      className='ml-2'
                                      id='sEmailWarning'
                                      checked={this.state.newForm.sEmailWarning}
                                      onClick={() => this.checkBoxChange('sEmailWarning')}
                                    />
                                    Email告警
                                  </label>
                                  {/* <div className='ml-1 error-message'>{this.state.sWarningErrMsg}</div> */}
                                  <div className='ml-1 error-message'>{this.state.noticeErrMsg}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='3' className='w-20 text-center border-gray backgroundColor-gray'>
                              監控時段
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              <div className='mt-2'>
                                {/* selectedMonitor */}
                                {this.state.selectedMonitor.sSaturdayBegin ? (
                                  <div className='mt-2'>
                                    週六:{this.state.selectedMonitor.sSaturdayBegin}~
                                    {this.state.selectedMonitor.sSaturdayEnd}
                                  </div>
                                ) : null}
                                {this.state.selectedMonitor.sHolidayBegin ? (
                                  <div className='mt-2'>
                                    週日:{this.state.selectedMonitor.sHolidayBegin}~
                                    {this.state.selectedMonitor.sHolidayEnd}
                                  </div>
                                ) : null}
                                {this.state.selectedMonitor.sWorkingdayBegin ? (
                                  <div className='mt-2'>
                                    非假日:{this.state.selectedMonitor.sWorkingdayBegin}~
                                    {this.state.selectedMonitor.sWorkingdayEnd}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                          <tr></tr>
                          <tr className='bg-white'>
                            <td className='w-60 p-3 border-gray'>
                              請選擇欲修改的監控時段
                              <br />
                              <span className='red'>*全天監控請設定在00:00~24:00</span>
                              <div className='ml-4'>
                                <div className='mt-3 d-flex'>
                                  <div className='col-2 mt-1'>週六</div>
                                  <div className='col-10'>
                                    <select
                                      onClick={(e) => this.onChange('sStartHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.sStartHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('sStartMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.sStartMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='ml-1'>分 ~ </span>
                                    <select
                                      onClick={(e) => this.onChange('sStopHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.sStopHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('sStopMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.sStopMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>分</span>
                                  </div>
                                </div>
                                <div className='d-flex mt-3'>
                                  <div className='col-2 mt-1'>假日</div>
                                  <div className='col-10'>
                                    <select
                                      onClick={(e) => this.onChange('hStartHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.hStartHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('hStartMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.hStartMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='ml-1'>分 ~ </span>
                                    <select
                                      onClick={(e) => this.onChange('hStopHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.hStopHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('hStopMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.hStopMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>分</span>
                                  </div>
                                </div>
                                <div className='d-flex mt-3'>
                                  <div className='col-2 mt-1'>非假日</div>
                                  <div className='col-10'>
                                    <select
                                      onClick={(e) => this.onChange('wStartHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.wStartHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('wStartMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.wStartMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='ml-1'>分 ~ </span>
                                    <select
                                      onClick={(e) => this.onChange('wStopHour', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.hrList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.wStopHour == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>時</span>
                                    <select
                                      onClick={(e) => this.onChange('wStopMin', e.target.value)}
                                      disabled={this.state.newForm.sWarning !== '1'}>
                                      {' '}
                                      {this.state.minList.map((value, index) => (
                                        <option
                                          key={index}
                                          selected={this.state.newForm.wStopMin == value}
                                          value={value != '--' ? value : ''}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                    <span className='mr-1 ml-1'>分</span>
                                  </div>
                                </div>
                                <div className='ml-1 error-message'>{this.state.monitorTimeErrMsg}</div>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              監控IP
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.selectedMonitor.sMonitorIP ? this.state.selectedMonitor.sMonitorIP : 'N/A'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3 is-required'
                                name='sMonitorIP'
                                value={this.state.newForm.sMonitorIP}
                                maxLength={15}
                                label=''
                                placeholder='請輸入欲修改的監控IP'
                                onChange={this.onChange}
                                failResultMsg={this.state.monitorIPErrMsg}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Formsy>
                )}
                <div className='mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    fetkey=''
                    onClick={() => this.updateClarifyMonitor()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' size='large' fetkey='' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            {/* 3:新增監控聯絡人 */}
            {this.state.step == 3 ? (
              <div>
                <div className='title mb-5 ml-3 mt-5 is-text-bold'>新增主動監控資料</div>
                {this.state.sLevel === 'SI' ? (
                  <Formsy>
                    <Grid container spacing={3} className='mt-md-3 ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sName'
                          value={this.state.newForm.sName}
                          required={true}
                          maxLength={20}
                          placeholder='請輸入欲新增的聯絡人姓名'
                          label='姓名'
                          onChange={this.onChange}
                          failResultMsg={this.state.nameErrMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sPhone'
                          value={this.state.newForm.sPhone}
                          required={false}
                          maxLength={20}
                          placeholder='請輸入欲新增的市內電話'
                          label='市內電話(範例:xx-xxxxxxxx)'
                          onChange={this.onChange}
                          failResultMsg={this.state.phoneErrMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sMobile'
                          value={this.state.newForm.sMobile}
                          maxLength={12}
                          label='手機號碼'
                          placeholder='請輸入欲新增的手機號碼，格式範例: 0912345678'
                          onChange={this.onChange}
                          failResultMsg={this.state.mobileErrMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sEmail'
                          value={this.state.newForm.sEmail}
                          required={false}
                          label='Email'
                          maxLength={40}
                          placeholder='請輸入欲新增的Email'
                          onChange={this.onChange}
                          failResultMsg={this.state.emailErrMsg}
                        />
                      </Grid>
                    </Grid>
                    {/* 20220919更新--不需讓使用者填寫，直接送null到後端即可 */}
                    {/* <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sLocation'
                          value={this.state.newForm.sLocation}
                          // required={this.state.sLevel !== 'SI'}
                          required={this.state.sLevel === 'E2E' ? true : false}
                          label='據點名稱'
                          maxLength={40}
                          placeholder='請輸入據點名稱'
                          onChange={this.onChange}
                          failResultMsg={this.state.locationErrMsg}
                        />
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 ml-4 is-required font-bold'>告警通知</label>
                        <input
                          type='radio'
                          className='radio-button ml-4'
                          name='sWarning'
                          id='noWaring'
                          value='0'
                          checked={this.state.newForm.sWarning === '0'}
                          onChange={(e) => this.onChange('sWarning', e.target.value)}></input>
                        <label htmlFor='noWaring' className='h5'>
                          不告警
                        </label>
                        <input
                          type='radio'
                          className='radio-button ml-4'
                          name='sWarning'
                          value='1'
                          id='warning'
                          checked={this.state.newForm.sWarning === '1'}
                          onChange={(e) => this.onChange('sWarning', e.target.value)}></input>
                        <label htmlFor='warning' className='h5'>
                          要告警
                        </label>
                        <div className='ml-4 error-message'>{this.state.sWarningErrMsg}</div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1 mt-3'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 ml-4 is-required font-bold'>告警方式</label>
                        <label className='h6 ml-4'>
                          <input
                            disabled={true}
                            type='checkbox'
                            name='sSMSWarning'
                            className='ml-3'
                            id='sSMSWarning'
                            checked={this.state.newForm.sSMSWarning}
                            onClick={() => this.checkBoxChange('sSMSWarning')}
                          />
                          簡訊告警
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 ml-4 is-required font-bold mt-4'>監控時段</label>
                        <div className='ml-4'>
                          <br />
                          <span className='red'>*全天監控請設定在00:00~24:00</span>
                          <div className='mt-3 d-flex'>
                            <div className='col-2 mt-1'>週六</div>
                            <div className='col-10'>
                              <select
                                onClick={(e) => this.onChange('sStartHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.sStartHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('sStartMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.sStartMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='ml-1'>分 ~ </span>
                              <select
                                onClick={(e) => this.onChange('sStopHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.sStopHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('sStopMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.sStopMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>分</span>
                            </div>
                          </div>
                          <div className='d-flex mt-3'>
                            <div className='col-2 mt-1'>假日</div>
                            <div className='col-10'>
                              <select
                                onClick={(e) => this.onChange('hStartHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.hStartHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('hStartMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.hStartMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='ml-1'>分 ~ </span>
                              <select
                                onClick={(e) => this.onChange('hStopHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.hStopHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('hStopMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.hStopMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>分</span>
                            </div>
                          </div>
                          <div className='d-flex mt-3'>
                            <div className='col-2 mt-1'>非假日</div>
                            <div className='col-10'>
                              <select
                                onClick={(e) => this.onChange('wStartHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.wStartHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('wStartMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.wStartMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='ml-1'>分 ~ </span>
                              <select
                                onClick={(e) => this.onChange('wStopHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.wStopHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('wStopMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.wStopMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>分</span>
                            </div>
                          </div>
                          <div className='ml-1 error-message'>{this.state.monitorTimeErrMsg}</div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1 mt-3'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sMonitorIP'
                          value={this.state.newForm.sMonitorIP}
                          required={false}
                          label='監控IP'
                          maxLength={40}
                          placeholder='請輸入欲新增的監控IP'
                          onChange={this.onChange}
                          failResultMsg={this.state.monitorIPErrMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1 mt-3'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sRemark'
                          value={this.state.newForm.sRemark}
                          required={false}
                          label='備註'
                          maxLength={40}
                          placeholder='請輸入備註'
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                  </Formsy>
                ) : (
                  // 新增 E2E
                  <Formsy>
                    <Grid container spacing={3} className='mt-md-3 ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sName'
                          value={this.state.newForm.sName}
                          required={true}
                          maxLength={20}
                          placeholder='請輸入欲新增的聯絡人姓名'
                          label='姓名'
                          onChange={this.onChange}
                          failResultMsg={this.state.nameErrMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sPhone'
                          value={this.state.newForm.sPhone}
                          required={false}
                          maxLength={20}
                          placeholder='請輸入欲新增的市內電話'
                          label='市內電話(範例:xx-xxxxxxxx)'
                          onChange={this.onChange}
                          failResultMsg={this.state.phoneErrMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sMobile'
                          value={this.state.newForm.sMobile}
                          maxLength={12}
                          label='手機號碼'
                          placeholder='請輸入欲新增的手機號碼，格式範例: 0912345678'
                          onChange={this.onChange}
                          failResultMsg={this.state.mobileErrMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sEmail'
                          value={this.state.newForm.sEmail}
                          required={false}
                          label='Email'
                          maxLength={40}
                          placeholder='請輸入欲新增的Email'
                          onChange={this.onChange}
                          failResultMsg={this.state.emailErrMsg}
                        />
                      </Grid>
                    </Grid>
                    {/* 依據 spec 據點名稱為必填 */}
                    {/* <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sLocation'
                          value={this.state.newForm.sLocation}
                          required={this.state.sLevel === 'E2E' ? true : false}
                          label='據點名稱'
                          maxLength={40}
                          placeholder='請輸入據點名稱'
                          onChange={this.onChange}
                          failResultMsg={this.state.locationErrMsg}
                        />
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 ml-4 is-required font-bold'>告警通知</label>
                        <input
                          type='radio'
                          className='radio-button'
                          name='sWarning'
                          id='noWaring'
                          value='0'
                          checked={this.state.newForm.sWarning === '0'}
                          onChange={(e) => this.onChange('sWarning', e.target.value)}></input>
                        <label htmlFor='noWaring' className='h5'>
                          不告警
                        </label>
                        <input
                          type='radio'
                          className='radio-button'
                          name='sWarning'
                          value='1'
                          id='warning'
                          checked={this.state.newForm.sWarning === '1'}
                          onChange={(e) => this.onChange('sWarning', e.target.value)}></input>
                        <label htmlFor='warning' className='h5'>
                          要告警
                        </label>
                        <div className='ml-4 error-message'>{this.state.sWarningErrMsg}</div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1 mt-3'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 ml-4 is-required font-bold'>
                          設定告警方式<span className='red'>(可複選)</span>
                        </label>
                        <div className='ml-4 mt-4'>
                          <label className='h6 ml-2'>
                            <input
                              disabled={!this.state.newForm.sWarning || this.state.newForm.sWarning === '0'}
                              type='checkbox'
                              name='sTeleWarning'
                              id='sTeleWarning'
                              value={this.state.newForm.sTeleWarning}
                              checked={this.state.newForm.sTeleWarning}
                              onClick={() => this.checkBoxChange('sTeleWarning')}
                            />
                            電話告警
                          </label>
                          <label className='h6'>
                            <input
                              disabled={!this.state.newForm.sWarning || this.state.newForm.sWarning === '0'}
                              type='checkbox'
                              name='sSMSWarning'
                              className='ml-3'
                              id='sSMSWarning'
                              checked={this.state.newForm.sSMSWarning}
                              onClick={() => this.checkBoxChange('sSMSWarning')}
                            />
                            簡訊告警
                          </label>
                          <label className='h6'>
                            <input
                              disabled={!this.state.newForm.sWarning || this.state.newForm.sWarning === '0'}
                              type='checkbox'
                              name='sEmailWarning'
                              className='ml-2'
                              id='sEmailWarning'
                              checked={this.state.newForm.sEmailWarning}
                              onClick={() => this.checkBoxChange('sEmailWarning')}
                            />
                            Email告警
                          </label>

                          <div className='ml-1 error-message'>{this.state.noticeErrMsg}</div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <label className='h5 ml-4 is-required font-bold mt-4'>監控時段</label>
                        <div className='ml-4'>
                          <br />
                          <span className='red'>*全天監控請設定在00:00~24:00</span>
                          <div className='mt-3 d-flex'>
                            <div className='col-2 mt-1'>週六</div>
                            <div className='col-10'>
                              <select
                                onClick={(e) => this.onChange('sStartHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.sStartHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('sStartMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.sStartMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='ml-1'>分 ~ </span>
                              <select
                                onClick={(e) => this.onChange('sStopHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.sStopHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('sStopMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.sStopMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>分</span>
                            </div>
                          </div>
                          <div className='d-flex mt-3'>
                            <div className='col-2 mt-1'>假日</div>
                            <div className='col-10'>
                              <select
                                onClick={(e) => this.onChange('hStartHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.hStartHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('hStartMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.hStartMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='ml-1'>分 ~ </span>
                              <select
                                onClick={(e) => this.onChange('hStopHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.hStopHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('hStopMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.hStopMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>分</span>
                            </div>
                          </div>
                          <div className='d-flex mt-3'>
                            <div className='col-2 mt-1'>非假日</div>
                            <div className='col-10'>
                              <select
                                onClick={(e) => this.onChange('wStartHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.wStartHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('wStartMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.wStartMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='ml-1'>分 ~ </span>
                              <select
                                onClick={(e) => this.onChange('wStopHour', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.hrList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.wStopHour == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>時</span>
                              <select
                                onClick={(e) => this.onChange('wStopMin', e.target.value)}
                                disabled={this.state.newForm.sWarning !== '1'}>
                                {' '}
                                {this.state.minList.map((value, index) => (
                                  <option
                                    key={index}
                                    selected={this.state.newForm.wStopMin == value}
                                    value={value != '--' ? value : ''}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              <span className='mr-1 ml-1'>分</span>
                            </div>
                          </div>
                          <div className='ml-1 error-message'>{this.state.monitorTimeErrMsg}</div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1 mt-3'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sMonitorIP'
                          value={this.state.newForm.sMonitorIP}
                          required={false}
                          label='監控IP'
                          maxLength={40}
                          placeholder='請輸入欲新增的監控IP'
                          onChange={this.onChange}
                          failResultMsg={this.state.monitorIPErrMsg}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className=' ml-1 mt-3'>
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          className='ml-4'
                          name='sRemark'
                          value={this.state.newForm.sRemark}
                          required={false}
                          label='備註'
                          maxLength={40}
                          placeholder='請輸入備註'
                          onChange={this.onChange}
                        />
                      </Grid>
                    </Grid>
                  </Formsy>
                )}
                <div className='ml-4 mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    fetkey=''
                    onClick={() => this.insertClarifyMonitor()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' fetkey='' size='large' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            <Dialog
              open={this.state.isPopOut}
              onClose={() => this.setState({ isPopOut: false })}
              fullWidth
              scroll={'paper'}
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>請確認是否要刪除這筆聯絡人資料?</div>
              </DialogContent>
              <DialogActions>
                <Button
                  btnStyle='primary'
                  className='w5 my-2'
                  fetkey=''
                  onClick={() => {
                    this.deleteClarifyMonitor();
                    this.setState({
                      isPopOut: false,
                    });
                  }}
                  autoFocus>
                  確認
                </Button>
                <Button
                  btnStyle='secondary'
                  className='w5 my-2'
                  fetkey=''
                  onClick={() =>
                    this.setState({
                      isPopOut: false,
                    })
                  }
                  autoFocus>
                  取消
                </Button>
              </DialogActions>
            </Dialog>
            {/* 結果視窗 */}
            <Dialog
              open={this.state.isResultPopOut}
              onClose={() => this.setState({ isResultPopOut: false })}
              fullWidth
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>提示訊息</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>
                  {this.state.result === this.state.RESULT_SUCCESS
                    ? this.state.successPrefix + '成功'
                    : this.state.description}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  fetkey=''
                  btnStyle='primary'
                  className='w5 my-2'
                  onClick={() => {
                    this.setState({
                      isResultPopOut: false,
                    });
                  }}
                  autoFocus>
                  關閉
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default ClfyMonitorContactInfoPage;
