import React from 'react';
import { withRouter } from 'react-router-dom';
import LabelInput from '../../components/form/LabelInput';
import { Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';

class MobileInternetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeObstaclesContent = (name, value) => {
    this.props.changeObstaclesContent(name, value);
    this.isValid();
  };

  changeSelect = (name, data) => {
    this.props.changeSelect(name, data);
    this.isValid();
  };

  componentDidMount = () => {};

  isValid = () => {
    this.props.validInputData();
  };

  render() {
    return (
      <div>
        <React.Fragment>
          {this.props.hasObstacleType ? (
            <Grid container spacing={6}>
              <Grid item xs={12} md={10} className='form-group'>
                <label className='h5 ml-0 mb-4 is-text-bold is-required'>障礙類型</label>
                <div className='row  mb-5'>
                  <Dropdown
                    className='is-button col-7 pl-0'
                    list={this.props.problemCategoryList}
                    label={this.props.showProblemCategory.text ? this.props.showProblemCategory.text : '請選擇'}
                    arrow={true}
                    hasCheck={false}
                    onChange={(e) => this.changeSelect('problemCategory', e)}
                  />
                  <span className='error-message'>{this.props.errMsg.problemCategory}</span>
                </div>
              </Grid>
            </Grid>
          ) : null}
          {this.props.showMocForm ? (
            <div>
              {this.props.showForm.moc0002 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0002'
                      value={this.props.obstaclesContent.moc0002}
                      required={true}
                      placeholder='請輸入手機門號'
                      label='手機門號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0002}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0003 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0003'
                      value={this.props.obstaclesContent.moc0003}
                      required={true}
                      placeholder='請輸入發話方電話號碼'
                      label='發話方電話號碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0003}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0004 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0004'
                      value={this.props.obstaclesContent.moc0004}
                      required={true}
                      placeholder='請輸入受話方電話號碼'
                      label='受話方電話號碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0004}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0005 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0005'
                      value={this.props.obstaclesContent.moc0005}
                      required={true}
                      placeholder='請輸入手機廠牌'
                      label='手機廠牌'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0005}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0006 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0006'
                      value={this.props.obstaclesContent.moc0006}
                      required={true}
                      placeholder='請輸入手機型號'
                      label='手機型號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0006}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0007 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>手機訊號格數</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.signalList}
                        label={this.props.obstaclesContent.moc0007 ? this.props.obstaclesContent.moc0007 : '請選擇'}
                        name='moc0007'
                        arrow={true}
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0007', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0007}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0008 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>是否願意讓工程師進入屋內測試</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.defauleOptionList}
                        label={this.props.obstaclesContent.moc0008 ? this.props.obstaclesContent.moc0008 : '請選擇'}
                        arrow={true}
                        name='moc0008'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0008', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0008}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0009 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>發生區域之實際地址或地標</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.happenAreaList}
                        label={this.props.obstaclesContent.moc0009 ? this.props.obstaclesContent.moc0009 : '請選擇'}
                        arrow={true}
                        name='moc0009'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0009', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0009}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0010 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>是否願意提供加設基地台的地點</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.addCellSiteList}
                        label={this.props.showAddCellSite.text ? this.props.showAddCellSite.text : '請選擇'}
                        arrow={true}
                        name='moc0010'
                        hasCheck={false}
                        onChange={(e) => this.changeSelect('moc0010', e)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0010}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0011 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>
                      同一地點是否其他遠傳客戶有同樣情況？
                    </label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.defauleOptionList}
                        label={this.props.obstaclesContent.moc0011 ? this.props.obstaclesContent.moc0011 : '請選擇'}
                        arrow={true}
                        name='moc0011'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0011', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0011}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0012 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0012'
                      value={this.props.obstaclesContent.moc0012}
                      required={true}
                      placeholder='請輸入撥打國家名稱'
                      label='撥打國家名稱'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0012}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0013 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>一般收發話是否正常</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.defauleOptionList}
                        label={this.props.obstaclesContent.moc0013 ? this.obstaclesContent.moc0013 : '請選擇'}
                        arrow={true}
                        name='moc0013'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0013', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0013}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0014 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>撥打之固網國際撥號冠碼</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.dialPrefixList}
                        label={this.props.obstaclesContent.moc0014 ? this.props.obstaclesContent.moc0014 : '請選擇'}
                        arrow={true}
                        name='moc0014'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0014', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0014}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0015 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0015'
                      value={this.props.obstaclesContent.moc0015}
                      required={true}
                      placeholder='請輸入加值服務名稱'
                      label='加值服務名稱'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0015}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0016 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>手機是否有出現GPRS訊號或字樣</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.defauleOptionList}
                        label={this.props.obstaclesContent.moc0016 ? this.props.obstaclesContent.moc0016 : '請選擇'}
                        arrow={true}
                        name='moc0016'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0016', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0016}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0017 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0017'
                      value={this.props.obstaclesContent.moc0017}
                      required={true}
                      placeholder='請輸入錯誤訊息提示'
                      label='錯誤訊息提示'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0017}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0018 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>撥接網站</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.dialUpSiteList}
                        label={this.props.obstaclesContent.moc0018 ? this.props.obstaclesContent.moc0018 : '請選擇'}
                        arrow={true}
                        name='moc0018'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0018', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0018}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0019 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0019'
                      value={this.props.obstaclesContent.moc0019}
                      required={true}
                      placeholder='請輸入無法進入或出現的錯誤訊息'
                      label='無法進入或出現的錯誤訊息'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0019}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0020 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0020'
                      value={this.props.obstaclesContent.moc0020}
                      required={true}
                      placeholder='請輸入漫遊時註冊網路業者(和信、遠傳)'
                      label='漫遊時註冊網路業者(和信、遠傳)'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0020}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0021 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0021'
                      value={this.props.obstaclesContent.moc0021}
                      required={true}
                      placeholder='請輸入漫遊國家'
                      label='漫遊國家'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0021}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0022 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>漫遊時收發話是否正常</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.defauleOptionList}
                        label={this.props.obstaclesContent.moc0022 ? this.props.obstaclesContent.moc0022 : '請選擇'}
                        arrow={true}
                        name='moc0022'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0022', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0022}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0023 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0023'
                      value={this.props.obstaclesContent.moc0023}
                      required={true}
                      placeholder='請輸入出現的錯誤訊息提示'
                      label='漫遊時連上GPRS出現的錯誤訊息提示'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0023}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0024 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0024'
                      value={this.props.obstaclesContent.moc0024}
                      required={true}
                      placeholder='請輸入手機門號/統一編號'
                      label='手機門號/統一編號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0024}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0025 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>發話方為桌機或手機</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.speakingPartyList}
                        label={this.props.obstaclesContent.moc0025 ? this.props.obstaclesContent.moc0025 : '請選擇'}
                        arrow={true}
                        name='moc0025'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0025', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0025}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0026 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>任意郵+版本</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.anyPostList}
                        label={this.props.obstaclesContent.moc0026 ? this.props.obstaclesContent.moc0026 : '請選擇'}
                        arrow={true}
                        name='moc0026'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0026', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0026}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0027 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0027'
                      value={this.props.obstaclesContent.moc0027}
                      required={true}
                      placeholder='請輸入連線設備(廠牌/型號)'
                      label='連線設備(廠牌/型號)'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0027}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0028 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0028'
                      value={this.props.obstaclesContent.moc0028}
                      required={true}
                      placeholder='請輸入GPRS/3G連線門號'
                      label='GPRS/3G連線門號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0028}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0029 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0029'
                      value={this.props.obstaclesContent.moc0029}
                      required={true}
                      placeholder='請輸入APN設定'
                      label='APN設定'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0029}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0030 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0030'
                      value={this.props.obstaclesContent.moc0030}
                      required={true}
                      placeholder='請輸入欲查詢時間'
                      label='欲查詢時間'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0030}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0031 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>使用產品</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.useProductList}
                        label={this.props.obstaclesContent.moc0031 ? this.props.obstaclesContent.moc0031 : '請選擇'}
                        arrow={true}
                        name='moc0031'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0031', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0031}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0032 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0032'
                      value={this.props.obstaclesContent.moc0032}
                      required={true}
                      placeholder='請輸入發訊人代碼'
                      label='發訊人代碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0032}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0033 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0033'
                      value={this.props.obstaclesContent.moc0033}
                      required={true}
                      placeholder='請輸入接收簡訊之手機門號'
                      label='接收簡訊之手機門號'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0033}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0034 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0034'
                      value={this.props.obstaclesContent.moc0034}
                      required={true}
                      placeholder='請輸入客戶名稱'
                      label='客戶名稱'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0034}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0035 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0035'
                      value={this.props.obstaclesContent.moc0035}
                      required={true}
                      placeholder='請輸入聯絡人/聯絡電話'
                      label='聯絡人/聯絡電話'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0035}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0036 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0036'
                      value={this.props.obstaclesContent.moc0036}
                      required={true}
                      placeholder='請輸入節費器電話號碼'
                      label='節費器電話號碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0036}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0037 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0037'
                      value={this.props.obstaclesContent.moc0037}
                      required={true}
                      placeholder='請輸入節費器安裝地址'
                      label='節費器安裝地址'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0037}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0038 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0038'
                      value={this.props.obstaclesContent.moc0038}
                      required={true}
                      placeholder='請輸入傳送國家名稱'
                      label='傳送國家名稱'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0038}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0039 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0039'
                      value={this.props.obstaclesContent.moc0039}
                      required={true}
                      placeholder='請輸入簡訊中心代碼'
                      label='簡訊中心代碼'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0039}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0040 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0040'
                      value={this.props.obstaclesContent.moc0040}
                      required={true}
                      placeholder='請輸入簡訊發送後的提示'
                      label='簡訊發送後的提示'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0040}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0041 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0041'
                      value={this.props.obstaclesContent.moc0041}
                      required={true}
                      placeholder='請輸入所聽到語音訊息'
                      label='所聽到語音訊息'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0041}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0042 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>接收容量信封是否閃爍</label>
                    <div className='row  mb-5'>
                      <Dropdown
                        className='is-button col-5 pl-0'
                        list={this.props.mocSelectList.defauleOptionList}
                        label={this.props.obstaclesContent.moc0042 ? this.props.obstaclesContent.moc0042 : '請選擇'}
                        arrow={true}
                        name='moc0042'
                        hasCheck={false}
                        onChange={(e) => this.changeObstaclesContent('moc0042', e.value)}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0042}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0043 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <LabelInput
                      className='ml-0'
                      name='moc0043'
                      value={this.props.obstaclesContent.moc0043}
                      required={true}
                      placeholder='請輸入發送後所出現訊息或錯誤訊息'
                      label='發送後所出現訊息或錯誤訊息'
                      onChange={this.changeObstaclesContent}
                      failResultMsg={this.props.errMsg.moc0043}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {this.props.showForm.moc0001 ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={10} className='form-group'>
                    <label className='h5 ml-0 mb-4 is-text-bold is-required'>障礙狀況/其他補充</label>
                    <div className='row'>
                      <textarea
                        onKeyUp={this.isValid}
                        name='moc0001'
                        className='text-input col-11'
                        defaultValue={this.props.obstaclesContent.moc0001}
                        onChange={(event) => this.changeObstaclesContent(event.target.name, event.target.value)}
                        style={{ resize: 'none', height: '200px ' }}
                      />
                      <span className='error-message'>{this.props.errMsg.moc0001}</span>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          ) : null}
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(MobileInternetForm);
