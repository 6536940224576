import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import Banner from '../../components/partials/banner/Banner';

class HowtoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>郵件代管</title>
        </Helmet>
        <body>
          <table className='art'>
            <tr>
              <td>
                <table className='mt-4 howtopage-title'>
                  <tr>
                    <td>
                      <div className='h5 pt-2'>Virtual Mail線上異動功能及使用說明</div>
                    </td>
                  </tr>
                </table>

                <ol className='ml-2 pr-4'>
                  <li>
                    <div className='h5 mt-4'>郵件帳號異動</div>
                    共分成三種功能異動，包括：Email信箱設定/異動、Group群組信箱設定/異動、Email信箱成員設定群組。
                    <div className='howtopage-color-blue'>1.Email信箱設定/異動：</div>
                    <div>您可透過此介面異動郵件帳號及密碼，亦可設定該郵件信箱轉信及掃毒功能。</div>
                    <div className='howtopage-color-blue'>2.Group群組信箱設定/異動：</div>
                    <div>您可透過此介面設定或異動群組ID。</div>
                    <div className='howtopage-color-blue'>3.Email信箱成員設定群組：</div>
                    <div>您可以透過此介面設定／異動群組內的收信對象包含那些郵件帳號。</div>
                    <div className='howtopage-color-red'>群組信箱功能說明：</div>
                    <div className='howtopage-color-orange'>
                      假設您設定群組信箱ID為aaa，而您的郵件主機名稱為abc.com.tw，在您設定群組內
                      的收信對象後，只要發信者寄發Email給aaa@abc.com.tw，該群組內的郵件信箱都可收到信件。
                    </div>
                  </li>
                  <li>
                    <div className='h5 mt-4'>基本資料異動</div>
                    您可更改管理者帳號的密碼及基本資料，包括聯絡人、聯絡地址、聯絡電話以及聯絡信箱。
                  </li>
                  <li>
                    <div className='h5 mt-4'>增租郵件帳號</div>

                    <div>
                      您可透過此增購郵件帳號。若您原為月繳客戶，申請增購後次月方始增收費用，計費方式如
                      <a
                        className='howtopage-a'
                        href='#'
                        onClick={() => {
                          window.open(
                            'http://product.seed.net.tw/virtualmail/intro.shtml#charge',
                            '_blank',
                            'height=800, width=1000, resizable=0'
                          );
                        }}
                        rel='noreferrer'>
                        <u>網頁公告</u>
                      </a>
                      。 若您為年繳客戶，至您增購日起至前次購買年繳到期日／12 X
                      增購後年繳費用，即為當次增購需補繳的金額。
                    </div>
                    <div className='howtopage-color-red'>
                      範例：A公司於91年10月1日購買(年繳)了9個郵件帳號，共支付了17280元
                      (=1920元x9個)，而A公司又於92年4月1日增購10個郵件帳號，此時Ａ公司需再支付多少費用？
                    </div>
                    <div className='howtopage-color-orange'>
                      91/10/1該次年繳到期日是92/9/30。 92/4/1－92/9/30共為6個月 <br />
                      19個年繳帳號應為150元x 12個月x 8折x 19個＝ 27360元 <br />
                      27360元／12月 x 6個月－14400元(前次年繳金額)／12 x 6 ＝ 6480元 <br />
                      註：前次帳單金額說明
                      <br />
                      <span className='ml-4 pl-2'>購買8-10個月租金為1500元</span>
                      <br />
                      <span className='ml-4 pl-2'>1500元x 12個月 x 8折 = 14400元</span>
                      <br />
                      A公司於4/1增購時需再支付6480元
                    </div>
                  </li>
                  <li>
                    <div className='h5 mt-4'>減租郵件帳號</div>
                    若您有部分郵件帳號已無使用，可透過此介面刪除不需要的郵件帳號。
                    作業完成後，申請減租的郵件帳號，將即刻停止，但原本之費用仍將計算至月底，下月初開始才會以減租後的帳號數計費，若欲刪除之郵件帳號尚有使用時限，申請
                    減租後，恕不退還未使用期間的費用(年繳客戶會將餘額折抵於下期的帳單中)。因此，申請減租服務時請您務必三思，強烈建議您在月底時再進行作業即可。
                  </li>
                  <li>
                    <div className='h5 mt-4'>異動郵件主機名稱／代管方式</div>
                    供您異動郵件主機名稱，申請異動完成後，該新主機名稱立即生效。
                    <div className='howtopage-color-red'>
                      若DNS由
                      Seednet托管，主機名稱更改後DNS尚有Cache問題，實際Mail可正常收發，國內大約需時24小時；國外需時三天。
                    </div>
                  </li>
                  <li>
                    <div className='h5 mt-4'>異動繳費方式</div>
                    您可透過此介面異動繳費方式，每月最多僅可異動一次。異動完成後，下次繳費日即是新繳費方式生效日（如：原月繳客戶轉年繳，則是次月改以年繳方式收費﹔原
                    年繳客戶轉月繳，則是年繳到期後改以月繳方式收費） 。
                  </li>
                </ol>
              </td>
            </tr>
          </table>
        </body>
      </React.Fragment>
    );
  }
}

export default withRouter(HowtoPage);
