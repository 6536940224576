import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import * as callApiUtil from '../../utils/callApiUtil';
import publicIp from 'public-ip';

class BackEndLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      menuList: {},
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    // 取得IP
    (async () => {
      let clientIp = await publicIp.v4();

      //組成API input Value

      let url = new URL(window.location.href);
      let Vin = {
        clientIp: clientIp,
        loginID: url.searchParams.get('loginID'),
        userID: url.searchParams.get('userID'),
        magicCode: url.searchParams.get('magicCode'),
        sysAccntCode: url.searchParams.get('sysAccntCode'),
      };
      // call BackstageLogin API
      callApiUtil
        .callApi('/ECPAP/API/BackEndLoginController/backEndLogin.action', Vin, '/ECP/loginPage')
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.resultMessage.message == 's') {
            response.systemMenuAuthList.pop(); //將最後一筆移除(首頁)
            //API回傳成功
            //1.將munu清單與sidbar清單放入 session
            let menuList = response.systemMenuAuthList.map((data) => {
              let uri = '';
              let pageList = [];
              if (data.pageAuthList.length > 0) {
                //將第一筆放入menu 的uri，若沒有siderBar 則直接從menu點選此路徑
                uri = data.pageAuthList[0].uri;
                //siderBar過濾條件: showLink =true 加入siderBar
                pageList = data.pageAuthList.filter((value) => value.showLink == true);
              }
              return {
                menuId: data.moduleId,
                menuName: data.moduleName,
                uri: uri,
                pageList: pageList,
              };
            });
            let menuobject = { menuList: menuList };
            menuobject = JSON.stringify(menuobject);
            window.localStorage.setItem('menuList', menuobject);
            window.localStorage.setItem('accountName', response.accountName);
            this.props.history.push('/index');
          } else {
            //API回傳失敗
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: 'fail',
                pageName: '後臺代登',
                title: '後臺代登',
                description: response.resultMessage.message,
              }
            );
          }
        });
    })();
  };

  render() {
    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>後台代登</title>
          <div className='bg-img'>
            <div className='container'></div>
          </div>
        </Helmet>
      </React.Fragment>
    );
  }
}

export default withRouter(BackEndLoginPage);
