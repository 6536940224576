import React from 'react';
import * as callApiUtil from '../../utils/callApiUtil';
import ProductListPage from './ProductListPage';
import ProdPage from './ProdPage';
import * as validation from '../../utils/validation';
import { Helmet } from 'react-helmet';

class RootComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 產品分類清單
      productCategoryList: [],
      // 產品清單
      productInfoList: [],
      // 從後端撈出的產品清單，用於產品清單頁查詢用
      originProductList: [],
      isLoading: false,
      //產品清單
      productList: [],
      // 選擇的產品分類
      productCategory: {},
      // 要顯示哪一個畫面 productCategoryListPage 產品分類清單 (預設)/ productListPage 產品分類底下的線路編號清單/ productServicePage 產品線路編號可使用的產品服務功能
      page: 'productCategoryListPage',
      productId: null,
      productName: null,
      userNo: null,
      fx: '',
      search: '', // 門號或線路編號
      hasUserNoInfo: true,
      searchErrMsg: null,
      referServiceId: null,
      extraInfo: null,
      acctId: null,
    };
  }

  // 初始化-取得產品分類資料
  componentDidMount = () => {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/ProductController/queryProdCateInfo.action', {}).then((response) => {
      // 判斷回傳資料
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success
      ) {
        //API回傳成功，將資料顯示在頁面上
        this.setState({ productCategoryList: response.productCategoryList });
        this.setState({ isLoading: false });
      } else {
        //API回傳失敗
        this.setState({ isLoading: false });
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: '產品服務作業查詢',
            title: '查詢失敗',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回企業用戶專區',
                link: '/index',
                btnStyle: 'primary',
              },
            ],
          }
        );
      }
    });
  };

  //新增點擊紀錄，recordType為'P'，代表要儲存的類型為 product
  recordClick = (categoryId) => {
    callApiUtil.callApi('/ECPAP/API/RecordClickController/recordClick.action', {
      recordType: 'P',
      categoryId: categoryId,
    });
  };

  /*顯示產品線路資料or直接顯示產品服務功能頁 */
  showProductInfo = (productCategory) => {
    // 若產品分類的showUserNo = true，則進入產品頁面(有產品線路)
    if (productCategory.showUserNo) {
      // 先打後端取得資料再導頁
      this.queryProductInfo(productCategory);

      // 若產品分類的showUserNo = false，則進入產品服務功能頁
    } else {
      // 先打後端取得資料再導頁
      this.queryProductServieInfo(productCategory);
    }
    //打後端記錄點擊資料和產品類別
    this.recordClick(productCategory.categoryId);
  };

  /* 打API後端撈資料*/
  //查詢產品資料
  queryProductInfo = (productCategory) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/queryProdInfo.action', {
        categoryId: productCategory.categoryId,
      })
      .then((response) => {
        // 判斷回傳資料
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({ isLoading: false });

          let prodList = [];

          // 將產品線路編號整理成List
          if (validation.notEmpty(response.productInfoList)) {
            response.productInfoList.map((productInfo, i) => {
              productInfo.productList.map((product, productIndex) => {
                prodList.push({
                  productId: product.productId,
                  name: product.name,
                  engName: product.engName,
                  userNo: product.userNo,
                  avaiable: product.avaiable,
                  categoryId: product.categoryId,
                  extraInfo: product.extraInfo,
                  extraInfoType: product.extraInfoType,
                  fx: product.fx,
                  nickName: product.nickName,
                  productDetailId: product.productDetailIdct,
                  referProductId: product.referProductId,
                  referServiceId: product.referServiceId,
                  scode: product.scode,
                  visible: product.visible,
                  hasService: product.hasService,
                  acctId: product.acctId,
                });
              });
            });
          }
          // 依線路編號排序
          prodList.sort((a, b) => (a.userNo > b.userNo ? 1 : b.userNo > a.userNo ? -1 : 0));

          // 將整理好的產品清單放回productList、將選擇的產品分類資料放進productCategory忠、並顯示產品清單畫面
          this.setState({
            productList: prodList,
            productCategory: productCategory,
            page: 'productListPage',
            originProductList: prodList,
          });
        } else {
          //API回傳失敗
          this.setState({ isLoading: false });
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '產品服務作業查詢',
              title: '查詢失敗',
              description: response.resultMessage.message,
              actions: [
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'primary',
                },
              ],
            }
          );
        }
      });
  };

  //查詢產品服務資料
  queryProductServieInfo = (productCategory) => {
    let fx = productCategory.productList[0].fx;
    let name = productCategory.name;

    this.setState({
      page: 'productServicePage',
      productId: productCategory.referProductId,
      fx: fx,
      productName: name,
    });
  };

  // 將修改的值帶進參數中
  changeText = (e) => {
    this.setState(() => ({
      search: e.target.value,
    }));
  };

  // 驗證搜尋欄位是否有輸入參數
  validSearch = () => {
    if (validation.notEmpty(this.state.search)) {
      this.queryProdNoInfo();
    } else {
      this.setState({
        hasUserNoInfo: false,
        searchErrMsg: '*請輸入完整門號或線路編號',
      });
    }
  };

  // 搜尋門號、線路編號
  queryProdNoInfo = () => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/queryUserNoInfo.action', {
        userNo: this.state.search,
      })
      .then((response) => {
        // 判斷回傳資料
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({ isLoading: false });
          //API回傳成功，將資料顯示在頁面上
          this.setState({ isLoading: false });

          let prodList = [];

          // 將產品線路編號整理成List
          if (validation.notEmpty(response.productInfoList)) {
            response.productInfoList.map((productInfo, i) => {
              productInfo.productList.map((product, productIndex) => {
                prodList.push({
                  productId: product.productId,
                  name: product.name,
                  engName: product.engName,
                  userNo: product.userNo,
                  avaiable: product.avaiable,
                  categoryId: product.categoryId,
                  categoryName: product.categoryName,
                  extraInfo: product.extraInfo,
                  extraInfoType: product.extraInfoType,
                  fx: product.fx,
                  nickName: product.nickName,
                  productDetailId: product.productDetailIdct,
                  referProductId: product.referProductId,
                  referServiceId: product.referServiceId,
                  scode: product.scode,
                  visible: product.visible,
                  hasService: product.hasService,
                  acctId: product.acctId,
                });
              });

              // 將整理好的產品清單放回productList，並顯示產品清單畫面
              this.setState({
                productList: prodList,
                page: 'productListPage',
                originProductList: prodList,
                searchErrMsg: null,
              });
            });

            // 如果沒有查到資料，則不導頁
          } else {
            this.setState({
              hasUserNoInfo: false,
              searchErrMsg: '*查無資料',
            });
          }
        } else {
          //API回傳失敗
          this.setState({ isLoading: false });
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '產品服務作業查詢',
              title: '查詢失敗',
              description: response.resultMessage.message,
              actions: [
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'primary',
                },
              ],
            }
          );
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>產品服務作業</title>
        </Helmet>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        {/* 該用戶所屬的產品分類清單頁 */}
        {this.state.page == 'productCategoryListPage' ? (
          <div>
            <div className='bg-img'>
              <div className='section container'>
                {validation.notEmpty(this.state.productCategoryList) ? (
                  <div className='title text-black ml-2 mt-3'>
                    貴公司目前所申請的產品如下：
                    <p className='mt-3 h3'>您可點取任一客戶編號或線路資訊，進行後續作業。</p>
                    <div className='mt-3'>
                      <input
                        className='h-100 w-20 subtitle border-gray shadow'
                        placeholder='輸入門號或線路編號查詢'
                        name='searchProd'
                        value={this.state.search}
                        onChange={this.changeText}></input>
                      <button className='is-primary fui-button is-small ml-3' onClick={this.validSearch}>
                        搜尋
                      </button>
                      {!this.state.hasUserNoInfo ? (
                        <span className='subtitle red mt-1'>{this.state.searchErrMsg}</span>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className='title text-black ml-2 mt-3'>查無任何產品服務資訊</div>
                )}
                <div className='row'>
                  {validation.notEmpty(this.state.productCategoryList) &&
                    this.state.productCategoryList.map((option, i) => (
                      <div
                        className='fui-card card-3 m-2'
                        key={'prodInfo' + i}
                        onClick={() => this.showProductInfo(option)}>
                        <div className='fui-card-caption'>
                          <div className='fui-card-content'>
                            <div className='fui-card-title'>{option.name}</div>
                          </div>
                          <div className='fui-card-extra mt-3'>
                            <div className='fui-button is-arrow'>詳細內容</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* 產品分類底下的產品線路清單頁 */}
        <div>
          {this.state.page == 'productListPage' ? (
            <ProductListPage productList={this.state.productList} productCategory={this.state.productCategory} />
          ) : null}
        </div>
        {/* 產品線路可用的產品服務功能頁 */}
        <div>
          {this.state.page == 'productServicePage' ? (
            <ProdPage
              productId={this.state.productId != null ? this.state.productId : null}
              fx={this.state.fx != null ? this.state.fx : null}
              productName={this.state.productName != null ? this.state.productName : null}
              referServiceId={this.state.referServiceId != null ? this.state.referServiceId : null}
              extraInfo={this.state.extraInfo != null ? this.state.extraInfo : null}
              acctId={this.state.acctId != null ? this.state.acctId : null}
              userNo={this.state.userNo != null ? this.state.userNo : null}></ProdPage>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default RootComponents;
