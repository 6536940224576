import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import * as callApiUtil from '../../utils/callApiUtil';
import { Helmet } from 'react-helmet';
import * as validation from '../../utils/validation';

class MaintainPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      form: {
        oldPassword: { value: '', text: '' }, //原密碼
        newPassword: { value: '', text: '' }, //新密碼
        newPasswordCheck: { value: '', text: '' }, //確認新密碼
      },
      oldPasswordErrMsg: '',
      newPasswordErrMsg: '',
      newPasswordCheckErrMsg: '',
      isLoading: false,
    };
  }

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value;
    this.setState({ form: newForm });
  };

  resetData = () => {
    let defaultForm = {
      oldPassword: { value: '', text: '' }, //原密碼
      newPassword: { value: '', text: '' }, //新密碼
      newPasswordCheck: { value: '', text: '' }, //確認新密碼
    };

    this.setState({ form: defaultForm });
  };

  //驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;
    let oldPasswordErrMsg = '';
    let newPasswordErrMsg = '';
    let newPasswordCheckErrMsg = '';
    //檢查所有欄位
    if (
      validation.isEmpty(this.state.form.oldPassword.value) ||
      validation.isEmpty(this.state.form.newPassword.value) ||
      validation.isEmpty(this.state.form.newPasswordCheck.value)
    ) {
      isValid = false;
    }
    if (
      validation.notEmpty(this.state.form.oldPassword.value) &&
      !validation.validPWD(this.state.form.oldPassword.value)
    ) {
      oldPasswordErrMsg = '舊密碼格式必須為6~12碼的英數字';
      isValid = false;
    }
    if (
      validation.notEmpty(this.state.form.newPassword.value) &&
      !validation.validPWD(this.state.form.newPassword.value)
    ) {
      newPasswordErrMsg = '新密碼格式必須為6~12碼的英數字';
      isValid = false;
    }
    if (
      validation.notEmpty(this.state.form.newPasswordCheck.value) &&
      this.state.form.newPassword.value != this.state.form.newPasswordCheck.value
    ) {
      newPasswordCheckErrMsg = '確認新密碼必須與新密碼相同';
      isValid = false;
    }
    this.setState(() => ({
      oldPasswordErrMsg: oldPasswordErrMsg,
      newPasswordErrMsg: newPasswordErrMsg,
      newPasswordCheckErrMsg: newPasswordCheckErrMsg,
      isValidate: isValid,
    }));
  };

  updatePWD = () => {
    //若驗證資料成功
    if (this.state.isValidate) {
      //組成API input Value
      var UpdatePwdVin = {
        // 0330 弱點掃描，將密碼名稱改為 credential
        newCredential: this.state.form.newPassword.value,
        confirmNewCredential: this.state.form.newPasswordCheck.value,
        originalCredential: this.state.form.oldPassword.value,
      };

      let isSuccess;
      let title;
      let message;
      this.setState({ isLoading: true });
      callApiUtil.callApi('/ECPAP/API/UpdatePwdController/updatePwd.action', UpdatePwdVin).then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          //API回傳成功
          isSuccess = true;
          title = '密碼修改成功';
          message = '您的密碼已更新，下次請使用新密碼登入。';
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: isSuccess ? 'success' : 'fail',
              pageName: '帳號密碼維護',
              title: title,
              description: message,
              actions: [
                {
                  text: '返回帳號密碼維護',
                  link: '/maintainPasswordPage',
                  btnStyle: 'primary',
                  fetkey: '',
                  dataTrackingId: '',
                },
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'secondary',
                  fetkey: '',
                  dataTrackingId: '',
                },
              ],
            }
          );
        } else {
          //API回傳失敗
          if (response.resultMessage.code == 'MA_002_002') {
            this.setState({ oldPasswordErrMsg: '舊密碼輸入錯誤', isLoading: false });
          } else if (response.resultMessage.code == 'MA_002_004') {
            this.setState({ newPasswordErrMsg: '新密碼與舊密碼相同', isLoading: false });
          } else {
            message = response.resultMessage.message;
            isSuccess = false;
            title = '密碼修改失敗';
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: isSuccess ? 'success' : 'fail',
                pageName: '帳號密碼維護',
                title: title,
                description: message,
                actions: [
                  {
                    text: '返回帳號密碼維護',
                    link: '/maintainPasswordPage',
                    btnStyle: 'primary',
                    fetkey: '',
                    dataTrackingId: '',
                  },
                  {
                    text: '返回企業用戶專區',
                    link: '/index',
                    btnStyle: 'secondary',
                    fetkey: '',
                    dataTrackingId: '',
                  },
                ],
              }
            );
          }
        }
      });
    }
  };

  render() {
    const items = [
      {
        title: '基本資料維護',
        itemId: '/maintainAccountInfoPage',
      },
      {
        title: '帳號密碼維護',
        itemId: '/maintainPasswordPage',
      },
      {
        title: '第二代理人(子帳號)權限管理',
        itemId: '/maintainSubCustAccountInfoPage',
      },
      {
        title: '公司基本資料異動申請',
        itemId: '/maintainCorpInfoPage',
      },
      {
        title: '第一代理人(管理者)變更及基本資料異動申請',
        itemId: '/maintainFirstAgentPage',
      },
    ];
    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>帳號密碼維護</title>
        </Helmet>
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' items={items} title='帳號管理' history={this.props.history}></Sidebar>
              <div className='container bg-white col-9 shadow pl-4'>
                <Formsy onValid={this.enableButton}>
                  <div className='title pt-2 mt-5 ml-3'>帳號密碼維護 Password Management</div>
                  <Grid container spacing={6} className='mt-md-3'>
                    <Grid item xs={12} md={8} className='pt-0 mt-3'>
                      <LabelInput
                        type='password'
                        className='ml-4'
                        name='oldPassword'
                        value={this.state.form.oldPassword.value}
                        placeholder='請輸入舊密碼 Please enter original password'
                        label='舊密碼'
                        labelEng='Original password'
                        onChange={this.onChange}
                        inputType='maintainPwdPage'
                        failResultMsg={this.state.oldPasswordErrMsg}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={6} className='mt-md-3'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        type='password'
                        className='ml-4'
                        name='newPassword'
                        value={this.state.form.newPassword.value}
                        placeholder='請輸入新密碼 Please enter new password'
                        label='新密碼'
                        labelEng='New password'
                        onChange={this.onChange}
                        inputType='maintainPwdPage'
                        failResultMsg={this.state.newPasswordErrMsg}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={6} className='mt-md-3'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        type='password'
                        className='ml-4'
                        name='newPasswordCheck'
                        value={this.state.form.newPasswordCheck.value}
                        placeholder='請再次輸入新密碼 Please enter new password, again'
                        label='再次輸入新密碼'
                        labelEng='Please enter new password, again'
                        onChange={this.onChange}
                        inputType='maintainPwdPage'
                        failResultMsg={this.state.newPasswordCheckErrMsg}
                      />
                    </Grid>
                  </Grid>
                </Formsy>
                <div className='button-group mb-5 ml-4'>
                  <div className='mt-5 two-buttons'>
                    <Button
                      btnStyle='primary'
                      size='large'
                      onClick={() => this.updatePWD()}
                      disabled={!this.state.isValidate}>
                      送出 Send
                    </Button>
                    <Button btnStyle='secondary' size='large' onClick={this.resetData}>
                      重填 Reset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MaintainPasswordPage);
