import React, { Children } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import CheckboxCollapse from '../../components/partials/collapse/CheckboxCollapse';
import Button from '../../components/Button';
import * as callApiUtil from '../../utils/callApiUtil';
import TransactionApplication from '../resultController/TransactionApplication';
import * as validation from '../../utils/validation';
import * as emptyUtil from '../../utils/emptyUtil';

class SubCustAccountUpdatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      basicInfo: false, //是否修改修改基本資料
      canSubmit: false, //是否可以送出
      oriAccntType: null,
      oriGender: null,
      accountRule: false, // 是否勾選同意條款
      authChange: false, // 是否修改權限或帳務類別
      emailMsg: '',
      rocIdMsg: '',
      foxNoMsg: '',
      companyPhoneMsg: '',
      authMsg: '',
      msisdnMsg: '',
      allMsg: '',
      isLoading: false,
      subBillList: [],
      subNonBillList: [],
      subAllList: [],
      selectedCust: {},
      form: {
        f1BillInfo: [],
        f5BillInfo: [],
        f6BillInfo: [],
        productInfo: [],
        accountType: '', // 帳務類別
        userName: '', // 使用者姓名
        gender: '', // 稱謂
        rocId: '', // 身分證
        companyPhone: '', //公司電話
        msisdn: '', //行動電話
        foxNo: '', // 傳真
        email: '',
      },
      acntTypeAll: '2',
      acntTypeBill: '3',
      acntTypeNonBill: '4',
    };
  }

  // 初始化
  componentDidMount() {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/SubCustModController/MaintainSubCustInit.action', {}).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState(() => ({
          // 塞custAccountBean
          subAllList: response.subAllList,
          subNonBillList: response.subNonBillList,
          subBillList: response.subBillList,
          isLoading: false,
        }));
        document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        //API回傳失敗
        this.toErrPage(response.resultMessage.message);
      }
    });
  }
  // 取該用戶可設定的產品權限(先判斷state有沒有)
  getProdAuth = (custAccountBean) => {
    this.setState(() => ({ isLoading: true }));
    callApiUtil
      .callApi('/ECPAP/API/SubCustModController/getProdPrivSetting.action', { custAccountBean })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          //API回傳成功
          let prodcutCategoryList = [];
          // 判斷是否有值
          if (response.productCategoryInfoList != null) {
            for (let i in response.productCategoryInfoList) {
              if (
                response.productCategoryInfoList[i] != null &&
                response.productCategoryInfoList[i].products != null &&
                response.productCategoryInfoList[i].products.length > 0
              ) {
                prodcutCategoryList.push(response.productCategoryInfoList[i]);
              }
            }
          }

          let newForm = Object.assign(this.state.form);
          newForm['productInfo'] = prodcutCategoryList;
          newForm['accountType'] = this.state.acntTypeNonBill;

          this.setState(() => ({
            isLoading: false,
            form: newForm,
          }));
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      });
  };
  // 取該用戶可設定的帳務權限(先判斷state有沒有)
  getBillAuth = (custAccountBean) => {
    this.setState(() => ({ isLoading: true }));

    callApiUtil
      .callApi('/ECPAP/API/SubCustModController/getBillPrivSetting.action', { custAccountBean })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          //API回傳成功
          let f1BillInfo = null;
          let f5BillInfo = null;
          let f6BillInfo = null;
          // 判斷是否有值
          if (response.billInfoBean != null) {
            if (
              response.billInfoBean.f1BillInfo != null &&
              response.billInfoBean.f1BillInfo.billFunctionInfoList != null &&
              response.billInfoBean.f1BillInfo.billFunctionInfoList.length > 0
            ) {
              f1BillInfo = response.billInfoBean.f1BillInfo.billFunctionInfoList;
            }
            if (
              response.billInfoBean.f5BillInfo != null &&
              response.billInfoBean.f5BillInfo.billAccountInfoList != null &&
              response.billInfoBean.f5BillInfo.billAccountInfoList.length > 0
            ) {
              f5BillInfo = response.billInfoBean.f5BillInfo.billAccountInfoList;
            }
            if (
              response.billInfoBean.f6BillInfo != null &&
              response.billInfoBean.f6BillInfo.billAccountInfoList != null &&
              response.billInfoBean.f6BillInfo.billAccountInfoList.length > 0
            ) {
              f6BillInfo = response.billInfoBean.f6BillInfo.billAccountInfoList;
            }
          }

          let newForm = Object.assign(this.state.form);
          newForm['f1BillInfo'] = f1BillInfo;
          newForm['f5BillInfo'] = f5BillInfo;
          newForm['f6BillInfo'] = f6BillInfo;
          newForm['accountType'] = this.state.acntTypeBill;

          this.setState(() => ({
            isLoading: false,
            form: newForm,
          }));
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      });
  };
  // 送出
  submit = () => {
    this.setState({ isLoading: true });
    //判斷所選擇的帳務類別
    if (this.state.form.accountType == this.state.acntTypeAll) {
      this.setAllPriv();
    } else if (this.state.form.accountType == this.state.acntTypeBill) {
      this.setBillPriv();
    } else if (
      this.state.form.accountType == this.state.acntTypeNonBill &&
      this.state.oriAccntType != this.state.acntTypeNonBill
    ) {
      this.setOtherNonBillPriv();
    } else if (
      this.state.form.accountType == this.state.acntTypeNonBill &&
      this.state.oriAccntType == this.state.acntTypeNonBill
    ) {
      this.setNonBillPriv();
    }
  };

  onChange = (name, value) => {
    if (name == 'accountType') {
      if (value == this.state.acntTypeAll) {
        let newForm = Object.assign(this.state.form);
        newForm['accountType'] = this.state.acntTypeAll;
        this.setState(() => ({ form: newForm, authChange: true }));
      } else if (value == this.state.acntTypeBill) {
        if (
          (this.state.form.f1BillInfo == null || this.state.form.f1BillInfo.length == 0) &&
          (this.state.form.f5BillInfo == null || this.state.form.f5BillInfo.length == 0) &&
          (this.state.form.f6BillInfo == null || this.state.form.f6BillInfo.length == 0)
        ) {
          this.getBillAuth(this.state.selectedCust);
        }
      } else if (value == this.state.acntTypeNonBill) {
        if (this.state.form.productInfo == null || this.state.form.productInfo.length == 0) {
          this.getProdAuth(this.state.selectedCust);
        }
      }
      let newForm = Object.assign(this.state.form);
      newForm[name] = value;

      this.setState(() => ({ form: newForm, authChange: true }));
    } else if (name == 'step') {
      //是否勾選修改基本資料
      this.setState(() => ({ step: value }));
    } else if (name == 'authChange') {
      //是否修改權限
      this.setState(() => ({ authChange: value }));
    } else {
      let newForm = Object.assign(this.state.form);
      newForm[name] = value;

      this.setState(() => ({ form: newForm }));
    }

    this.enableButton(this.state.accountRule);
  };

  // 帳務類別為第二代理人全部
  setAllPriv = () => {
    // 取用戶修改的資料, 組成custAccountBean
    let custAccountBean = this.getCustAcntBean();

    callApiUtil.callApi('/ECPAP/API/SubCustModController/setAllPriv.action', { custAccountBean }).then((response) => {
      this.setState({ isLoading: false });
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        this.toSuccessPage();
      } else {
        if (response.resultMessage.code == 'MA_003_203') {
          this.toErrPage('該子帳號目前尚有未審核的異動申請，請待審核結束後再送出新的申請。');
        } else if (response.resultMessage.code == 'MA_003_213') {
          this.setState(() => ({ allMsg: '請至少選擇一個要異動的欄位' }));
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      }
    });
  };

  // 帳務類別為第二代理人帳務
  setBillPriv = () => {
    // 取用戶修改的資料, 組成custAccountBean
    let custAccountBean = this.getCustAcntBean();

    let billInfoBean = {
      f1BillInfo: { billFunctionInfoList: this.state.form.f1BillInfo },
      f5BillInfo: { billAccountInfoList: this.state.form.f5BillInfo },
      f6BillInfo: { billAccountInfoList: this.state.form.f6BillInfo },
    };

    let setBillPrivVin = {
      custAccountBean: custAccountBean,
      billInfoBean: billInfoBean,
    };

    callApiUtil.callApi('/ECPAP/API/SubCustModController/setBillPriv.action', setBillPrivVin).then((response) => {
      this.setState({ isLoading: false });
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        this.toSuccessPage();
      } else {
        if (response.resultMessage.code == 'MA_003_203') {
          this.toErrPage('該子帳號目前尚有未審核的異動申請，請待審核結束後再送出新的申請。');
        } else if (response.resultMessage.code == 'MA_003_213') {
          this.setState(() => ({ allMsg: '請至少選擇一個要異動的欄位' }));
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      }
    });
  };

  // 帳務類別為第二代理人非帳務且原本也是非帳務
  setNonBillPriv = () => {
    // 組成vin
    let setNonBillPrivVin = {
      custAccountBean: this.getCustAcntBean(),
      productCategoryInfoList: this.state.form.productInfo,
    };

    callApiUtil.callApi('/ECPAP/API/SubCustModController/setNonBillPriv.action', setNonBillPrivVin).then((response) => {
      this.setState({ isLoading: false });
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        this.toSuccessPage();
      } else {
        if (response.resultMessage.code == 'MA_003_213') {
          this.setState(() => ({ allMsg: '請至少選擇一個要異動的欄位' }));
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      }
    });
  };

  // 帳務類別為第二代理人非帳務且原本不是非帳務
  setOtherNonBillPriv = () => {
    // 取用戶修改的資料, 組成custAccountBean
    let setOtherNonBillPrivVin = {
      custAccountBean: this.getCustAcntBean(),
      productCategoryInfoList: this.state.form.productInfo,
    };
    callApiUtil
      .callApi('/ECPAP/API/SubCustModController/setOtherNonBillPriv.action', setOtherNonBillPrivVin)
      .then((response) => {
        this.setState({ isLoading: false });
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          this.toSuccessPage();
        } else {
          if (response.resultMessage.code == 'MA_003_203') {
            this.toErrPage('該子帳號目前尚有未審核的異動申請，請待審核結束後再送出新的申請。');
          } else if (response.resultMessage.code == 'MA_003_213') {
            this.setState(() => ({ allMsg: '請至少選擇一個要異動的欄位' }));
          } else {
            //API回傳失敗
            this.toErrPage(response.resultMessage.message);
          }
        }
      });
  };

  toSuccessPage = () => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: 'success',
        pageName: '第二代理人(子帳號)權限管理',
        title: '更新完成',
        description: '第二代理人(子帳號)權限管理異動成功！',
        showActions: true,
        actions: [
          {
            text: '返回第二代理人(子帳號)權限管理',
            link: '/maintainSubCustAccountInfoPage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/index',
            btnStyle: 'secondary',
          },
        ],
      }
    );
  };

  // 是否要開啟修改基本資料
  openBasicInfo = () => {
    this.setState(() => ({ basicInfo: !this.state.basicInfo }));
    this.enableButton(this.state.accountRule);
  };

  // 驗證是否有勾會員條款
  checkAccountRule = () => {
    this.setState(() => ({ accountRule: !this.state.accountRule }));
    this.enableButton(!this.state.accountRule);
  };

  // 驗證是否可以送出
  enableButton = (accountRule) => {
    let canSubmit = false;
    let msisdnValidate = false;
    let foxNoValidate = false;
    let companyPhoneValidate = false;
    let rocIdValidate = false;
    let emailValidate = false;
    let allEmpty = false;
    let msisdnMsg = null;
    let foxNoMsg = null;
    let companyPhoneMsg = null;
    let rocIdMsg = null;
    let emailMsg = null;
    let authMsg = null;
    if (
      validation.isEmpty(this.state.form.userName) &&
      validation.isEmpty(this.state.form.rocId) &&
      validation.isEmpty(this.state.form.companyPhone) &&
      validation.isEmpty(this.state.form.msisdn) &&
      validation.isEmpty(this.state.form.foxNo) &&
      validation.isEmpty(this.state.form.email) &&
      (validation.isEmpty(this.state.form.gender) || this.state.oriGender == this.state.form.gender)
    ) {
      allEmpty = true;
    }
    // 驗證格式
    if (validation.isEmpty(this.state.form.msisdn) || validation.mobile(this.state.form.msisdn)) {
      msisdnValidate = true;
      msisdnMsg = '';
    } else {
      msisdnMsg = '請輸入正確的行動電話';
    }
    if (validation.isEmpty(this.state.form.foxNo) || validation.phone(this.state.form.foxNo)) {
      foxNoValidate = true;
      foxNoMsg = '';
    } else {
      foxNoMsg = '請輸入正確的傳真';
    }
    if (validation.isEmpty(this.state.form.companyPhone) || validation.phone(this.state.form.companyPhone)) {
      companyPhoneValidate = true;
      companyPhoneMsg = '';
    } else {
      companyPhoneMsg = '請輸入正確的連絡電話';
    }
    if (validation.isEmpty(this.state.form.rocId) || validation.twId(this.state.form.rocId)) {
      rocIdValidate = true;
      rocIdMsg = '';
    } else {
      rocIdMsg = '請輸入正確的身分證字號';
    }
    if (validation.isEmpty(this.state.form.email) || validation.email(this.state.form.email)) {
      emailValidate = true;
      emailMsg = '';
    } else {
      emailMsg = '請輸入正確的email';
    }

    // 驗證若為第二代理人帳務、非帳務是否有選擇權限
    let selected = false;
    if (this.state.authChange && this.state.form.accountType == 3) {
      if (this.state.form.f6BillInfo != null && this.state.form.f6BillInfo.length > 0) {
        for (let i in this.state.form.f6BillInfo) {
          if (this.state.form.f6BillInfo[i].selected) {
            selected = true;
            break;
          }
        }
      }
      if (this.state.form.f5BillInfo != null && this.state.form.f5BillInfo.length > 0) {
        for (let i in this.state.form.f5BillInfo) {
          if (this.state.form.f5BillInfo[i].selected) {
            selected = true;
            break;
          }
        }
      }
      if (this.state.form.f1BillInfo != null && this.state.form.f1BillInfo.length > 0) {
        for (let i in this.state.form.f1BillInfo) {
          if (this.state.form.f1BillInfo[i].selected) {
            selected = true;
            break;
          }
        }
      }
      if (!selected) {
        authMsg = '請至少勾選一個帳務權限！';
      } else {
        authMsg = '';
      }
    } else if (this.state.authChange && this.state.form.accountType == 4) {
      if (this.state.form.productInfo != null && this.state.form.productInfo.length > 0) {
        for (let i in this.state.form.productInfo) {
          if (this.state.form.productInfo[i].products != null && this.state.form.productInfo[i].products.length > 0) {
            for (let j in this.state.form.productInfo[i].products) {
              if (this.state.form.productInfo[i].products[j].selected) {
                selected = true;
                break;
              }
            }
          }
          if (selected) {
            break;
          }
        }
      }

      if (!selected) {
        authMsg = '請至少勾選一個產品權限！';
      } else {
        authMsg = '';
      }
    } else if (this.state.authChange && this.state.form.accountType == 2) {
      selected = true;
    }

    if (
      // 是否修改基本資料
      (accountRule &&
        !allEmpty &&
        msisdnValidate &&
        foxNoValidate &&
        companyPhoneValidate &&
        rocIdValidate &&
        emailValidate) ||
      // 是否修改權限
      (this.state.authChange && accountRule && selected)
    ) {
      canSubmit = true;
    } else {
      canSubmit = false;
    }

    this.setState(() => ({
      canSubmit: canSubmit,
      emailMsg: emailMsg,
      rocIdMsg: rocIdMsg,
      foxNoMsg: foxNoMsg,
      companyPhoneMsg: companyPhoneMsg,
      msisdnMsg: msisdnMsg,
      authMsg: authMsg,
    }));
  };

  // 選擇操作哪一個第二代理人
  selecteCust = (custAccountBean) => {
    let newForm = Object.assign(this.state.form);
    newForm['accountType'] = custAccountBean.accntType;
    newForm['gender'] = custAccountBean.gender;
    this.setState(() => ({
      oriAccntType: custAccountBean.accntType,
      oriGender: custAccountBean.gender,
      isLoading: true,
      selectedCust: custAccountBean,
      step: '2',
      form: newForm,
    }));
    // call api 取得對應的權限內容
    if (custAccountBean.accntType == this.state.acntTypeBill) {
      this.getBillAuth(custAccountBean);
    } else if (custAccountBean.accntType == this.state.acntTypeNonBill) {
      this.getProdAuth(custAccountBean);
    } else if (custAccountBean.accntType == this.state.acntTypeAll) {
      this.setState(() => ({
        isLoading: false,
      }));
    }
    document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
  };

  // 轉換custAcntBean
  getCustAcntBean = () => {
    let newCust = {
      accntCode: this.state.selectedCust.accntCode,
      accntEmail: '',
      accntFaxNO: '',
      accntID: '',
      accntMsisdn: '',
      accntName: '',
      accntTelNO: '',
      accntType: this.state.selectedCust.accntType,
      gender: '',
      loginID: this.state.selectedCust.loginID,
      userID: this.state.selectedCust.userID,
    };
    // 帳務類別
    if (validation.notEmpty(this.state.form.accountType)) {
      newCust.accntType = this.state.form.accountType;
    }
    // 使用者姓名
    if (validation.notEmpty(this.state.form.userName)) {
      newCust.accntName = this.state.form.userName;
    }
    // 稱謂
    if (validation.notEmpty(this.state.form.gender)) {
      newCust.gender = this.state.form.gender;
    }
    // 身分證
    if (validation.notEmpty(this.state.form.rocId)) {
      newCust.accntID = this.state.form.rocId;
    }
    //公司電話
    if (validation.notEmpty(this.state.form.companyPhone)) {
      newCust.accntTelNO = this.state.form.companyPhone;
    }
    //行動電話
    if (validation.notEmpty(this.state.form.msisdn)) {
      newCust.accntMsisdn = this.state.form.msisdn;
    }
    // 傳真
    if (validation.notEmpty(this.state.form.foxNo)) {
      newCust.accntFaxNO = this.state.form.foxNo;
    }
    // email
    if (validation.notEmpty(this.state.form.email)) {
      newCust.accntEmail = this.state.form.email;
    }
    return newCust;
  };

  // 導錯誤頁
  toErrPage = (errMsg) => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: 'fail',
        pageName: '第二代理人(子帳號)權限管理',
        title: '提醒訊息',
        description: errMsg,
        showActions: true,
        actions: [
          {
            text: '返回第二代理人(子帳號)權限管理',
            link: '/maintainSubCustAccountInfoPage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/index',
            btnStyle: 'secondary',
          },
        ],
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>第二代理人(子帳號)權限管理</title>
        </Helmet>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='帳號管理' history={this.props.history}></Sidebar>
              {/* 第一頁 */}
              {this.state.step == 1 && (
                <div className='container bg-white col-9 shadow pl-4 pr-4'>
                  <div className='title pt-2 mt-5 ml-3'>第二代理人(子帳號)權限管理</div>
                  <div>
                    <div className='sub-title is-text-bold pl-3 pt-3'>第二代理人維護明細</div>

                    <div className='h5 mt-3 is-text-bold pl-3 pt-3'>第二代理人(子帳號)一全功能:帳務類+非帳務類</div>
                    {this.state.subAllList != null && this.state.subAllList.length != 0 ? (
                      <div className='w-100 pl-3'>
                        <table className='w-100'>
                          {this.state.subAllList.map((cust, index) => {
                            return (
                              <tr className='bg-white' key={'all' + index}>
                                <td className='w-40 text-center border-gray'>
                                  <a href='#' onClick={() => this.selecteCust(cust)}>
                                    {cust.loginID}
                                  </a>
                                </td>
                                <td className='w-60 p-3 border-gray'>
                                  {cust.accntName} {cust.gender == 'M' ? '先生' : '女士'}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      <div className='mt-3 pl-3 red font-weight-bold'>查無第二代理人(子帳號)一全功能</div>
                    )}

                    <div className='h5 mt-5 is-text-bold pl-3 pt-3'>第二代理人(子帳號)一帳務類功能</div>
                    {this.state.subBillList != null && this.state.subBillList.length != 0 ? (
                      <div className='w-100 pl-3'>
                        <table className='w-100'>
                          {this.state.subBillList.map((cust, j) => {
                            return (
                              <tr className='bg-white' key={'bill' + j}>
                                <td className='w-40 text-center border-gray'>
                                  <a href='#' onClick={() => this.selecteCust(cust)}>
                                    {cust.loginID}
                                  </a>
                                </td>
                                <td className='w-60 p-3 border-gray'>
                                  {cust.accntName} {cust.gender == 'M' ? '先生' : '女士'}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      <div className='mt-3 pl-3 red font-weight-bold'>查無第二代理人(子帳號)一帳務類功能</div>
                    )}

                    <div className='h5 mt-5 is-text-bold pl-3 pt-3'>第二代理人(子帳號)一非帳務類功能</div>
                    {this.state.subNonBillList != null && this.state.subNonBillList.length != 0 ? (
                      <div className='w-100 pl-3 pb-5'>
                        <table className='w-100'>
                          {this.state.subNonBillList.map((cust, k) => {
                            return (
                              <tr className='bg-white' key={'nonBill' + k}>
                                <td className='w-40 text-center border-gray'>
                                  <a href='#' onClick={() => this.selecteCust(cust)}>
                                    {cust.loginID}
                                  </a>
                                </td>
                                <td className='w-60 p-3 border-gray'>
                                  {cust.accntName} {cust.gender == 'M' ? '先生' : '女士'}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      <div className='mt-3 pl-3 red font-weight-bold mb-4'>查無第二代理人(子帳號)一非帳務類功能</div>
                    )}
                  </div>
                </div>
              )}
              {/* 第一頁 */}
              {/* 第二頁 */}

              {this.state.step == 2 && (
                <div className='container bg-white col-9 shadow pl-4 pr-4'>
                  <div className='title pt-2 mt-5 ml-3'>第二代理人(子帳號)權限管理</div>
                  <Formsy className='pl-3'>
                    <div className='sub-title is-text-bold pt-3 mb-2 title'>請選擇帳務類別</div>
                    <div className='mb-4 radio-group w-60'>
                      {/* 全功能 */}
                      <label
                        className={`h5 is-text-bold w-100 mt-3 p-3 text-center border-gray ${
                          this.state.form.accountType == this.state.acntTypeAll && 'btn-radio'
                        }`}>
                        第二代理人-全功能(帳務類+非帳務類)
                        <input
                          type='radio'
                          value='2'
                          id='all'
                          name='accountType'
                          className='radio-button w-100 h-100'
                          onChange={() => this.onChange('accountType', this.state.acntTypeAll)}
                        />
                      </label>
                      {this.state.form.accountType == '2' && (
                        <div className='gray-block p-4 font-weight-bold'>
                          <p>帳務類功能:</p>
                          <ol>
                            <li>
                              遠傳電信【簡易帳款報表/表單瀏覽、一般通話明細、公私分帳報表、公私分帳明細、其他、MVPN線上服務(內容包含異動、退出及申請狀況查詢)】。
                            </li>
                            <li>新世紀資通【帳單明細瀏覽、線上繳款、帳務報表服務】。</li>
                            <li>
                              Seednet【帳單明細瀏覽、線上繳款、原Seednet Wagaly Enterprise通話明細、帳務報表服務】。
                            </li>
                          </ol>
                          <p>非帳務類功能:</p>
                          <p>產品查詢作業、障礙申告及查詢、系統使用紀錄查詢。</p>
                        </div>
                      )}
                      {/* 全功能 */}
                      {/* 帳務類 */}
                      <label
                        className={`h5 is-text-bold w-100 mt-4 p-3 border-gray text-center ${
                          this.state.form.accountType == this.state.acntTypeBill && 'btn-radio'
                        }`}>
                        第二代理人-帳務
                        <input
                          type='radio'
                          value='3'
                          id='bill'
                          name='accountType'
                          className='radio-button w-100 h-100'
                          onChange={() => this.onChange('accountType', this.state.acntTypeBill)}
                        />
                      </label>
                      {this.state.form.accountType == this.state.acntTypeBill && (
                        <div className='gray-block p-4 font-weight-bold'>
                          <p>帳務類功能:</p>
                          <ol>
                            <li>
                              遠傳電信【簡易帳款報表/表單瀏覽、一般通話明細、公私分帳報表、公私分帳明細、其他、MVPN線上服務(內容包含異動、退出及申請狀況查詢)】。
                            </li>
                            <li>新世紀資通【帳單明細瀏覽、線上繳款、帳務報表服務】。</li>
                            <li>
                              Seednet【帳單明細瀏覽、線上繳款、原Seednet Wagaly Enterprise通話明細、帳務報表服務】。
                            </li>
                          </ol>
                        </div>
                      )}
                      {/* 帳務類 */}
                      {/* 非帳務類 */}
                      <label
                        className={`h5 is-text-bold w-100 mt-4 p-3 border-gray text-center ${
                          this.state.form.accountType == this.state.acntTypeNonBill && 'btn-radio'
                        }`}>
                        第二代理人-非帳務
                        <input
                          type='radio'
                          value='4'
                          id='nonBill'
                          name='accountType'
                          className='radio-button w-100 h-100'
                          onChange={() => this.onChange('accountType', this.state.acntTypeNonBill)}
                        />
                      </label>
                      {this.state.form.accountType == this.state.acntTypeNonBill && (
                        <div className='gray-block p-4 font-weight-bold'>
                          <p>非帳務類功能:</p>
                          <p>產品查詢作業、障礙申告及查詢、系統使用紀錄查詢。</p>
                        </div>
                      )}
                    </div>
                    {/* 非帳務類 */}

                    {/* 設定權限區塊 */}
                    <div className={`${this.state.form.accountType == this.state.acntTypeAll ? 'd-none' : ''}`}>
                      <div className='sub-title is-text-bold pt-3 mb-4 title'>請設定權限內容</div>
                      {validation.notEmpty(this.state.authMsg) ? (
                        <span className='error-message'>{this.state.authMsg}</span>
                      ) : (
                        ''
                      )}
                      {/* 帳務權限 */}
                      {this.state.form.accountType == this.state.acntTypeBill ? (
                        <div className='gray-block p-4 mt-3 mb-5 font-weight-bold'>
                          {this.state.form.f1BillInfo == null ? null : (
                            <React.Fragment>
                              <CheckboxCollapse
                                title={'遠傳帳務資訊/新世紀資通-雲端及加值服務'}
                                content={this.state.form.f1BillInfo}
                                type={'billFunction'}
                                onchange={() => this.onChange('authChange', true)}
                              />
                              <hr />
                            </React.Fragment>
                          )}
                          {this.state.form.f5BillInfo == null ? null : (
                            <React.Fragment>
                              <CheckboxCollapse
                                title={'新世紀資通帳務資訊'}
                                content={this.state.form.f5BillInfo}
                                type={'billAccount'}
                                onchange={() => this.onChange('authChange', true)}
                              />
                              <hr />
                            </React.Fragment>
                          )}

                          {this.state.form.f6BillInfo == null ? null : (
                            <React.Fragment>
                              <CheckboxCollapse
                                title={'Seednet帳務資訊'}
                                content={this.state.form.f6BillInfo}
                                type={'billAccount'}
                                onchange={() => this.onChange('authChange', true)}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      ) : null}
                      {/* 帳務權限 */}
                      {/* 產品權限 */}
                      {this.state.form.accountType == this.state.acntTypeNonBill ? (
                        <div className='gray-block p-4 mt-3 mb-5 font-weight-bold'>
                          {this.state.form.productInfo.map((prodCate, index) => {
                            return (
                              <CheckboxCollapse
                                key={'prod' + index}
                                title={prodCate.categoryName}
                                content={prodCate}
                                type={'prod'}
                                onchange={() => this.onChange('authChange', true)}
                              />
                            );
                          })}
                        </div>
                      ) : null}
                      {/* 產品權限 */}
                    </div>
                    {/* 設定權限區塊 */}

                    {/* 修改基本資料區塊 */}
                    <input
                      type='checkbox'
                      id='basicInfo'
                      name='basicInfo'
                      value={this.state.basicInfo}
                      className='mb-4'
                      onClick={this.openBasicInfo}
                      checked={this.state.basicInfo}
                    />
                    <label htmlFor='basicInfo' className='checkbox-label font-weight-bold'>
                      修改基本資料
                    </label>

                    {this.state.basicInfo && (
                      <React.Fragment>
                        <table className='w-100'>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-30 text-center border-gray gray-block'>
                              使用者姓名
                            </td>
                            <td className='w-70 p-3 border-gray'>
                              {emptyUtil.checkVal(this.state.selectedCust.accntName)}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              更改之使用者姓名
                              <LabelInput
                                className='mt-3  ml-0'
                                name='userName'
                                maxLength='10'
                                value={this.state.form.userName}
                                placeholder='請輸入更改之使用者姓名'
                                onChange={this.onChange}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td rowSpan='2' className='w-30 text-center border-gray gray-block'>
                              使用者稱謂
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              <input
                                type='radio'
                                value='M'
                                id='M'
                                name='gender'
                                className='radio-button'
                                checked={this.state.form.gender == 'M'}
                                onChange={(e) => this.onChange('gender', e.target.value)}
                              />
                              <label htmlFor='M'>先生</label>

                              <input
                                type='radio'
                                value='F'
                                id='F'
                                name='gender'
                                className='radio-button ml-3'
                                checked={this.state.form.gender == 'F'}
                                onChange={(e) => this.onChange('gender', e.target.value)}
                              />
                              <label htmlFor='F'>女士</label>
                            </td>
                          </tr>

                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-30 text-center border-gray gray-block'>
                              使用者身分證字號
                            </td>
                            <td className='w-70 p-3 border-gray'>
                              {emptyUtil.checkVal(this.state.selectedCust.accntID)}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              更改之使用者身分證字號
                              <LabelInput
                                className='mt-3  ml-0'
                                name='rocId'
                                maxLength='10'
                                value={this.state.form.rocId}
                                placeholder='請輸入更改之使用者身分證字號'
                                onChange={this.onChange}
                                failResultMsg={this.state.rocIdMsg}
                              />
                            </td>
                          </tr>

                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-30 text-center border-gray gray-block'>
                              連絡電話
                            </td>
                            <td className='w-70 p-3 border-gray'>
                              {emptyUtil.checkVal(this.state.selectedCust.accntTelNO)}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              更改之連絡電話
                              <LabelInput
                                className='mt-3  ml-0'
                                name='companyPhone'
                                maxLength='17'
                                value={this.state.form.companyPhone}
                                placeholder='請輸入更改之連絡電話'
                                onChange={this.onChange}
                                failResultMsg={this.state.companyPhoneMsg}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-30 text-center border-gray gray-block'>
                              行動電話
                            </td>
                            <td className='w-70 p-3 border-gray'>
                              {emptyUtil.checkVal(this.state.selectedCust.accntMsisdn)}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              更改之行動電話
                              <LabelInput
                                className='mt-3 ml-0'
                                name='msisdn'
                                maxLength='10'
                                value={this.state.form.msisdn}
                                placeholder='請輸入更改之行動電話'
                                onChange={this.onChange}
                                failResultMsg={this.state.msisdnMsg}
                              />
                            </td>
                          </tr>

                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-30 text-center border-gray gray-block'>
                              傳真
                            </td>
                            <td className='w-70 p-3 border-gray'>
                              {emptyUtil.checkVal(this.state.selectedCust.accntFaxNO)}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              更改之傳真
                              <LabelInput
                                className='mt-3 ml-0'
                                name='foxNo'
                                maxLength='17'
                                value={this.state.form.foxNo}
                                placeholder='請輸入更改之傳真'
                                onChange={this.onChange}
                                failResultMsg={this.state.foxNoMsg}
                              />
                            </td>
                          </tr>

                          <tr className='bg-white'>
                            <td rowSpan='2' className='w-30 text-center border-gray gray-block'>
                              Email(發送通知函使用)
                            </td>
                            <td className='w-70 p-3 border-gray'>
                              {emptyUtil.checkVal(this.state.selectedCust.accntEmail)}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              更改之Email
                              <LabelInput
                                className='mt-3 ml-0'
                                maxLength='100'
                                name='email'
                                value={this.state.form.email}
                                placeholder='請輸入更改之Email'
                                onChange={this.onChange}
                                failResultMsg={this.state.emailMsg}
                              />
                            </td>
                          </tr>
                        </table>
                      </React.Fragment>
                    )}

                    <div className='mt-5 error-message'>{this.state.allMsg}</div>
                    <div className='row end mb-5'>
                      <Button
                        className={`${this.state.canSubmit ? '' : 'disabled'}`}
                        btnStyle='primary'
                        size='large'
                        onClick={this.submit}>
                        送出申請
                      </Button>
                      <input
                        type='checkbox'
                        name='accountRule'
                        className='ml-4 mr-2'
                        id='accountRule'
                        onClick={this.checkAccountRule}
                      />
                      <label for='accountRule' className='checkbox-label'>
                        我同意遠傳
                        <a
                          href='#'
                          onClick={() => {
                            window.open(
                              process.env.PUBLIC_URL + '/agreementPage',
                              '_blank',
                              'height=800, width=800, resizable=0'
                            );
                          }}
                          rel='noreferrer'>
                          會員條款
                        </a>
                      </label>
                    </div>
                  </Formsy>
                </div>
              )}
              {/* 第二頁 */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SubCustAccountUpdatePage);
