import React from 'react';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as validation from '../../utils/validation';
class TechContactInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 顯示頁面
      step: 1, //2:異動 , 3:新增
      hasSelect: false, //有選擇資料才能click異動button
      isPopOut: false, //確認是否刪除資料的提示視窗
      //修改欄位是否全為空
      empty: false,
      //驗證資料, 控制送出按鈕開關
      isValidate: false,
      nameErrMsg: '',
      officePhoneErrMsg: '',
      mobilePhoneErrMsg: '',
      faxErrMsg: '',
      emailErrMsg: '',
      form: {
        id: null,
        site: null, //A:甲端, B:乙端
        name: null, //新姓名
        officePhone: null, //新公司電話
        mobilePhone: null, //新手機電話
        fax: null, //新傳真號碼
        email: null, //新信箱
      },
      newForm: {
        id: null,
        site: null, //A:甲端, B:乙端
        name: null, //新姓名
        officePhone: null, //新公司電話
        mobilePhone: null, //新手機電話
        fax: null, //新傳真號碼
        email: null, //新信箱
      },

      isLoading: false,
      // 先放Mock資料
      ASiteContactInfoList: [
        {
          id: 1,
          site: 'A',
          name: '王X名',
          officePhone: '02-7710***9',
          mobilePhone: '0987***123',
          fax: '02-7710***5',
          email: 'ti***@hy-tech.com.tw',
        },
        {
          id: 2,
          site: 'A',
          name: '陳X名',
          officePhone: '02-7710***9',
          mobilePhone: '0987***123',
          fax: '02-7710***5',
          email: 'ti***@hy-tech.com.tw',
        },
        {
          id: 3,
          site: 'A',
          name: '何X名',
          officePhone: '02-7710***9',
          mobilePhone: '0987***123',
          fax: '02-7710***5',
          email: 'ti***@hy-tech.com.tw',
        },
      ],
      BSiteContactInfoList: [
        {
          id: 1,
          site: 'B',
          name: '王X名',
          officePhone: '02-7710***9',
          mobilePhone: '0987***123',
          fax: '02-7710***5',
          email: 'ti***@hy-tech.com.tw',
        },
        {
          id: 2,
          site: 'B',
          name: '林X名',
          officePhone: '02-7710***9',
          mobilePhone: '0987***123',
          fax: '02-7710***5',
          email: 'ti***@hy-tech.com.tw',
        },
        {
          id: 3,
          site: 'B',
          name: '蕭X名',
          officePhone: '02-7710***9',
          mobilePhone: '0987***123',
          fax: '02-7710***5',
          email: 'ti***@hy-tech.com.tw',
        },
      ],
    };
  }
  onChange = (name, value) => {
    // console.log('name+value', name + value);
    let newForm = Object.assign(this.state.newForm);
    newForm[name] = value.trim();
    this.setState({ newForm: newForm });
    this.enableButton();
  };
  selecteSiteContact = (siteContactInfo) => {
    this.state.newForm = siteContactInfo;
    this.state.form = JSON.parse(JSON.stringify(siteContactInfo));
    this.setState({
      hasSelect: true,
      // form: newForm,
      // newForm: siteContactInfo,
    });
    // console.log('this.state.form', this.state.form);
    // console.log('this.state.newForm', this.state.newForm);
  };
  resetNewForm = () => {
    let newForm = {
      id: null,
      site: null,
      name: null,
      officePhone: null,
      mobilePhone: null,
      fax: null,
      email: null,
    };
    this.setState({
      newForm: newForm,
    });
  };
  deleteContactInfo = () => {
    // console.log('要刪除的 this.state.form ::>', this.state.form);
  };

  //驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;

    let nameErrMsg = '';
    let officePhoneErrMsg = '';
    let mobilePhoneErrMsg = '';
    let faxErrMsg = '';
    let emailErrMsg = '';

    // 驗證格式
    if (validation.isEmpty(this.state.newForm.name)) {
      nameErrMsg = '請輸入正確的姓名';
      isValid = false;
    }
    if (validation.notEmpty(this.state.newForm.officePhone) && !validation.phone(this.state.newForm.officePhone)) {
      officePhoneErrMsg = '請輸入正確的電話';
      isValid = false;
    }
    if (validation.notEmpty(this.state.newForm.mobilePhone) && !validation.mobile(this.state.newForm.mobilePhone)) {
      mobilePhoneErrMsg = '請輸入正確的行動電話';
      isValid = false;
    }
    if (validation.notEmpty(this.state.newForm.fax) && !validation.phone(this.state.newForm.fax)) {
      // console.log('validation.email(this.state.newForm.fax)>>', validation.phone(this.state.newForm.fax));
      faxErrMsg = '請輸入正確的傳真';
      isValid = false;
    }
    if (!validation.email(this.state.newForm.email)) {
      // console.log('validation.email(this.state.newForm.email)>>', validation.email(this.state.newForm.email));
      emailErrMsg = '請輸入正確的email';
      isValid = false;
    }
    this.setState(() => ({
      nameErrMsg: nameErrMsg,
      officePhoneErrMsg: officePhoneErrMsg,
      mobilePhoneErrMsg: mobilePhoneErrMsg,
      faxErrMsg: faxErrMsg,
      emailErrMsg: emailErrMsg,
      isValidate: isValid,
    }));
  };
  componentDidMount() {
    // 指定該頁面背景顏色為白色
    document.body.style.backgroundColor = 'white';
    // 將頁面高度傳至iframe鑲嵌頁面
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  }

  render() {
    return (
      <div className='w-100 p-3'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        {/* {this.state.docList == null ? (
          <h2 className='ml-3 pt-3 pb-3'>查無技術聯絡人資料</h2>
        ) : ( */}
        <React.Fragment>
          <div>
            {this.state.step == 1 ? (
              <div className='w-100 p-3'>
                <div className='title mt-4 mb-5'></div>
                <div className='sub-title mb-4 ml-3 is-text-bold'>甲端聯絡人資料</div>
                {this.state.ASiteContactInfoList != null ? (
                  <div className='w-100 pl-3'>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        <td className='w-8 text-center border-gray'></td>
                        <td className='w-12 text-center p-3 border-gray'>姓名</td>
                        <td className='w-20 p-3 border-gray'>辦公室電話</td>
                        <td className='w-20 p-3 border-gray'>行動電話</td>
                        <td className='w-20 p-3 border-gray'>傳真</td>
                        <td className='w-20 p-3 border-gray'>Email</td>
                      </tr>
                      {this.state.ASiteContactInfoList.map((ASiteContacInfo, index) => {
                        return (
                          <tr className='bg-white' key={'ASiteContacInfo' + index}>
                            <td className='w-8 text-center border-gray'>
                              <input
                                type='radio'
                                name='siteContacInfo'
                                id={'ASiteContacInfo' + ASiteContacInfo.id}
                                onClick={() => this.selecteSiteContact(ASiteContacInfo)}
                              />
                            </td>
                            <td className='w-12 text-center p-3 border-gray'>{ASiteContacInfo.name}</td>
                            <td className='w-20 p-3 border-gray'>{ASiteContacInfo.officePhone}</td>
                            <td className='w-20 p-3 border-gray'>{ASiteContacInfo.mobilePhone}</td>
                            <td className='w-20 p-3 border-gray'>{ASiteContacInfo.fax}</td>
                            <td className='w-20 p-3 border-gray'>{ASiteContacInfo.email}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <div className='mt-3 pl-3 red font-weight-bold'>查無甲端聯絡人資料</div>
                )}
                <div className='sub-title mb-4 ml-3 mt-5 is-text-bold'>乙端聯絡人資料</div>
                {this.state.ASiteContactInfoList != null ? (
                  <div className='w-100 pl-3'>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        <td className='w-8 text-center border-gray'></td>
                        <td className='w-12 text-center p-3 border-gray'>姓名</td>
                        <td className='w-20 p-3 border-gray'>辦公室電話</td>
                        <td className='w-20 p-3 border-gray'>行動電話</td>
                        <td className='w-20 p-3 border-gray'>傳真</td>
                        <td className='w-20 p-3 border-gray'>Email</td>
                      </tr>
                      {this.state.ASiteContactInfoList.map((ASiteContacInfo, index) => {
                        return (
                          <tr className='bg-white' key={'ASiteContacInfo' + index}>
                            <td className='w-8 text-center border-gray'>
                              <input
                                type='radio'
                                name='siteContacInfo'
                                id={'ASiteContacInfo' + ASiteContacInfo.id}
                                onClick={() => this.selecteSiteContact(ASiteContacInfo)}
                              />
                            </td>
                            <td className='w-12 text-center p-3 border-gray'>{ASiteContacInfo.name}</td>
                            <td className='w-20 p-3 border-gray'>{ASiteContacInfo.officePhone}</td>
                            <td className='w-20 p-3 border-gray'>{ASiteContacInfo.mobilePhone}</td>
                            <td className='w-20 p-3 border-gray'>{ASiteContacInfo.fax}</td>
                            <td className='w-20 p-3 border-gray'>{ASiteContacInfo.email}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <div className='mt-3 pl-3 red font-weight-bold'>查無乙端聯絡人資料</div>
                )}
                <div className='button-group mb-4 ml-3'>
                  <div className='mt-5'>
                    <Button
                      btnStyle='primary'
                      size='large'
                      onClick={() => {
                        this.resetNewForm();
                        this.setState({
                          step: 3,
                        });
                      }}>
                      新增
                    </Button>
                    <Button
                      btnStyle='primary'
                      size='large'
                      disabled={!this.state.hasSelect}
                      onClick={() =>
                        this.setState({
                          isPopOut: true,
                        })
                      }>
                      刪除
                    </Button>
                    <Button
                      btnStyle='primary'
                      size='large'
                      disabled={!this.state.hasSelect}
                      onClick={() =>
                        this.setState({
                          step: 2,
                        })
                      }>
                      異動
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.step == 2 ? (
              <div>
                <div className='sub-title mb-4 ml-3 mt-5 is-text-bold'>異動聯絡人資料</div>
                <Formsy>
                  <div className='button-group p-3'>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          姓名
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.name}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3 is-required'
                            name='name'
                            value={this.state.newForm.name}
                            maxLength={17}
                            placeholder='請輸入欲更改的姓名'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.officePhoneErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          辦公室電話
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.officePhone}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='officePhone'
                            value={this.state.newForm.officePhone}
                            maxLength={17}
                            placeholder='請輸入更改的辦公室電話'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.officePhoneErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          行動電話
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.mobilePhone}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='mobilePhone'
                            maxLength={10}
                            value={this.state.newForm.mobilePhone}
                            placeholder='請輸入更改的行動電話'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.mobilePhoneErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          傳真
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.fax}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='fax'
                            maxLength={17}
                            value={this.state.newForm.fax}
                            placeholder='請輸入更改的傳真號碼'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.faxErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          Email
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.email}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='email'
                            maxLength={17}
                            value={this.state.newForm.email}
                            placeholder='請輸入更改的Email地址'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.faxErrMsg}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </Formsy>
                <div className='mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    onClick={() => this.submitSiteContactInfo()}
                    // disabled={!this.state.isValidate}
                  >
                    送出
                  </Button>
                  <Button btnStyle='secondary' size='large' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.step == 3 ? (
              <div>
                <div className='title mb-5 ml-3 mt-5 is-text-bold'>新增聯絡人資料</div>
                <Formsy>
                  <Grid container spacing={3} className='mt-md-3 ml-1 form-group '>
                    <Grid item xs={12} md={8}>
                      <label className='h5 ml-4 is-required'>新增</label>
                      <input
                        type='radio'
                        className='radio-button ml-4'
                        name='site'
                        id='ASite'
                        value='A'
                        checked={this.state.newForm.site == 'A'}
                        onChange={(e) => this.onChange('site', e.target.value)}></input>
                      <label htmlFor='ASite' className='h5'>
                        甲端
                      </label>
                      <input
                        type='radio'
                        className='radio-button ml-4'
                        name='site'
                        value='B'
                        id='BSite'
                        checked={this.state.newForm.site == 'B'}
                        onChange={(e) => this.onChange('site', e.target.value)}></input>
                      <label htmlFor='BSite' className='h5'>
                        乙端
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='name'
                        value={this.state.newForm.name}
                        required={true}
                        placeholder='請輸入欲新增的聯絡人姓名'
                        label='姓名'
                        onChange={this.onChange}
                        failResultMsg={this.state.nameErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='officePhone'
                        value={this.state.newForm.officePhone}
                        required={true}
                        placeholder='請輸入欲新增的聯絡人的辦公室電話'
                        label='辦公室電話'
                        onChange={this.onChange}
                        failResultMsg={this.state.officePhoneErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='mobilePhone'
                        value={this.state.newForm.mobilePhone}
                        required={true}
                        label='行動電話'
                        placeholder='請輸入欲新增的聯絡人的行動電話'
                        onChange={this.onChange}
                        failResultMsg={this.state.mobilePhoneErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='fax'
                        value={this.state.newForm.fax}
                        required={true}
                        label='傳真'
                        placeholder='請輸入欲新增的聯絡人的傳真'
                        onChange={this.onChange}
                        failResultMsg={this.state.faxErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='email'
                        value={this.state.newForm.email}
                        required={true}
                        label='Email'
                        placeholder='請輸入欲新增的聯絡人的Email'
                        onChange={this.onChange}
                        failResultMsg={this.state.emailErrMsg}
                      />
                    </Grid>
                  </Grid>
                </Formsy>
                <div className='ml-4 mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    onClick={() => this.submitSiteContactInfo()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' size='large' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            <Dialog
              open={this.state.isPopOut}
              onClose={() => this.setState({ isPopOut: false })}
              fullWidth
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>請確認是否要刪除這筆聯絡人資料?</div>
              </DialogContent>
              <DialogActions>
                <Button
                  btnStyle='primary'
                  className='w5 my-2'
                  onClick={() => {
                    this.deleteContactInfo();
                    this.setState({
                      isPopOut: false,
                    });
                  }}
                  autoFocus>
                  確認
                </Button>
                <Button
                  btnStyle='secondary'
                  className='w5 my-2'
                  onClick={() =>
                    this.setState({
                      isPopOut: false,
                    })
                  }
                  autoFocus>
                  取消
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
        {/* )} */}
      </div>
    );
  }
}

export default TechContactInfoPage;
