import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../../components/Button';
import * as callApiUtil from '../../utils/callApiUtil';
import RadioGroup from '../../components/form/RadioGroup';
import Formsy from 'formsy-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faStamp, faFileUpload, faFileWord } from '@fortawesome/free-solid-svg-icons';
import * as validation from '../../utils/validation';

class TransactionApplication extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      //資料
      title: '',
      doc: '',
      applyTrustee: true, //預設是會顯示2. 填寫&列印 的文件
    };
  }
  componentDidMount = () => {
    // 1.若註冊帳號時無申請受任人，則不顯示企業客戶限定委任書
    // 2.若第一代理人修改第一代理人基本資料，也不顯示受任人相關資訊
    if (this.props.applyTrustee === false) {
      this.setState({
        applyTrustee: this.props.applyTrustee,
      });
    }
    callApiUtil
      .callApi(
        '/ECPAP/API/CustAccountApplyController/docDownloadInit.action',
        {
          displayPath: window.location.pathname,
        },
        null,
        true //loading遮罩
      )
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success &&
          response.docInfoBeanList[0]
        ) {
          let docBeanList = [];
          if (validation.notEmpty(response.docInfoBeanList)) {
            let index = 0;
            for (index in response.docInfoBeanList) {
              // 取出帳號申請相關檔案下載
              if (response.docInfoBeanList[index].categoryId === 'DC0066') {
                docBeanList = response.docInfoBeanList[index];
              }
            }
          }
          this.setState({
            doc: docBeanList.documentList,
          });
        }
      });
  };

  handleClick = () => {};

  download(document) {
    if (document.uri != null) {
      window.open(document.uri, document.uri, 'height=auto,width=auto'); // 呼叫外部URI下載檔案
    } else {
      callApiUtil.postFile(
        '/ECPAP/API/CustAccountApplyController/downloadDoc.action',
        {
          documentId: document.documentId,
        },
        null, //成功後執行
        null, //錯誤處理
        true //loading遮罩
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        {validation.notEmpty(this.props.title) ? (
          <div className='title pt-2 ml-3'>{this.props.title ? this.props.title : null}</div>
        ) : null}
        <div className='container'>
          <ul className='step-list mt-2 pl-0'>
            <li className='mb-1 d-flex container vh35 pl-4 align-items-center'>
              <div className='row w-100 pl-2 mb-0'>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                  <FontAwesomeIcon icon={faPrint} size={'4x'} />
                </div>
                <div className='col-10'>
                  <div className='mt-0 ml-4 title-blue h4'>1. 列印「企業客戶網路服務平台-ECP」申請書</div>
                  <p className='mt-0 ml-4 h5'>請列印方才填寫的申請書，並確認資料無誤。</p>
                  <div className='button-group ml-4 mt-2'>
                    <div className='row two-buttons end'>
                      <button className='is-primary fui-button col-3 mt-4' onClick={this.props.print}>
                        列印
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <hr></hr>
            <div>
              <li className='mb-1 d-flex container vh35 pl-4 align-items-center'>
                <div className='row w-100 pl-2 mb-0'>
                  <div className='col-2 d-flex justify-content-center align-items-center'>
                    <FontAwesomeIcon icon={faFileWord} size={'4x'} />
                  </div>
                  <div className='col-10'>
                    <div className='mt-0 ml-4 title-blue h4'>2. 填寫&列印「受任人委任書」</div>
                    <p className='mt-0 ml-4 h5'>
                      {!this.state.applyTrustee || this.state.doc == null || this.state.doc.length == 0 ? (
                        <div className='my-5'>
                          <span className='sub-title'>無需填寫或下載受任人委任書</span>
                        </div>
                      ) : (
                        <React.Fragment>
                          {this.state.doc.map((document, i) => (
                            <div>
                              <span>請下載「</span>
                              <a className='pointer' href='#' onClick={() => this.download(document)}>
                                {document.displayName}
                              </a>
                              <span>」</span>
                              <br />
                              <span>並請於委任書用印處蓋上公司大小章並於受任人簽名欄位簽名。</span>
                            </div>
                          ))}
                        </React.Fragment>
                      )}
                    </p>
                  </div>
                </div>
              </li>
              <hr></hr>
            </div>
            <li className='mb-1 d-flex container vh35 pl-4 align-items-center'>
              <div className='row w-100 pl-2 mb-0'>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                  <FontAwesomeIcon
                    icon={faStamp}
                    size={'4x'}
                    className={'d-flex justify-content-center align-items-center"'}
                  />
                </div>
                <div className='col-10'>
                  <div className='mt-0 ml-4 title-blue h4'>3. 簽名與蓋章</div>
                  <p className='mt-0 ml-4 h5'>請於申請書用印處蓋上公司大小章，並在帳號使用者欄位簽名。</p>
                </div>
              </div>
            </li>
            <hr></hr>
            <li className='mb-1 d-flex container vh35 pl-4 align-items-center'>
              <div className='row w-100 pl-2 mb-0'>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                  <FontAwesomeIcon
                    icon={faFileUpload}
                    size={'4x'}
                    className={'d-flex justify-content-center align-items-center"'}
                  />
                </div>
                <div className='col-10'>
                  <div className='mt-0 ml-4 title-blue h4'>4. 提交申請書</div>
                  <p className='mt-0 ml-4 h5'>
                    1.請確認申請書已用印無誤，並檢附相關證件：
                    <p className='ml-4 mb-1 text_size_12'>
                      公司營業登記證+負責人身分證正反面影本。
                      <br />
                      {this.state.applyTrustee ? '受任人: 身分證正反面影本+健保卡/駕照影本。' : null}
                      {this.state.applyTrustee ? <br /> : null}
                      第一代理人：身分證正反面影本。
                    </p>
                    2.若同時新申請受任人，請一併檢附限定委任書。
                    <br />
                    3.郵寄至『台北市114內湖區港墘路220號10樓』遠傳電信股份有限公司 訂單管理組 收。
                  </p>
                </div>
              </div>
            </li>
          </ul>

          <hr></hr>
          <div className='button-group mt-4 mb-3'>
            <div className='row two-buttons'>
              {/* #4882 若寄出確認信，button 確認送出改為disabled */}
              {this.props.isMailed ? (
                <Button className='col-3 mt-3 middle' btnStyle='primary' size='large' disabled={this.props.isMailed}>
                  確認送出
                </Button>
              ) : (
                <Button
                  className='col-3 mt-3 middle'
                  btnStyle='primary'
                  size='large'
                  onClick={this.props.remind}
                  disabled={this.state.applyType == 'online' && this.state.upload == null ? 'disabled' : ''}>
                  確認送出
                </Button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(TransactionApplication);
