import React from 'react';
import { Navigation } from 'react-minimal-side-navigation';
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: {
        menuId: '',
        menuName: '',
        pageList: [],
        uri: '',
      },
      item: [
        {
          title: '',
          itemId: '',
        },
      ],
    };
  }
  //從session取得對應的siderBar清單

  componentDidMount() {
    //取得當前頁面的URI
    let pageUri = window.location.pathname;
    //取得該用戶的menuList
    let sysMenu = window.localStorage.getItem('menuList');
    if (sysMenu != null) {
      let filist = JSON.parse(sysMenu);
      //過濾siderBar pageList有值才會有sideBar
      let filterList = filist.menuList.filter((value) => value.pageList.length > 0);
      let modulId = '';
      if (pageUri === '/ECP/accountManagePage') {
        modulId = 'M01';
      }
      //取的該頁面URI對應的modulId
      filterList.forEach(function (item, index, array) {
        if (item != null) {
          item.pageList.forEach(function (value, index, array) {
            if (pageUri.includes(value.uri)) {
              modulId = value.moduleId;
            }
          });
        }
      });
      //用modulId取得對應的 sidebar
      let pageList = filterList.find((value) => value.menuId === modulId);
      if (pageList != null && pageList != undefined) {
        let itemList = pageList.pageList.reduce((accumulator, value, index) => {
          accumulator.push({
            title: value.itemName,
            itemId: value.uri,
          });
          return accumulator;
        }, []);
        this.setState({
          pageList: pageList,
          item: itemList,
        });
      }
    }
  }
  toPage = (itemId) => {
    window.location.href = itemId;
  };

  render() {
    return (
      <div className={`left-sidebar ${this.props.className}`}>
        <div className='nav-title side-navigation-panel-select-option'>{this.state.pageList.menuName}</div>

        <Navigation
          onSelect={({ itemId }) => {
            this.toPage(itemId);
          }}
          items={this.state.item}
        />
      </div>
    );
  }
}
export default Sidebar;
