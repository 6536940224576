import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';
import * as callApiUtil from '../../utils/callApiUtil';
import * as emptyUtil from '../../utils/emptyUtil';
import Pagination from '../../components/Pagination';
import LabelInput from '../../components/form/LabelInput';
import Sidebar from '../../components/Sidebar';
import * as validation from '../../utils/validation';

class UploadApplyRecordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchBar: false, //是否彈出搜尋欄位
      searchSubmit: false, //送出查詢條件與否
      yearList: [],
      monthList: [],
      startDayList: [],
      endDayList: [],
      curDate: null,
      form: {
        startYear: { value: '', text: '' }, //查詢起始時間
        startMonth: { value: '', text: '' },
        startDay: { value: '', text: '' },
        endYear: { value: '', text: '' }, //結束時間
        endMonth: { value: '', text: '' },
        endDay: { value: '', text: '' },
        applyCode: null,
      },
      canSubmit: true,
      searchResultList: [],
      errMsg: '',
      //分頁
      currentPage: 1,
      maxlength: 10, //一頁幾筆資料
      showMax: 6, //分頁欄顯示頁數
    };
  }

  componentDidMount() {
    let endDate = new Date();
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth() + 1;
    let endDay = endDate.getDate();

    let startDate = new Date();
    startDate.setMonth(endMonth - 3);
    let startYear = startDate.getFullYear();

    let yearList;
    if (startYear == endYear) {
      yearList = [{ text: startYear, value: startYear }];
    } else {
      yearList = [
        { text: endYear, value: endYear },
        { text: endYear - 1, value: endYear - 1 },
      ];
    }
    let monthList = this.getMonthList();
    let startDayList = this.getDayList(endYear, endMonth);
    let endDayList = this.getDayList(endYear, endMonth);

    // 設定預設查詢起始日期
    let newForm = Object.assign(this.state.form);
    newForm['endYear'] = { value: endYear, text: endYear };
    newForm['endMonth'] = { value: endMonth, text: endMonth };
    newForm['endDay'] = { value: endDay, text: endDay };

    let defalutStartDate = new Date();
    defalutStartDate.setDate(endDay - 7);
    newForm['startYear'] = { value: defalutStartDate.getFullYear(), text: defalutStartDate.getFullYear() };
    newForm['startMonth'] = { value: defalutStartDate.getMonth() + 1, text: defalutStartDate.getMonth() + 1 };
    newForm['startDay'] = { value: defalutStartDate.getDate(), text: defalutStartDate.getDate() };

    this.setState(() => ({
      curDate: endDate,
      yearList: yearList,
      monthList: monthList,
      startDayList: startDayList,
      endDayList: endDayList,
      form: newForm,
    }));
  }

  //是否彈出搜尋欄位
  searchBar = () => {
    this.setState(() => ({
      searchBar: !this.state.searchBar,
    }));
  };

  //送出查詢條件(依條件抓回資料更新table)
  searchSubmit = () => {
    if (this.validate()) {
      this.setState({ isLoading: true });
      //組成API input Value
      let startDate = new Date(
        this.state.form.startYear.value,
        this.state.form.startMonth.value - 1,
        this.state.form.startDay.value
      );
      let endDate = new Date(
        this.state.form.endYear.value,
        this.state.form.endMonth.value - 1,
        this.state.form.endDay.value
      );
      endDate.setDate(endDate.getDate() + 1);

      let Vin = {
        applyCode: this.state.form.applyCode,
        startDate: startDate,
        endDate: endDate,
      };
      callApiUtil.callApi('/ECPAP/API/UploadApplyRecordController/queryRecordInit.action', Vin).then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          //API回傳成功
          this.searchBar();
          this.setState(() => ({
            searchResultList: response.orderReviewInfoList,
            searchSubmit: true,
          }));
          // 取完初始資料後, 取第一頁資料在 AIMS 中的狀態;
          this.getAimsData(1, response.orderReviewInfoList);
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '產品服務異動進件紀錄查詢',
              title: '產品服務異動進件紀錄查詢',
              description: response.resultMessage.message,
              actions: [
                {
                  text: '返回產品服務異動進件紀錄查詢',
                  link: '/uploadApplyRecordPage',
                  btnStyle: 'primary',
                },
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'secondary',
                },
              ],
            }
          );
        }
      });
    }
  };

  getAimsData = (page, applyList) => {
    if (this.validate()) {
      this.setState({ isLoading: true });
      // 取該分頁的資料
      let applyCodeList = [];
      let topLimit = page * this.state.maxlength > applyList.length ? applyList.length : page * this.state.maxlength;
      let bottomLimit = (page - 1) * this.state.maxlength;
      applyList.slice(bottomLimit, topLimit).map((applyInfo) => applyCodeList.push(applyInfo.applyCode));
      let Vin = { ecpApplyCodeList: applyCodeList };
      callApiUtil.callApi('/ECPAP/API/UploadApplyRecordController/queryRecord.action', Vin).then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          //API回傳成功
          // 更新此次查詢的資料中, AIMS 中存有資料的狀態
          if (response.orderReviewInfoList != null && response.orderReviewInfoList.length > 0) {
            for (let i = 0; i < response.orderReviewInfoList.length; i++) {
              let resInfo = response.orderReviewInfoList[i];
              for (bottomLimit; bottomLimit < topLimit; bottomLimit++) {
                let applyInfo = applyList[bottomLimit];
                if (resInfo.applyCode == applyInfo.applyCode) {
                  applyInfo.status = resInfo.status;
                  applyInfo.aimsCode = resInfo.aimsCode;
                  applyInfo.reason = resInfo.reason;
                  applyInfo.prodName = resInfo.prodName;
                  break;
                }
              }
            }
          }

          this.setState(() => ({
            searchSubmit: true,
            isLoading: false,
            searchResultList: applyList,
          }));
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '產品服務異動進件紀錄查詢',
              title: '產品服務異動進件紀錄查詢',
              description: response.resultMessage.message,
              actions: [
                {
                  text: '返回產品服務異動進件紀錄查詢',
                  link: '/uploadApplyRecordPage',
                  btnStyle: 'primary',
                },
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'secondary',
                },
              ],
            }
          );
        }
      });
    }
  };

  validate = () => {
    // 驗證查詢日期
    let errMsg;
    let canSubmit = true;
    if (
      validation.notEmpty(this.state.form.startYear.value) &&
      validation.notEmpty(this.state.form.startMonth.value) &&
      validation.notEmpty(this.state.form.startDay.value) &&
      validation.notEmpty(this.state.form.endYear.value) &&
      validation.notEmpty(this.state.form.endMonth.value) &&
      validation.notEmpty(this.state.form.endDay.value)
    ) {
      let curDate = this.state.curDate;
      let startDate = new Date(
        this.state.form.startYear.value,
        this.state.form.startMonth.value - 1,
        this.state.form.startDay.value
      );
      let endDate = new Date(
        this.state.form.endYear.value,
        this.state.form.endMonth.value - 1,
        this.state.form.endDay.value
      );
      endDate.setDate(endDate.getDate() + 1);

      // 驗證查詢開始日與結束日是否超過當前三個月
      let difSTime = parseInt(Math.abs(curDate - startDate) / 1000 / 60 / 60 / 24);
      if (difSTime > 90) {
        errMsg = '查詢時間僅限三個月內，請重新選擇查詢';
        canSubmit = false;
      }

      // 驗證單次查詢是否超過一個月
      let difTime = parseInt(Math.abs(endDate - startDate) / 1000 / 60 / 60 / 24);
      if (difTime > 30) {
        errMsg = '單次查詢，僅能查詢一個月的紀錄，請重新選擇查詢';
        canSubmit = false;
      }

      if (startDate > endDate) {
        // 日期比大小
        errMsg = '查詢起始時間晚於結束時間，請選擇正確的日期';
        canSubmit = false;
      }
    } else {
      errMsg = '請選擇正確的查詢時間';
      canSubmit = false;
    }
    this.setState(() => ({
      errMsg: errMsg,
      canSubmit: canSubmit,
    }));

    return canSubmit;
  };

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name] = value;
    this.setState(() => ({
      form: newForm,
    }));
  };

  dateChange = (name, selected) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = selected.value;
    newForm[name].text = selected.text;
    let startDayList = this.state.startDayList;
    let endDayList = this.state.endDayList;
    if (name == 'startMonth') {
      startDayList = this.getDayList(this.state.form.startYear.value, selected.value);
      newForm['startDay'] = { value: '', text: '' };
      this.setState(() => ({
        startDayList: startDayList,
      }));
    } else if (name == 'endMonth') {
      endDayList = this.getDayList(this.state.form.endYear.value, selected.value);
      newForm['endDay'] = { value: '', text: '' };
      this.setState(() => ({
        endDayList: endDayList,
      }));
    } else if (name == 'startYear') {
      startDayList = this.getDayList(selected.value, this.state.form.startMonth.value);
      newForm['startDay'] = { value: '', text: '' };
      this.setState(() => ({
        startDayList: startDayList,
      }));
    } else if (name == 'endYear') {
      endDayList = this.getDayList(selected.value, this.state.form.endMonth.value);
      newForm['endDay'] = { value: '', text: '' };
      this.setState(() => ({
        endDayList: endDayList,
      }));
    }
    this.setState({ form: newForm, startDayList: startDayList, endDayList: endDayList, canSubmit: true, errMsg: '' });
  };

  //分頁換頁
  handleChangePage = (newPage) => {
    this.getAimsData(newPage, this.state.searchResultList);
    this.setState({ currentPage: newPage });
  };

  // 初始化表單資料
  resetData = () => {
    let newForm = {
      startYear: { value: this.state.startYear, text: this.state.startYear }, //查詢起始時間
      startMonth: { value: this.state.startMonth, text: this.state.startMonth },
      startDay: { value: this.state.startDay, text: this.state.startDay },

      endYear: { value: this.state.endYear, text: this.state.endYear }, //結束時間
      endMonth: { value: this.state.endMonth, text: this.state.endMonth },
      endDay: { value: this.state.endDay, text: this.state.endDay },
      applyCode: null,
    };
    this.setState({ form: newForm });
  };

  getMonthList() {
    let monthList = [];
    for (let i = 1; i < 13; i++) {
      monthList.push({ value: i.toString(), text: i.toString() });
    }
    return monthList;
  }

  getDayList(year, month) {
    // 2021/08/11 修改日期沒有隨月份變更問題
    let dayList = [];
    let day = new Date(year, month, 0).getDate();
    for (let i = 1; i <= day; i++) {
      dayList.push({ value: i.toString(), text: i.toString() });
    }
    return dayList;
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>產品服務異動進件紀錄查詢</title>
        </Helmet>
        <div className={this.state.isLoading ? 'is-loading' : ''} />

        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' history={this.props.history}></Sidebar>
              <div className='col-9 container bg-white shadow pl-4 pr-4'>
                <div className='title pt-2 mt-5 ml-3'>
                  產品服務異動進件紀錄查詢
                  <div className='h5 mt-4 is-text-bold text-black w-90'>
                    *本服務之查詢資料僅保留 3 個月內之資料，且單筆查詢最多僅能查詢 30 天的資料。
                  </div>
                </div>
                {this.state.searchBar ? (
                  <div style={{ position: 'absolute', top: '50%', left: '90%' }}>
                    <button className='is-text-bold bg-white title no-border' onClick={this.searchBar}>
                      +
                    </button>
                  </div>
                ) : null}

                {!this.state.searchBar ? (
                  <Formsy className='pl-3 ml-4'>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10} className='form-group'>
                        <label className='h5 ml-0 mb-4 is-text-bold is-required'>查詢起始時間</label>
                        <div className='row h5 is-text-bold align-items-center'>
                          <Dropdown
                            className='is-button col-3 pl-0'
                            list={this.state.yearList}
                            label={this.state.form.startYear.value ? this.state.form.startYear.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.dateChange('startYear', e)}
                          />
                          年
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.monthList}
                            label={this.state.form.startMonth.value ? this.state.form.startMonth.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.dateChange('startMonth', e)}
                          />
                          月
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.startDayList}
                            label={this.state.form.startDay.value ? this.state.form.startDay.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.dateChange('startDay', e)}
                          />
                          日
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10} className='form-group'>
                        <label className='h5 ml-0 mb-4 is-text-bold is-required'>結束時間</label>
                        <div className='row h5 is-text-bold align-items-center'>
                          <Dropdown
                            className='is-button col-3 pl-0'
                            list={this.state.yearList}
                            label={this.state.form.endYear.value ? this.state.form.endYear.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.dateChange('endYear', e)}
                          />
                          年
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.monthList}
                            label={this.state.form.endMonth.value ? this.state.form.endMonth.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.dateChange('endMonth', e)}
                          />
                          月
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.endDayList}
                            label={this.state.form.endDay.value ? this.state.form.endDay.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.dateChange('endDay', e)}
                          />
                          日
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10}>
                        <LabelInput
                          className='ml-0 mb-5 w-60'
                          name='applyCode'
                          value={this.state.form.applyCode}
                          label='進件編號'
                          maxLength='16'
                          placeholder='請輸入進件編號'
                          onChange={this.onChange}
                          failResultMsg={this.state.errMsg}
                        />
                      </Grid>
                    </Grid>
                    <div className='two-buttons mb-5'>
                      <Button
                        btnStyle='primary'
                        size='large'
                        disabled={!this.state.canSubmit}
                        onClick={this.searchSubmit}>
                        查詢
                      </Button>
                      <Button btnStyle='secondary' size='large' onClick={this.resetData}>
                        重填
                      </Button>
                    </div>
                  </Formsy>
                ) : null}
              </div>

              {/* 查詢結果 */}
              {this.state.searchSubmit ? (
                <React.Fragment>
                  <div className='col-3' style={{ 'margin-left': '-1%' }} />
                  <div className=' col-9 container bg-white shadow mt-5'>
                    <div className='pl-5 pr-5'>
                      <div className='title pt-2 mt-5'>查詢結果</div>
                      <table className='w-100 mb-5'>
                        <tr className='white bg-dropdown'>
                          <td className='h5 text-center border-gray'>進件編號</td>
                          <td className='h5 text-center border-gray'>上傳時間</td>
                          <td className='h5 text-center border-gray'>狀態</td>
                          <td className='h5 text-center border-gray'>產品類別</td>
                          <td className='h5 text-center border-gray'>AIMS 單號</td>
                          <td className='h5 text-center border-gray'>退件原因</td>
                        </tr>
                        {this.state.searchResultList
                          .slice(
                            (this.state.currentPage - 1) * this.state.maxlength,
                            this.state.currentPage * this.state.maxlength
                          )
                          .map((item, idx) => (
                            <tr className='bg-white' key={'searchResultList' + idx}>
                              <td className='p-3 text-center border-gray'>{emptyUtil.checkVal(item.applyCode)}</td>
                              <td className='p-3 text-center border-gray'>{emptyUtil.checkVal(item.uploadTime)}</td>
                              <td className='p-3 text-center border-gray'>{emptyUtil.checkVal(item.status)}</td>
                              <td className='p-3 text-center border-gray'>{emptyUtil.checkVal(item.prodName)}</td>
                              <td className='p-3 text-center border-gray'>{emptyUtil.checkVal(item.aimsCode)}</td>
                              <td className='p-3 text-center border-gray'>{emptyUtil.checkVal(item.reason)}</td>
                            </tr>
                          ))}
                      </table>
                      {this.state.searchResultList.length > this.state.maxlength ? (
                        <div>
                          <hr></hr>
                          <Pagination
                            changePage={this.handleChangePage}
                            currentPage={this.state.currentPage}
                            totalPage={Math.ceil(this.state.searchResultList.length / this.state.maxlength)}
                            showMax={this.state.showMax}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(UploadApplyRecordPage);
