import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import * as callApiUtil from '../../../utils/callApiUtil';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import { Table } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
import * as validation from '../../../utils/validation';
class UserNoUserNamePage extends React.Component {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.state = {
      // 顯示頁面
      isLoading: 'false',
      prodName: '',
      prodInfo: this.props.prodInfo,
      title: this.props.title,
      prodId: this.props.prodId,
      userNo: this.props.userNo,
      extraInfo: this.props.extraInfo,
      showButton: true,
      //分頁
      currentPage: 1,
      maxlength: 10, //一頁幾筆資料
      showMax: 10, //分頁欄顯示頁數
    };
  }

  queryByAccntId = () => {
    this.setState({ isLoading: true });
    let vin = {
      prodName: this.props.prodName,
      extraInfo: this.props.acctId,
      productId: this.props.prodId,
      titleId: this.props.title,
    };
    // 打API撈取資料
    callApiUtil.callApi('/ECPAP/API/ProductController/queryProdInfoByExtraInfo.action', vin).then((response) => {
      // 判斷回傳資料
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success
      ) {
        //API回傳成功，將資料顯示在頁面上
        this.setState({
          isLoading: false,
          prodInfo: response.prodInfo,
          // 再次查詢時不再顯示查詢其他服務按鈕
          showButton: false,
          title: response.title,
          prodName: response.prodName,
        });
        //將頁面高度傳至iframe鑲嵌頁面
        this.handleClick();
      } else {
        //API回傳失敗
        this.setState({ isLoading: false });
        this.props.history.push(
          {
            pathname: '/basicResultNoHeaderPage',
          },
          {
            type: 'fail',
            pageName: '產品服務作業查詢',
            title: '查詢失敗',
            description: response.resultMessage.message,
          }
        );
      }
    });
  };

  getItemInfo = (item) => {
    let result = [];

    for (const [key, value] of Object.entries(item)) {
      result.push(
        <td className='p-3 border-gray' key={key + value}>
          {value}
        </td>
      );

      result.push();
    }

    return result;
  };

  getTitle = (item) => {
    // 先取得最上面說明列
    let result = [];
    for (const [key, value] of Object.entries(item)) {
      result.push(<th className='p-3 border-gray keep-all'>{key}</th>);
    }
    return result;
  };

  handleChangePage = (newPage) => {
    this.setState({ currentPage: newPage });
  };

  handleClick = () => {
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  };

  render() {
    return (
      <React.Fragment>
        <table className='w-100'>
          <tr className='bg-white mb-3'>
            <td className='w-40 text-center border-gray'>客戶編號與客戶名稱</td>
            <td className='w-60 p-3 border-gray'>
              {!validation.isEmpty(this.state.title) ? this.state.title : <span>N/A</span>}
            </td>
          </tr>
        </table>
        <div className='h4 title-blue is-text-bold mt-5'>產品資料</div>
        <ScrollContainer className='mb-4 scroll-container' hideScrollbars='false'>
          <Table responsive className='mt-4 text-center '>
            <tr className='bg-white'>
              {/* 先取header */}
              {this.state.prodInfo != null && this.state.prodInfo.length > 0 ? (
                this.getTitle(this.state.prodInfo[0])
              ) : (
                <td className='p-3 border-gray is-text-bold red'>查無產品基本資料，請洽客服人員!</td>
              )}
            </tr>
            {this.state.prodInfo != null && this.state.prodInfo.length > 0
              ? this.state.prodInfo
                  .slice(
                    (this.state.currentPage - 1) * this.state.maxlength,
                    this.state.currentPage * this.state.maxlength
                  )
                  .map((item, idx) => (
                    <tr className='bg-white' key={'item' + idx}>
                      {item != null ? this.getItemInfo(item) : null}
                    </tr>
                  ))
              : null}
          </Table>
        </ScrollContainer>
        {this.state.prodInfo != null && this.state.prodInfo.length > this.state.maxlength ? (
          <Pagination
            changePage={this.handleChangePage}
            currentPage={this.state.currentPage}
            totalPage={Math.ceil(this.state.prodInfo.length / this.state.maxlength)}
            showMax={this.state.showMax}
          />
        ) : null}
        {/* 2021/12/27 同一服務號碼可能有多筆資料, 所以不限制筆數為 1 才顯示 */}
        {this.state.prodInfo != null && this.state.prodInfo.length > 0 && this.state.showButton ? (
          <Button className='fui-button is-primary' onClick={this.queryByAccntId}>
            查詢同一用戶編號的其他服務號碼
          </Button>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(UserNoUserNamePage);
