import React from 'react';
import { withRouter } from 'react-router-dom';
import * as callApiUtil from '../../utils/callApiUtil';
import '../../css/print.css';
import * as emptyUtil from '../../utils/emptyUtil';

class PrintMaintFirstAgentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: {
        //訂單資訊
        applyCode: '',
        createDate: '',
        //公司資料
        cmpName: '',
        cmpFaxNo: '',
        cmpTelNo: '',
        cmpAddress1: '',
        cmpAddress2: '',
        userId: '',
        cmpOwner: '',
        cmpOwnerId: '',
        // 第一代理人資料
        accntName: '',
        gender: '',
        accntEmail: '',
        accntFaxNo: '',
        accntMsisdn: '',
        accntTelNo: '',
        accntId: '',
        loginId: '',
      },
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/MaintainFirstAgentController/print.action', null, '/ECP/maintainFirstAgentPage')
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          //API回傳成功
          let createDate = new Date(response.createDate);
          let newForm = {
            //訂單資訊
            applyCode: emptyUtil.checkVal(response.applyCode),
            createDate: `${createDate.getFullYear()}/${createDate.getMonth() + 1}/${createDate.getDate()}`,
            // 公司基本資料
            cmpName: emptyUtil.checkVal(response.cmpName),
            cmpFaxNo: emptyUtil.checkVal(response.cmpFaxNo),
            cmpTelNo: emptyUtil.checkVal(response.cmpTelNo),
            cmpAddress1: emptyUtil.checkVal(response.cmpAddress1),
            cmpAddress2: emptyUtil.checkVal(response.cmpAddress2),
            userId: emptyUtil.checkVal(response.userId),
            cmpOwner: emptyUtil.checkVal(response.cmpOwner),
            cmpOwnerId: emptyUtil.checkVal(response.cmpOwnerId),
            // 第一代理人資料
            accntName: emptyUtil.checkVal(response.accntName),
            gender: emptyUtil.checkVal(response.gender),
            accntEmail: emptyUtil.checkVal(response.accntEmail),
            accntFaxNo: emptyUtil.checkVal(response.accntFaxNo),
            accntMsisdn: emptyUtil.checkVal(response.accntMsisdn),
            accntTelNo: emptyUtil.checkVal(response.accntTelNo),
            accntId: emptyUtil.checkVal(response.accntId),
            loginId: emptyUtil.checkVal(response.loginId),
          };
          this.setState({ form: newForm, isLoading: false });
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '第一代理人(管理者)變更及基本資料異動申請',
              title: '第一代理人(管理者)變更及基本資料異動申請',
              description: response.resultMessage.message,
            }
          );
        }
      });
  }

  render() {
    return (
      <div className='printPage'>
        <div className={this.state.isLoading ? 'is-loading' : ''} />
        {/* 禁止右鍵 */}
        <body
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}>
          <div class='row'>
            <div class='col'></div>
            <div class='col-6' className='printPage'>
              <table width='635' cellSpacing='0' cellPadding='0' style={{ border: '0' }}>
                <tbody>
                  <tr>
                    <td width='120' height='90' valign='top' rowSpan='2'>
                      <img src={process.env.PUBLIC_URL + '/resources/common/images/logo.png'} width='120' height='71' />
                    </td>
                    <td height='45' align='center'>
                      <strong>遠傳電信企業用戶專區第一代理人(管理者)資料異動申請書 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td height='45' align='right'>
                      <p className='text_size_12'>營業機密：客戶資料不得外洩或複製</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='printbox'>
                <table style={{ border: '0' }} cellPadding='0' cellSpacing='0'>
                  <tr>
                    <td>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/common/images/printimg01.jpg'}
                        width='631'
                        height='53'
                        alt=''
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width='611' style={{ border: '0' }} cellPadding='5' cellSpacing='0' className='printtable'>
                        <tr>
                          <td align='right' className='titletd02'>
                            表單序號：
                          </td>
                          <td colSpan='3' className='contenttd03'>
                            {this.state.form.applyCode}
                          </td>
                        </tr>
                        <tr>
                          <td width='22%' align='right' className='titletd02'>
                            申請日期：
                          </td>
                          <td width='28%' className='contenttd03'>
                            {this.state.form.createDate}
                          </td>
                          <td width='24%' align='right' className='titletd02'>
                            申請事由：
                          </td>
                          <td className='contenttd03'>異動帳號資料</td>
                        </tr>
                      </table>
                      <table width='611' style={{ align: 'center', border: '0' }} cellPadding='0' cellSpacing='0'>
                        <tr>
                          <td>
                            <table width='100%' style={{ border: '0' }} cellPadding='5' cellSpacing='0'>
                              <tr>
                                <td width='10'>
                                  <img
                                    src={process.env.PUBLIC_URL + '/resources/common/images/printimg03.gif'}
                                    width='10'
                                    height='9'
                                  />
                                </td>
                                <td>
                                  <strong>公司基本資料</strong>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src={process.env.PUBLIC_URL + '/resources/common/images/printimg02.jpg'}
                              width='611'
                              height='20'
                              alt=''
                            />
                          </td>
                        </tr>
                      </table>
                      <table width='611' style={{ border: '0' }} cellPadding='5' cellSpacing='0' className='printtable'>
                        <tr>
                          <td width='22%' align='right' className='titletd02'>
                            公司/機關名稱/用戶名稱：
                          </td>
                          <td width='28%' className='contenttd03'>
                            {this.state.form.cmpName}
                          </td>
                          <td width='24%' align='right' className='titletd02'>
                            統一編號/其他：
                          </td>
                          <td className='contenttd03'>{this.state.form.userId}</td>
                        </tr>

                        <tr>
                          <td align='right' className='titletd02'></td>
                          <td className='contenttd03'></td>
                          <td align='right' className='titletd02'>
                            公司/室內電話：
                          </td>
                          <td className='contenttd03'>{this.state.form.cmpTelNo}</td>
                        </tr>
                      </table>
                      <table width='611' style={{ border: '0' }} cellPadding='0' cellSpacing='0'>
                        <tr>
                          <td>
                            <table width='100%' style={{ border: '0' }} cellPadding='5' cellSpacing='0'>
                              <tr>
                                <td width='10'>
                                  <img
                                    src={process.env.PUBLIC_URL + '/resources/common/images/printimg03.gif'}
                                    width='10'
                                    height='9'
                                    alt=''
                                  />
                                </td>
                                <td>
                                  <strong>公司第一代理人基本資料</strong>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img
                              src={process.env.PUBLIC_URL + '/resources/common/images/printimg02.jpg'}
                              width='611'
                              height='20'
                              alt=''
                            />
                          </td>
                        </tr>
                      </table>
                      <table width='611' style={{ border: '0' }} cellPadding='5' cellSpacing='0' className='printtable'>
                        <tr>
                          <td width='22%' align='right' className='titletd02'>
                            使用者姓名：
                          </td>
                          <td width='28%' className='contenttd03'>
                            {this.state.form.accntName} {this.state.form.gender == 'M' ? '先生' : '小姐'}
                          </td>
                          <td width='22%' align='right' className='titletd02'>
                            身份證字號：
                          </td>
                          <td width='28%' className='contenttd03'>
                            {this.state.form.accntId}
                          </td>
                        </tr>
                        <tr>
                          <td align='right' className='titletd02'>
                            連絡Email：
                          </td>
                          <td className='contenttd03'>{this.state.form.accntEmail}</td>
                          <td align='right' className='titletd02'>
                            連絡電話：
                          </td>
                          <td width='28%' className='contenttd03'>
                            {this.state.form.accntTelNo}
                          </td>
                        </tr>
                        <tr>
                          <td align='right' className='titletd02'>
                            連絡傳真：
                          </td>
                          <td className='contenttd03'>{this.state.form.accntFaxNo}</td>
                          <td align='right' className='titletd02'>
                            連絡行動電話：
                          </td>
                          <td width='28%' className='contenttd03'>
                            {this.state.form.accntMsisdn}
                          </td>
                        </tr>
                      </table>
                      <div className='printcontent01'>用印處(公司大小章)</div>
                      <table width='611' style={{ border: '0' }} cellPadding='5' cellSpacing='0' className='printtable'>
                        <tr>
                          <td width='22%' align='right' valign='top' className='titletd02'>
                            帳號類別：
                          </td>
                          <td width='28%' valign='top' className='contenttd03'>
                            第一代理人(管理者)帳號
                          </td>
                          <td width='22%' align='right' valign='top' className='titletd02'>
                            使用者帳號：
                          </td>
                          <td width='28%' valign='top' className='contenttd03'>
                            {this.state.form.loginId}
                          </td>
                        </tr>
                      </table>
                      <div className='printcontent01'>第一代理人簽名</div>
                    </td>
                  </tr>
                </table>
                <table width='635' style={{ border: '0' }} cellPadding='0' cellSpacing='0'>
                  <tr>
                    <td>
                      <table width='611' style={{ border: '0' }} cellPadding='5' cellSpacing='0' className='printtable'>
                        <tr>
                          <td width='5%' height='33' align='right' valign='middle' className='text_size_12'>
                            <input name='checkbox' type='checkbox' checked disabled style={{ cursor: 'none' }} />
                          </td>
                          <td valign='middle' className='text_size_12'>
                            我已詳閱約定條款內容及遠傳電信依個人資料保護法第8條告知事項
                          </td>
                        </tr>
                        <tr>
                          <td colSpan='2' valign='middle' className='color_09 text_size_12'>
                            ( 條款內容可詳見企業用戶網路服務平台-ECP首頁 → 點選『立即註冊』→ 帳號申請說明『會員條款』)
                          </td>
                        </tr>
                        <tr>
                          <td colSpan='4' valign='top' className='contenttd03'>
                            1.請確認申請書已用印無誤，並檢附相關證件：
                            <div className='ml-4 mt-1 mb-1'>公司營業登記證+負責人身分證正反面影本。</div>
                            {this.state.form.applyTrustee ? (
                              <div className='ml-4 mt-1 mb-1'>受任人: 身分證正反面影本+健保卡/駕照影本。</div>
                            ) : null}
                            <div className='ml-4 mt-1 mb-1'>第一代理人:身分證正反面影本。</div>
                            2.郵寄至『台北市114內湖區港墘路220號10樓』遠傳電信股份有限公司 訂單管理組 收。
                          </td>
                        </tr>
                      </table>
                      <div className='bot_location'>
                        <input name='Submit' type='button' className='btn_3' value='列印' onClick={window.print} />
                        <input
                          name='Submit22'
                          type='button'
                          className='btn_3 left_width_5'
                          value='關閉視窗'
                          onClick={window.close}
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class='col'></div>
          </div>
        </body>
      </div>
    );
  }
}

export default withRouter(PrintMaintFirstAgentPage);
