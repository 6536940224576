import React, { useEffect, useState } from 'react';
import { Pagination as ReactPagination } from 'react-bootstrap';

const Pagination = ({ changePage, currentPage, totalPage, showMax }) => {
  const [page, setPage] = useState([]);

  useEffect(() => {
    const tmp = [];
    for (let i = currentPage - Math.floor(showMax / 2); i <= currentPage + Math.floor(showMax / 2); i++) {
      if (i > 1 && i < totalPage) {
        tmp.push(i);
      }
    }
    setPage(tmp);
  }, [currentPage, totalPage, showMax]);

  const firstPage = () => {
    changePage(1);
  };

  const lastPage = () => {
    changePage(totalPage);
  };

  const nextPage = () => {
    if (currentPage < totalPage) {
      changePage(currentPage + 1);
    }
  };

  const prePage = () => {
    if (currentPage > 1) {
      changePage(currentPage - 1);
    }
  };

  return (
    <ReactPagination className='d-flex justify-content-center'>
      <ReactPagination.First onClick={() => firstPage()} />
      <ReactPagination.Prev onClick={() => prePage()} />
      <ReactPagination.Item active={1 === currentPage} onClick={() => firstPage()}>
        {1}
      </ReactPagination.Item>
      {currentPage - Math.floor(showMax / 2) > 2 ? (
        <ReactPagination.Ellipsis onClick={() => changePage(currentPage - Math.floor(showMax / 2) - 1)} />
      ) : (
        ''
      )}
      {page.map((item, index) => {
        return (
          <ReactPagination.Item key={'page' + item} active={item === currentPage} onClick={() => changePage(item)}>
            {item}
          </ReactPagination.Item>
        );
      })}
      {currentPage + Math.floor(showMax / 2) < totalPage - 1 ? (
        <ReactPagination.Ellipsis onClick={() => changePage(currentPage + Math.floor(showMax / 2) + 1)} />
      ) : (
        ''
      )}
      {totalPage > 1 ? (
        <ReactPagination.Item active={totalPage === currentPage} onClick={() => lastPage()}>
          {totalPage}
        </ReactPagination.Item>
      ) : (
        ''
      )}
      <ReactPagination.Next onClick={() => nextPage()} />
      <ReactPagination.Last onClick={() => lastPage()} />
    </ReactPagination>
  );
};

export default Pagination;
