import React, { Children } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import CheckboxCollapse from '../../components/partials/collapse/CheckboxCollapse';
import Button from '../../components/Button';
import * as callApiUtil from '../../utils/callApiUtil';
import * as validation from '../../utils/validation';
import { Grid } from '@material-ui/core';

class SubCustAccountCreatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      basicInfo: true, //是否修改修改基本資料
      canSubmit: false, //是否可以送出
      accountRule: false, // 是否勾選同意條款
      authChange: false, // 是否修改權限或帳務類別
      emailMsg: '',
      rocIdMsg: '',
      foxNoMsg: '',
      companyPhoneMsg: '',
      msisdnMsg: '',
      userNameMsg: '',
      isLoading: false,
      form: {
        f1BillInfo: [],
        f5BillInfo: [],
        f6BillInfo: [],
        productInfo: [],
        accountType: '2', // 帳務類別
        userName: '', // 使用者姓名
        gender: 'M', // 稱謂
        rocId: '', // 身分證
        companyPhone: '', //公司電話
        msisdn: '', //行動電話
        foxNo: '', // 傳真
        email: '',
      },
      acntTypeAll: '2',
      acntTypeBill: '3',
      acntTypeNonBill: '4',
      authMsg: '',
    };
  }

  // 初始化
  componentDidMount() {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/SubCustAddController/init.action', {}).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        let prodcutCategoryList = [];
        // 判斷產品權限是否有值
        if (response.productCategoryInfoList != null) {
          for (let i in response.productCategoryInfoList) {
            if (
              response.productCategoryInfoList[i] != null &&
              response.productCategoryInfoList[i].products != null &&
              response.productCategoryInfoList[i].products.length > 0
            ) {
              prodcutCategoryList.push(response.productCategoryInfoList[i]);
            }
          }
        }
        let f1BillInfo = null;
        let f5BillInfo = null;
        let f6BillInfo = null;
        // 判斷帳務是否有值
        if (response.billInfoBean != null) {
          if (
            response.billInfoBean.f1BillInfo != null &&
            response.billInfoBean.f1BillInfo.billFunctionInfoList != null &&
            response.billInfoBean.f1BillInfo.billFunctionInfoList.length > 0
          ) {
            f1BillInfo = response.billInfoBean.f1BillInfo.billFunctionInfoList;
          }
          if (
            response.billInfoBean.f5BillInfo != null &&
            response.billInfoBean.f5BillInfo.billAccountInfoList != null &&
            response.billInfoBean.f5BillInfo.billAccountInfoList.length > 0
          ) {
            f5BillInfo = response.billInfoBean.f5BillInfo.billAccountInfoList;
          }
          if (
            response.billInfoBean.f6BillInfo != null &&
            response.billInfoBean.f6BillInfo.billAccountInfoList != null &&
            response.billInfoBean.f6BillInfo.billAccountInfoList.length > 0
          ) {
            f6BillInfo = response.billInfoBean.f6BillInfo.billAccountInfoList;
          }
        }

        let newForm = Object.assign(this.state.form);
        newForm['productInfo'] = prodcutCategoryList;
        newForm['f1BillInfo'] = f1BillInfo;
        newForm['f5BillInfo'] = f5BillInfo;
        newForm['f6BillInfo'] = f6BillInfo;

        this.setState(() => ({
          isLoading: false,
          form: newForm,
        }));
        document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        //API回傳失敗
        this.toErrPage(response.resultMessage.message);
      }
    });
  }

  onChange = (name, value) => {
    if (name == 'accountType') {
      let newForm = Object.assign(this.state.form);
      newForm[name] = value;
      this.setState(() => ({ form: newForm, authChange: true }));
    } else if (name == 'step') {
      //是否勾選修改基本資料
      this.setState(() => ({ step: value }));
    } else if (name == 'authChange') {
      //是否修改權限
      this.setState(() => ({ authChange: value }));
    } else {
      let newForm = Object.assign(this.state.form);
      newForm[name] = value;

      this.setState(() => ({ form: newForm }));
    }
    this.enableButton(this.state.accountRule);
  };

  // 是否要開啟修改基本資料
  openBasicInfo = () => {
    this.setState(() => ({ basicInfo: !this.state.basicInfo }));
    this.enableButton(this.state.accountRule);
  };

  // 驗證是否有勾會員條款
  checkAccountRule = () => {
    this.setState(() => ({ accountRule: !this.state.accountRule }));
    this.enableButton(!this.state.accountRule);
  };

  // 驗證是否可以送出
  enableButton = (accountRule) => {
    let canSubmit = true;
    let nameMsg = null;
    let msisdnMsg = null;
    let foxNoMsg = null;
    let companyPhoneMsg = null;
    let rocIdMsg = null;
    let emailMsg = null;
    let authMsg = null;

    // 驗證格式
    if (validation.isEmpty(this.state.form.userName)) {
      nameMsg = '請輸入正確的姓名';
      canSubmit = false;
    } else {
      nameMsg = '';
    }
    if (!validation.mobile(this.state.form.msisdn)) {
      canSubmit = false;
      msisdnMsg = '請輸入正確的行動電話';
    } else {
      msisdnMsg = '';
    }
    // [SR253879-ECP申請流程調整] 傳真改為非必填
    if (!validation.isEmpty(this.state.form.foxNo) && !validation.phone(this.state.form.foxNo)) {
      canSubmit = false;
      foxNoMsg = '請輸入正確的傳真';
    } else {
      foxNoMsg = '';
    }
    if (validation.isEmpty(this.state.form.companyPhone) || !validation.phone(this.state.form.companyPhone)) {
      canSubmit = false;
      companyPhoneMsg = '請輸入正確的連絡電話';
    } else {
      companyPhoneMsg = '';
    }
    if (!validation.twId(this.state.form.rocId)) {
      canSubmit = false;
      rocIdMsg = '請輸入正確的身分證字號';
    } else {
      rocIdMsg = '';
    }
    if (!validation.email(this.state.form.email)) {
      canSubmit = false;
      emailMsg = '請輸入正確的email';
    } else {
      emailMsg = '';
    }
    // 驗證若為第二代理人帳務、非帳務是否有選擇權限
    if (this.state.form.accountType == 3) {
      let selected = false;
      if (this.state.form.f6BillInfo != null && this.state.form.f6BillInfo.length > 0) {
        for (let i in this.state.form.f6BillInfo) {
          if (this.state.form.f6BillInfo[i].selected) {
            selected = true;
          }
        }
      }
      if (this.state.form.f5BillInfo != null && this.state.form.f5BillInfo.length > 0) {
        for (let i in this.state.form.f5BillInfo) {
          if (this.state.form.f5BillInfo[i].selected) {
            selected = true;
          }
        }
      }
      if (this.state.form.f1BillInfo != null && this.state.form.f1BillInfo.length > 0) {
        for (let i in this.state.form.f1BillInfo) {
          if (this.state.form.f1BillInfo[i].selected) {
            selected = true;
          }
        }
      }
      if (!selected) {
        canSubmit = false;
        authMsg = '請至少勾選一個帳務權限！';
      } else {
        authMsg = '';
      }
    } else if (this.state.form.accountType == 4) {
      let selected = false;
      if (this.state.form.productInfo != null && this.state.form.productInfo.length > 0) {
        for (let i in this.state.form.productInfo) {
          if (this.state.form.productInfo[i].products != null && this.state.form.productInfo[i].products.length > 0) {
            for (let j in this.state.form.productInfo[i].products) {
              if (this.state.form.productInfo[i].products[j].selected) {
                selected = true;
              }
            }
          }
        }
      }

      if (!selected) {
        canSubmit = false;
        authMsg = '請至少勾選一個產品權限！';
      } else {
        authMsg = '';
      }
    }

    this.setState(() => ({
      canSubmit: canSubmit && accountRule,
      emailMsg: emailMsg,
      rocIdMsg: rocIdMsg,
      foxNoMsg: foxNoMsg,
      nameMsg: nameMsg,
      companyPhoneMsg: companyPhoneMsg,
      msisdnMsg: msisdnMsg,
      authMsg: authMsg,
    }));
  };

  // 送出
  submit = () => {
    this.setState({ isLoading: true });
    //判斷所選擇的帳務類別
    if (this.state.form.accountType == this.state.acntTypeAll) {
      this.setAllPriv();
    } else if (this.state.form.accountType == this.state.acntTypeBill) {
      this.setBillPriv();
    } else if (this.state.form.accountType == this.state.acntTypeNonBill) {
      this.setNonBillPriv();
    }
  };

  // 帳務類別為第二代理人全部
  setAllPriv = () => {
    // 取用戶修改的資料, 組成custAccountBean
    let custAccountBean = this.getCustAcntBean();

    callApiUtil.callApi('/ECPAP/API/SubCustAddController/addAll.action', { custAccountBean }).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        this.toSuccessPage('全功能');
      } else {
        //API回傳失敗
        this.toErrPage(response.resultMessage.message);
      }
    });
  };

  // 帳務類別為第二代理人帳務
  setBillPriv = () => {
    // 取用戶修改的資料, 組成custAccountBean
    let custAccountBean = this.getCustAcntBean();

    let billInfoBean = {
      f1BillInfo: { billFunctionInfoList: this.state.form.f1BillInfo },
      f5BillInfo: { billAccountInfoList: this.state.form.f5BillInfo },
      f6BillInfo: { billAccountInfoList: this.state.form.f6BillInfo },
    };

    callApiUtil
      .callApi('/ECPAP/API/SubCustAddController/addBill.action', { custAccountBean, billInfoBean })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          this.toSuccessPage('帳務類');
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      });
  };

  // 帳務類別為第二代理人非帳務
  setNonBillPriv = () => {
    // 組成vin
    let custAccountBean = this.getCustAcntBean();
    let productCategoryInfoList = this.state.form.productInfo;

    callApiUtil
      .callApi('/ECPAP/API/SubCustAddController/addNonBill.action', { custAccountBean, productCategoryInfoList })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          this.toSuccessPage('非帳務類');
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      });
  };

  // 轉換custAcntBean
  getCustAcntBean = () => {
    return {
      accntType: this.state.form.accountType,
      accntName: this.state.form.userName,
      gender: this.state.form.gender,
      accntID: this.state.form.rocId,
      accntTelNO: this.state.form.companyPhone,
      accntMsisdn: this.state.form.msisdn,
      accntFaxNO: this.state.form.foxNo,
      accntEmail: this.state.form.email,
    };
  };

  // 導錯誤頁
  toErrPage = (errMsg) => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: 'fail',
        pageName: '第二代理人(子帳號)權限管理',
        title: '提醒訊息',
        description: errMsg,
        showActions: true,
        actions: [
          {
            text: '返回第二代理人(子帳號)權限管理',
            link: '/maintainSubCustAccountInfoPage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/index',
            btnStyle: 'secondary',
          },
        ],
      }
    );
  };

  // 導成功頁
  toSuccessPage = (accountType) => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: 'success',
        pageName: '第二代理人(子帳號)權限管理',
        title: '新增成功',
        description: `您已申請【第二代理人(子帳號)－${accountType}】帳號，系統將自動發送Email通知使用者啟用。`,
        showActions: true,
        actions: [
          {
            text: '返回第二代理人(子帳號)權限管理',
            link: '/maintainSubCustAccountInfoPage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/index',
            btnStyle: 'secondary',
          },
        ],
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>第二代理人(子帳號)權限管理</title>
        </Helmet>

        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='帳號管理' history={this.props.history}></Sidebar>
              {/* 第一頁 */}
              {this.state.step == 1 && (
                <div className='container bg-white col-9 shadow pl-4 pr-4'>
                  <div className='title pt-2 mt-5 ml-3'>新增第二代理人(子帳號)</div>
                  <Formsy className='pl-3'>
                    <div className='sub-title is-text-bold pt-3 mb-2 title'>請選擇帳務類別</div>
                    <div className='mb-4 radio-group w-60'>
                      {/* 全功能 */}
                      <label
                        className={`h5 is-text-bold w-100 mt-3 p-3 text-center border-gray ${
                          this.state.form.accountType == this.state.acntTypeAll && 'btn-radio'
                        }`}>
                        第二代理人-全功能(帳務類+非帳務類)
                        <input
                          type='radio'
                          value='2'
                          id='all'
                          name='accountType'
                          className='radio-button w-100 h-100'
                          onChange={() => this.onChange('accountType', this.state.acntTypeAll)}
                        />
                      </label>
                      {this.state.form.accountType == '2' && (
                        <div className='gray-block p-4 font-weight-bold'>
                          <p>帳務類功能:</p>
                          <ol>
                            <li>
                              遠傳電信【簡易帳款報表/表單瀏覽、一般通話明細、公私分帳報表、公私分帳明細、其他、MVPN線上服務(內容包含異動、退出及申請狀況查詢)】。
                            </li>
                            <li>新世紀資通【帳單明細瀏覽、線上繳款、帳務報表服務】。</li>
                            <li>
                              Seednet【帳單明細瀏覽、線上繳款、原Seednet Wagaly Enterprise通話明細、帳務報表服務】。
                            </li>
                          </ol>
                          <p>非帳務類功能:</p>
                          <p>產品查詢作業、障礙申告及查詢、系統使用紀錄查詢。</p>
                        </div>
                      )}
                      {/* 全功能 */}
                      {/* 帳務類 */}
                      <label
                        className={`h5 is-text-bold w-100 mt-4 p-3 border-gray text-center ${
                          this.state.form.accountType == this.state.acntTypeBill && 'btn-radio'
                        }`}>
                        第二代理人-帳務
                        <input
                          type='radio'
                          value='3'
                          id='bill'
                          name='accountType'
                          className='radio-button w-100 h-100'
                          onChange={() => this.onChange('accountType', this.state.acntTypeBill)}
                        />
                      </label>
                      {this.state.form.accountType == this.state.acntTypeBill && (
                        <div className='gray-block p-4 font-weight-bold'>
                          <p>帳務類功能:</p>
                          <ol>
                            <li>
                              遠傳電信【簡易帳款報表/表單瀏覽、一般通話明細、公私分帳報表、公私分帳明細、其他、MVPN線上服務(內容包含異動、退出及申請狀況查詢)】。
                            </li>
                            <li>新世紀資通【帳單明細瀏覽、線上繳款、帳務報表服務】。</li>
                            <li>
                              Seednet【帳單明細瀏覽、線上繳款、原Seednet Wagaly Enterprise通話明細、帳務報表服務】。
                            </li>
                          </ol>
                        </div>
                      )}
                      {/* 帳務類 */}
                      {/* 非帳務類 */}
                      <label
                        className={`h5 is-text-bold w-100 mt-4 p-3 border-gray text-center ${
                          this.state.form.accountType == this.state.acntTypeNonBill && 'btn-radio'
                        }`}>
                        第二代理人-非帳務
                        <input
                          type='radio'
                          value='4'
                          id='nonBill'
                          name='accountType'
                          className='radio-button w-100 h-100'
                          onChange={() => this.onChange('accountType', this.state.acntTypeNonBill)}
                        />
                      </label>
                      {this.state.form.accountType == this.state.acntTypeNonBill && (
                        <div className='gray-block p-4 font-weight-bold'>
                          <p>非帳務類功能:</p>
                          <p>產品查詢作業、障礙申告及查詢、系統使用紀錄查詢。</p>
                        </div>
                      )}
                    </div>
                    {/* 非帳務類 */}

                    {/* 設定權限區塊 */}
                    <div className={`${this.state.form.accountType == this.state.acntTypeAll ? 'd-none' : ''}`}>
                      <div className='sub-title is-text-bold pt-3 mb-3 title'>請設定權限內容</div>
                      {validation.notEmpty(this.state.authMsg) ? (
                        <span className='error-message'>{this.state.authMsg}</span>
                      ) : (
                        ''
                      )}
                      {/* 帳務權限 */}
                      {this.state.form.accountType == this.state.acntTypeBill ? (
                        <div className='gray-block p-4 mt-3 mb-5 font-weight-bold'>
                          {this.state.form.f1BillInfo == null ? null : (
                            <React.Fragment>
                              <CheckboxCollapse
                                title={'遠傳帳務資訊/新世紀資通-雲端及加值服務'}
                                content={this.state.form.f1BillInfo}
                                type={'billFunction'}
                                onchange={() => this.onChange('authChange', true)}
                              />
                              <hr />
                            </React.Fragment>
                          )}
                          {this.state.form.f5BillInfo == null ? null : (
                            <React.Fragment>
                              <CheckboxCollapse
                                title={'新世紀資通帳務資訊'}
                                content={this.state.form.f5BillInfo}
                                type={'billAccount'}
                                onchange={() => this.onChange('authChange', true)}
                              />
                              <hr />
                            </React.Fragment>
                          )}

                          {this.state.form.f6BillInfo == null ? null : (
                            <React.Fragment>
                              <CheckboxCollapse
                                title={'Seednet帳務資訊'}
                                content={this.state.form.f6BillInfo}
                                type={'billAccount'}
                                onchange={() => this.onChange('authChange', true)}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      ) : null}
                      {/* 帳務權限 */}
                      {/* 產品權限 */}
                      {this.state.form.accountType == this.state.acntTypeNonBill ? (
                        <div className='gray-block p-4 mt-3 mb-5 font-weight-bold'>
                          {this.state.form.productInfo.map((prodCate, index) => {
                            return (
                              <CheckboxCollapse
                                title={prodCate.categoryName}
                                content={prodCate}
                                type={'prod'}
                                key={'subCreatePage' + index}
                                onchange={() => this.onChange('authChange', true)}
                              />
                            );
                          })}
                        </div>
                      ) : null}
                      {/* 產品權限 */}
                    </div>
                    {/* 設定權限區塊 */}

                    {/* 基本資料區塊 */}
                    <div className='sub-title is-text-bold pt-3 mb-4 title'>請輸入基本資料</div>

                    <React.Fragment>
                      <Grid container spacing={6} className='mt-md-3 form-group w-100'>
                        <Grid item xs={12} md={8}>
                          <LabelInput
                            className='ml-4 w-100'
                            name='userName'
                            value={this.state.form.userName}
                            required={true}
                            label='使用者姓名'
                            maxLength='10'
                            placeholder='請輸入使用者姓名'
                            onChange={this.onChange}
                            failResultMsg={this.state.nameMsg}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6} className='mb-4 mt-md-3 form-group w-100'>
                        <Grid item xs={12} md={8}>
                          <label className='h5 mb-3 ml-4 is-required w-100'>稱謂</label>
                          <div className='text-input'>
                            <input
                              type='radio'
                              value='M'
                              id='M'
                              name='gender'
                              className='radio-button ml-4'
                              checked={this.state.form.gender == 'M'}
                              onChange={(e) => this.onChange('gender', e.target.value)}
                            />
                            <label htmlFor='M' className='h5'>
                              先生
                            </label>
                            <input
                              type='radio'
                              value='F'
                              id='F'
                              name='gender'
                              className='radio-button ml-4'
                              checked={this.state.form.gender == 'F'}
                              onChange={(e) => this.onChange('gender', e.target.value)}
                            />
                            <label htmlFor='F' className='h5'>
                              女士
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={6} className='mt-md-3 form-group w-100'>
                        <Grid item xs={12} md={8}>
                          <LabelInput
                            className='ml-4 w-100'
                            name='rocId'
                            value={this.state.form.rocId}
                            required={true}
                            label='身份證字號'
                            maxLength='10'
                            placeholder='請輸入使用者身份證字號'
                            onChange={this.onChange}
                            failResultMsg={this.state.rocIdMsg}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6} className='mt-md-3 form-group w-100'>
                        <Grid item xs={12} md={8}>
                          <LabelInput
                            className='ml-4 w-100'
                            name='companyPhone'
                            value={this.state.form.companyPhone}
                            required={true}
                            label='連絡電話'
                            maxLength='17'
                            placeholder='請輸入使用者連絡電話'
                            onChange={this.onChange}
                            failResultMsg={this.state.companyPhoneMsg}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={6} className='mt-md-3 form-group w-100'>
                        <Grid item xs={12} md={8}>
                          <LabelInput
                            className='ml-4 w-100'
                            name='msisdn'
                            value={this.state.form.msisdn}
                            required={true}
                            label='連絡行動電話'
                            maxLength='10'
                            placeholder='請輸入使用者連絡行動電話'
                            onChange={this.onChange}
                            failResultMsg={this.state.msisdnMsg}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={6} className='mt-md-3 form-group w-100'>
                        <Grid item xs={12} md={8}>
                          <LabelInput
                            className='ml-4 w-100'
                            name='foxNo'
                            value={this.state.form.foxNo}
                            required={false}
                            label='連絡傳真'
                            maxLength='17'
                            placeholder='請輸入使用者連絡傳真'
                            onChange={this.onChange}
                            failResultMsg={this.state.foxNoMsg}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={6} className='mt-md-3 form-group w-100'>
                        <Grid item xs={12} md={8}>
                          <LabelInput
                            className='ml-4 w-100'
                            name='email'
                            value={this.state.form.email}
                            required={true}
                            label='連絡Email'
                            maxLength='100'
                            placeholder='請輸入使用者連絡Email'
                            onChange={this.onChange}
                            failResultMsg={this.state.emailMsg}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>

                    <div className='row end mb-5'>
                      <Button
                        className={`mt-5 ${this.state.canSubmit ? '' : 'disabled'}`}
                        btnStyle='primary'
                        size='large'
                        onClick={this.submit}>
                        送出申請
                      </Button>
                      <input
                        type='checkbox'
                        name='accountRule'
                        className='ml-4 mr-2'
                        id='accountRule'
                        checked={this.state.accountRule}
                        onClick={this.checkAccountRule}></input>
                      <label for='accountRule' className='checkbox-label'>
                        我同意遠傳
                        <a
                          href='#'
                          onClick={() => {
                            window.open(
                              process.env.PUBLIC_URL + '/agreementPage',
                              '_blank',
                              'height=800, width=800, resizable=0'
                            );
                          }}
                          rel='noreferrer'>
                          會員條款
                        </a>
                      </label>
                    </div>
                  </Formsy>
                </div>
              )}
              {/* 第一頁 */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SubCustAccountCreatePage);
