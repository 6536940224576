import React from 'react';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as validation from '../../utils/validation';
import * as callApiUtil from '../../utils/callApiUtil';
class MisTechContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 顯示頁面
      step: 1, //2:異動 , 3:新增
      hasSelect: false, //有選擇資料才能click異動button
      isPopOut: false, //確認是否刪除資料的提示視窗
      isResultPopOut: false, //刪除後視窗
      //修改欄位是否全為空
      empty: false,
      //驗證資料, 控制送出按鈕開關
      isValidate: false,
      nameErrMsg: '',
      telNoErrMsg: '',
      cellErrMsg: '',
      faxNoErrMsg: '',
      emailErrMsg: '',
      sendMsgFlagErrMsg: '',
      form: {
        id: null,
        name: null, //新姓名
        telNo: null, //新公司電話
        cell: null, //新手機電話
        faxNo: null, //新傳真號碼
        email: null, //新信箱
        sendMsgFlag: null, //簡報傳送
      },
      newForm: {
        id: null,
        name: null, //新姓名
        telNo: null, //新公司電話
        cell: null, //新手機電話
        faxNo: null, //新傳真號碼
        email: null, //新信箱
        sendMsgFlag: null, //簡報傳送
      },

      isLoading: false,
      contactInfoList: [],
      isTrustee: true,
      subUserNo: null,
      userNo: null,
      seqNo: null,
      returnCode: null,
      description: null,
      successPrefix: null, //成功小視窗 前綴字
    };
  }
  componentDidMount() {
    // 指定該頁面背景顏色為白色
    document.body.style.backgroundColor = 'white';
    // 將頁面高度傳至iframe鑲嵌頁面
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
    //取得userNo
    let subUserNo = new URLSearchParams(this.props.location.search).get('userNo');
    this.setState({ subUserNo: subUserNo });
    this.queryMisTechContact(subUserNo);
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.newForm);
    newForm[name] = value.trim();
    this.setState({ newForm: newForm });
    this.enableButton();
  };

  resetNewForm = () => {
    let newForm = {
      id: null,
      name: null,
      telNo: null,
      cell: null,
      faxNo: null,
      email: null,
      sendMsgFlag: null,
    };

    this.setState({
      newForm: newForm,
      isValidate: false,
      nameErrMsg: '',
      telNoErrMsg: '',
      cellErrMsg: '',
      emailErrMsg: '',
      faxNoErrMsg: '',
      sendMsgFlagErrMsg: '',
    });
    //更新重置
  };

  selecteContact = (contactInfo) => {
    this.setState({ seqNo: contactInfo.seqNo });
    this.state.form = JSON.parse(JSON.stringify(contactInfo));
    this.setState({
      hasSelect: true,
    });
  };
  //驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;
    let nameErrMsg = '';
    let telNoErrMsg = '';
    let cellErrMsg = '';
    let faxNoErrMsg = '';
    let emailErrMsg = '';
    let sendMsgFlagErrMsg = '';

    if (this.state.step == 2) {
      if (
        validation.isEmpty(this.state.newForm.name) &&
        validation.isEmpty(this.state.newForm.telNo) &&
        validation.isEmpty(this.state.newForm.email) &&
        validation.isEmpty(this.state.newForm.cell) &&
        validation.isEmpty(this.state.newForm.faxNo)
      ) {
        isValid = false;
      }
    }

    //新增驗證
    if (this.state.step == 3) {
      if (validation.isEmpty(this.state.newForm.name)) {
        nameErrMsg = '姓名不能為空';
        isValid = false;
      }
      if (validation.isEmpty(this.state.newForm.telNo)) {
        telNoErrMsg = '電話不能為空';
        isValid = false;
      }
      if (validation.isEmpty(this.state.newForm.email)) {
        emailErrMsg = 'email不能為空';
        isValid = false;
      }
      //新增時才會做此驗證
      if (validation.isEmpty(this.state.newForm.sendMsgFlag)) {
        sendMsgFlagErrMsg = '請選擇簡訊傳送';
        isValid = false;
      }
    }
    this.setState(() => ({
      nameErrMsg: nameErrMsg,
      telNoErrMsg: telNoErrMsg,
      cellErrMsg: cellErrMsg,
      faxNoErrMsg: faxNoErrMsg,
      emailErrMsg: emailErrMsg,
      sendMsgFlagErrMsg: sendMsgFlagErrMsg,
      isValidate: isValid,
    }));
  };
  //查詢技術聯絡人
  queryMisTechContact = (subUserNo) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/getTechContact.action', {
        subUserNo: subUserNo,
        function: 'query',
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          this.setState({
            contactInfoList: response.techContactList,
            isTrustee: response.isTrustee,
            userNo: response.userNo,
          });
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '技術聯絡人',
              title: '提示訊息',
              description: response.resultMessage.message,
            }
          );
        }
        this.setState({ isLoading: false });
        this.resetNewForm();
      });
  };

  //新增技術聯絡人
  insertContactInfo = () => {
    this.setState({ isLoading: true, clickInsertOrUpdate: 'insert' });
    let techContactVo = Object.assign({}, this.state.newForm);
    techContactVo.userNo = this.state.userNo;
    callApiUtil
      .callApi('/ECPAP/API/ProductController/insertTechContact.action', {
        subUserNo: this.state.subUserNo,
        function: 'insert',
        techContactVo: techContactVo,
        sendMsgFlag: this.state.newForm.sendMsgFlag,
        userNo: this.state.userNo,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          if (response.returnCode === '0000') {
            this.queryMisTechContact(this.state.subUserNo);
            this.setState({ step: 1, hasSelect: false, successPrefix: '新增' });
          }
          this.setState({ isResultPopOut: true, returnCode: response.returnCode, description: response.description });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '技術聯絡人',
              title: '新增失敗',
              description: response.description,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };
  //更新技術聯絡人
  updateContactInfo = () => {
    this.setState({ isLoading: true, clickInsertOrUpdate: 'update' });
    let techContactVo = Object.assign({}, this.state.newForm);
    techContactVo.userNo = this.state.userNo;
    callApiUtil
      .callApi('/ECPAP/API/ProductController/updateTechContact.action', {
        subUserNo: this.state.subUserNo,
        function: 'update',
        techContactVo: techContactVo,
        seqNo: this.state.seqNo,
        userNo: this.state.userNo,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          if (response.returnCode === '0000') {
            this.queryMisTechContact(this.state.subUserNo);
            this.setState({ step: 1, hasSelect: false, successPrefix: '更新' });
          }
          this.setState({ isResultPopOut: true, returnCode: response.returnCode, description: response.description });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '技術聯絡人',
              title: '更新失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };

  //刪除MIS技術聯絡人
  deleteContactInfo = () => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/deleteTechContact.action', {
        subUserNo: this.state.subUserNo,
        function: 'delete',
        userNo: this.state.userNo,
        seqNo: this.state.seqNo,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          if (response.returnCode === '0000') {
            this.queryMisTechContact(this.state.subUserNo);
            this.setState({ step: 1, hasSelect: false, successPrefix: '刪除' });
          }
          this.setState({ isResultPopOut: true, returnCode: response.returnCode, description: response.description });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '技術聯絡人',
              title: '刪除失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };

  render() {
    return (
      <div className='w-100 p-3'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        {/* {this.state.docList == null ? (
          <h2 className='ml-3 pt-3 pb-3'>查無技術聯絡人資料</h2>
        ) : ( */}
        <React.Fragment>
          <div>
            {this.state.step == 1 ? (
              <div className='w-100 p-3'>
                <div className='title mt-4 mb-5'>技術聯絡人資料</div>
                {this.state.contactInfoList != null ? (
                  <div className='w-100 pl-3'>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        {this.state.isTrustee ? <td className='w-8 text-center border-gray'></td> : null}
                        <td className='w-20 text-center p-3 border-gray'>姓名</td>
                        <td className='w-20 p-3 border-gray'>市內電話</td>
                        <td className='w-20 p-3 border-gray'>行動電話</td>
                        <td className='w-20 p-3 border-gray'>傳真</td>
                        <td className='w-12 p-3 border-gray'>Email</td>
                      </tr>
                      {this.state.contactInfoList.map((contacInfo, index) => {
                        return (
                          <tr className='bg-white' key={'contacInfo' + index}>
                            {this.state.isTrustee ? (
                              <td className='w-8 text-center border-gray'>
                                <input
                                  type='radio'
                                  name='siteContacInfo'
                                  id={contacInfo.seqNo}
                                  onClick={() => this.selecteContact(contacInfo)}
                                />
                              </td>
                            ) : null}
                            <td className='w-20 text-center p-3 border-gray'>
                              {contacInfo.majar === 'Y' ? <span className='red mr-1'>*</span> : null}
                              {contacInfo.name}
                            </td>
                            <td className='w-20 p-3 border-gray'>{contacInfo.telNo}</td>
                            <td className='w-20 p-3 border-gray'>{contacInfo.cell}</td>
                            <td className='w-20 p-3 border-gray'>{contacInfo.faxNo}</td>
                            <td className='w-12 p-3 border-gray'>{contacInfo.email}</td>
                          </tr>
                        );
                      })}
                    </table>
                    <div className='mt-2'>
                      <span className='red mr-1'>*</span>為主要聯絡人
                    </div>
                  </div>
                ) : (
                  <div className='mt-3 pl-3 red font-weight-bold'>查無聯絡人資料</div>
                )}
                <div className='button-group mb-4 ml-3'>
                  {/* 受任人才能進行新增、刪除、更新 */}
                  {this.state.isTrustee ? (
                    <div className='mt-5'>
                      <Button
                        btnStyle='primary'
                        size='large'
                        onClick={() => {
                          this.resetNewForm();
                          this.setState({
                            step: 3,
                          });
                        }}>
                        新增
                      </Button>
                      <Button
                        btnStyle='primary'
                        size='large'
                        disabled={!this.state.hasSelect}
                        onClick={() =>
                          this.setState({
                            isPopOut: true,
                          })
                        }>
                        刪除
                      </Button>
                      <Button
                        btnStyle='primary'
                        size='large'
                        disabled={!this.state.hasSelect}
                        onClick={() =>
                          this.setState({
                            step: 2,
                          })
                        }>
                        異動
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {this.state.step == 2 ? (
              <div>
                <div className='title mt-4 mb-5'>異動聯絡人資料</div>
                <Formsy>
                  <div className='button-group p-3'>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          姓名
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.name}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3 is-required'
                            name='name'
                            value={this.state.newForm.name}
                            maxLength={17}
                            placeholder='請輸入欲更改的姓名'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.nameErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          市內電話(範例XX-XXXXXXXX)
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.telNo}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='telNo'
                            value={this.state.newForm.telNo}
                            maxLength={17}
                            placeholder='請輸入欲更改的市內電話'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.telNoErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          行動電話
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.cell}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='cell'
                            maxLength={10}
                            value={this.state.newForm.cell}
                            placeholder='請輸入欲更改的行動電話'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.cellErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          傳真(範例XX-XXXXXXXX)
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.faxNo}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='faxNo'
                            maxLength={17}
                            value={this.state.newForm.faxNo}
                            placeholder='請輸入欲更改的傳真號碼'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.faxNoErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          Email
                        </td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.email}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='email'
                            value={this.state.newForm.email}
                            placeholder='請輸入欲更改的Email'
                            label=''
                            onChange={this.onChange}
                            failResultMsg={this.state.emailErrMsg}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </Formsy>
                <div className='mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    onClick={() => this.updateContactInfo()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' size='large' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            {this.state.step == 3 ? (
              <div>
                <div className='title mb-5 ml-3 mt-5 is-text-bold'>新增聯絡人資料</div>
                <Formsy>
                  <Grid container spacing={3} className='mt-md-3 ml-1 form-group '></Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='name'
                        value={this.state.newForm.name}
                        required={true}
                        placeholder='請輸入欲新增的聯絡人姓名'
                        label='姓名'
                        onChange={this.onChange}
                        failResultMsg={this.state.nameErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='telNo'
                        value={this.state.newForm.telNo}
                        required={true}
                        placeholder='請輸入欲新增的聯絡人的市內電話'
                        label='市內電話(範例xx-xxxxxxxx)'
                        onChange={this.onChange}
                        failResultMsg={this.state.telNoErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='cell'
                        value={this.state.newForm.cell}
                        label='行動電話'
                        placeholder='請輸入欲新增的聯絡人的行動電話'
                        onChange={this.onChange}
                        failResultMsg={this.state.cellErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='faxNo'
                        value={this.state.newForm.faxNo}
                        label='傳真(範例xx-xxxxxxxx)'
                        placeholder='請輸入欲新增的聯絡人的傳真'
                        onChange={this.onChange}
                        failResultMsg={this.state.faxNoErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='email'
                        value={this.state.newForm.email}
                        required={true}
                        label='Email'
                        placeholder='請輸入欲新增的聯絡人的Email'
                        onChange={this.onChange}
                        failResultMsg={this.state.emailErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1 form-group '>
                    <Grid item xs={12} md={8}>
                      <label className='h5 ml-4 is-required'>簡訊傳送</label>
                      <input
                        type='radio'
                        className='radio-button ml-4'
                        name='sendMsgFlag'
                        id='sendMsgY'
                        value='Y'
                        checked={this.state.newForm.sendMsgFlag == 'Y'}
                        onChange={(e) => this.onChange('sendMsgFlag', e.target.value)}></input>
                      <label htmlFor='sendMsgY' className='h5'>
                        是
                      </label>
                      <input
                        type='radio'
                        className='radio-button ml-4'
                        name='sendMsgFlag'
                        value='N'
                        id='sendMsgN'
                        checked={this.state.newForm.sendMsgFlag == 'N'}
                        onChange={(e) => this.onChange('sendMsgFlag', e.target.value)}></input>
                      <label htmlFor='sendMsgN' className='h5'>
                        否
                      </label>
                    </Grid>
                  </Grid>
                </Formsy>
                <div className='ml-4 mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    onClick={() => this.insertContactInfo()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' size='large' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            {/* 刪除警告視窗 */}
            <Dialog
              open={this.state.isPopOut}
              onClose={() => this.setState({ isPopOut: false })}
              fullWidth
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>請確認是否要刪除這筆聯絡人資料?</div>
              </DialogContent>
              <DialogActions>
                <Button
                  btnStyle='primary'
                  className='w5 my-2'
                  onClick={() => {
                    this.deleteContactInfo();
                    this.setState({
                      isPopOut: false,
                    });
                  }}
                  autoFocus>
                  確認
                </Button>
                <Button
                  btnStyle='secondary'
                  className='w5 my-2'
                  onClick={() =>
                    this.setState({
                      isPopOut: false,
                    })
                  }
                  autoFocus>
                  取消
                </Button>
              </DialogActions>
            </Dialog>
            {/* 結果視窗 */}
            <Dialog
              open={this.state.isResultPopOut}
              onClose={() => this.setState({ isResultPopOut: false })}
              fullWidth
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>提示訊息</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>
                  {this.state.returnCode === '0000' ? this.state.successPrefix + '成功' : this.state.description}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  btnStyle='primary'
                  className='w5 my-2'
                  onClick={() => {
                    this.setState({
                      isResultPopOut: false,
                    });
                  }}
                  autoFocus>
                  關閉
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
        {/* )} */}
      </div>
    );
  }
}

export default MisTechContactPage;
