import React from 'react';
import { Navigation } from 'react-minimal-side-navigation';
class CommonServiceSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          title: '註冊問題',
          itemId: '/registerProblemPage',
        },
        {
          title: '會員資料',
          itemId: '/memberInfoProblemPage',
        },
        {
          title: '帳號問題',
          itemId: '/accountProblemPage',
        },
        {
          title: '登入問題',
          itemId: '/loginProblemPage',
        },
        {
          title: '密碼問題',
          itemId: '/passwordProblemPage',
        },
      ],
    };
  }
  toPage = (itemId) => {
    this.props.history.push(itemId);
  };

  render() {
    return (
      <div className={'left-sidebar col-3 font-weight-bold'}>
        <div className='nav-title side-navigation-panel-select-option'>常用服務</div>
        <Navigation
          onSelect={({ itemId }) => {
            this.toPage(itemId);
          }}
          items={this.state.items}
        />
      </div>
    );
  }
}
export default CommonServiceSidebar;
