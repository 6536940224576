import React from 'react';
import Sidebar from '../../components/Sidebar';
import Button from '../../components/Button';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import * as callApiUtil from '../../utils/callApiUtil';

class UploadFilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      errMsg: '',
      canSubmit: false,
      // 一次最多上傳 20 張圖片
      fileAmounts: 20,
      // 每張圖片最大不得超過 20MB
      fileSize: 1024 * 1024 * 20,
    };

    this.uploadFiles = this.uploadFiles.bind(this);
  }

  componentDidMount = () => {
    callApiUtil.callApi('/ECPAP/API/UploadFileController/uploadInit.action', {}).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState({
          fileList: [],
          errMsg: '',
          canSubmit: false,
          fileAmounts: response.fileAmounts,
          fileSize: response.fileSize,
        });
      } else {
        //API回傳失敗
        this.toPage(false, response.resultMessage.message);
      }
    });
  };

  handleClick = (e) => {
    this.file.click(e);
  };

  remove = (index) => {
    let fileList = this.state.fileList;
    fileList.splice(index, 1);
    let canSubmit = true;
    let errMsg = '';
    if (1 > fileList.length) {
      canSubmit = false;
    }
    if (fileList.length > this.state.fileAmounts) {
      canSubmit = false;
      errMsg = '單筆上傳最多只能上傳 20 張圖檔。';
    }
    this.setState(() => ({
      fileList: fileList,
      canSubmit: canSubmit,
      errMsg: errMsg,
    }));
  };

  submit = () => {
    this.setState({ isLoading: true });
    const formData = new FormData();

    this.state.fileList.map((file) => {
      formData.append('files', file);
    });

    callApiUtil.callApi('/ECPAP/API/UploadFileController/uploadApplyFile.action', formData).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.toPage(true, response.applyCode);
      } else {
        let submit = false;
        let errMsg = '';
        if (response.resultMessage.code == 'MA_009_002') {
          errMsg = '上傳的檔案中有非 .JPG/.PNG/.TIF 格式的檔案。';
        } else if (response.resultMessage.code == 'MA_009_004') {
          errMsg = '上傳的檔案中有超過 20 MB 的檔案，請將檔案壓縮後重新上傳。';
        } else {
          //API回傳失敗
          this.toPage(false, response.resultMessage.message);
        }
        this.setState(() => ({
          errMsg: errMsg,
          canSubmit: submit,
          isLoading: false,
        }));
      }
    });
  };

  uploadFiles = (e, name) => {
    let fileList = this.state.fileList;
    let errMsg = '';
    let limitSize = this.state.fileSize;
    let limitAmounts = this.state.fileAmounts;

    e.preventDefault();
    if (name == 'drag') {
      if (e.dataTransfer.items) {
        for (let item of e.dataTransfer.items) {
          if (item.kind === 'file') {
            let file = item.getAsFile();
            let canUplaod = true;
            if (file.size > limitSize) {
              errMsg = '單一檔案限制 20MB 以內，請將檔案壓縮後重新上傳。';
              canUplaod = false;
            }
            let fileType = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
            if (fileType != '.jpg' && fileType != '.png' && fileType != '.tif') {
              errMsg = '請將圖片轉為 .JPG/.PNG/.TIF 格式後重新上傳。';
              canUplaod = false;
            }
            if (canUplaod) {
              file.displayName = file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name;
              fileList.push(file);
            }
          }
        }
      }
    } else {
      e.target.files.forEach((file) => {
        let canUplaod = true;
        if (file.size > limitSize) {
          errMsg = '單一檔案限制 20MB 以內，請將檔案壓縮後重新上傳。';
          canUplaod = false;
        }
        let fileType = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
        if (fileType != '.jpg' && fileType != '.png' && fileType != '.tif') {
          errMsg = '請將圖片轉為 .JPG/.PNG/.TIF 格式後重新上傳。';
          canUplaod = false;
        }
        if (canUplaod) {
          file.displayName = file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name;
          fileList.push(file);
        }
      });
    }
    let canSubmit = true;
    if (1 > fileList.length) {
      canSubmit = false;
      errMsg = errMsg.length < 1 ? '請選擇要上傳的圖檔。' : errMsg;
    } else if (fileList.length > limitAmounts) {
      canSubmit = false;
      errMsg = '單筆上傳最多只能上傳 20 張圖檔。';
    }

    this.setState(() => ({
      canSubmit: canSubmit,
      fileList: fileList,
      errMsg: errMsg,
      hover: false,
    }));
  };

  dragOverHandler = (e) => {
    this.setState({ hover: true });
    e.preventDefault();
  };

  dragLeave = (e) => {
    this.setState({ hover: false });
    e.preventDefault();
  };

  // 導錯誤頁
  toPage = (success, msg) => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: success ? 'success' : 'fail',
        pageName: '申請書上傳',
        title: success ? '提醒訊息' : '上傳失敗',
        description: success ? '申請書上傳成功，此次進件編號為' + msg + '。' : msg,
        showActions: true,
        actions: [
          {
            text: '返回申請書上傳',
            link: '/uploadFilePage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/index',
            btnStyle: 'secondary',
          },
        ],
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>檔案上傳</title>
        </Helmet>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='帳號管理' history={this.props.history} />
              <div className='container bg-white col-9 shadow pl-4 pr-4'>
                <div className='w-100 p-3'>
                  <div className='title mt-4 mb-5 h-20'>申請書上傳</div>
                  <ol className='mb-4 mt-5 ml-3 mr-3 pr-3'>
                    <li className='h6 mb-2 mt-5'>
                      請確認此次上傳的申請書的檔案格式須為 .JPG /.PNG
                      /.TIF，其他檔案格式不提供上傳且申請書內容是否皆完整填寫，若有不清楚之處，還請洽所屬業務人員或洽客服電話:4499-365(手機撥打請加02)諮詢。
                    </li>
                    <li className='h6 mb-2'>
                      由於電信相關服務是須企業客戶完整用印及證明文件等或企業受任人簽名，以茲符合完整申請要件。
                    </li>
                    <li className='h6 mb-2'>
                      若您此筆申請書是全新服務需求或產品需求需搭配他家固網業者服務者，則須以正本方式遞交業務或郵寄至『台北市114內湖區港墘路220號10樓』遠傳電信股份有限公司
                      訂單管理組 收，請勿以此上傳功能方式申請。
                    </li>
                    <li className='h6 mb-2'>
                      國際相關產品因配合國外業者施作需求，不適合透過此上傳功能方式申請，請仍以跟相關所屬業務洽詢並進行申請，造成不便之處，還請見諒。
                    </li>
                    <li className='h6'>
                      申請書完成上傳後，須待本公司訂單管理單位審核並確認無誤後，將依該產品申裝流程進行服務異動；若須查詢申請進度，請至
                      <a href='/ECP/uploadApplyRecordPage'>各式紀錄查詢 – 產品服務異動進件紀錄</a>
                      查詢或洽客服電話:4499-365(手機撥打請加02)確認。
                    </li>
                  </ol>

                  <div className='ml-5'>
                    <FontAwesomeIcon
                      className='mr-2 pointer black'
                      icon={faFileUpload}
                      size='2x'
                      onClick={this.handleClick}
                    />
                    上傳檔案
                    <input
                      className='ml-5 mb-5 mt-5 row'
                      type='file'
                      name='file'
                      onChange={(e) => this.uploadFiles(e, 'upload')}
                      multiple
                      style={{ display: 'none' }}
                      ref={(file) => (this.file = file)}
                    />
                    <div
                      className={`mt-3 mb-2 file-group w-100 p-2 ${
                        this.state.hover ? 'border-orange' : 'border-darkgray'
                      }`}
                      onDrop={(e) => this.uploadFiles(e, 'drag')}
                      onDragOver={(e) => this.dragOverHandler(e)}
                      onDragLeave={(e) => this.dragLeave(e)}>
                      {this.state.fileList.length > 0 ? (
                        this.state.fileList.map((file, index) => {
                          return (
                            <p key={'file' + index} className='file col-4'>
                              <span className='mr-2 pointer mb-2 is-text-bold' onClick={() => this.remove(index)}>
                                X
                              </span>
                              <span
                                class={`file-name mb-1 bg-white`}
                                onMouseEnter={(event) => {
                                  event.target.style.whiteSpace = 'normal';
                                }}
                                onMouseLeave={(event) => {
                                  event.target.style.whiteSpace = 'nowrap';
                                }}>
                                {file.name}
                              </span>
                            </p>
                          );
                        })
                      ) : (
                        <h5 className={`middle pt-3 ${this.state.hover ? 'orange' : ''}`}>
                          請將圖片檔案拖曳至此或點擊上傳檔案
                        </h5>
                      )}
                    </div>
                    <p className='error-message'>{this.state.errMsg}</p>
                  </div>

                  <Button
                    disabled={!this.state.canSubmit}
                    btnStyle='primary'
                    className='ml-5 mt-3 fui-button is-primary row'
                    onClick={this.submit}>
                    確認送出
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadFilePage;
