import * as stringUtil from './stringUtil';
//轉換日期格式(2020-08-07T03:54:49.508+0000) to (2020/08/07)
export const format = (Longdate) => {
  let curDate = new Date(Longdate);
  let month = curDate.getMonth() + 1;
  let date = curDate.getDate();
  return curDate.getFullYear() + '/' + stringUtil.pad(month, 2) + '/' + stringUtil.pad(date, 2);
};
//轉換日期格式(2020-08-07T03:54:49.508+0000) to (2020年08月07日)
export const Chineseformat = (Longdate) => {
  let curDate = new Date(Longdate);
  let month = curDate.getMonth() + 1;
  let date = curDate.getDate();
  return curDate.getFullYear() + '年' + stringUtil.pad(month, 2) + '月' + stringUtil.pad(date, 2) + '日';
};
//轉換日期格式(2020-08-07T03:54:49.508+0000) to (2020/08/07 12:00)
export const formatDateAndTime = (Longdate) => {
  let curDate = new Date(Longdate);
  let month = curDate.getMonth() + 1;
  let date = curDate.getDate();
  let hours = curDate.getHours();
  let minutes = curDate.getMinutes();
  return (
    curDate.getFullYear() +
    '/' +
    stringUtil.pad(month, 2) +
    '/' +
    stringUtil.pad(date, 2) +
    ' ' +
    stringUtil.pad(hours, 2) +
    ':' +
    stringUtil.pad(minutes, 2)
  );
};
