import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Button from '../../components/Button';
import { Helmet } from 'react-helmet';
import * as validation from '../../utils/validation';
import { Grid } from '@material-ui/core';
import * as callApiUtil from '../../utils/callApiUtil';
import sentAA from '../../service/WebTrackingService';

class BillInfoSettingPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      // 顯示頁面
      step: 1,
      isLoading: false,
      form: { account: '', smsNotice: null, mailNotice: null, msisdn: null, email: null },
      options: [],
      canSubmit: false,
      oriMailNotice: null,
      oriSmsNotice: null,
      table: {
        title: '',
        contentName: [],
        contentList: [],
      },
    };
  }

  // 初始化
  componentDidMount() {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/BillSettingController/BillSettingInit.action', {}).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        this.setState({ isLoading: false, options: response.accntList });
      } else {
        //API回傳失敗
        this.toErrPage(response.resultMessage.message);
      }
    });
  }

  // 取目前繳款設定資料
  getBillSetting = () => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/BillSettingController/GetBillSetting.action', { accountId: this.state.form.account })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          let newForm = Object.assign(this.state.form);
          newForm['msisdn'] = response.maskMsisdn;
          newForm['email'] = response.maskEmail;
          newForm['smsNotice'] = response.smsSetting;
          newForm['mailNotice'] = response.mailSetting;
          this.setState({
            form: newForm,
            isLoading: false,
            step: 2,
            oriMailNotice: response.mailSetting,
            oriSmsNotice: response.smsSetting,
            canSubmit: false,
          });
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      });
  };

  // 更改繳款設定
  submit = () => {
    this.setState({ isLoading: true });
    let vin = {
      smsSetting: this.state.form.smsNotice,
      mailSetting: this.state.form.mailNotice,
      accountId: this.state.form.account,
    };
    callApiUtil.callApi('/ECPAP/API/BillSettingController/UpdateBillSetting.action', vin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'success',
            pageName: '繳費通知設定',
            title: '設定成功',
            description: '您的變更已成功。',
            showActions: true,

            actions: [
              {
                text: '返回繳費通知設定',
                link: '/billInfoSettingPage',
                btnStyle: 'primary',
              },
              {
                text: '返回企業用戶專區',
                link: '/index',
                btnStyle: 'secondary',
              },
            ],
          }
        );
      } else {
        //API回傳失敗
        this.toErrPage(response.resultMessage.message);
      }
    });
  };

  // 導確認頁面
  toConfirmPage = () => {
    this.setState(() => ({
      step: '3',
      table: {
        title: '設定詳情',
        contentName: ['項目', '狀態'],
        contentList: [
          { name: '簡訊設定', value: this.state.form.smsNotice ? '開啟' : '關閉' },
          { name: 'email設定', value: this.state.form.mailNotice ? '開啟' : '關閉' },
        ],
      },
    }));
  };

  // 導錯誤頁
  toErrPage = (errMsg) => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: 'fail',
        pageName: '繳費通知設定',
        title: '提醒訊息',
        description: errMsg,
        showActions: true,
        actions: [
          {
            text: '返回繳費通知設定',
            link: '/billInfoSettingPage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/index',
            btnStyle: 'secondary',
          },
        ],
      }
    );
  };

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name] = value;
    // 開啟按鈕
    let canSubmit = false;
    if (name == 'account') {
      canSubmit = true;
    }
    if (name == 'mailNotice' || name == 'smsNotice') {
      if (newForm['mailNotice'] != this.state.oriMailNotice || newForm['smsNotice'] != this.state.oriSmsNotice) {
        canSubmit = true;
      }
    }
    this.setState({ form: newForm, canSubmit: canSubmit });
  };

  chagnePage = (step) => {
    this.setState({ step: step, canSubmit: false });
  };

  toSelectePage = () => {
    this.setState({ step: 1, canSubmit: false });
  };

  render() {
    return (
      <div>
        <Helmet>
          {/* 10/29 user提出將繳款通知設定改為繳費通知設定 */}
          <title>繳費通知設定</title>
        </Helmet>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='繳費通知設定' history={this.props.history} />
              <div className='container col-9 pl-4 pr-4'>
                <div className='bg-white shadow'>
                  {this.state.step == 1 ? (
                    this.state.options.length == 0 ? (
                      <h4 className='ml-3 pt-3 pb-3'>查無資料</h4>
                    ) : (
                      <React.Fragment>
                        <div className='ml-3 pl-4 title pt-5'>繳費通知設定</div>
                        <h4 className='ml-3 pl-4 mt-4 mb-4'>請選擇欲變更帳號 僅提供【雲端與加值服務】帳號設定</h4>
                        <div className='radio-group form-group bill ml-3 pl-3'>
                          {this.state.options.map((account, idx) => (
                            <div>
                              <label className='radio is-icon-box' for='input' key={'acnt' + idx}>
                                <input
                                  className='mt-1'
                                  type='radio'
                                  name='account'
                                  value={account}
                                  onChange={() => this.onChange('account', account)}
                                />
                                <div className='content'>
                                  <div className='text'>{account}</div>
                                </div>
                              </label>
                            </div>
                          ))}
                        </div>
                        <Button
                          className='ml-5 mb-5'
                          btnStyle='primary'
                          size='large'
                          disabled={!this.state.canSubmit}
                          onClick={this.getBillSetting}>
                          確定
                        </Button>
                      </React.Fragment>
                    )
                  ) : null}
                  {this.state.step == 2 ? (
                    <React.Fragment>
                      <div className='ml-4 bg-white'>
                        <h3 className='pt-4 mb-4 ml-4'>帳號:{this.state.form.account}</h3>
                        <table className='ml-4 w-90'>
                          <tr className='bg-white'>
                            <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              簡訊設定
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {validation.isEmpty(this.state.form.msisdn)
                                ? '尚未設定帳務聯絡人門號。'
                                : `帳務聯絡人門號:${this.state.form.smsNotice ? this.state.form.msisdn : ''}`}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              <input
                                type='radio'
                                value='true'
                                id='smsNoticeY'
                                name='smsNoticeY'
                                className='radio-button'
                                checked={this.state.form.smsNotice}
                                onChange={(e) => this.onChange('smsNotice', true)}
                                disabled={validation.isEmpty(this.state.form.msisdn) ? 'true' : ''}
                              />
                              <label htmlFor='smsNoticeY'>開啟</label>

                              <input
                                type='radio'
                                value='false'
                                id='smsNoticeN'
                                name='smsNoticeN'
                                className='radio-button ml-3'
                                checked={!this.state.form.smsNotice}
                                onChange={(e) => this.onChange('smsNotice', false)}
                                disabled={validation.isEmpty(this.state.form.msisdn) ? 'true' : ''}
                              />
                              <label htmlFor='smsNoticeN'>關閉</label>
                            </td>
                          </tr>

                          <tr className='bg-white'>
                            <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              email設定
                            </td>
                            <td className='w-60 p-3 border-gray'>
                              {validation.isEmpty(this.state.form.email)
                                ? '尚未設定email。'
                                : `email:${this.state.form.mailNotice ? this.state.form.email : ''}`}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-70 px-3 py-4 border-gray'>
                              <input
                                type='radio'
                                value='true'
                                id='mailNoticeY'
                                name='mailNoticeY'
                                className='radio-button'
                                checked={this.state.form.mailNotice}
                                onChange={(e) => this.onChange('mailNotice', true)}
                                disabled={validation.isEmpty(this.state.form.email) ? 'true' : ''}
                              />
                              <label htmlFor='mailNoticeY'>開啟</label>

                              <input
                                type='radio'
                                value='false'
                                id='mailNoticeN'
                                name='mailNoticeN'
                                className='radio-button ml-3'
                                checked={!this.state.form.mailNotice}
                                onChange={(e) => this.onChange('mailNotice', false)}
                                disabled={validation.isEmpty(this.state.form.msisdn) ? 'true' : ''}
                              />
                              <label htmlFor='mailNoticeN'>關閉</label>
                            </td>
                          </tr>
                        </table>
                        <p className='is-text-bold w-90 ml-4 mt-1'>
                          **繳費通知會發送予帳務聯絡人，若欲異動帳務聯絡人，請致電客服中心449-9365
                        </p>
                        <div className='button-group ml-4 pb-4 mt-4 d-flex'>
                          <div className='mt-4'>
                            <Button
                              btnStyle='primary'
                              size='large'
                              disabled={!this.state.canSubmit}
                              onClick={() => this.toConfirmPage()}>
                              確定
                            </Button>
                          </div>
                          <div className='mt-4'>
                            <Button btnStyle='secondary' size='large' onClick={() => this.toSelectePage()}>
                              返回
                            </Button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                  {this.state.step == '3' ? (
                    <React.Fragment>
                      {/* 表單內文：table */}
                      <div className='row'>
                        <div className='w-100 pl-5 pr-5 pt-4 pb-4'>
                          <div className='d-flex justify-content-center'>
                            <img src={process.env.PUBLIC_URL + '/resources/common/images/completed.png'} />
                          </div>
                          <h4 className='mb-0 h3 is-text-bold text-center ml-3 mb-2'>確認設定</h4>
                          <div className='text-center w-100 middle'>
                            <p className='text-sm'>
                              {this.state.form.smsNotice
                                ? '此次設定「開啟繳費簡訊通知」提醒您：通知簡訊發送予「帳號下帳務聯絡人」請再次確認，謝謝!!'
                                : '此次設定「關閉繳費簡訊通知」請再次確認，謝謝!!'}
                            </p>
                            <p className='mt-2 text-sm'>
                              {this.state.form.mailNotice
                                ? '此次設定「開啟繳費email通知」提醒您：通知email發送予「帳號下帳務聯絡人」請再次確認，謝謝!!'
                                : '此次設定「關閉繳費email通知」請再次確認，謝謝!!'}
                            </p>
                          </div>
                          <h3 className='mt-2 mb-4 is-text-bold'>{this.state.table.title}</h3>
                          <table className='w-100'>
                            <thead>
                              <tr className='backgroundColor-gray '>
                                {this.state.table.contentName.map((name, i) => (
                                  <td className='w-50 text-center border-gray' key={`result-table-name-${i}`}>
                                    {name}
                                  </td>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.table.contentList.map((content, i) =>
                                validation.notEmpty(content.value) ? (
                                  <tr className='w-100 text-center border-gray' key={content.name}>
                                    <td className='text-center border-gray' key={`result-table-value-${i}`}>
                                      {content.name}
                                    </td>
                                    <td className='text-center border-gray' key={`result-table-value-${i}`}>
                                      {content.value}
                                    </td>
                                  </tr>
                                ) : null
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className='button-group mt-3 ml-5 mb-4 pb-2'>
                          <Button onClick={() => this.submit()} btnStyle='primary' size='large'>
                            確定
                          </Button>
                          <Button onClick={() => this.toSelectePage()} btnStyle='secondary' size='large'>
                            取消
                          </Button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BillInfoSettingPage);
