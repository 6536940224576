import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
class AgreementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>FETnet 遠傳</title>
        </Helmet>
        <div
          className='bg-img row'
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}>
          <div className='col-3 '></div>
          <div className='col-6 rulebox bg-white mt-4 '>
            <p align='center'>
              <strong>遠傳電信/新世紀資通</strong>
              <u>
                <strong>企業客戶網路服務平台-ECP</strong>
              </u>
              <strong>服務使用條款</strong>
            </p>
            <p>
              遠傳電信股份有限公司/新世紀資通股份有限公司（以下稱「本公司」）將依照以下條款之約定（以下稱「本使用條款」），提供您各項資訊服務。為保障您的權益，請於註冊前詳細閱讀。您可以透過網際網路取得用戶密碼，您與本公司均同意此等方式是在考量您的便利性下，基於雙方的誠信與善意而完成。因此，當您從網際網路的視窗按下「同意」，或實際開始使用本公司之服務時，均視為您已詳細閱讀並同意遵守本使用條款。且本公司得於任何時候修改本使用條款之內容，並透過網際網路在本公司網站上公告，由於使用人數眾多，本公司並不會再另行通知，故您在此認知並表示同意將會隨時注意並自行上網查詢使用條款內容是否有所更動及其更動之內容；您參與或使用本公司相關活動或服務，與本公司間所生之權利義務關係，均應以當時最後修訂之使用條款內容為依據。您若於使用條款修改公告後仍繼續使用本公司之資訊服務，亦表示您同意修改後之使用條款。如果您經過考慮後不同意本使用條款的內容，請您不要進行註冊程序，或立即停止使用本公司之各項資訊服務。
            </p>
            <p>基於以上之充分認知，您同意使用本公司之資訊服務，並遵守以下之約定：</p>
            <p>
              <strong>一、服務內容與基本規範</strong>
            </p>
            <ol>
              <li>
                本使用條款用以約定您在本公司所提供之服務上所進行之查詢、檢索、閱覽個人帳戶資料、使用加值服務或取得各式商品或服務及其他相關消費資訊、購買商品或服務（以下統稱「本服務」）時，您與本公司間之權利義務關係。
              </li>
              <li>
                您在註冊時不需事先支付或承諾任何費用，但您在註冊以前，應詳閱本使用條款之內容。當您同意接受本使用條款約定之全部內容時，您需經過註冊始可參與本服務相關活動或利用本服務。您的註冊、參與本服務相關活動或利用本服務、或利用本服務介面建立其個人社群、新聞台、發送電子郵件或傳送任何圖文與語音訊息時，均視為已同意接受本使用條款內容之拘束。{' '}
              </li>
            </ol>
            <p>
              <strong>二、您的義務與責任</strong>
            </p>
            <ol>
              <li>
                本公司係透過有線/無線網際網路或行動通訊設備提供您各項資訊服務。您若要使用本服務必須自行配備使用服務所需之各項相關設備、負擔接上有線/無線網際網路之費用、電話費用或手機通話費用。
              </li>
              <li>
                您承諾絕不為任何不法行為，並承諾不破壞及干擾本服務所提供之各項資料、活動或功能，並且不以任何方式侵入、試圖侵入、破壞本服務之任何系統，或藉由本服務為任何侵害或破壞行為，亦不公佈、傳送、寄發任何病毒、垃圾信件、廣告信函、郵件炸彈，或上傳包含任何毀謗、不實、威脅、不雅、猥褻、不法、攻擊性、毀謗性或侵害他人智慧財產權的文字，圖片或任何形式的檔案、資料或訊息。
              </li>
              <li>
                鑒於網路特性，本網站不保證各項活動及其他相關消費資訊之正確性，亦不擔保所上傳之活動及其他相關消費資訊是否被正確顯示，您應自行負責查證，如您發現或懷疑有任何錯誤，請您立即通知本網站確認。
              </li>
              <li>
                您對本身於使用本服務所傳輸的一切內容自負全責，並應遵守中華民國相關法令及一切國際網路禮節與慣例，且絕無任何涉及毀謗、不實、猥褻或侵害智慧財產權等不法或違背法律公序良俗之行為。
              </li>
              <li>
                若您因違反本使用條款或相關法令，或侵害其他人之任何權利等一切不法或不當行為，致第三人、本公司、本公司之關係企業或上開人等之受僱人受有損害或支出費用者，您應負損害賠償或填補其費用支出之責任。
              </li>
              <li>
                您同意並承諾遵守中華民國相關法規及一切國際網際網路相關之規定與慣例。您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。
              </li>
              <li>
                您同意授權本網站對會員所公佈、張貼、傳送之任何資料訊息享有公開散布、發行、重製、修改，編輯、轉載、轉授權、轉售或刪除之權利。
              </li>
              <li>
                您使用本服務如依約定須由本公司另行收費或代收資訊服務費時，應詳閱並同意遵守本公司相關之收費辦法及說明。
              </li>
            </ol>
            <p>
              <strong>三、用戶資料之註冊、修改與保密</strong>
            </p>
            <ol>
              <li>
                您應確保在註冊表單上所填寫之資料，均為正確、最新、完整且符合真實之資料；您的個人資料事後如有變更，應立即上線更新之，若未為之，對本公司不生效力；若填寫資料不實、您原本所提供之資料已不符合真實（例如電子郵件地址已變更等）、或本公司無法確認資料之真偽時，本公司得立即取消您使用本服務資格或終止參與本網站上之相關活動或利用本服務之權利。您已確認所填寫之資料正確無誤，亦無偽造、變造等不法情事。
              </li>
              <li>
                您所填寫個人資料如有重複、刻意冒用他人名義、違反公序良俗、或有侵害他人姓名權、公司名稱、商標或其他智慧財產權之虞、或有造成他人誤解之虞、或涉有其他違反法令之嫌、或破壞本網站服務宗旨者，本公司得拒絕其註冊、或事後要求其更改、或取消其使用資格之權利，您並應自負一切民事與刑事上之責任。
              </li>
              <li>
                確保帳號、門號或密碼的機密安全，是您的責任。您將對利用該帳號、門號或密碼所進行的一切行動，負完全的責任，並同意以下事項：
                <br />
                (1)
                您的帳號、門號或密碼遭到冒用或有其他任何安全問題發生時，請立即通知本公司；若您未即時通知而造成您的損失，本公司不負擔任何責任。
                <br />
                (2)
                若您的使用者帳號或密碼有遭異動，或未經您的許可有人擅自以您帳戶提出相關服務新申請及異動項目時，請您立刻連絡本公司。
                <br />
                (3)
                您使用本服務時，應對於密碼及其他相關資料負保密及維護其安全之義務，不得洩露或提供給第三人知悉、或由第三人使用之。
                <br />
                (4)
                以該帳號、門號或密碼進入本公司網站或使用本服務後之所有行為，包括查詢、檢索、閱覽、更改個人資料、參與各項活動暨取得相關消費資訊、購買商品或服務，以及其他涉及用戶個人權益之類似行為等，均視為您本人之行為。
                <br />
                (5)
                您不可對其他任何人洩露您的帳號、門號或密碼，亦不可使用其他任何人之帳號、門號或密碼。本公司已透過最嚴格的保密機制採取監督與保護之措施，若您違反上述規定導致您的損失，本公司不負擔任何責任。
                <br />
              </li>
            </ol>
            <p>
              <strong>四、下載軟體或資料</strong>
            </p>
            <p>
              本公司對於您使用本服務或經由本服務連結之其他網站而下載的軟體或資料，不負任何擔保責任。您應於下載前自行斟酌與判斷上述軟體或資料之合適性、有效性、正確性、完整性、及是否侵害他人權利，以免遭受損失（例如：造成您電腦系統受損、或儲存資料流失等）；本公司對於該等損失不負任何賠償責任。
            </p>
            <p>
              <strong>五、廣告</strong>
            </p>
            <p>
              您在本服務中瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，均由各該廣告商或商品及服務提供者所設計與提出。您對於廣告之正確性與可信度應自行斟酌與判斷。本公司僅接受委託予以刊登，不對上述廣告負擔保責任。
            </p>
            <p>
              <strong>六、取消、停止使用及本服務之終止</strong>
            </p>
            <ol>
              <li>
                若您違反本使用條款之任一條款時，本公司有權不待通知、隨時取消或停止您的之使用資格，或加以限制，此為本公司為維護全體用戶利益所必要，您同意不要求補償。情形嚴重者，本公司有權交由主管機關或司法機關處理；您同意本公司就您的行為是否符合本使用條款之規定，及是否必須採行何等之因應措施，有視違反之情形加以裁量決定之權利。
              </li>
              <li>
                本公司保留將來新增、修改或刪除所提供各項服務之全部或一部之權利，且不另行個別通知，您不得因此而要求任何補償或賠償。
              </li>
              <li>
                本公司保留得隨時終止任一用戶使用或限制其使用本服務之權利，且無需事先通知您。無論任何情形，就新增、停止或更改服務，或終止您使用本服務所可能產生之困擾、不便或損害，本公司對任何用戶或第三人均不負任何責任。
              </li>
              <li>
                除對於特定用戶提供服務之終止外，於發生下列情形之一時（包括但不限於），本公司均有權立即對不特定多數之用戶停止或中斷提供本服務，或被迫停止或中斷提供本服務；
                <br />
                (1) 對本公司之電子通信設備進行必要之保養及施工時；
                <br />
                (2) 發生突發性之電子通信設備故障時；
                <br />
                (3) 本公司所申請之電子通信服務不問任何原因被停止，無法提供服務時；
                <br />
                (4) 由於天災等不可抗力之因素致使本公司無法提供服務時。
                <br />
              </li>
              <li>
                本公司以目前一般認為合理之方式及技術維護本服務系統之正常運作，您應瞭解並同意，本公司可能因公司、其他協力廠商或相關電信業者網路系統軟硬體設備之故障或失靈、或人為操作上之疏失而全部或一部中斷、暫時無法使用、遲延、或造成資料傳輸或儲存上之錯誤或資訊顯示不正確、或遭第三人侵入系統篡改或偽造變造資料、或因其他類似事由致本服務無法正常提供，您不得因此而要求任何補償或賠償。
              </li>
            </ol>
            <p>
              <strong>七、免責聲明</strong>
            </p>
            <ol>
              <li>
                本公司就本服務不負任何明示或默示之擔保責任，且不保證本服務之穩定、安全、無誤、及不中斷。您於閱讀本使用條款之內容後，業已瞭解風險之存在狀況並願承擔使用本服務之所有風險及可能致生之任何損害。
              </li>
              <li>
                您同意使用本服務係基於您的個人意願，並同意自負任何風險，包括因為透過本服務下載資料或圖片，或自本服務中獲得之資料導致您的電腦系統損壞，或是發生任何資料流失等結果。
              </li>
            </ol>
            <p>
              <strong>八、個人資料之運用及隱私權保護</strong>
            </p>
            <ol>
              <li>
                對於您所註冊或留存之個人資料，除下列情況外，本公司同意在未獲得您的同意以前，絕不對外揭露您的姓名、地址、電子郵件地址及其他依法受保護之個人資料：
                <br />
                (1) 基於法律之規定；
                <br />
                (2) 受司法機關或其他有權機關基於法定程序之要求；
                <br />
                (3) 為保障本公司之財產及權益；
                <br />
                (4) 在緊急情況下為維護其他用戶或第三人之人身安全。
              </li>
              <li>
                對於您所註冊或留存之個人資料，您同意本公司及關係企業或合作對象，得於合理之範圍內蒐集、處理及利用該等資料，以提供您其他資訊或服務、或作成用戶統計資料、或進行廣告宣傳、或進行關於網路行為之調查研究，或為任何之合法使用，或提供其他服務。
              </li>
              <li>
                基於網際網路之特性，有關用戶資料使用與保護之細部約定，您同意依照本公司之隱私權保護政策之規範。隱私權保護政策之內容請參閱本公司網站
                <a href='https://www.fetnet.net/content/cbu/tw/information/fetprivacy.html'>
                  https://www.fetnet.net/content/cbu/tw/information/fetprivacy.html
                </a>
              </li>
            </ol>
            <p>
              <strong>九、賠償責任限制</strong>
            </p>
            <p>
              基於無償提供服務之性質，以及您對於資訊傳輸存在不確定風險之認知，本公司對於您使用本服務、或無法使用本服務所致生之任何直接、間接、衍生或其他一切損害，均不負任何賠償責任。但若您係付費使用本服務時，則本公司對於您的賠償範圍上限為您於事由發生之該年已支付本服務費用之百分之三十（30%）。
            </p>

            <p>
              <strong>十、智慧財產權之保護</strong>
            </p>
            <p>
              本公司所使用之軟體或程式、網站上之所有著作及資料，其著作權、專利權、商標、營業秘密、其他智慧財產權、所有權或其他權利，均為本公司或其權利人所有。除事先經本公司或其權利人之合法授權外，
              任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載上述軟體、程式或網站內容，必須依法取得本公司或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償責任。本公司及其關係企業為行銷宣傳本服務，就本服務相關之商品或服務名稱、圖樣等（以下稱「本公司商標」），依其註冊或使用之狀態，受商標法及公平交易法等之保護，未經本公司事前書面同意，您同意不以任何方式使用本公司商標。
            </p>
            <p>
              <strong>十一、您對本公司之授權</strong>
            </p>
            <p>
              若您無合法得授權他人或轉授權第三人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料之權利時，請勿擅自將該資料上載、傳送、輸入或提供予本公司。任何資料一經您上載、傳送、輸入或提供予本公司時，視為您已允許本公司可以基於公益或私益之目的，無條件使用、修改、重製、公開播送、改作、散布、發行、公開發表該等資料，並得將上述權利轉授權他人，您對此絕無異議。您並應保證本公司使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對本公司負損害賠償責任。
            </p>
            <p>
              <strong>十二、.連結</strong>
            </p>
            <p>
              本公司在網站或所有服務相關網頁上所提供之所有連結，可能連結到其他個人、公司或組織之網站，提供該等連結之目的，僅係為便利您自行搜集或取得資訊，本公司對於被連結之該等個人、公司或組織之網站上所提供之產品、服務或資訊，不擔保其真實性、完整性、即時性、可信賴性或合法性，該連結亦不得因此而被視為本公司與該等被連結網站或網頁上之資訊內容或活動有任何關連或合意，該等個人、公司或組織亦不因此而當然與本公司有任何僱傭、委任、代理、合夥或其他類似之關係。
            </p>
            <p>
              <strong>十三、個別條款之效力</strong>
            </p>
            <p>本使用條款所定之任何條款之全部或一部無效時，不影響其他條款之效力。</p>
            <p>
              <strong>十四、準據語言文字、準據法及管轄法院</strong>
            </p>
            <p>
              本使用條款之解釋、適用，以及您因使用本服務而與本公司間所生之權利義務關係，應依中華民國法令解釋適用之。其因此所生之爭議，以臺灣士林地方法院為第一審管轄法院。本使用條款之內容、據以而提供之本服務，以及用戶與本公司間一切通知、聯繫與意思表示等，悉以中華民國政府官方語文（中文）及語言（國語）為之。
            </p>
            <p>
              <strong>十五、特別約定</strong>
            </p>
            <p>
              本使用條款構成您與本公司就您使用本服務之完整合意，取代您先前與本公司間有關本服務所為之任何約定。您於使用或購買特定本公司服務、關係企業服務、第三方內容或軟體時，可能亦須適用額外條款或條件。本公司未行使或執行本使用條款任何權利或規定，不構成前開權利或規定之棄權。
            </p>
          </div>
          <div className='col-3 '></div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AgreementPage);
