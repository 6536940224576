import React from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';
import * as IndexService from '../../service/IndexService';
import sentAA from '../../service/WebTrackingService';

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      required: this.props.required || false,
      options: this.props.options,
      selected: this.props.default || null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleClick = () => {
    if (this.props.onClick) this.props.onClick();
  };
  componentDidMount() {
    if (this.props.default) {
      this.props.setValue(this.props.default);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.default !== this.props.default) {
      this.setState({
        selected: this.props.default,
      });
    }
  }

  handleChange(event) {
    //   debugger
    if (event.currentTarget.checked) {
      this.setState({ selected: event.currentTarget.value });
      this.props.setValue(this.state.selected);
      this.props.onChange(this.props.name, event.currentTarget.value);
    }
    this.forceUpdate();
  }

  render() {
    const errorMessage = this.props.getErrorMessage();
    return (
      <div className={`form-radio ${this.props.className ? this.props.className : ''}`}>
        <label className={this.state.required ? 'is-required' : ''}>{this.props.label}</label>

        <div className='radio-group'>
          {this.props.options.map((option, i) => (
            <label className='radio is-icon-box d-flex' key={`radio-${this.props.name}-${i}`}>
              <input
                className='mt-2'
                type='radio'
                name={this.props.name}
                value={option.value}
                onChange={(event) => this.handleChange(event)}
                defaultChecked={this.props.default === option.value}
              />
              <div
                className='content d-lg-inline-block d-flex'
                fetkey={option.fetname != null ? IndexService.getFetkeyVariablePrefix() + option.fetname : ''}
                data-aa-tracking-id={option.dataTrackingId != null ? option.dataTrackingId : ''}
                onClick={(e) => sentAA(e)}>
                {option.icon ? (
                  <div className='icon'>
                    <img src={process.env.PUBLIC_URL + option.icon.default} className='default' alt={option.label} />
                    <img src={process.env.PUBLIC_URL + option.icon.selected} className='selected' alt={option.label} />
                    {this.props.className.indexOf('is-popup') > -1 ? (
                      <div
                        onClick={() => {
                          this.props.openModal(option.icon.source);
                        }}
                        className='open-image'>
                        <i className='icon-search' />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  ''
                )}
                <div className='text'>{option.label}</div>
              </div>
            </label>
          ))}
        </div>
        {!!errorMessage ? <span className='error-message'>{errorMessage}</span> : null}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  default: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        default: PropTypes.string,
        selected: PropTypes.string,
      }),
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      fetname: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default withFormsy(RadioGroup);
