import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { isEmpty } from '../../../utils/validation';
import * as callApiUtil from '../../../utils/callApiUtil';
import Pagination from '../../../components/Pagination';
import { Table } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
class EquipPage extends React.Component {
  // 設備銷售 產品基本資料
  constructor(props) {
    super(props);
    this.state = {
      equipHardWareInfo: [], //設備硬體資料
      equipSoftWareInfo: [], //設備軟體資料
      //分頁
      currentPage: 1,
      softWareCurrentPage: 1,
      maxlength: 4, //一頁幾筆資料
      showMax: 10, //分頁欄顯示頁數
    };
  }

  componentDidMount = () => {
    for (const prodInfoMap of this.props.prodInfo) {
      // 依 spec 制式設備 資料取自 ESSP.V_EQUIPMENT_SW_ITEM_INFO，因此取為'S'開頭的欄位資料
      // 依 spec 制式設備軟體服務 資料取自 ESSP.V_EQUIPMENT_HW_ITEM_INFO，因此取為'H'開頭的欄位資料
      if (Object.keys(prodInfoMap)[0].slice(0, 1) == 'S') {
        const equipHardWare = new Map();
        for (let [key, value] of Object.entries(prodInfoMap)) {
          key = key.slice(1);
          equipHardWare.set(key, value);
        }
        this.state.equipHardWareInfo.push(equipHardWare);
      } else {
        const equipSoftWare = new Map();
        for (let [key, value] of Object.entries(prodInfoMap)) {
          key = key.slice(1);
          equipSoftWare.set(key, value);
        }
        this.state.equipSoftWareInfo.push(equipSoftWare);
      }
    }
    this.setState({ equipHardWareInfo: this.state.equipHardWareInfo, equipSoftWareInfo: this.state.equipSoftWareInfo });
  };

  getTitle = (item) => {
    // 先取得最上面說明列
    let result = [];
    result.push(<th className='p-3 border-gray keep-all'>項次</th>);
    for (const [key, value] of item.entries()) {
      result.push(<th className='w-10 p-3 border-gray text-dontBreak'>{key}</th>);
    }
    return result;
  };

  getItemInfo = (item) => {
    let result = [];
    for (const [key, value] of item.entries()) {
      result.push(
        <td className='p-3 border-gray text-dontBreak' style={{ 'white-space': 'nowrap' }} key={key + value}>
          {!isEmpty(value) ? value : <span>N/A</span>}
        </td>
      );
    }
    return result;
  };

  // 導錯誤頁
  toErrPage = (errMsg) => {
    this.props.history.push(
      {
        pathname: '/basicResultNoHeaderPage',
      },
      {
        type: 'fail',
        pageName: '產品服務作業查詢',
        title: '查詢失敗',
        description: errMsg,
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <table className='w-100'>
          <tr className='bg-white'>
            <td className='w-40 text-center border-gray'>客戶證號及名稱</td>
            <td className='w-60 p-3 border-gray'>{!isEmpty(this.props.title) ? this.props.title : <span>N/A</span>}</td>
          </tr>
        </table>
        {/*設備銷售-制式設備軟體服務 */}
        <div className='h4 title-blue is-text-bold mt-5 mb-3'>制式設備軟體服務</div>
        <ScrollContainer className='mb-4 scroll-container' hideScrollbars='false'>
          <Table responsive className='w-100 mt-4 text-center'>
            <tr className='bg-white'>
              {/* 先取header */}
              {this.state.equipSoftWareInfo != null && this.state.equipSoftWareInfo.length > 0 ? (
                this.getTitle(this.state.equipSoftWareInfo[0])
              ) : (
                <td className='p-3 border-gray is-text-bold red'>查無產品基本資料，請洽客服人員!</td>
              )}
            </tr>
            {/* 取欄位資料 */}
            {this.state.equipSoftWareInfo != null && this.state.equipSoftWareInfo.length > 0
              ? this.state.equipSoftWareInfo
                  .slice(
                    (this.state.softWareCurrentPage - 1) * this.state.maxlength,
                    this.state.softWareCurrentPage * this.state.maxlength
                  )
                  .map((item, idx) => (
                    <tr className='bg-white' key={'cashflow' + idx}>
                      <td className='p-3 border-gray'>
                        {(this.state.softWareCurrentPage - 1) * this.state.maxlength + idx + 1}
                      </td>
                      {item != null ? this.getItemInfo(item) : null}
                    </tr>
                  ))
              : null}
          </Table>
        </ScrollContainer>
        {/* 分頁 */}
        {this.state.equipSoftWareInfo != null && this.state.equipSoftWareInfo.length > this.state.maxlength ? (
          <Pagination
            changePage={(newPage) => this.setState({ softWareCurrentPage: newPage })}
            currentPage={this.state.softWareCurrentPage}
            totalPage={Math.ceil(this.state.equipSoftWareInfo.length / this.state.maxlength)}
            showMax={this.state.showMax}
          />
        ) : null}
        {/*設備銷售-制式設備 */}
        <div className='h4 title-blue is-text-bold mt-5 mb-3'>制式設備</div>
        <ScrollContainer className='mb-4 scroll-container' hideScrollbars='false'>
          <Table responsive className='w-100 mt-4 text-center'>
            <tr className='bg-white'>
              {/* 先取header */}
              {this.state.equipHardWareInfo != null && this.state.equipHardWareInfo.length > 0 ? (
                this.getTitle(this.state.equipHardWareInfo[0])
              ) : (
                <td className='p-3 border-gray is-text-bold red'>查無產品基本資料，請洽客服人員!</td>
              )}
            </tr>
            {/* 取欄位資料 */}
            {this.state.equipHardWareInfo != null && this.state.equipHardWareInfo.length > 0
              ? this.state.equipHardWareInfo
                  .slice(
                    (this.state.currentPage - 1) * this.state.maxlength,
                    this.state.currentPage * this.state.maxlength
                  )
                  .map((item, idx) => (
                    <tr className='bg-white' key={'lineNo' + idx}>
                      <td className='p-3 border-gray'>
                        {(this.state.currentPage - 1) * this.state.maxlength + idx + 1}
                      </td>
                      {item != null ? this.getItemInfo(item, idx) : null}
                    </tr>
                  ))
              : null}
          </Table>
        </ScrollContainer>
        {/* 分頁 */}
        {this.state.equipHardWareInfo != null && this.state.equipHardWareInfo.length > this.state.maxlength ? (
          <Pagination
            changePage={(newPage) => this.setState({ currentPage: newPage })}
            currentPage={this.state.currentPage}
            totalPage={Math.ceil(this.state.equipHardWareInfo.length / this.state.maxlength)}
            showMax={this.state.showMax}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(EquipPage);
