import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Banner from '../../../components/partials/banner/Banner';
import Sidebar from './CommonProblemSideBar';
import { Collapse } from 'react-bootstrap';

class AccountProblemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapse: '',
      open: false,
    };
  }

  //是否彈出搜尋欄位
  openCollapse = (openCollapse) => {
    let curCollapse = this.state.openCollapse;
    this.setState(() => ({
      openCollapse: curCollapse === openCollapse ? '' : openCollapse,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>帳號申請常見問題</title>
        </Helmet>
        <Banner title='帳號申請常見問題' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <Sidebar history={this.props.history} />
              <div className='bg-white col-9 shadow p-2'>
                <div className='title mt-5 ml-4'>帳號問題</div>
                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '1' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>我服務的公司有使用遠傳企業產品，該如何申請帳號？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('1')}
                        aria-controls='collapse-one'
                        aria-expanded={this.state.openCollapse == '1'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '1'}>
                    <div className='ml-3 mt-3 h5'>
                      申請遠傳企業用戶專區第一代理人/受任人帳號，請點選立即註冊_填寫申請書，填寫完成並用印後，將紙本申請書郵寄至本公司；帳號可由您設定並填寫於申請書中。
                    </div>
                  </Collapse>
                </div>

                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '2' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>忘記「帳號」時應該怎麼辦？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('2')}
                        aria-controls='collapse-two'
                        aria-expanded={this.state.openCollapse == '2'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '2'}>
                    <div className='ml-3 mt-4 h5'>
                      若為第二代理人帳號，您可洽貴公司第一代理人/受任人，請第一代理人/受任人登入後點選帳號管理功能，進行第二代理人(子帳號)權限管理中查詢即可。
                      若您為第一代理人/受任人，為確保貴公司之資料安全，請您電洽遠傳電信客戶服務中心0809-080-080
                      (免費)、MVPN 用戶遠傳手機直撥 99888 (免費)、市話直撥 449-9365(市話計費)。
                    </div>
                  </Collapse>
                </div>

                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '3' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>如何取消會員帳號？</div>
                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('3')}
                        aria-controls='collapse-three'
                        aria-expanded={this.state.openCollapse == '3'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '3'}>
                    <div className='ml-3 mt-4 h5'>
                      若為第二代理人帳號，您可洽貴公司第一代理人/受任人，請第一代理人/受任人登入後點選帳號管理功能，進行第二代理人(子帳號)權限管理中，點選欲取消的帳號進入後，執行"停用帳號"功能。
                      若您為第一代理人/受任人，請您電洽遠傳電信客戶服務中心0809-080-080 (免費)、MVPN 用戶遠傳手機直撥
                      99888 (免費)、市話直撥 449-9365(市話計費)。
                    </div>
                  </Collapse>
                </div>
                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '4' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>若不再使用遠傳門企業產品時，帳號會被取消嗎？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('4')}
                        aria-controls='collapse-four'
                        aria-expanded={this.state.openCollapse == '4'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '4'}>
                    <div className='ml-3 mt-4 h5'>
                      帳號並不會取消，您可以使用這組帳號和原來的密碼登入。唯需注意您申請之相關服務內容，如服務已終止，將依遠傳電信管理規範提供各服務可檢視之期限。
                    </div>
                  </Collapse>
                </div>
                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '5' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>我可以同時申請第一代理人帳號跟第二代理人帳號嗎?？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('5')}
                        aria-controls='collapse-five'
                        aria-expanded={this.state.openCollapse == '5'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '5'}>
                    <div className='ml-3 mt-4 h5'>
                      可以的。您可同時擔任貴公司的第一代理人及第二代理人的角色。(如:總公司人員同時為台北分公司之負責窗口人員)
                    </div>
                  </Collapse>
                </div>
                <div
                  className={`gray-block p-5 mt-2 mb-5 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '6' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>如何更改帳號的相關內容?？</div>
                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('6')}
                        aria-controls='collapse-six'
                        aria-expanded={this.state.openCollapse == '6'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '6'}>
                    <div className='ml-3 mt-4 h5'>
                      您可登入遠傳企業用戶專區後，點選帳號管理及"基本資料維護"或"帳號密碼維護"等功能中變更相關資料。
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AccountProblemPage);
