import axios from 'axios';
import * as errorUtil from '../utils/errorUtil';

export const callApi = (apiPathName, vin, pageAction, useSubmitLoading) => {
  let page = '';
  if (pageAction != null) {
    page = pageAction;
  } else {
    page = window.location.pathname;
  }
  let config = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      targetURL: window.location.href,
      pageAction: page,
      isRest: true,
    },
    withCredentials: true,
  };
  // 送出時，是否跳出 loading 遮罩
  if (useSubmitLoading) {
    //導頁動畫設定
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    $html.classList.add('is-loading');
    $html.classList.add('is-loading-block');
    $body.classList.add('is-loading');
    $html.classList.remove('fade-in');
    $body.classList.remove('fade-in');
  }
  //設定call API的限制時間
  axios.defaults.timeout = parseInt(process.env.REACT_APP_API_TIMEOUT);
  return axios
    .post(apiPathName, vin, config)
    .then((result) => {
      //導錯誤公版
      if (result.data === 'noAuth') {
        window.location.href = process.env.PUBLIC_URL + '/basicResultPage?status=noAuth';
      }
      //未登入
      if (result.data === 'noLogin') {
        //iframe傳訊息給父層導登入頁面
        let loginAuth = { type: 'loginAuth', loginAuth: 'noLogin' };
        window.parent.postMessage(loginAuth, process.env.REACT_APP_API_BASE_URL);
        //導登入頁面
        window.location.href = process.env.PUBLIC_URL + '/loginPage';
      }
      return result.data;
    })
    .catch((error) => {
      if (apiPathName.includes('/ECPAP/API/')) {
        window.location.href = process.env.PUBLIC_URL + '/basicResultPage';
      }
      console.log('錯誤:' + error);
    })
    .finally(() => {
      // 移除 loading 遮罩
      if (useSubmitLoading) {
        //導頁動畫設定
        const $html = document.getElementsByTagName('html')[0];
        const $body = document.body;
        $html.classList.remove('is-loading');
        $html.classList.remove('is-loading-block');
        $body.classList.remove('is-loading');
        $html.classList.add('fade-in');
        $body.classList.add('fade-in');
      }
    });
};

export const callApiGet = (apiPathName, vin, contentType, responseType) => {
  responseType = responseType || 'json';
  contentType = contentType || 'application/json;charset=utf-8';

  let config = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    responseType: responseType,
    headers: {
      'Content-Type': contentType,
      targetURL: window.location.href,
      pageAction: window.location.pathname,
      isRest: true,
    },
    withCredentials: true,
  };

  return axios
    .post(apiPathName, vin, config)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {});
};

/**
 *  checkPageAuthorization，
 *  驗證權限，
 *  propData是從上一層傳來的this.props，為了發生錯誤時重導頁面用
 */
export const checkPageAuthorization = (propData, fastLoginUrl) => {
  return callApi('/eservice/api/authorizationController/checkPageAuthorization', {})
    .then((data) => {
      if (data == null) {
        // 登入頁面
        window.location.href = data.fetnetAuthUrl;
      } else if (
        data.processResult != null &&
        data.processResult.status != null &&
        data.processResult.status == 's' &&
        data.goFakeLogin
      ) {
        //登入時需要導去快速登入。
        propData.history.push(fastLoginUrl);
      } else if (data.fetnetAuthUrl != null && data.fetnetAuthUrl != '') {
        // 登入頁面
        window.location.href = data.fetnetAuthUrl;
      } else if (
        data.processResult != null &&
        data.processResult.status != null &&
        data.processResult.status == 's' &&
        data.userHaveAuthority
      ) {
        // 成功
        data.pass = true;
      } else {
        data.pass = false;
        errorUtil.ToAuthorizationErrorPage(propData);
      }
      return data;
    })
    .catch((error) => {
      errorUtil.ToAuthorizationErrorPage(propData);
    });
};

//call 外部API (處理cross domain問題)
export const callCrossApiGet = (apiPathName, useSubmitLoading) => {
  // 送出時，是否跳出 loading 遮罩
  if (useSubmitLoading) {
    //導頁動畫設定
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    $html.classList.add('is-loading');
    $html.classList.add('is-loading-block');
    $body.classList.add('is-loading');
    $html.classList.remove('fade-in');
    $body.classList.remove('fade-in');
  }
  //設定call API的限制時間
  axios.defaults.timeout = parseInt(process.env.REACT_APP_API_TIMEOUT);
  return axios
    .get(apiPathName, { 'Access-Control-Allow-Origin': '*' })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log('api錯誤，PathName:' + apiPathName);
    })
    .finally(() => {
      // 移除 loading 遮罩
      if (useSubmitLoading) {
        //導頁動畫設定
        const $html = document.getElementsByTagName('html')[0];
        const $body = document.body;
        $html.classList.remove('is-loading');
        $html.classList.remove('is-loading-block');
        $body.classList.remove('is-loading');
        $html.classList.add('fade-in');
        $body.classList.add('fade-in');
      }
    });
};

export const postFile = (url, data, callBack, errorCallBack, useSubmitLoading) => {
  // 送出時，是否跳出 loading 遮罩
  if (useSubmitLoading) {
    //導頁動畫設定
    const $html = document.getElementsByTagName('html')[0];
    const $body = document.body;
    $html.classList.add('is-loading');
    $html.classList.add('is-loading-block');
    $body.classList.add('is-loading');
    $html.classList.remove('fade-in');
    $body.classList.remove('fade-in');
  }
  axios
    .post(`${url}`, data, {
      headers: {
        targetURL: window.location.href,
        pageAction: window.location.pathname,
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      // 1. 處理正常回傳
      if (response && response.headers && response.data) {
        //導錯誤公版
        var data = new TextDecoder().decode(response.data);
        if (data === 'noAuth') {
          window.location.href = process.env.PUBLIC_URL + '/basicResultPage?status=noAuth';
        }
        //未登入
        if (data === 'noLogin') {
          //iframe傳訊息給父層導登入頁面
          let loginAuth = { type: 'loginAuth', loginAuth: 'noLogin' };
          window.parent.postMessage(loginAuth, process.env.REACT_APP_API_BASE_URL);
          //導登入頁面
          window.location.href = process.env.PUBLIC_URL + '/loginPage';
        }
        // 認 Header 的 content-type，判斷要跳訊息還是要下載檔案
        const contentType = response.headers['content-type'];
        if (contentType) {
          // 回傳這個表示要跳訊息
          if (contentType.indexOf('json') !== -1) {
            const text = Buffer.from(response.data).toString('utf8');
            const jsonObj = JSON.parse(text);
            callBack(jsonObj);
          } else {
            downloadFile(response);
            callBack({
              success: true,
            });
          }
        }
      }
    })
    .catch((error) => {
      // 2 呼叫後端失敗
      if (errorCallBack) {
        // 2.1 有給自定義要執行的 error call back 的 function
        errorCallBack(error);
      } else {
        // 2.2 預設網路傳輸錯誤處理
        console.log('錯誤:' + error);
      }
    })
    .finally(() => {
      // 移除 loading 遮罩
      if (useSubmitLoading) {
        //導頁動畫設定
        const $html = document.getElementsByTagName('html')[0];
        const $body = document.body;
        $html.classList.remove('is-loading');
        $html.classList.remove('is-loading-block');
        $body.classList.remove('is-loading');
        $html.classList.add('fade-in');
        $body.classList.add('fade-in');
      }
    });
};
function downloadFile(response) {
  // 取後端傳回的檔案名稱
  const contentDisposition = response.headers['content-disposition'];
  let filename = '';
  if (contentDisposition) {
    if (contentDisposition.indexOf('filename=') !== -1) {
      const filenameStr = contentDisposition.substring(contentDisposition.indexOf('filename='));
      const filenameArr = filenameStr.split('=');
      filename = decodeURI(filenameArr[1]);
    }
  }

  // 下載檔案
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); // or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}
