import { Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import { withRouter } from 'react-router-dom';
import * as validation from '../../utils/validation';
import sentAA from '../../service/WebTrackingService';

class BasicResultNoHeaderPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: '',
      title: '請重新操作',
      description: '系統繁忙中，請稍後再試，謝謝!',
      ...this.props.location.state,
      hasAccountInfo: false, // 判斷基本資料設定
    };
    // AA track Start
    // this.props.trackPageViewStart();
  }

  componentDidMount = () => {
    let status = new URLSearchParams(this.props.location.search).get('status');
    if (status == 'noAuth') {
      this.setState({ description: '您的帳號無法使用此功能，請洽您的企業受任人(管理者)進行操作。' });
    }
  };

  render() {
    return (
      <main className={this.state ? 'd-block' : 'd-none'}>
        <Helmet>
          <title>遠傳電信</title>
        </Helmet>
        <section className='e-service-result is-success'>
          <div className='noheaderbg-img'>
            <div className='fui-container'>
              <Grid container>
                <Grid item xs={12}>
                  {this.state.type != null ? (
                    this.state.type == 'fail' ? (
                      <div className='d-flex justify-content-center pt-5'>
                        <img src={process.env.PUBLIC_URL + '/resources/common/images/failed.png'} />
                      </div>
                    ) : (
                      <div className='d-flex justify-content-center'>
                        <img src={process.env.PUBLIC_URL + '/resources/common/images/completed.png'} />
                      </div>
                    )
                  ) : (
                    ''
                  )}
                  {/* 標題設定 */}
                  <div className='content'>
                    <h4 className='mb-0 h3 is-text-bold text-center ml-3'>
                      {this.state.title != null ? this.state.title : ''}
                    </h4>
                    <p
                      className='body1 text-center'
                      dangerouslySetInnerHTML={
                        this.state.description != null
                          ? {
                              __html: this.state.description,
                            }
                          : null
                      }></p>
                  </div>
                </Grid>
              </Grid>

              {/* 表單內文：table */}
              {this.state.table ? (
                <div className='container'>
                  <div className='row'>
                    <div className='container bg-white col-9 shadow pl-4 pr-4'>
                      <div className='w-100 pl-3 pr-3 pt-3 pb-5'>
                        <div className='sub-title mt-3 mb-4 is-text-bold'>{this.state.table.title}</div>
                        <table className='w-100'>
                          <thead>
                            <tr className='backgroundColor-gray '>
                              {this.state.table.contentName.map((name, i) => (
                                <td className='w-50 text-center border-gray' key={`result-table-name-${i}`}>
                                  {name}
                                </td>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.table.contentList.map((content, i) =>
                              validation.notEmpty(content.value) ? (
                                <tr className='w-100 text-center border-gray' key={content.name}>
                                  <td className='text-center border-gray' key={`result-table-value-${i}`}>
                                    {content.name}
                                  </td>
                                  <td className='text-center border-gray' key={`result-table-value-${i}`}>
                                    {content.value}
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default withRouter(BasicResultNoHeaderPage);
