import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { isEmpty } from '../../../utils/validation';
import * as callApiUtil from '../../../utils/callApiUtil';
import Pagination from '../../../components/Pagination';
import { Table } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
class CashFlowPage extends React.Component {
  //金流平台應用服務
  constructor(props) {
    super(props);
    this.state = {
      showCashflow: false, //顯示產品資料/金流服務資料
      userNo: this.props.userNo,
      title: this.props.title,
      prodName: this.props.prodName,
      prodInfo: this.props.prodInfo,
      proName: null, //點擊品項名稱，可依transNO查取額外資訊
      cashflowInfo: null, //點擊線號，依transNO取得的金流服務資料
      //分頁
      currentPage: 1,
      cashflowCurrentPage: 1,
      maxlength: 4, //一頁幾筆資料
      showMax: 10, //分頁欄顯示頁數
    };
  }

  handleClick = () => {
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  };

  getTitle = (item) => {
    // 先取得最上面說明列
    let result = [];
    for (const [key, value] of Object.entries(item)) {
      result.push(<th className='w-10 p-3 border-gray text-dontBreak'>{key}</th>);
    }
    return result;
  };

  getItemInfo = (item) => {
    let result = [];
    for (const [key, value] of Object.entries(item)) {
      if (key == '品項名稱' && !this.state.showCashflow) {
        result.push(
          <td className='text-center border-gray text-dontBreak'>
            <a href='#' onClick={() => this.queryByTransNo(item['交易序號'], value)}>
              {value}
            </a>
          </td>
        );
      } else {
        result.push(
          <td className='p-3 border-gray text-dontBreak' key={key + value}>
            {!isEmpty(value) ? value : <span>N/A</span>}
          </td>
        );
      }
    }
    return result;
  };

  queryByTransNo = (transNo, proName) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/queryProdCashFlow.action', {
        userNo: this.props.userNo,
        productId: this.props.prodId,
        transNo: transNo,
      })
      .then((response) => {
        // 判斷回傳資料
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({
            proName: proName,
            isLoading: false,
            showCashflow: true,
            currentPage: 1,
            prodInfo: response.lineInfo,
            cashflowInfo: response.cashFlowInfo,
          });
          //將頁面高度傳至iframe鑲嵌頁面
          this.handleClick();
        } else {
          //API回傳失敗
          this.toErrPage(response.resultMessage.message);
        }
      });
  };

  // 導錯誤頁
  toErrPage = (errMsg) => {
    this.props.history.push(
      {
        pathname: '/basicResultNoHeaderPage',
      },
      {
        type: 'fail',
        pageName: '產品服務作業查詢',
        title: '查詢失敗',
        description: errMsg,
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.showCashflow ? (
          // 金流平台應用服務(第一頁)
          <React.Fragment>
            <table className='w-100'>
              <tr className='bg-white'>
                <td className='w-40 text-center border-gray'>客戶證號及名稱</td>
                <td className='w-60 p-3 border-gray'>
                  {!isEmpty(this.props.title) ? this.props.title : <span>N/A</span>}
                </td>
              </tr>
            </table>
            <div className='h4 title-blue is-text-bold mt-5 mb-3'>使用中的服務品項</div>
            <ScrollContainer className='mb-4 scroll-container' hideScrollbars='false'>
              <Table responsive className='w-100 mt-4 text-center'>
                <tr className='bg-white'>
                  {/* 先取header */}
                  {this.props.prodInfo != null && this.props.prodInfo.length > 0 ? (
                    this.getTitle(this.props.prodInfo[0])
                  ) : (
                    <td className='p-3 border-gray is-text-bold red'>查無產品基本資料，請洽客服人員!</td>
                  )}
                </tr>
                {/* 取欄位資料 */}
                {this.props.prodInfo != null && this.props.prodInfo.length > 0
                  ? this.props.prodInfo
                      .slice(
                        (this.state.currentPage - 1) * this.state.maxlength,
                        this.state.currentPage * this.state.maxlength
                      )
                      .map((item, idx) => (
                        <tr className='bg-white' key={'item' + idx}>
                          {item != null ? this.getItemInfo(item) : null}
                        </tr>
                      ))
                  : null}
              </Table>
            </ScrollContainer>
            {/* 分頁 */}
            {this.props.prodInfo != null && this.props.prodInfo.length > this.state.maxlength ? (
              <Pagination
                changePage={(newPage) => this.setState({ currentPage: newPage })}
                currentPage={this.state.currentPage}
                totalPage={Math.ceil(this.props.prodInfo.length / this.state.maxlength)}
                showMax={this.state.showMax}
              />
            ) : null}
          </React.Fragment>
        ) : (
          // 金流平台應用服務(第二頁)
          <React.Fragment>
            <table className='w-100'>
              <tr className='bg-white'>
                <td className='w-40 text-center border-gray'>品項名稱</td>
                <td className='w-60 p-3 border-gray'>
                  {!isEmpty(this.state.proName) ? this.state.proName : <span>N/A</span>}
                </td>
              </tr>
            </table>
            {/*金流平台應用服務(第二頁)-線路服務 */}
            <div className='h4 title-blue is-text-bold mt-5 mb-3'>線路服務</div>
            <ScrollContainer className='mb-4 scroll-container' hideScrollbars='false'>
              <Table responsive className='w-100 mt-4 text-center'>
                <tr className='bg-white'>
                  {/* 先取header */}
                  {this.state.prodInfo != null && this.state.prodInfo.length > 0 ? (
                    this.getTitle(this.state.prodInfo[0])
                  ) : (
                    <td className='p-3 border-gray is-text-bold red'>查無產品基本資料，請洽客服人員!</td>
                  )}
                </tr>
                {/* 取欄位資料 */}
                {this.state.prodInfo != null && this.state.prodInfo.length > 0
                  ? this.state.prodInfo
                      .slice(
                        (this.state.currentPage - 1) * this.state.maxlength,
                        this.state.currentPage * this.state.maxlength
                      )
                      .map((item, idx) => (
                        <tr className='bg-white' key={'lineNo' + idx}>
                          {item != null ? this.getItemInfo(item) : null}
                        </tr>
                      ))
                  : null}
              </Table>
            </ScrollContainer>
            {/* 分頁 */}
            {this.state.prodInfo != null && this.state.prodInfo.length > this.state.maxlength ? (
              <Pagination
                changePage={(newPage) => this.setState({ currentPage: newPage })}
                currentPage={this.state.currentPage}
                totalPage={Math.ceil(this.state.prodInfo.length / this.state.maxlength)}
                showMax={this.state.showMax}
              />
            ) : null}
            {/*金流平台應用服務(第二頁)-金流應用服務 */}
            <div className='h4 title-blue is-text-bold mt-5 mb-3'>金流應用服務</div>
            <ScrollContainer className='mb-4 scroll-container' hideScrollbars='false'>
              <Table responsive className='w-100 mt-4 text-center'>
                <tr className='bg-white'>
                  {/* 先取header */}
                  {this.state.cashflowInfo != null && this.state.cashflowInfo.length > 0 ? (
                    this.getTitle(this.state.cashflowInfo[0])
                  ) : (
                    <td className='p-3 border-gray is-text-bold red'>查無產品基本資料，請洽客服人員!</td>
                  )}
                </tr>
                {/* 取欄位資料 */}
                {this.state.cashflowInfo != null && this.state.cashflowInfo.length > 0
                  ? this.state.cashflowInfo
                      .slice(
                        (this.state.cashflowCurrentPage - 1) * this.state.maxlength,
                        this.state.cashflowCurrentPage * this.state.maxlength
                      )
                      .map((item, idx) => (
                        <tr className='bg-white' key={'cashflow' + idx}>
                          {item != null ? this.getItemInfo(item) : null}
                        </tr>
                      ))
                  : null}
              </Table>
            </ScrollContainer>
            {/* 分頁 */}
            {this.state.cashflowInfo != null && this.state.cashflowInfo.length > this.state.maxlength ? (
              <Pagination
                changePage={(newPage) => this.setState({ cashflowCurrentPage: newPage })}
                currentPage={this.state.cashflowCurrentPage}
                totalPage={Math.ceil(this.state.cashflowInfo.length / this.state.maxlength)}
                showMax={this.state.showMax}
              />
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(CashFlowPage);
