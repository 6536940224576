import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Banner from '../../../components/partials/banner/Banner';
import Sidebar from './CommonProblemSideBar';
import { Collapse } from 'react-bootstrap';

class PasswordProblemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapse: '',
      open: false,
    };
  }

  //是否彈出搜尋欄位
  openCollapse = (openCollapse) => {
    let curCollapse = this.state.openCollapse;
    this.setState(() => ({
      openCollapse: curCollapse === openCollapse ? '' : openCollapse,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>帳號申請常見問題</title>
        </Helmet>
        <Banner title='帳號申請常見問題' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <Sidebar history={this.props.history} />

              <div className='bg-white col-9 shadow p-2'>
                <div className='title mt-5 ml-4'>密碼問題</div>
                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '1' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>忘記「密碼」時應該怎麼辦？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('1')}
                        aria-controls='collapse-one'
                        aria-expanded={this.state.openCollapse == '1'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '1'}>
                    <div className='ml-3 mt-3 h5'>
                      請在登入頁面上點選「忘記密碼」，並依網頁說明輸入並填資料後，系統將送新密碼至您設定的電子郵件信箱。
                    </div>
                  </Collapse>
                </div>

                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '2' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>如何設定密碼？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('2')}
                        aria-controls='collapse-two'
                        aria-expanded={this.state.openCollapse == '2'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '2'}>
                    <div className='ml-3 mt-4 h5'>
                      您可登入遠傳企業用戶專區後，點選帳號管理及"基本資料維護"或"帳號密碼維護"等功能中變更相關資料。
                    </div>
                  </Collapse>
                </div>

                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '3' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>如何修改密碼？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('3')}
                        aria-controls='collapse-three'
                        aria-expanded={this.state.openCollapse == '3'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '3'}>
                    <div className='ml-3 mt-4 h5'>
                      您可登入遠傳企業用戶專區後，點選帳號管理及"基本資料維護"或"帳號密碼維護"等功能中變更相關資料。
                    </div>
                  </Collapse>
                </div>
                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '4' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>為什麼我無法取得新密碼？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('4')}
                        aria-controls='collapse-four'
                        aria-expanded={this.state.openCollapse == '4'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '4'}>
                    <div className='ml-3 mt-4 h5'>
                      無論是更改密碼或是忘記密碼，在您完成網頁申請/更新後，系統將透過E-mail將新密碼傳送到您手上，請確認E-mail接收正常。如果還是無法取得新密碼，請您電洽遠傳電信客戶服務中心0809-080-080
                      (免費)、MVPN 用戶遠傳手機直撥 99888 (免費)、市話直撥 449-9365(市話計費)，由客服人員為您處理。
                    </div>
                  </Collapse>
                </div>
                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '5' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>為什麼我的密碼無效？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('5')}
                        aria-controls='collapse-five'
                        aria-expanded={this.state.openCollapse == '5'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '5'}>
                    <div className='ml-3 mt-4 h5'>請確認您的帳號/密碼之正確性，密碼須區分大小寫。</div>
                  </Collapse>
                </div>
                <div
                  className={`gray-block p-5 mt-2 mb-5 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '6' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>請問密碼的英數位元組數有限制嗎？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('6')}
                        aria-controls='collapse-six'
                        aria-expanded={this.state.openCollapse == '6'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '6'}>
                    <div className='ml-3 mt-4 h5'>您可設定為6-12碼英文或數字。</div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PasswordProblemPage);
