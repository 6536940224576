import React from 'react';
import Menu from './Menu';
import Item from './item/LinkItem';
import PropTypes from 'prop-types';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isClose: false,
      selectedItem: this.props.label,
      isHandleClick: false,
    };

    this.getSelected = this.getSelected.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedItem: this.props.label,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.label !== this.props.label ||
      prevProps.list.length !== this.props.list.length ||
      (prevProps.list.length &&
        prevProps.list[0].text !== this.props.list[0].text &&
        prevProps.list[0].value !== this.props.list[0].value)
    ) {
      this.setState({
        selectedItem: this.props.label,
      });
    }
  }

  closeMenu = (event) => {
    if (this.props.close) this.props.close();
    setTimeout(() => {
      this.setState({
        anchorEl: null,
        isClose: false,
      });
      // console.log('closeMenu close')
      // this.trigger.current.blur();
    }, 120);
  };

  handleClick = (e) => {
    e.preventDefault();

    if (this.state.isClose === true) return;
    this.setState({ isHandleClick: true });

    if (Boolean(this.state.anchorEl)) {
      // console.log('handleClick close')
      this.setState({
        anchorEl: null,
        isHandleClick: false,
      });
    } else {
      // console.log('open')
      this.setState({
        anchorEl: e.currentTarget,
        isHandleClick: false,
      });
      if (this.props.onActive) this.props.onActive();
    }
  };

  selectItem = (item, idx) => {
    if (typeof window !== 'undefined') {
      if (window.event.preventDefault) window.event.preventDefault();
      else window.event.returnValue = false;
    }

    this.setState({
      selectedItem: item.text,
    });

    if (this.props.onChange) this.props.onChange(item, idx);
    this.closeMenu();
  };

  getSelected = (item) => {
    return this.state.selected === item.label ? 'active' : '';
  };

  readyToClose = (item) => {
    if (this.state.isHandleClick === true) return;
    this.setState({
      isClose: true,
    });
    this.forceUpdate();

    setTimeout(() => {
      this.closeMenu();
    }, 50);
  };

  render() {
    return (
      <div
        className={`
                fui-dropdown 
                ${this.props.arrow ? 'with-arrow' : ''} 
                ${this.props.className ? this.props.className : ''}
                ${Boolean(this.state.anchorEl) ? 'is-show' : ''} 
                `}
        onClick={this.props.onActive}>
        <button
          aria-haspopup='true'
          aria-expanded={Boolean(this.state.anchorEl)}
          aria-label={this.props.label}
          disabled={this.props.disabled}
          onClick={this.handleClick}
          className='fui-dropdown-item'>
          <span
            dangerouslySetInnerHTML={{
              __html: this.props.label,
            }}></span>
          {this.props.arrow ? <i className='icon-chevron-down'></i> : ''}
        </button>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.readyToClose}>
          <div className='menu-wrapper'>
            {this.props.list.map((item, idx) => (
              <Item
                {...item}
                className={`${
                  (this.props.selected && this.props.selected === item.text) ||
                  (!this.props.selected &&
                    (this.props.label === item.text || (this.props.value && this.props.value === item.value)))
                    ? 'active'
                    : ''
                } ${this.props.hasCheck ? 'check-icon' : ''}`}
                key={'menu-item' + this.props.id + idx}
                onClick={(e) => this.selectItem(item, idx)}>
                {item.text}
              </Item>
            ))}
          </div>
        </Menu>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  hasCheck: false,
  onActive: null,
};
Dropdown.propTypes = {
  list: PropTypes.arrayOf(Item),
  arrow: PropTypes.bool, // Boolean
  className: PropTypes.string,
  selected: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func, // callback
  onActive: PropTypes.func,
  hasCheck: PropTypes.bool,
  disabled: PropTypes.bool, // default false
};

export default Dropdown;
