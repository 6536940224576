import React from 'react';
import ProdPage from './ProdPage';
import * as validation from '../../utils/validation';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableFooter } from '@material-ui/core';
import Pagination from '../../components/Pagination';
class ProductListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 顯示聯絡我們
      contactus: false,
      //產品清單
      productList: this.props.productList,
      showList: this.props.productList,
      productCategory: {},
      isLoading: false,
      page: 'productListPage',
      productId: null,
      extraInfo: null,
      referServiceId: null,
      userNo: null,
      productName: null,
      fx: null,
      acctId: null,
      //分頁
      currentPage: 1,
      maxlength: 6, //一頁幾筆資料
      showMax: 10, //分頁欄顯示頁數
      search: '',
    };
  }

  componentDidMount = () => {
    document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
  };

  onChange = () => {
    this.setState(() => ({
      canSubmit: !this.state.canSubmit,
    }));
  };

  queryDevicesInfo = (product) => {
    this.setState({ isLoading: true });
    // 取完資料打開productServicePage畫面
    this.setState({
      page: 'productServicePage',
      productId: product.productId,
      extraInfo: product.extraInfo,
      referServiceId: product.referServiceId,
      productName: validation.notEmpty(this.props.productCategory.name)
        ? this.props.productCategory.name
        : product.categoryName,
      userNo: product.userNo,
      fx: product.fx,
      acctId: product.acctId,
    });
    this.setState({ isLoading: false });
  };

  // 將修改的值帶進參數中
  changeText = (e) => {
    this.setState(() => ({
      search: e.target.value,
    }));
  };

  // 搜尋門號、線路編號
  search = () => {
    let searchString = this.state.search; // 符合條件的線路編號
    var regex = new RegExp(searchString, 'g'); // 把篩選條件放進
    let originProdList = Object.assign(this.state.productList); // 從後端取出的產品清單
    let prodList = originProdList.filter((prod) => prod.userNo.match(regex) != null);
    // 將比對過的資料加回productList中，畫面上即會呈現篩選的線路編號
    this.setState({
      showList: prodList,
      currentPage: 1,
    });
  };

  handleChangePage = (newPage) => {
    this.setState({ currentPage: newPage });
  };

  render() {
    const name = (product) => {
      return product.visible
        ? product.nickName != null
          ? ` (  ${product.nickName} )`
          : ` (  ${product.name} )`
        : null;
    };
    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        {/* 產品分類底下的產品線路清單頁 */}
        {this.state.page == 'productListPage' ? (
          <div>
            {validation.notEmpty(this.props.productCategory.name) ? (
              <section className='banner text-white p-5'>
                <div className='container'>
                  <div className='row'>
                    <span className='offset-1'>{this.props.productCategory.name}</span>
                  </div>
                </div>
              </section>
            ) : null}
            <div className='bg-img'>
              <TableContainer>
                <Table className='container middle'>
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <div className='text-black pr-4 pt-4 pb-2 ml-4 pl-5'>
                        {validation.notEmpty(this.state.showList) ? (
                          <div className='mt-3 title pl-2 text-black ml-3'>
                            您可點取任一客戶編號或線路資訊，進行後續作業。
                          </div>
                        ) : (
                          <div className='mt-3 title pl-2 text-black ml-3'>查無資料</div>
                        )}
                      </div>
                    </TableRow>
                    <TableRow>
                      {validation.notEmpty(this.state.showList) ? (
                        <div className='container mb-5 ml-5 pl-5'>
                          <input
                            className='h-100 w-20 border-gray shadow'
                            placeholder='輸入門號或線路編號查詢'
                            name='searchProd'
                            value={this.state.search}
                            onChange={this.changeText}></input>
                          <button className='is-primary fui-button is-small ml-3 ' onClick={this.search}>
                            搜尋
                          </button>
                        </div>
                      ) : null}
                    </TableRow>
                    {validation.notEmpty(this.state.showList) &&
                      this.state.showList
                        .slice(
                          (this.state.currentPage - 1) * this.state.maxlength,
                          this.state.currentPage * this.state.maxlength
                        )
                        .map((product, i) => (
                          <TableRow key={'prodList' + i}>
                            <div className='ml-5 pl-5 mt-2'>
                              {product.hasService ? (
                                <div
                                  className='fui-card col-11 m-1 mb-2'
                                  onClick={() => this.queryDevicesInfo(product)}>
                                  <div className='fui-card-caption container'>
                                    <div className='fui-card-content row'>
                                      <div className='fui-card-title h2 col-10 pb-3'>
                                        {product.userNo}
                                        {/* 判斷visible欄位，若為true則顯示nickName，若nickName沒有值則顯示name */}
                                        {name(product)}
                                      </div>
                                      <div className='fui-button is-arrow mb-0 offset-1'>詳細內容</div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className='fui-card col-11 m-1 mb-2'>
                                  <div className='fui-card-caption no-pointer container'>
                                    <div className='fui-card-content row'>
                                      <div className='fui-card-title h2 col-10 pb-3'>
                                        {product.userNo}
                                        {/* 判斷visible欄位，若為true則顯示nickName，若nickName沒有值則顯示name */}
                                        {name(product)}
                                      </div>
                                      <div className='mt-3 h6 is-text-bold red offset-1'>請洽客服</div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TableRow>
                        ))}
                  </TableBody>
                  {this.state.showList.length > this.state.maxlength ? (
                    <TableFooter>
                      <TableRow>
                        <hr></hr>
                        <Pagination
                          changePage={this.handleChangePage}
                          currentPage={this.state.currentPage}
                          totalPage={Math.ceil(this.state.showList.length / this.state.maxlength)}
                          showMax={this.state.showMax}
                        />
                      </TableRow>
                    </TableFooter>
                  ) : null}
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : null}
        {/* 產品線路可用的產品服務功能頁 */}
        <div>
          {this.state.page == 'productServicePage' ? (
            <ProdPage
              productId={this.state.productId != null ? this.state.productId : null}
              extraInfo={this.state.extraInfo != null ? this.state.extraInfo : null}
              referServiceId={this.state.referServiceId != null ? this.state.referServiceId : null}
              userNo={this.state.userNo != null ? this.state.userNo : null}
              productName={this.state.productName != null ? this.state.productName : null}
              fx={this.state.fx != null ? this.state.fx : null}
              acctId={this.state.acctId != null ? this.state.acctId : null}></ProdPage>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default ProductListPage;
