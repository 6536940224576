import React from 'react';

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
    };
  }
  render() {
    return (
      <section className='banner text-white p-5'>
        <div className='container'>
          <div className='row'>
            <span className='offset-1'>{this.state.title}</span>
          </div>
        </div>
      </section>
    );
  }
}
export default Banner;
