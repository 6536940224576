import React from 'react';
import PropTypes from 'prop-types';

const Menu = (props) => {
  const menuRef = React.useRef();

  React.useEffect(() => {
    if (Boolean(props.anchorEl)) {
      setTimeout(() => {
        menuRef.current.focus();
      }, 200);
    }
  }, [props, menuRef]);

  return (
    <div
      ref={menuRef}
      role='menu'
      onBlur={props.onClose}
      aria-labelledby={props.id}
      tabIndex='-1'
      className={`
            fui-menu 
            ${props.className ? props.className : ''} 
            ${Boolean(props.anchorEl) ? 'd-block' : ''}
        `}>
      {props.children}
    </div>
  );
};

Menu.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func,
};

export default Menu;
