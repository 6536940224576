import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import * as callApiUtil from '../../utils/callApiUtil';
import * as validation from '../../utils/validation';
import { Helmet } from 'react-helmet';
import TransactionApplication from '../resultController/TransactionApplication';
import * as emptyUtil from '../../utils/emptyUtil';

class MaintainCorpInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      // 顯示頁面
      step: 1,
      isLoading: false,
      //驗證產生的錯誤訊息
      CmpTelNoErrMsg: '',
      //驗證資料, 控制送出按鈕開關
      isValidate: false,

      form: {
        cmpName: { value: '', text: '' }, //公司/機關名稱/用戶名稱
        userId: { value: '', text: '' }, //統一編號/其他
        cmpTelNo: { value: '', text: '' }, //公司/室內電話
      },
      newForm: {
        newCmpTelNo: { value: '', text: '' }, //新公司/室內電話
      },
      errmsg: '',
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    //組成API input Value
    let InitCorpInfoVin = {};
    callApiUtil.callApi('/ECPAP/API/MaintainCorpController/initCorpInfo.action', InitCorpInfoVin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        let data = this.state.form;
        data.cmpName.value = emptyUtil.checkVal(response.cmpName);
        data.userId.value = emptyUtil.checkVal(response.userId);
        data.cmpTelNo.value = emptyUtil.checkVal(response.cmpTelNo);
        this.setState({ form: data });
        this.setState({ isLoading: false });
      } else {
        //API回傳失敗
        this.setState({ isLoading: false });
      }
    });
  };

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.newForm);
    newForm[name].value = value.trim();
    this.setState({ newForm: newForm });
  };

  // 修改資料頁-重設按鈕
  resetData = () => {
    let resetNewForm;
    resetNewForm = {
      newCmpAddress: { value: '', text: '' }, //新公司地址/戶籍地址
      newCmpTelNo: { value: '', text: '' }, //新公司/室內電話
      newCmpFaxNo: { value: '', text: '' }, //新公司傳真號碼
    };

    this.setState({ newForm: resetNewForm });
  };

  // 到變更公司基本資料頁
  modifyCorpInfo = () => {
    this.setState({ step: 2 });
  };

  //驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;
    let CmpTelNoErrMsg = '';

    //至少需修改一個欄位
    if (validation.isEmpty(this.state.newForm.newCmpTelNo.value)) {
      isValid = false;
    }
    if (
      validation.notEmpty(this.state.newForm.newCmpTelNo.value) &&
      !validation.phone(this.state.newForm.newCmpTelNo.value)
    ) {
      CmpTelNoErrMsg = '輸入的公司/室內電話有誤';
      isValid = false;
    }

    this.setState(() => ({
      CmpTelNoErrMsg: CmpTelNoErrMsg,
      isValidate: isValid,
    }));
  };

  submitApply = () => {
    if (this.state.isValidate) {
      this.setState({ isLoading: true });
      //組成API input Value
      let ModifyCorpInfoVin = {
        newCmpTelNo: this.state.newForm.newCmpTelNo.value,
      };
      let message;
      let isSuccess;
      let title;
      callApiUtil
        .callApi('/ECPAP/API/MaintainCorpController/modifyCorpInfo.action', ModifyCorpInfoVin)
        .then((response) => {
          this.setState({ isLoading: false });
          if (
            response != null &&
            response != undefined &&
            response.resultMessage != null &&
            response.resultMessage != undefined &&
            response.resultMessage.message == 's'
          ) {
            //API回傳成功
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: 'success',
                pageName: '公司基本資料異動',
                title: '更新完成',
                description: '您異動的資料已更新成功！',
                actions: [
                  {
                    text: '返回公司基本資料異動',
                    link: '/maintainCorpInfoPage',
                    btnStyle: 'primary',
                  },
                  {
                    text: '返回企業用戶專區',
                    link: '/',
                    btnStyle: 'secondary',
                    fetkey: '',
                    dataTrackingId: '',
                  },
                ],
              }
            );
          } else {
            //API回傳失敗
            message = response.resultMessage.message;
            isSuccess = false;
            title = '修改失敗';
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: isSuccess ? 'success' : 'fail',
                pageName: '公司基本資料異動',
                title: title,
                description: message,
                actions: [
                  {
                    text: '返回公司基本資料異動',
                    link: '/maintainCorpInfoPage',
                    btnStyle: 'primary',
                    fetkey: '',
                    dataTrackingId: '',
                  },
                  {
                    text: '返回企業用戶專區',
                    link: '/index',
                    btnStyle: 'secondary',
                    fetkey: '',
                    dataTrackingId: '',
                  },
                ],
              }
            );
          }
        });
    }
  };

  render() {
    const items = [
      {
        title: '基本資料維護',
        itemId: '/maintainAccountInfoPage',
      },
      {
        title: '帳號密碼維護',
        itemId: '/maintainPasswordPage',
      },
      {
        title: '第二代理人(子帳號)權限管理',
        itemId: '/maintainSubCustAccountInfoPage',
      },
      {
        title: '公司基本資料異動申請',
        itemId: '/maintainCorpInfoPage',
      },
      {
        title: '第一代理人(管理者)變更及基本資料異動申請',
        itemId: '/maintainFirstAgentPage',
      },
    ];

    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>公司基本資料異動</title>
        </Helmet>
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' items={items} title='帳號管理' history={this.props.history}></Sidebar>
              <div className='container bg-white col-9 shadow pl-4 pr-4'>
                {this.state.step != 3 ? <div className='title pt-2 mt-5 ml-3'>公司基本資料異動申請</div> : null}
                {this.state.step == 1 ? (
                  <div>
                    <div className='sub-title is-text-bold pl-3 pt-3'>目前登記的資料</div>
                    <div className='w-100 p-3'>
                      <table className='w-100'>
                        <tr className='bg-white'>
                          <td className='w-40 text-center border-gray'>公司/機關名稱/用戶名稱</td>
                          <td className='w-60 p-3 border-gray'>{this.state.form.cmpName.value}</td>
                        </tr>

                        <tr className='bg-white'>
                          <td className='w-40 text-center border-gray'>統一編號/其他</td>
                          <td className='w-60 p-3 border-gray'>{this.state.form.userId.value}</td>
                        </tr>

                        <tr className='bg-white'>
                          <td className='w-40 text-center border-gray'>公司/室內電話</td>
                          <td className='w-60 p-3 border-gray'>{this.state.form.cmpTelNo.value}</td>
                        </tr>
                      </table>
                      <div className='mt-3'>
                        <span>
                          此處基本資料異動僅作為更新網站資料使用，如需變更帳務或相關服務資訊，請與企業客服中心聯繫
                        </span>
                      </div>
                      <div className='mt-1'>
                        <span>TEL：4499-365(手機撥打請加02)。</span>
                      </div>
                      <div className='button-group mb-4'>
                        <div className='mt-4'>
                          <Button btnStyle='primary' size='large' onClick={() => this.modifyCorpInfo()}>
                            變更設定
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {this.state.step == 2 ? (
                  <div className='pt-3'>
                    <Formsy onValid={this.enableButton}>
                      <div className='button-group pl-3 pr-3'>
                        <table className='w-100'>
                          <tr className='bg-white'>
                            <td className='w-40 text-center border-gray backgroundColor-gray'>
                              公司/機關名稱/用戶名稱
                            </td>
                            <td className='w-60 p-3 border-gray'>{this.state.form.cmpName.value}</td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 text-center border-gray backgroundColor-gray'>統一編號/其他</td>
                            <td className='w-60 p-3 border-gray'>{this.state.form.userId.value}</td>
                          </tr>

                          <tr className='bg-white'>
                            <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              公司/室內電話
                            </td>
                            <td className='w-60 p-3 border-gray'>{this.state.form.cmpTelNo.value}</td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='newCmpTelNo'
                                value={this.state.newForm.newCmpTelNo.value}
                                required={false}
                                placeholder='請輸入更改的公司/室內電話'
                                label=''
                                onChange={this.onChange}
                                failResultMsg={this.state.CmpTelNoErrMsg}
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </Formsy>
                    <div className='mt-3 pl-3'>
                      <span>
                        此處基本資料異動僅作為更新網站資料使用，如需變更帳務或相關服務資訊，請與企業客服中心聯繫
                      </span>
                    </div>
                    <div className='mt-1 pl-3'>
                      <span>TEL：4499-365(手機撥打請加02)。</span>
                    </div>
                    <div className='button-group mb-5 ml-3'>
                      <div className='mt-4 two-buttons'>
                        <Button
                          btnStyle='primary'
                          size='large'
                          onClick={() => this.submitApply()}
                          disabled={!this.state.isValidate}>
                          送出申請
                        </Button>
                        <Button btnStyle='secondary' size='large' onClick={this.resetData}>
                          重填
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MaintainCorpInfoPage);
