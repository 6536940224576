import './App.css';
import Footer from './footer/Footer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './header/Header';
import React, { useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import ProductServicePage from './pages/productInfoController/ProductServicePage';
import ProdPage from './pages/productInfoController/ProdPage';
import CustInfoPage from './pages/productInfoController/CustInfoPage';
import ProdInfoPage from './pages/productInfoController/ProdInfoPage';
import ReplaceSimPage from './pages/productInfoController/ReplaceSimPage';
import TechContactInfoPage from './pages/productInfoController/TechContactInfoPage';
import MisMonitorContactInfoPage from './pages/productInfoController/MisMonitorContactInfoPage';
import ClfyMonitorContactInfoPage from './pages/productInfoController/ClfyMonitorContactInfoPage';
import MaintainPasswordPage from './pages/accountManagementController/MaintainPasswordPage';
import LoginPage from './pages/basicController/LoginPage';
import ProductListPage from './pages/productInfoController/ProductListPage';
import ApplyAccountPage from './pages/basicController/ApplyAccountPage';
import PasswordReset from './pages/basicController/PasswordReset';
import TrusteeInfoPage from './pages/basicController/TrusteeInfoPage';
import MaintainAccountInfoPage from './pages/accountManagementController/MaintainAccountInfoPage';
import BasicResultPage from './pages/resultController/BasicResultPage';
import BasicResultNoHeaderPage from './pages/resultController/BasicResultNoHeaderPage';
import ObstacleApplyPage from './pages/obstacleNotifyController/ObstacleApplyPage';
import CommonServicePage from './pages/basicController/CommonService/CommonServicePage';
import CloudServicePage from './pages/basicController/CommonService/CloudServicePage';
import AreaServerPage from './pages/basicController/CommonService/AreaServerPage';
import TestSpeedPage from './pages/basicController/CommonService/TestSpeedPage';
import ProductManualPage from './pages/basicController/CommonService/ProductManualPage';
import EInvoiceService from './pages/basicController/CommonService/EInvoiceServicePage';
import QueryObstacleRecordPage from './pages/obstacleNotifyController/QueryObstacleRecordPage';
import RedirectPTRMS from './pages/obstacleNotifyController/RedirectPTRMS';

import MaintainCorpInfoPage from './pages/accountManagementController/MaintainCorpInfoPage';

import MaintainSubCustAccountInfoPage from './pages/accountManagementController/MaintainSubCustAccountInfoPage';
import SubCustAccountCreatePage from './pages/accountManagementController/SubCustAccountCreatePage';
import SubCustAccountUpdatePage from './pages/accountManagementController/SubCustAccountUpdatePage';
import SubCustAccountSuspendPage from './pages/accountManagementController/SubCustAccountSuspendPage';

import MaintainFirstAgentPage from './pages/accountManagementController/FirstAgentUpdatePage';
import MaintainTrusteePage from './pages/accountManagementController/MaintainTrusteePage';

import RegisterProblemPage from './pages/basicController/CommonProblem/RegisterProblemPage';
import MemberInfoProblemPage from './pages/basicController/CommonProblem/MemberInfoProblemPage';
import AccountProblemPage from './pages/basicController/CommonProblem/AccountProblemPage';
import LoginProblemPage from './pages/basicController/CommonProblem/LoginProblemPage';
import PasswordProblemPage from './pages/basicController/CommonProblem/PasswordProblemPage';
import AgreementPage from './pages/basicController/AgreementPage';
import BackEndLoginPage from './pages/basicController/BackEndLoginPage';
import SysUsedRecordPage from './pages/RecordController/SysUsedRecordPage';
import UploadApplyRecordPage from './pages/RecordController/UploadApplyRecordPage';

import PrintApplyAccountPage from './pages/application/PrintApplyAccountPage';
import HowtoPage from './pages/productInfoController/HowtoPage';
import CustAcntActivatePage from './pages/basicController/CustAcntActivatePage';
import BillInfoPage from './pages/billInfoController/BillInfoPage';
import ForceChangePwd from './pages/basicController/ForceChangePwd';
import BillInfoSettingPage from './pages/accountManagementController/BillInfoSettingPage';
import DownloadFilePage from './pages/productInfoController/DownloadFilePage';
import UploadFilePage from './pages/accountManagementController/UploadFilePage';
import PrintMaintFirstAgentPage from './pages/application/PrintMaintFirstAgentPage';

import Index from './pages/Index';
import './css/hy-light-theme.css';
import AccountManagePage from './pages/accountManagementController/AccountManagePage';
import Error from './pages/Error';
import MisTechContactPage from './pages/productInfoController/MisTechContactPage';
import ClfyTechContactPage from './pages/productInfoController/ClfyTechContactPage';
import ProdServiceProductInfoPage from './pages/productInfoController/ProdServiceProductInfoPage';

const App = (state) => {
  // [SR253879 ECP申請流程調整] 列印頁不顯示footer
  let needFooter = true;
  let pageUri = window.location.href;
  if (pageUri.includes('/printMaintFirstAgentPage') || pageUri.includes('/printApplyAccountPage')) {
    needFooter = false;
  }
  const [isHeaderReady, setHeaderReady] = useState(false);
  return (
    <Router basename={'ECP'}>
      <Header callback={() => setHeaderReady(true)} />
      {isHeaderReady ? (
        <ParallaxProvider>
          <Switch>
            {/* 登入頁 */}
            <Route path='/loginPage' exact component={LoginPage} />
            <Route path='/' exact component={Index} />
            {/* 登入後首頁*/}
            <Route path='/index' exact component={Index} />
            {/* 產品服務作業-產品分類 */}
            <Route path='/productServicePage' exact component={ProductServicePage} />
            {/* 產品服務作業-產品 */}
            <Route path='/productListPage' exact component={ProductListPage} />
            {/* 產品服務作業-產品詳細內容 */}
            <Route path='/ProdPage' exact component={ProdPage} />
            {/* 產品服務作業-客戶資料 */}
            <Route path='/productService/custInfoPage' exact component={CustInfoPage} />
            {/* 產品服務作業-產品資料 */}
            <Route path='/productService/prodInfoPage' exact component={ProdInfoPage} />
            {/* 產品服務作業-更換SIM卡 */}
            <Route path='/productService/replaceSimPage' exact component={ReplaceSimPage} />
            {/* 產品服務作業-帳號密碼維護 */}
            <Route path='/maintainPasswordPage' exact component={MaintainPasswordPage} />
            {/* 產品服務作業-技術聯絡人 */}
            <Route path='/productService/techContactInfoPage' exact component={TechContactInfoPage} />
            {/* 產品服務作業-主動式監控 */}
            <Route path='/productService/misMonitorContactInfoPage' exact component={MisMonitorContactInfoPage} />
            {/* 產品服務作業-主動式監控_clfy */}
            <Route path='/productService/clfyMonitorContactInfoPage' exact component={ClfyMonitorContactInfoPage} />
            {/* MIS技術聯絡人 */}
            <Route path='/productService/misTechContactPage' exact component={MisTechContactPage} />
            {/* Clfy技術聯絡人 */}
            <Route path='/productService/clfyTechContactPage' exact component={ClfyTechContactPage} />
            {/* 市話NGN基本資料 */}
            <Route path='/productService/prodServiceProductInfoPage' exact component={ProdServiceProductInfoPage} />
            {/* 帳號管理頁 */}
            <Route path='/accountManagePage' exact component={AccountManagePage} />
            {/* 帳號申請 */}
            <Route path='/applyAccountPage' exact component={ApplyAccountPage} />
            {/* 常用服務 */}
            <Route path='/commonServicePage' exact component={CommonServicePage} />
            {/* 雲端產品常用服務 */}
            <Route path='/cloudServicePage' exact component={CloudServicePage} />
            {/* 帳號申請常見問題-註冊問題 */}
            <Route path='/registerProblemPage' exact component={RegisterProblemPage} />
            {/* 帳號申請常見問題-會員資料 */}
            <Route path='/memberInfoProblemPage' exact component={MemberInfoProblemPage} />
            {/* 帳號申請常見問題-帳號問題 */}
            <Route path='/accountProblemPage' exact component={AccountProblemPage} />
            {/* 帳號申請常見問題-登入問題 */}
            <Route path='/loginProblemPage' exact component={LoginProblemPage} />
            {/* 帳號申請常見問題-密碼問題 */}
            <Route path='/passwordProblemPage' exact component={PasswordProblemPage} />
            {/* 各地區服務主機 */}
            <Route path='/areaServerPage' exact component={AreaServerPage} />
            {/* 線上測速 */}
            <Route path='/testSpeedPage' exact component={TestSpeedPage} />
            {/* 產品服務手冊 */}
            <Route path='/productManualPage' exact component={ProductManualPage} />
            {/* 企業用戶免登入查發票GO */}
            <Route path='/eInvoiceServicePage' exact component={EInvoiceService} />
            {/* 登入頁-忘記密碼 */}
            <Route path='/passwordReset' exact component={PasswordReset} />
            {/* 登入頁-忘記密碼 */}
            <Route path='/trusteeInfoPage' exact component={TrusteeInfoPage} />
            {/* 帳號啟用 */}
            <Route path='/custAcntActivatePage' component={CustAcntActivatePage} />
            {/* 帳號管理-基本資料維護 */}
            <Route path='/maintainAccountInfoPage' exact component={MaintainAccountInfoPage} />
            {/* 強制變更密碼 */}
            <Route path='/forceChangePwd' component={ForceChangePwd} />
            {/* 帳號管理-公司基本資料異動 */}
            <Route path='/maintainCorpInfoPage' exact component={MaintainCorpInfoPage} />
            {/* 帳號管理-第一代理人(管理者)變更及基本資料異動申請 */}
            {/* 帳號管理-第一代理人(管理者)變更及基本資料異動申請 -列印申請書*/}
            <Route path='/printMaintFirstAgentPage' exact component={PrintMaintFirstAgentPage} />
            <Route path='/maintainFirstAgentPage' exact component={MaintainFirstAgentPage} />
            {/* 帳號管理-第二代理人(子帳號)權限管理 */}
            <Route path='/maintainSubCustAccountInfoPage' exact component={MaintainSubCustAccountInfoPage} />
            {/* 帳號管理-第二代理人(子帳號)權限管理-新增第二代理人 */}
            <Route
              path='/maintainSubCustAccountInfoPage/subCustAccountCreatePage'
              exact
              component={SubCustAccountCreatePage}
            />
            {/* 帳號管理-第二代理人(子帳號)權限管理-修改第二代理人 */}
            <Route
              path='/maintainSubCustAccountInfoPage/subCustAccountUpdatePage'
              exact
              component={SubCustAccountUpdatePage}
            />
            {/* 帳號管理-第二代理人(子帳號)權限管理-停用第二代理人 */}
            <Route
              path='/maintainSubCustAccountInfoPage/subCustAccountSuspendPage'
              exact
              component={SubCustAccountSuspendPage}
            />
            {/* 帳號管理-受任人變更及基本資料異動申請 */}
            <Route path='/maintainSubCustAccountInfoPage/maintainTrusteePage' exact component={MaintainTrusteePage} />
            {/* 共用-結果頁 */}
            <Route path='/basicResultPage' exact component={BasicResultPage} />
            {/* 共用-結果頁(NoHeader) */}
            <Route path='/basicResultNoHeaderPage' exact component={BasicResultNoHeaderPage} />
            {/* 障礙申告及查詢-線上申告 */}
            <Route path='/obstacleApplyPage' exact component={ObstacleApplyPage} />
            {/* 障礙申告及查詢-申告記錄查詢 */}
            <Route path='/queryObstacleRecordPage' exact component={QueryObstacleRecordPage} />
            {/* 障礙申告及查詢-IPv6反解申請 */}
            <Route path='/redirectPTRMS' exact component={RedirectPTRMS} />
            {/* 系統使用紀錄查詢 */}
            <Route path='/sysUsedRecordPage' exact component={SysUsedRecordPage} />
            {/* 產品服務異動進件紀錄查詢 */}
            <Route path='/uploadApplyRecordPage' exact component={UploadApplyRecordPage} />
            {/* 帳號申請 -列印申請書*/}
            <Route path='/printApplyAccountPage' exact component={PrintApplyAccountPage} />
            {/* 產品服務作業-郵件代管功能說明*/}
            <Route path='/howtoPage' exact component={HowtoPage} />
            {/* 使用條款*/}
            <Route path='/agreementPage' exact component={AgreementPage} />
            {/* 後臺代登*/}
            <Route path='/backEndLoginPage' exact component={BackEndLoginPage} />
            {/* 帳務資訊*/}
            <Route path='/billInfoPage' exact component={BillInfoPage} />
            {/* new 繳款通知設定 */}
            <Route path='/billInfoSettingPage' exact component={BillInfoSettingPage} />
            {/* 產品服務檔案下載 */}
            <Route path='/productService/downloadFilePage' exact component={DownloadFilePage} />
            {/* 產品服務檔案上傳 */}
            <Route path='/uploadFilePage' exact component={UploadFilePage} />
            <Route component={Error} />
          </Switch>
        </ParallaxProvider>
      ) : null}
      {needFooter ? <Footer /> : null}
    </Router>
  );
};

export default App;
