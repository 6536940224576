/**
 * 登入驗證錯誤公版，
 * propData 等於上一層的props
 */
export const ToAuthorizationErrorPage = (propData) => {
  propData.history.push(
    {
      pathname: '/resultController/ResultPage',
    },
    {
      type: 'fail',
      pageName: '',
      title: '',
      description: '您登入的門號/帳號不適用此服務，謝謝!',
      actions: [
        {
          text: '前往個人專區',
          link: process.env.REACT_APP_HOME_PAGE,
          btnStyle: 'primary',
        },
      ],
    }
  );
};
