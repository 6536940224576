import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import TransactionApplication from '../resultController/TransactionApplication';
import * as callApiUtil from '../../utils/callApiUtil';
import * as emptyUtil from '../../utils/emptyUtil';

class SubCustAccountSuspendPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      subBillList: [],
      subNonBillList: [],
      subAllList: [],
      form: {
        accntName: '', //使用者姓名
        accntTelNo: '', //公司電話
        newAccntTelNo: '', //新公司電話
        accntMSISDN: '', //手機電話
        newAccntMSISDN: '', //新手機電話
        accntFaxNo: '', //傳真號碼
        newAccntFaxNo: '', //新傳真號碼
        accntEmail: '',
        newAccntEmail: '', //新Email
      },
      selectedCust: {},
      all: '2',
      bill: '3',
      nonBill: '4',
    };
  }

  // 初始化
  componentDidMount() {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/SubCustSuspendController/subCustSuspendInit.action', {}).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState(() => ({
          // 塞custAccountBean
          subAllList: response.subAllList,
          subNonBillList: response.subNonBillList,
          subBillList: response.subBillList,
          isLoading: false,
        }));
        document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        //API回傳失敗
        this.toErrPage(response.resultMessage.message);
      }
    });
  }

  // 選擇操作哪一個第二代理人
  selecteCust = (custAccountBean) => {
    this.setState(() => ({
      selectedCust: custAccountBean,
      step: '2',
    }));
  };

  // 送出停用申請
  submit = () => {
    if (this.state.selectedCust.accntType == this.state.all || this.state.selectedCust.accntType == this.state.bill) {
      this.setState({ isLoading: true });
      callApiUtil
        .callApi('/ECPAP/API/SubCustSuspendController/suspendBillAndAll.action', {
          custAccountBean: this.state.selectedCust,
        })
        .then((response) => {
          if (
            response != null &&
            response != undefined &&
            response.resultMessage != null &&
            response.resultMessage != undefined &&
            response.resultMessage.message == 's'
          ) {
            //API回傳成功
            this.toSuccess();
          } else {
            if (response.resultMessage.code == 'MA_006_007') {
              this.toErrPage('該子帳號目前尚有未審核的異動申請，請待審核結束後再送出新的申請。');
            }
            //API回傳失敗
            this.toErrPage(response.resultMessage.message);
          }
        });
    } else if (this.state.selectedCust.accntType == this.state.nonBill) {
      this.setState({ isLoading: true });
      callApiUtil
        .callApi('/ECPAP/API/SubCustSuspendController/suspendNonBill.action', {
          custAccountBean: this.state.selectedCust,
        })
        .then((response) => {
          if (
            response != null &&
            response != undefined &&
            response.resultMessage != null &&
            response.resultMessage != undefined &&
            response.resultMessage.message == 's'
          ) {
            //API回傳成功
            this.toSuccess();
          } else {
            //API回傳失敗
            this.toErrPage(response.resultMessage.message);
          }
        });
    }
  };

  onChange = (name, value) => {
    if (name == 'step') {
      //是否勾選修改基本資料
      this.setState({ step: value });
    } else {
      let newForm = Object.assign(this.state.form);
      newForm[name].value = value.trim();
      this.setState({ form: newForm });
    }
  };

  toSuccess = () => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: 'success',
        pageName: '第二代理人(子帳號)權限管理',
        title: '更新完成',
        description: '您申請帳號停用已審核成功，自即日起正式生效，謝謝。',
        showActions: true,
        actions: [
          {
            text: '返回第二代理人(子帳號)權限管理',
            link: '/maintainSubCustAccountInfoPage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/index',
            btnStyle: 'secondary',
          },
        ],
      }
    );
  };

  // 導錯誤頁
  toErrPage = (errMsg) => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: 'fail',
        pageName: '第二代理人(子帳號)權限管理',
        title: '提醒訊息',
        description: errMsg,
        showActions: true,
        actions: [
          {
            text: '返回第二代理人(子帳號)權限管理',
            link: '/maintainSubCustAccountInfoPage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/index',
            btnStyle: 'secondary',
          },
        ],
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>第二代理人(子帳號)權限管理</title>
        </Helmet>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />

        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='帳號管理' history={this.props.history}></Sidebar>
              {/* 第一頁 */}
              {this.state.step == 1 && (
                <div className='container bg-white col-9 shadow pl-4 pr-4'>
                  <div className='title pt-2 mt-5 ml-3'>第二代理人(子帳號)權限管理</div>
                  <div>
                    <div className='sub-title is-text-bold pl-3 pt-3'>第二代理人維護明細</div>

                    <div className='h5 mt-3 is-text-bold pl-3 pt-3'>第二代理人(子帳號)一全功能:帳務類+非帳務類</div>
                    {this.state.subAllList != null && this.state.subAllList.length != 0 ? (
                      <div className='w-100 pl-3'>
                        <table className='w-100'>
                          {this.state.subAllList.map((cust, index) => {
                            return (
                              <tr className='bg-white' key={'all' + index}>
                                <td className='w-40 text-center border-gray'>
                                  <a href='#' onClick={() => this.selecteCust(cust)}>
                                    {cust.loginID}
                                  </a>
                                </td>
                                <td className='w-60 p-3 border-gray'>
                                  {cust.accntName} {cust.gender == 'M' ? '先生' : '女士'}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      <div className='mt-3 pl-3 red font-weight-bold'>查無第二代理人(子帳號)一全功能</div>
                    )}

                    <div className='h5 mt-5 is-text-bold pl-3 pt-3'>第二代理人(子帳號)一帳務類功能</div>
                    {this.state.subBillList != null && this.state.subBillList.length != 0 ? (
                      <div className='w-100 pl-3'>
                        <table className='w-100'>
                          {this.state.subBillList.map((cust, j) => {
                            return (
                              <tr className='bg-white' key={'bill' + j}>
                                <td className='w-40 text-center border-gray'>
                                  <a href='#' onClick={() => this.selecteCust(cust)}>
                                    {cust.loginID}
                                  </a>
                                </td>
                                <td className='w-60 p-3 border-gray'>
                                  {cust.accntName} {cust.gender == 'M' ? '先生' : '女士'}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      <div className='mt-3 pl-3 red font-weight-bold'>查無第二代理人(子帳號)一帳務類功能</div>
                    )}

                    <div className='h5 mt-5 is-text-bold pl-3 pt-3'>第二代理人(子帳號)一非帳務類功能</div>
                    {this.state.subNonBillList != null && this.state.subNonBillList.length != 0 ? (
                      <div className='w-100 pl-3 pb-5'>
                        <table className='w-100'>
                          {this.state.subNonBillList.map((cust, k) => {
                            return (
                              <tr className='bg-white' key={'nonBill' + k}>
                                <td className='w-40 text-center border-gray'>
                                  <a href='#' onClick={() => this.selecteCust(cust)}>
                                    {cust.loginID}
                                  </a>
                                </td>
                                <td className='w-60 p-3 border-gray'>
                                  {cust.accntName} {cust.gender == 'M' ? '先生' : '女士'}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    ) : (
                      <div className='mt-3 pl-3 red font-weight-bold mb-4'>查無第二代理人(子帳號)一非帳務類功能</div>
                    )}
                  </div>
                </div>
              )}
              {/* 第一頁 */}
              {/* 第二頁 */}
              {this.state.step == 2 && (
                <div className='container bg-white col-9 shadow pl-4 pr-4'>
                  <div className='title pt-2 mt-5 ml-3'>停用第二代理人(子帳號)</div>
                  <div className='w-100 p-3'>
                    <div className='sub-title mb-4 is-text-bold'>目前登記的資料</div>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>使用者姓名</td>
                        <td className='w-60 p-3 border-gray'>
                          {emptyUtil.checkVal(this.state.selectedCust.accntName)}{' '}
                          {this.state.selectedCust.gender == 'M' ? '先生' : '女士'}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>辦公室電話</td>
                        <td className='w-60 p-3 border-gray'>
                          {emptyUtil.checkVal(this.state.selectedCust.accntTelNO)}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>行動電話</td>
                        <td className='w-60 p-3 border-gray'>
                          {emptyUtil.checkVal(this.state.selectedCust.accntMsisdn)}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>傳真</td>
                        <td className='w-60 p-3 border-gray'>
                          {emptyUtil.checkVal(this.state.selectedCust.accntFaxNO)}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>Email</td>
                        <td className='w-60 p-3 border-gray'>
                          {emptyUtil.checkVal(this.state.selectedCust.accntEmail)}
                        </td>
                      </tr>
                    </table>
                    <div className='button-group mb-4'>
                      <div className='mt-5'>
                        <Button btnStyle='primary' size='large' onClick={this.submit}>
                          送出申請
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* 第二頁 */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SubCustAccountSuspendPage);
