import React from 'react';
import { withRouter } from 'react-router-dom';
import Banner from '../../components/partials/banner/Banner';
import Helmet from 'react-helmet';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import * as validation from '../../utils/validation';
import * as callApiUtil from '../../utils/callApiUtil';

class ForceChangePwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPwdMsg: '',
      newPwdMsg: '',
      confirmPwdMsg: '',
      isChecked: false,
      form: {
        // 忘記密碼需要輸入的驗證資料
        oldPwd: '', // 舊密碼
        newPwd: '', // 新密碼
        confirmPwd: '', // 確認密碼
        useOriPwd: false, // 沿用舊密碼
      },
      isLoading: false,
      canSubmit: false,
    };
  }

  // 將表單輸入的資料加回form參數中
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name] = value;
    let canSubmit = this.state.canSubmit;
    if (name == 'useOriPwd') {
      if (value) {
        newForm['oldPwd'] = '';
        newForm['newPwd'] = '';
        newForm['confirmPwd'] = '';
      }
      canSubmit = value;
      this.setState({
        form: newForm,
        canSubmit: canSubmit,
        oldPwdMsg: '',
        newPwdMsg: '',
        confirmPwdMsg: '',
        isChecked: this.value,
      });
    } else {
      this.setState({
        form: newForm,
        canSubmit: canSubmit,
        oldPwdMsg: '',
        newPwdMsg: '',
        confirmPwdMsg: '',
        isChecked: this.value,
      });
      this.enableButton();
    }
  };

  // 送出資料到後端啟用帳號
  submit = () => {
    // 組vin
    let vin = {
      // 0330 弱點掃描，將密碼名稱改為 credential
      oldCredential: this.state.form.oldPwd,
      newCredential: this.state.form.newPwd,
      confirmCredential: this.state.form.confirmPwd,
      useOriPwd: this.state.form.useOriPwd,
    };
    if (this.state.canSubmit) {
      this.setState({ isLoading: true });
      callApiUtil.callApi('/ECPAP/API/ForceUpdatePwdController/forceUpdatePwd.action', vin).then((data) => {
        let message;
        let title;
        let isSuccess;
        if (
          data != null &&
          data != undefined &&
          data.resultMessage != null &&
          data.resultMessage != undefined &&
          data.resultMessage.success &&
          data.resultMessage.message == 's'
        ) {
          //API回傳成功
          data.systemMenuAuthList.pop(); //將最後一筆移除(首頁)
          //1.將munu清單與sidbar清單放入 session
          let menuList = data.systemMenuAuthList.map((item) => {
            let uri = '';
            let pageList = [];
            if (item.pageAuthList.length > 0) {
              //將第一筆放入menu 的uri，若沒有siderBar 則直接從menu點選此路徑
              uri = item.pageAuthList[0].uri;
              //siderBar過濾條件: showLink =true 加入siderBar
              pageList = item.pageAuthList.filter((value) => value.showLink == true);
            }
            return {
              menuId: item.moduleId,
              menuName: item.moduleName,
              uri: uri,
              pageList: pageList,
            };
          });
          let menuobject = { menuList: menuList };
          menuobject = JSON.stringify(menuobject);
          window.localStorage.setItem('menuList', menuobject);
          window.localStorage.setItem('accountName', data.accountName);
          //如果是沿用舊密碼, 直接導首頁
          if (this.state.form.useOriPwd) {
            this.props.history.push('index');
          } else {
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: 'successs',
                pageName: '變更密碼',
                title: '變更成功',
                description: '您的密碼已更新。',
                actions: [
                  {
                    text: '返回首頁',
                    link: '/index',
                    btnStyle: 'primary',
                  },
                ],
              }
            );
          }
        } else {
          if (data.resultMessage.code == 'MA_002_002') {
            this.setState({ oldPwdMsg: '舊密碼輸入錯誤', isLoading: false });
          } else if (data.resultMessage.code == 'MA_002_004') {
            this.setState({ newPwdMsg: '新密碼與舊密碼相同', isLoading: false });
          } else {
            //API回傳失敗
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: 'fail',
                pageName: '變更密碼',
                title: '提醒訊息',
                description: data.resultMessage.message,
                actions: [
                  {
                    text: '返回登入頁',
                    link: '/loginPage',
                    btnStyle: 'primary',
                  },
                ],
              }
            );
          }
        }
      });
    }
  };

  // 重置輸入的資料
  resetData = () => {
    let newForm = {
      oldPwd: '',
      newPwd: '',
      confirmPwd: '',
    };
    this.setState({
      form: newForm,
      canSubmit: false,
      oldPwdMsg: '',
      newPwdMsg: '',
      confirmPwdMsg: '',
      isChecked: false,
    });
  };

  // 驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;
    let oldPwdMsg = '';
    let newPwdMsg = '';
    let confirmPwdMsg = '';

    if (validation.isEmpty(this.state.form.oldPwd)) {
      oldPwdMsg = '請輸入密碼';
      isValid = false;
    } else {
      oldPwdMsg = '';
    }

    if (validation.isEmpty(this.state.form.newPwd) || this.state.form.newPwd == this.state.form.oldPwd) {
      isValid = false;
      newPwdMsg = '請輸入新密碼';
    } else if (!validation.validPWD(this.state.form.newPwd)) {
      isValid = false;
      newPwdMsg = '請輸入正確的新密碼';
    } else {
      newPwdMsg = '';
    }

    if (
      validation.isEmpty(this.state.form.confirmPwd) ||
      !validation.validPWD(this.state.form.confirmPwd) ||
      this.state.form.newPwd !== this.state.form.confirmPwd
    ) {
      isValid = false;
      confirmPwdMsg = '請輸入與上一欄位相同的新密碼';
    } else {
      confirmPwdMsg = '';
    }

    this.setState(() => ({
      oldPwdMsg: oldPwdMsg,
      newPwdMsg: newPwdMsg,
      confirmPwdMsg: confirmPwdMsg,
      canSubmit: isValid && !this.state.form.useOriPwd,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>變更密碼</title>
        </Helmet>
        <Banner title='變更密碼 Change password' />
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <span className='h4 is-text-bold'>您為初次登入或是已逾系統規定時間內未更新密碼! 請更新密碼。</span>
              <br />
              <span className='black-6E6E6E h5 font-bold'>
                It's your first time to log in or your password has expired. Please change your password.
              </span>
            </div>
            <div className='bg-white pb-3'>
              <Formsy onValid={this.enableButton}>
                <Grid container spacing={6} className='mt-md-3'>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      className='mt-3'
                      name='oldPwd'
                      value={this.state.form.oldPwd}
                      required={true}
                      placeholder='請輸入密碼 Please enter original password'
                      label='密碼'
                      labelEng='Current (Old) Password'
                      onChange={this.onChange}
                      disabled={this.state.form.useOriPwd}
                      type='password'
                      failResultMsg={this.state.oldPwdMsg}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} className='mt-md-3'>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      name='newPwd'
                      value={this.state.form.newPwd}
                      required={true}
                      placeholder='請輸入新密碼 Please enter new password'
                      label='新密碼(6~12碼英文或數字)'
                      labelEng='New Password (6~12 characters consisting of alphabets or numbers)'
                      onChange={this.onChange}
                      maxLength='12'
                      disabled={this.state.form.useOriPwd}
                      type='password'
                      failResultMsg={this.state.newPwdMsg}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} className='mt-md-3'>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      name='confirmPwd'
                      value={this.state.form.confirmPwd}
                      required={true}
                      placeholder='請再次輸入新密碼 Please enter new password, again'
                      label='再次輸入新密碼'
                      labelEng='New Password Confirm'
                      maxLength='12'
                      onChange={this.onChange}
                      disabled={this.state.form.useOriPwd}
                      type='password'
                      failResultMsg={this.state.confirmPwdMsg}
                    />
                  </Grid>
                </Grid>
                <input
                  type='checkbox'
                  name='useOriPwd'
                  className='ml-5 mr-2 mt-3'
                  id='useOriPwd'
                  checked={this.state.isChecked}
                  onClick={() => this.onChange('useOriPwd', !this.state.form.useOriPwd)}></input>
                <label for='useOriPwd' className='checkbox-label is-text-bold'>
                  沿用舊密碼
                </label>
                <span className='black-6E6E6E h6 font-bold'> Use Original Password.</span>
              </Formsy>
              <div className='button-group mt-4 mb-4'>
                <div className='row two-buttons'>
                  <Button
                    className='col-2 ml-5 mt-3'
                    btnStyle='primary'
                    size='large'
                    onClick={this.submit}
                    disabled={!this.state.canSubmit}>
                    送出 Send
                  </Button>
                  <Button className='col-1 mt-3' btnStyle='secondary' size='large' onClick={this.resetData}>
                    重設 Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ForceChangePwd);
