import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Helmet from 'react-helmet';
import { Navigation } from 'react-minimal-side-navigation';
import * as callApiUtil from '../../utils/callApiUtil';
import { validationRules } from 'formsy-react';
import Button from '../../components/Button';
import { v4 as uuidv4 } from 'uuid';

class ProdPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // 顯示頁面
      page: '',
      iframeHigh: '', //
      redirectUrl: '',
      iframeUrl: '',
      forceUpdRandNum: '', //每次更新內嵌url時以uuidv4產生亂數更新iframe的key
      sysApiUrl: '', //1:內部連接 0:外部連結
      //sider參數
      items: [
        {
          title: '',
          itemId: '',
          subNav: [
            {
              title: '',
              itemId: '',
              frameFlag: '',
              flagType: '',
              serviceId: '',
              sysApiUrl: '',
              productId: '',
            },
          ],
        },
      ],
      //導連URL參數
      referService: {
        title: '',
        itemId: '', //url
        frameFlag: '',
        flagType: '',
        serviceId: '',
        productId: '',
        sysApiUrl: '',
      },
      mvpnAgree: '', // 是否同意MVPN條款 SR260333
      mvpnAgreeContent: '', // MVPN條款內容
      canSubmit: false,
      isScrollToBottom: false,
      isDisableMvpnButton: false,
    };
  }
  componentDidMount = () => {
    // 取得頁面初始化資料
    this.setState({ isLoading: true });
    console.log(this.props.productId, 'productId');
    console.log(this.props.fx, 'fx');
    console.log(this.props.productName, 'productName');

    if (this.props.productId != null) {
      let QueryProdServiceInfoVin = { productId: this.props.productId };
      callApiUtil
        .callApi('/ECPAP/API/ProductController/queryProductServieInfo.action', QueryProdServiceInfoVin)
        .then((response) => {
          // 判斷回傳資料
          if (
            response != null &&
            response != undefined &&
            response.resultMessage != null &&
            response.resultMessage != undefined &&
            response.resultMessage.success
          ) {
            //API回傳成功，將資料顯示在頁面上
            let itemList = [];
            if (response.productServiceMenuBeanList != null) {
              itemList = response.productServiceMenuBeanList.map((data) => {
                let subNavList;
                if (data.serviceBeanList != null && data.serviceBeanList.length > 0) {
                  subNavList = data.serviceBeanList.map((value) => {
                    return {
                      title: value.title,
                      itemId: value.url,
                      frameFlag: value.frameFlag,
                      flagType: value.flagType,
                      serviceId: value.id,
                      productId: value.productId,
                      sysApiUrl: value.sysApiUrl,
                    };
                  });
                }

                return { title: data.itemName, itemId: data.itemId, subNav: subNavList };
              });
            }

            if (response.specialCaseList != null) {
              response.specialCaseList.map((value) => {
                let service = {
                  title: value.title,
                  itemId: value.url,
                  frameFlag: value.frameFlag,
                  flagType: value.flagType,
                  serviceId: value.id,
                  productId: value.productId,
                  sysApiUrl: value.sysApiUrl,
                };
                itemList.push(service);
              });
            }

            if (response.agreeMVPN != null) {
              let resMvpnAgree = response.agreeMVPN.flag;
              let resMvpnAgreeContent = response.agreeMVPN.content;
              this.setState({ mvpnAgree: resMvpnAgree, mvpnAgreeContent: resMvpnAgreeContent, isLoading: false });
            }

            //referServiceId有值 表示預設初始頁需要顯示此頁面
            if (this.props.referServiceId != null) {
              let referService;
              let serviceId = this.props.referServiceId;
              response.productServiceMenuBeanList.forEach(function (item, index, array) {
                if (item != null) {
                  item.serviceBeanList.forEach(function (value, index, array) {
                    if (serviceId === value.id) {
                      referService = {
                        title: value.title,
                        itemId: value.url,
                        frameFlag: value.frameFlag,
                        flagType: value.flagType,
                        serviceId: value.id,
                        productId: value.productId,
                        sysApiUrl: value.sysApiUrl,
                      };
                    }
                  });
                }
              });
              this.toServiceUrlPage(referService);
            }
            this.setState({ items: itemList, isLoading: false });
            document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            //API回傳失敗
            this.setState({ isLoading: false });
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: 'fail',
                pageName: '產品服務作業查詢',
                title: '查詢失敗',
                description: response.resultMessage.message,
                actions: [
                  {
                    text: '返回企業用戶專區',
                    link: '/index',
                    btnStyle: 'primary',
                  },
                ],
              }
            );
          }
        });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }
  //監聽器事件:取得iframe傳出來的高度、iframe登入權限驗證
  receiveMessage = (event) => {
    if (event !== undefined) {
      //取得高度
      if (event.data.type === 'high') {
        this.setState({ iframeHigh: event.data.bodyHight });
      }
      //登入權限驗證
      if (event.data.type === 'loginAuth' && event.data.loginAuth === 'noLogin') {
        window.location.href = process.env.PUBLIC_URL + '/loginPage';
      }
    }
  };

  //導連URL 組裝參數
  toPage = (itemId) => {
    this.setState({ isLoading: true });
    let referService = null;
    this.state.items.forEach(function (item, index, array) {
      if (item != null) {
        let navs = [];
        if (item.subNav == null) {
          navs.push(item);
        } else {
          navs = item.subNav;
        }
        navs.forEach(function (value, index, array) {
          if (itemId == value.itemId) {
            referService = {
              title: value.title,
              itemId: value.itemId,
              frameFlag: value.frameFlag,
              flagType: value.flagType,
              serviceId: value.serviceId,
              productId: value.productId,
              sysApiUrl: value.sysApiUrl,
            };
          }
        });
      }
    });
    if (referService != null) {
      this.toServiceUrlPage(referService);
    }
    this.setState({ isLoading: false });
  };

  //call URL 1.判斷內部連結或是外部連結 2.內嵌或外嵌
  toServiceUrlPage = (referService) => {
    //組裝內部連結URL參數
    let paramUrl =
      referService.itemId +
      '?categoryName=' +
      this.props.productName +
      '&userNo=' +
      this.props.userNo +
      '&prodId=' +
      this.props.productId +
      '&extraInfo=' +
      this.props.extraInfo +
      '&fx=' +
      this.props.fx +
      '&acctId=' +
      this.props.acctId;
    if (referService.sysApiUrl === '1') {
      //為內部API，直接導頁
      if (referService.frameFlag === '1') {
        window.open(paramUrl);
      } else if (referService.frameFlag === '0') {
        this.setState({
          page: 'iframe',
          sysApiUrl: '1',
          iframeUrl: paramUrl,
          forceUpdRandNum: uuidv4(),
        });
        //建立監聽器取得iframe傳出來的高度
        window.addEventListener('message', this.receiveMessage, false);
      }
      this.setState({ isLoading: false, redirectUrl: paramUrl });
    } else {
      //為外部http://連結時,callAPI取的magicCode等參數
      let QueryProdServiceUrlVin = {
        productId: referService.productId,
        serviceId: referService.serviceId,
        extraInfo: this.props.extraInfo,
        userNo: this.props.userNo,
      };
      callApiUtil
        .callApi('/ECPAP/API/ProductController/queryProductServieUrl.action', QueryProdServiceUrlVin)
        .then((response) => {
          // 判斷回傳資料
          if (
            response != null &&
            response != undefined &&
            response.resultMessage != null &&
            response.resultMessage != undefined &&
            response.resultMessage.success
          ) {
            //API回傳成功，將資料顯示在頁面上
            //需要外開視窗
            if (referService.frameFlag === '1') {
              window.open(response.redirectUrl, '', 'height=600,width=750,left=300,top=100');
            } else if (referService.frameFlag === '0') {
              this.setState({
                page: 'iframe',
                sysApiUrl: '0',
                iframeUrl: response.redirectUrl,
                forceUpdRandNum: uuidv4(),
              });
            }
            this.setState({ isLoading: false, redirectUrl: response.redirectUrl });
          } else {
            //API回傳失敗
            this.setState({ isLoading: false });
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: 'fail',
                pageName: '產品服務作業查詢',
                title: '查詢失敗',
                description: response.resultMessage.message,
                actions: [
                  {
                    text: '返回企業用戶專區',
                    link: '/index',
                    btnStyle: 'primary',
                  },
                ],
              }
            );
          }
        });
    }
  };

  onChange = (name, value) => {
    console.log(name, value);
    if (name == 'accountrule') {
      this.setState({ canSubmit: !this.state.canSubmit });
    } else {
    }
  };
  handleScroll = (event) => {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      console.log(target.scrollHeight);
      this.setState({ isScrollToBottom: true });
      console.log(this.state.canSubmit);
    }
  };
  nextStep = () => {
    this.setState({ mvpnAgreeContent: !this.state.mvpnAgreeContent });
    this.setState({ isDisableMvpnButton: true });
    let MvpnAgreePageVin = {};
    callApiUtil.callApi('/ECPAP/API/ProductController/approveMvpnAgree.action', MvpnAgreePageVin).then((response) => {
      // 判斷回傳資料
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success
      ) {
        this.setState({ mvpnAgree: 'Y' });
      }
    });
  };

  render() {
    let page;
    switch (this.state.page) {
      // 內嵌頁面設定
      case 'iframe':
        page = (
          <div className='w-100 p-3'>
            <iframe
              key={this.state.forceUpdRandNum}
              style={{
                width: '100%',
                border: '0px',
                height: this.state.sysApiUrl === '1' ? this.state.iframeHigh : '500px',
              }}
              sandbox='allow-scripts allow-forms allow-same-origin allow-popups allow-downloads'
              scrolling='auto'
              src={this.state.iframeUrl}
            />
          </div>
        );
        break;

      default:
        <div></div>;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>產品資訊</title>
        </Helmet>
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <div className='left-sidebar col-3'>
                <div className='nav-title side-navigation-panel-select-option'>產品服務作業</div>
                <div
                  style={{
                    display: this.state.mvpnAgree === 'N' ? 'none' : 'initial',
                  }}>
                  <Navigation
                    onSelect={({ itemId }) => {
                      this.toPage(itemId);
                    }}
                    items={this.state.items.length > 0 ? this.state.items : null}
                  />
                </div>
              </div>
              {this.state.page === 'iframe' ? (
                <div className={this.state.isLoading ? 'is-loading' : 'bg-white col-9 shadow p-10'}>{page}</div>
              ) : this.state.mvpnAgree === 'N' ? (
                <div className='bg-white col-9 shadow p-10'>
                  <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />

                  <div className='title mt-4 text-center mb-3'>遠傳智慧行動企業網路</div>
                  <div className='title mt-0 mb-2 text-center'>服務契約書</div>

                  <div className='row center'>
                    <textarea
                      name='foc0005'
                      className='text-input col-12 no-border nohighlimitbg-img mb-2'
                      readOnly={true}
                      defaultValue={this.state.mvpnAgreeContent}
                      style={{
                        resize: 'none',
                        height: '300px ',
                      }}
                      onScroll={this.handleScroll}
                    />
                    <div className='mt-0 h6 text-italic title-blue font-weight-bold'>※契約書請滑至最底並勾選同意</div>
                  </div>

                  <div className='row'>
                    <input
                      type='checkbox'
                      name='accountRule'
                      className='ml-4 mr-2'
                      id='accountRule'
                      onClick={() => this.onChange('accountrule', '')}></input>
                    <label for='accountRule' className='checkbox-label is-text-bold'>
                      我已閱覽及同意以上內容
                    </label>
                  </div>
                  <div className='row end'>
                    <Button
                      className={`ml-4 mt-2 ${this.state.canSubmit && this.state.isScrollToBottom ? '' : 'disabled'}`}
                      btnStyle='primary'
                      size='large'
                      disabled={this.state.isDisableMvpnButton}
                      onClick={this.nextStep}>
                      關閉視窗
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ProdPage);
