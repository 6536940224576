import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import * as callApiUtil from '../utils/callApiUtil';
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountName: null,
      menuList: [],
    };
  }
  componentDidMount() {
    //從session取得menu清單
    this.getMenuList();
    this.props.callback();
    // this.interval = window.setInterval(this.getMenuList, 1000);
  }
  shouldComponentUpdate() {
    // Wait for move...
    this.getMenuList();
  }
  getMenuList = () => {
    let sysMenu = window.localStorage.getItem('menuList');
    let sysAccountName = window.localStorage.getItem('accountName');
    if (sysMenu != null) {
      let filist = JSON.parse(sysMenu);
      this.setState({
        menuList: filist.menuList,
        accountName: sysAccountName,
      });
      window.localStorage.setItem('menuInit', 'success');

      this.forceUpdate();
    } else {
      //header若未取到 Menu清單，則重新更新Header
      this.forceUpdate();
    }
  };
  logout = () => {
    callApiUtil.callApi('/ECPAP/API/LogoutController/logout.action', {});
    localStorage.clear();
    this.props.history.push('/loginPage');
  };

  handleSelect = (value) => {
    this.props.history.push(value);
  };
  onClick = () => {
    //路徑不是trusteeInfoPage頁面，才能點擊導頁
    if (this.props.location.pathname != '/trusteeInfoPage') {
      if (this.props.location.pathname == '/loginPage') {
        window.open('https://enterprise.fetnet.net/', '_blank').focus();
      } else {
        this.props.history.push('/');
      }
    }
  };
  openWindow = (uri) => {
    window.location.href = uri;

    //點擊到帳務資訊，打後端紀錄點擊次數
    if (uri === '/ECP/billInfoPage') {
      this.recordClick();
    }
  };
  //新增點擊紀錄，recordType為'B'，代表要儲存的類型為 Bill
  recordClick = () => {
    callApiUtil.callApi('/ECPAP/API/RecordClickController/recordClick.action', { recordType: 'B', categoryId: null });
  };

  render() {
    return (
      <React.Fragment>
        {'/forceChangePwd' == this.props.location.pathname ||
        '/howtoPage' == this.props.location.pathname ||
        // noheader結果頁
        '/basicResultNoHeaderPage' == this.props.location.pathname ||
        '/test' == this.props.location.pathname ||
        '/printApplyAccountPage' == this.props.location.pathname ||
        this.props.location.pathname.includes('/productService/') ? (
          ''
        ) : (
          <Navbar className='bg-white p-3 no-print' variant='light'>
            <Navbar.Brand onClick={this.onClick} className='middle pointer'>
              <img src={process.env.PUBLIC_URL + '/resources/common/images/fetnet-logo.png'} height='40'></img>
            </Navbar.Brand>
          </Navbar>
        )}
        {/* 這裡是不須顯示預設header的設定 */}
        {/* 登入頁 */}
        {'/loginPage' == this.props.location.pathname ||
        // 帳號申請
        '/applyAccountPage' == this.props.location.pathname ||
        // 忘記密碼
        '/passwordReset' == this.props.location.pathname ||
        // 常用服務
        '/commonServicePage' == this.props.location.pathname ||
        // 各地區服務主機
        '/areaServerPage' == this.props.location.pathname ||
        // 線上測速
        '/testSpeedPage' == this.props.location.pathname ||
        // 產品手冊
        '/productManualPage' == this.props.location.pathname ||
        // 企業用戶免登入查發票GO
        '/eInvoiceServicePage' == this.props.location.pathname ||
        // 共用-結果頁
        '/basicResultPage' == this.props.location.pathname ||
        // 帳號申請常見問題-註冊問題
        '/registerProblemPage' == this.props.location.pathname ||
        // 帳號申請常見問題-會員資料
        '/memberInfoProblemPage' == this.props.location.pathname ||
        // 帳號申請常見問題-帳號問題
        '/accountProblemPage' == this.props.location.pathname ||
        // 帳號申請常見問題-登入問題
        '/loginProblemPage' == this.props.location.pathname ||
        // 帳號申請常見問題-密碼問題
        '/passwordProblemPage' == this.props.location.pathname ||
        // 帳號申請-列印申請書
        '/printApplyAccountPage' == this.props.location.pathname ||
        // 產品服務作業-郵件代管功能說明頁
        '/howtoPage' == this.props.location.pathname ||
        // 異動第二代理人-列印申請書
        '/printSubCustModPage' == this.props.location.pathname ||
        // 停用第二代理人-列印申請書
        '/printSuspendPage' == this.props.location.pathname ||
        // 使用條款頁
        '/agreementPage' == this.props.location.pathname ||
        // 強制變更密碼
        '/forceChangePwd' == this.props.location.pathname ||
        // 帳號啟用頁
        '/custAcntActivatePage' == this.props.location.pathname ||
        // noheader結果頁
        '/basicResultNoHeaderPage' == this.props.location.pathname ||
        // 雲端產品常用服務
        '/cloudServicePage' == this.props.location.pathname ||
        '/test' == this.props.location.pathname ||
        this.props.location.pathname.includes('/productService/') ||
        //受任人資料頁
        '/trusteeInfoPage' == this.props.location.pathname ? (
          ''
        ) : (
          <Nav
            className='justify-content-center bg-white text-black p-2 no-print'
            variant='tabs'
            activeKey='1'
            onSelect={this.handleSelect}>
            <div className='container'>
              <div className='row mb-0'>
                {this.state.accountName != null ? (
                  <p className='text-align-left col-2 font-weight-bold hello pt-1'>
                    <span className='h5 text-black font-weight-bold'>{this.state.accountName}</span>
                    <span>，您好！</span>
                  </p>
                ) : null}
                <div className='row middle header'>
                  {this.state.menuList.length > 0
                    ? this.state.menuList.map((option, index) => {
                        return option.pageList.length > 1 && option.moduleId !== 'M02' ? (
                          <NavDropdown
                            key={option.moduleId}
                            title={option.menuName}
                            id={option.menuId}
                            className='tabs'>
                            {option.pageList.map((page, j) => {
                              // 外部網址，開啟小視窗
                              return !page.uri.match(/\/ECP\//) ? (
                                <NavDropdown.Item
                                  key={page.itemeId}
                                  onClick={() => window.open(page.uri, '_blank', 'height=800, width=800, resizable=0')}>
                                  {page.itemName}
                                </NavDropdown.Item>
                              ) : // 常用服務，開啟分頁
                              option.menuId == 'M06' ? (
                                <NavDropdown.Item key={page.itemeId} onClick={() => window.open(page.uri, '_blank')}>
                                  {page.itemName}
                                </NavDropdown.Item>
                              ) : (
                                <NavDropdown.Item key={page.itemeId} onClick={() => (window.location.href = page.uri)}>
                                  {page.itemName}
                                </NavDropdown.Item>
                              );
                            })}
                          </NavDropdown>
                        ) : (
                          <Nav.Link
                            key={'header' + index}
                            id='nav-dropdown'
                            className='tabs'
                            onClick={() => this.openWindow(option.uri)}>
                            {option.menuName}
                          </Nav.Link>
                        );
                      })
                    : null}
                </div>
                <button
                  className='logout mt-1 btn btn-secondary col-1 font-weight-bold font-normal'
                  onClick={() => this.logout()}>
                  登出系統
                </button>
              </div>
            </div>
          </Nav>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Header);
