import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Banner from '../../../components/partials/banner/Banner';
import CommonServiceSidebar from './CommonServiceSidebar';
import * as callApiUtil from '../../../utils/callApiUtil';
class ProductManual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allTitleList: [],
    };
  }
  componentDidMount = () => {
    callApiUtil
      .callApi(
        '/ECPAP/API/DocumentController/docDownloadInit.action',
        {
          displayPath: window.location.pathname,
        },
        null,
        true //loading遮罩
      )
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          this.setState({
            allTitleList: response.docInfoBeanList,
          });
        }
      });
  };
  download(document) {
    if (document.uri != null) {
      window.open(document.uri, document.uri, 'height=auto,width=auto'); // 呼叫外部URI下載檔案
    } else {
      callApiUtil.postFile(
        '/ECPAP/API/DocumentController/downloadDoc.action',
        {
          documentId: document.documentId,
        },
        null, //成功後執行
        null, //錯誤處理
        true //loading遮罩
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>產品使用手冊</title>
        </Helmet>
        <Banner title='企業用戶專區' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <CommonServiceSidebar
                className='col-3'
                title='常用服務'
                history={this.props.history}></CommonServiceSidebar>
              <div className='bg-white col-9 shadow p-2 pb-5'>
                <div className='title mt-5 ml-4'>產品使用手冊</div>
                {this.state.allTitleList == null || this.state.allTitleList.length == 0 ? (
                  <div className='my-5 ml-5 pl-2'>
                    <span className='ml-3 sub-title'>查無產品使用手冊</span>
                  </div>
                ) : (
                  this.state.allTitleList.map((title) => (
                    <React.Fragment key={'title' + title.name}>
                      <div className='sub-title ml-5 pl-3'>{title.name}</div>
                      <ul className='ml-5 pt-4 h4 font-weight-bold'>
                        {title.documentList.map((document) => (
                          <React.Fragment>
                            <li className='ml-2 p-2 h5' key={'document' + document.displayName}>
                              <a className='pointer' href='#' onClick={() => this.download(document)}>
                                {document.displayName}
                              </a>
                            </li>
                          </React.Fragment>
                        ))}
                      </ul>
                    </React.Fragment>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ProductManual);
