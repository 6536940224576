import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Helmet } from 'react-helmet';
import * as callApiUtil from '../../utils/callApiUtil';
import * as validation from '../../utils/validation';
import * as emptyUtil from '../../utils/emptyUtil';
import Dropdown from '../../components/Dropdown';
import TransactionApplication from '../resultController/TransactionApplication';
import { Grid, Dialog, DialogActions, DialogContent } from '@material-ui/core';

class FirstAgentUpdatePage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      // 顯示頁面
      isLoading: false,
      step: 1,
      actionType: false, //依登入者統編下有無第一代理人，決定此頁可進行新增/修改動作
      loginAccntType: '', //登入者帳號類別 受任人(5)or 第一代理人(1)
      //使用者輸入的新資料
      changeForm: {
        accntId: '', //新使用者身分證字號
        accntName: '', //新(第一代理人)使用者姓名
        accntTelNo: '', //新使用者電話
        accntFaxNo: '', //新傳真號碼
        accntMsisdn: '', //新手機電話
        accntEmail: '', //新使用者電子郵件
        loginId: '', //新使用者帳號
        gender: '', //稱謂F or M
      },
      //欄位驗證訊息
      errMsg: {
        accntId: '',
        accntName: '',
        accntTelNo: '',
        accntFaxNo: '',
        mainMsisdn: '',
        accntMsisdn: '',
        loginId: '',
        gender: '',
        all: '',
      },
      //DB中原始的資料
      form: {
        cmpName: { value: '', text: '' }, //公司名稱
        userId: { value: '', text: '' }, //統一編號
        cmpTelNo: { value: '', text: '' }, //公司電話

        accntId: { value: '', text: '' }, //使用者身分證字號
        accntName: { value: '', text: '' }, //(第一代理人)使用者姓名
        accntTelNo: { value: '', text: '' }, //使用者電話
        accntFaxNo: { value: '', text: '' }, //傳真號碼
        accntMsisdn: { value: '', text: '' }, //手機電話
        accntEmail: { value: '', text: '' }, //使用者電子郵件
        loginId: { value: '', text: '' }, //使用者帳號
        gender: { value: '', text: '' }, //稱謂F or M
      },
      canSubmit: false,
      isPopOut: false, //是否彈出確認框
      submitRemind: false, //送出申請前的提醒
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/MaintainFirstAgentController/init.action', {}).then((response) => {
      this.setState({ isLoading: false });
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState(() => ({
          actionType: response.newActionType,
          loginAccntType: response.loginAccntType, //登入者帳號類別
          form: {
            cmpName: { value: emptyUtil.checkVal(response.cmpName), text: '' }, //公司名稱
            userId: { value: emptyUtil.checkVal(response.userId), text: '' }, //統一編號
            cmpTelNo: { value: emptyUtil.checkVal(response.cmpTelNo), text: '' }, //公司電話

            accntFaxNo: { value: emptyUtil.checkVal(response.accntFaxNo), text: '' }, //傳真號碼
            accntName: { value: emptyUtil.checkVal(response.accntName), text: '' }, //(第一代理人)使用者姓名
            accntId: { value: emptyUtil.checkVal(response.accntId), text: '' }, //使用者身分證字號
            accntMsisdn: { value: emptyUtil.checkVal(response.accntMsisdn), text: '' }, //手機電話
            accntTelNo: { value: emptyUtil.checkVal(response.accntTelNo), text: '' }, //使用者電話
            accntEmail: { value: emptyUtil.checkVal(response.accntEmail), text: '' }, //使用者電子郵件
            loginId: { value: emptyUtil.checkVal(response.loginId), text: '' }, //使用者帳號
            gender: { value: response.gender == null ? '' : response.gender, text: '' }, //稱謂F or M
          },
        }));
        this.onChange('gender', this.state.form.gender.value);
      } else {
        //API回傳失敗
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: '第一代理人(管理者)變更及基本資料異動申請',
            title: '第一代理人(管理者)變更及基本資料異動申請',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回第一代理人(管理者)變更及基本資料異動申請',
                link: '/maintainFirstAgentPage',
                btnStyle: 'primary',
              },
              {
                text: '返回企業用戶專區',
                link: '/',
                btnStyle: 'secondary',
              },
            ],
          }
        );
      }
    });
  }

  onChange = (name, value) => {
    let newChangeForm = Object.assign(this.state.changeForm);
    newChangeForm[name] = value.trim();
    this.setState({ changeForm: newChangeForm }, () => this.check());
  };

  // 修改資料頁-重設按鈕
  resetData = () => {
    let defaultChangeForm = {
      accntId: '', //新使用者身分證字號
      accntName: '', //新(第一代理人)使用者姓名
      accntTelNo: '', //新使用者電話
      accntFaxNo: '', //新傳真號碼
      accntMsisdn: '', //新手機電話
      accntEmail: '', //新使用者電子郵件
      loginId: '', //新使用者帳號
      gender: this.state.form.gender.value, //稱謂F or M
    };
    this.setState({ changeForm: defaultChangeForm }, () => this.check());
  };

  // 重設帳戶資料
  resetFirstAgent = () => {
    this.setState({ step: 2 });
  };
  //表格欄位驗證
  validate = (name, errMsg) => {
    let newErrMsg = Object.assign(this.state.errMsg);
    newErrMsg[name] = errMsg;
    this.setState({ errMsg: newErrMsg });
  };
  maxLength(value, max) {
    return value.length <= max;
  }

  check = () => {
    // 驗證欄位是否非空(修改時至少須有一個欄位更改)
    let notEmpty =
      validation.notEmpty(this.state.changeForm.accntId) ||
      validation.notEmpty(this.state.changeForm.accntName) ||
      validation.notEmpty(this.state.changeForm.accntTelNo) ||
      validation.notEmpty(this.state.changeForm.accntFaxNo) ||
      validation.notEmpty(this.state.changeForm.accntMsisdn) ||
      validation.notEmpty(this.state.changeForm.accntEmail) ||
      validation.notEmpty(this.state.changeForm.loginId) ||
      this.state.changeForm.gender != this.state.form.gender.value;
    let isValid = true;
    if (validation.notEmpty(this.state.changeForm.accntId) && !validation.twId(this.state.changeForm.accntId)) {
      this.validate('accntId', '請輸入正確的身分證字號');
      isValid = false;
    } else {
      this.validate('accntId', '');
    }
    if (!this.maxLength(this.state.changeForm.accntName, 20)) {
      this.validate('accntName', '請輸入正確的使用者姓名');
      isValid = false;
    } else {
      this.validate('accntName', '');
    }
    if (validation.notEmpty(this.state.changeForm.accntTelNo) && !validation.phone(this.state.changeForm.accntTelNo)) {
      this.validate('accntTelNo', '請輸入正確的連絡電話');
      isValid = false;
    } else {
      this.validate('accntTelNo', '');
    }
    if (validation.notEmpty(this.state.changeForm.accntFaxNo) && !validation.phone(this.state.changeForm.accntFaxNo)) {
      this.validate('accntFaxNo', '請輸入正確的連絡傳真');
      isValid = false;
    } else {
      this.validate('accntFaxNo', '');
    }
    if (
      validation.notEmpty(this.state.changeForm.accntMsisdn) &&
      !validation.mobile(this.state.changeForm.accntMsisdn)
    ) {
      this.validate('accntMsisdn', '請輸入正確的連絡行動電話');
      isValid = false;
    } else {
      this.validate('accntMsisdn', '');
    }
    if (validation.notEmpty(this.state.changeForm.accntEmail) && !validation.email(this.state.changeForm.accntEmail)) {
      this.validate('accntEmail', '請輸入正確的連絡Email');
      isValid = false;
    } else {
      this.validate('accntEmail', '');
    }
    if (validation.notEmpty(this.state.changeForm.loginId) && !this.maxLength(this.state.changeForm.loginId, 12)) {
      this.validate('loginId', '請輸入正確的使用者帳號');
      isValid = false;
    } else {
      this.validate('loginId', '');
    }
    this.validate('all', '');
    this.setState({ canSubmit: isValid && notEmpty });

    return isValid;
  };

  // 受任人改第一代理人資料，不須申請，直接審核通過
  submitFirstAgent = () => {
    if (this.check()) {
      let Vin = {
        accntId: this.state.changeForm.accntId, //新使用者身分證字號
        accntName: this.state.changeForm.accntName, //新(第一代理人)使用者姓名
        accntTelNo: this.state.changeForm.accntTelNo, //新使用者電話
        accntFaxNo: this.state.changeForm.accntFaxNo, //新傳真號碼
        accntMsisdn: this.state.changeForm.accntMsisdn, //新手機電話
        accntEmail: this.state.changeForm.accntEmail, //新使用者電子郵件
        loginId: this.state.changeForm.loginId, //新使用者帳號
        gender: this.state.changeForm.gender, //稱謂F or M
      };
      this.setState({ isLoading: true });
      this.setState({ canSubmit: false });
      callApiUtil.callApi('/ECPAP/API/MaintainFirstAgentController/submit.action', Vin).then((response) => {
        this.setState({ isLoading: false });
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined
        ) {
          //API回傳成功
          if (response.resultMessage.message == 's') {
            this.props.history.push(
              {
                pathname: '/basicResultPage',
              },
              {
                type: 'success',
                pageName: '第一代理人(管理者)變更及基本資料異動申請',
                title: '更新完成',
                description: '您異動的資料已更新成功！',
                actions: [
                  {
                    text: '返回第一代理人(管理者)變更及基本資料異動申請',
                    link: '/maintainFirstAgentPage',
                    btnStyle: 'primary',
                  },
                  {
                    text: '返回企業用戶專區',
                    link: '/',
                    btnStyle: 'secondary',
                    fetkey: '',
                    dataTrackingId: '',
                  },
                ],
              }
            );
          } else if (response.resultMessage.code == 'MA_005_004') {
            this.validate('all', response.resultMessage.message);
          } else if (response.resultMessage.code == 'MA_005_092') {
            this.validate('loginId', response.resultMessage.message);
          } else {
            this.toErrPage(response);
          }
        } else {
          this.toErrPage();
        }
      });
    }
  };
  // 第一代理人改第一代理人資料，須走申請流程
  submitApplyFirstAgent = () => {
    if (this.check()) {
      let Vin = {
        accntId: this.state.changeForm.accntId, //新使用者身分證字號
        accntName: this.state.changeForm.accntName, //新(第一代理人)使用者姓名
        accntTelNo: this.state.changeForm.accntTelNo, //新使用者電話
        accntFaxNo: this.state.changeForm.accntFaxNo, //新傳真號碼
        accntMsisdn: this.state.changeForm.accntMsisdn, //新手機電話
        accntEmail: this.state.changeForm.accntEmail, //新使用者電子郵件
        loginId: this.state.changeForm.loginId, //新使用者帳號
        gender: this.state.changeForm.gender, //稱謂F or M
      };
      this.setState({ isLoading: true });
      this.setState({ canSubmit: false });
      callApiUtil.callApi('/ECPAP/API/MaintainFirstAgentController/applySubmit.action', Vin).then((response) => {
        this.setState(() => ({ isLoading: false }));
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined
        ) {
          if (response.resultMessage.message == 's') {
            //API回傳成功
            this.setState(() => ({ step: 3 })); //送出成功，前往下一頁
          } else if (response.resultMessage.code == 'MA_005_059') {
            this.validate('all', response.resultMessage.message);
          } else if (response.resultMessage.code == 'MA_005_089') {
            this.validate('loginId', response.resultMessage.message);
          } else {
            //API回傳失敗
            this.toErrPage(response);
          }
        } else {
          this.toErrPage();
        }
      });
    }
  };
  toErrPage = (response) => {
    this.props.history.push(
      {
        pathname: '/basicResultPage',
      },
      {
        type: 'fail',
        pageName: '第一代理人(管理者)變更及基本資料異動申請',
        title: '第一代理人(管理者)變更及基本資料異動申請失敗',
        description: response != null && response.resultMessage != null ? response.resultMessage.message : null,
        actions: [
          {
            text: '返回第一代理人(管理者)變更及基本資料異動申請',
            link: '/maintainFirstAgentPage',
            btnStyle: 'primary',
          },
          {
            text: '返回企業用戶專區',
            link: '/',
            btnStyle: 'primary',
          },
        ],
      }
    );
  };
  // 送出列印請求
  print = () => {
    this.setState({ isPopOut: false });
    window.open(
      `/ECP/printMaintFirstAgentPage`,
      '_blank',
      'height=800, width=800, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=0,location=no, status=no'
    );
  };

  //submit前彈出提醒視窗
  openRemind = () => {
    this.setState({ submitRemind: true });
  };

  popOut = () => {
    this.setState({ isPopOut: true });
  };

  // 寄驗證信
  send = () => {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/MaintainFirstAgentController/mail.action').then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState({ isLoading: false });
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'success',
            pageName: '第一代理人(管理者)變更及基本資料異動申請',
            title: '提醒訊息',
            description: '您尚需將列印的申請書郵寄至遠傳！',
            actions: [
              {
                text: '返回第一代理人(管理者)變更及基本資料異動申請',
                link: '/maintainFirstAgentPage',
                btnStyle: 'primary',
              },
              {
                text: '返回企業用戶專區',
                link: '/',
                btnStyle: 'secondary',
                fetkey: '',
                dataTrackingId: '',
              },
            ],
          }
        );
      } else {
        //API回傳失敗
        this.setState({ isLoading: false });
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: '第一代理人(管理者)變更及基本資料異動申請',
            title: '第一代理人(管理者)變更及基本資料異動申請失敗',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回第一代理人(管理者)變更及基本資料異動申請頁',
                link: '/maintainFirstAgentPage',
                btnStyle: 'primary',
                fetkey: '',
                dataTrackingId: '',
              },
              {
                text: '返回企業用戶專區',
                link: '/',
                btnStyle: 'primary',
                fetkey: '',
                dataTrackingId: '',
              },
            ],
          }
        );
      }
    });
  };

  render() {
    const items = [
      {
        title: '基本資料維護',
        itemId: '/maintainAccountInfoPage',
      },
      {
        title: '帳號密碼維護',
        itemId: '/maintainPasswordPage',
      },
      {
        title: '第二代理人(子帳號)權限管理',
        itemId: '/maintainSubCustAccountInfoPage',
      },
      {
        title: '公司基本資料異動申請',
        itemId: '/maintainCorpInfoPage',
      },
      {
        title: '第一代理人(管理者)變更及基本資料異動申請',
        itemId: '/maintainFirstAgentPage',
      },
    ];

    return (
      <React.Fragment>
        <Helmet>
          <title>第一代理人(管理者)變更及基本資料異動申請</title>
        </Helmet>
        <div className='bg-img'>
          <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' items={items} title='帳號管理' history={this.props.history}></Sidebar>
              <div className='container bg-white col-9 shadow pl-4 pr-4'>
                <div className='title pt-2 mt-5 ml-3'>第一代理人(管理者)變更及基本資料異動申請</div>
                {this.state.actionType ? (
                  <div className='sub-title is-text-bold ml-3 mt-5'>
                    公司尚無第一代理人帳戶資料，請先進行第一代理人帳戶申請。
                  </div>
                ) : (
                  <Fragment>
                    {/* 顯示原始資料 */}
                    {this.state.step == 1 ? (
                      <div className='w-100'>
                        <div className='p-3'>
                          <div className='sub-title mb-4 is-text-bold'>目前登記的資料</div>
                          <table className='w-100 mt-5'>
                            <div className='font-weight-bold h5'>公司基本資料</div>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>公司/機關名稱/用戶名稱</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.cmpName.value}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>統一編號/其他</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.userId.value}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>公司/室內電話</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.cmpTelNo.value}</td>
                            </tr>
                          </table>

                          <table className='w-100 mt-5'>
                            <div className='font-weight-bold h5'>第一代理人(管理者)基本資料</div>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>使用者姓名</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.accntName.value}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>身分證字號</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.accntId.value}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>行動電話</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.accntMsisdn.value}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>聯絡電話</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.accntTelNo.value}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>傳真</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.accntFaxNo.value}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>Email</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.accntEmail.value}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 text-center border-gray'>使用者帳號</td>
                              <td className='w-60 p-3 border-gray'>{this.state.form.loginId.value}</td>
                            </tr>
                          </table>
                        </div>
                        <div className='button-group mb-5 ml-2 p-2'>
                          <div className='mt-4'>
                            <Button btnStyle='primary' size='large' onClick={() => this.resetFirstAgent()}>
                              確認變更
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* 修改表單 */}
                    {this.state.step == 2 ? (
                      <div>
                        <Formsy ref={this.form}>
                          <div className='button-group p-3'>
                            <table className='w-100'>
                              <tr className='bg-white'>
                                <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                  使用者姓名
                                </td>
                                <td className='w-60 p-3 border-gray'>{this.state.form.accntName.value}</td>
                              </tr>
                              <tr className='bg-white'>
                                <td className='w-40 border-gray'>
                                  <div className='mt-3 ml-3'>更改之使用者姓名</div>
                                  <LabelInput
                                    className='ml-3 mt-3'
                                    name='accntName'
                                    value={this.state.changeForm.accntName}
                                    required={false}
                                    placeholder='請輸入更改的使用者姓名'
                                    label=''
                                    maxLength='20'
                                    onChange={this.onChange}
                                    failResultMsg={this.state.errMsg.accntName}
                                  />
                                </td>
                              </tr>
                              <tr className='bg-white'>
                                <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                  稱謂
                                </td>
                              </tr>
                              <tr className='bg-white'>
                                <td className='w-40 border-gray'>
                                  <div className='ml-2 mt-2 p-1'>
                                    <input
                                      type='radio'
                                      value='M'
                                      id='M'
                                      name='gender'
                                      className='radio-button'
                                      checked={this.state.changeForm.gender == 'M'}
                                      onChange={(e) => this.onChange('gender', e.target.value)}
                                      failResultMsg={this.state.errMsg.companyName}
                                    />
                                    <label htmlFor='M'>先生</label>

                                    <input
                                      type='radio'
                                      value='F'
                                      id='F'
                                      name='gender'
                                      className='radio-button ml-3'
                                      checked={this.state.changeForm.gender == 'F'}
                                      onChange={(e) => this.onChange('gender', e.target.value)}
                                      failResultMsg={this.state.errMsg.companyName}
                                    />
                                    <label htmlFor='F'>女士</label>
                                  </div>
                                </td>
                              </tr>
                              <tr className='bg-white'>
                                <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                  身分證字號
                                </td>
                                <td className='w-60 p-3 border-gray'>{this.state.form.accntId.value}</td>
                              </tr>
                              <tr className='bg-white'>
                                <td className='w-40 border-gray'>
                                  <div className='mt-3 ml-3'>更改之身分證字號</div>
                                  <LabelInput
                                    className='ml-3 mt-3'
                                    name='accntId'
                                    value={this.state.changeForm.accntId}
                                    required={false}
                                    placeholder='請輸入更改的身分證字號'
                                    label=''
                                    maxLength='30'
                                    onChange={this.onChange}
                                    failResultMsg={this.state.errMsg.accntId}
                                  />
                                </td>
                              </tr>
                              <tr className='bg-white'>
                                <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                  辦公室電話
                                </td>
                                <td className='w-60 p-3 border-gray'>{this.state.form.accntTelNo.value}</td>
                              </tr>
                              <tr className='bg-white'>
                                <td className='w-40 border-gray'>
                                  <div className='mt-3 ml-3'>更改之辦公室電話</div>
                                  <LabelInput
                                    className='ml-3 mt-3'
                                    name='accntTelNo'
                                    value={this.state.changeForm.accntTelNo}
                                    required={false}
                                    placeholder='請輸入更改的辦公室電話'
                                    label=''
                                    maxLength='30'
                                    onChange={this.onChange}
                                    failResultMsg={this.state.errMsg.accntTelNo}
                                  />
                                </td>
                              </tr>
                              <tr className='bg-white'>
                                <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                  行動電話
                                </td>
                                <td className='w-60 p-3 border-gray'>{this.state.form.accntMsisdn.value}</td>
                              </tr>
                              <tr className='bg-white'>
                                <td className='w-40 border-gray'>
                                  <div className='mt-3 ml-3'>更改之行動電話</div>
                                  <LabelInput
                                    className='ml-3 mt-3'
                                    name='accntMsisdn'
                                    value={this.state.changeForm.accntMsisdn}
                                    required={false}
                                    placeholder='請輸入更改的行動電話'
                                    label=''
                                    maxLength='10'
                                    onChange={this.onChange}
                                    failResultMsg={this.state.errMsg.accntMsisdn}
                                  />
                                </td>
                              </tr>
                              <tr className='bg-white'>
                                <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                  辦公室傳真
                                </td>
                                <td className='w-60 p-3 border-gray'>{this.state.form.accntFaxNo.value}</td>
                              </tr>
                              <tr className='bg-white'>
                                <td className='w-40 border-gray'>
                                  <div className='mt-3 ml-3'>更改之辦公室傳真</div>
                                  <LabelInput
                                    className='ml-3 mt-3'
                                    name='accntFaxNo'
                                    value={this.state.changeForm.accntFaxNo}
                                    required={false}
                                    placeholder='請輸入更改的辦公室傳真'
                                    label=''
                                    maxLength='30'
                                    onChange={this.onChange}
                                    failResultMsg={this.state.errMsg.accntFaxNo}
                                  />
                                </td>
                              </tr>
                              <tr className='bg-white'>
                                <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                  Email
                                </td>
                                <td className='w-60 p-3 border-gray'>{this.state.form.accntEmail.value}</td>
                              </tr>
                              <tr className='bg-white'>
                                <td className='w-40 border-gray'>
                                  <div className='mt-3 ml-3'>更改之Email</div>
                                  <LabelInput
                                    className='ml-3 mt-3'
                                    name='accntEmail'
                                    value={this.state.changeForm.accntEmail}
                                    required={false}
                                    placeholder='請輸入更改的Email'
                                    label=''
                                    maxLength='50'
                                    onChange={this.onChange}
                                    failResultMsg={this.state.errMsg.accntEmail}
                                  />
                                </td>
                              </tr>
                              <tr className='bg-white'>
                                <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                  使用者帳號
                                </td>
                                <td className='w-60 p-3 border-gray'>{this.state.form.loginId.value}</td>
                              </tr>
                              <tr className='bg-white'>
                                <td className='w-40 border-gray'>
                                  <div className='mt-3 ml-3'>更改之使用者帳號</div>
                                  <LabelInput
                                    className='ml-3 mt-3'
                                    name='loginId'
                                    value={this.state.changeForm.loginId}
                                    required={false}
                                    placeholder='請輸入更改的使用者帳號'
                                    label=''
                                    maxLength='16'
                                    onChange={this.onChange}
                                    failResultMsg={this.state.errMsg.loginId}
                                  />
                                </td>
                              </tr>
                            </table>
                          </div>
                        </Formsy>
                        <div className='mt-5 two-buttons mb-5 pl-3'>
                          <span className='error-message'>{this.state.errMsg.all}</span>
                          <br />
                          <Button
                            btnStyle='primary'
                            size='large'
                            disabled={!this.state.canSubmit}
                            onClick={
                              this.state.loginAccntType == 5 ? this.submitFirstAgent : this.submitApplyFirstAgent
                            }>
                            送出申請
                          </Button>
                          <Button btnStyle='secondary' size='large' onClick={this.resetData}>
                            重填
                          </Button>
                        </div>
                      </div>
                    ) : null}
                    {this.state.step == 3 ? (
                      <div className='mt-5'>
                        <TransactionApplication
                          print={this.popOut}
                          remind={this.openRemind}
                          applyTrustee={this.state.loginAccntType == 5 ? true : false}></TransactionApplication>
                      </div>
                    ) : null}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* 彈出視窗 */}
        <Dialog open={this.state.isPopOut} onClose={() => this.setState({ isPopOut: false })} fullWidth maxWidth={'xs'}>
          <div className='bg-header'>
            <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
          </div>
          <DialogContent dividers={true}>
            <div className='h5 ml-3 my-4 is-text-bold'>
              請確認申請內容是否正確無誤，
              <br />
              若是請接續列印作業後按確認送出。
            </div>
          </DialogContent>
          <DialogActions>
            <Button btnStyle='primary' className='w5 my-2' onClick={() => this.print()} autoFocus>
              我知道了
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.submitRemind}
          onClose={() => this.setState({ submitRemind: false })}
          fullWidth
          maxWidth={'xs'}>
          <div className='bg-header'>
            <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
          </div>
          <DialogContent dividers={true}>
            <div className='h5 ml-3 my-4 is-text-bold'>
              提醒您，申請書下載用印後須於兩周內寄回，以利於後續帳號開通作業。
            </div>
          </DialogContent>
          <DialogActions>
            <Button btnStyle='primary' className='w5 my-2' onClick={() => this.send()} autoFocus>
              我知道了
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withRouter(FirstAgentUpdatePage);
