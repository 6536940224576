export const getFunctionName = (functionId) => {
  let showName = '';
  switch (functionId) {
    case 'F0001':
      showName = '簡易帳務報表/帳單瀏覽';
      break;
    case 'F0002':
      showName = '一般通話明細';
      break;
    case 'F0003':
      showName = 'MVPN線上服務(內容包含新增、異動、退出及申請狀況查詢)';
      break;
    case 'F0004':
      showName = 'MVPN服務異動申請';
      break;
    case 'F0005':
      showName = 'MVPN服務退出申請';
      break;
    case 'F0006':
      showName = 'MVPN申請狀況查詢';
      break;
    case 'F0007':
      showName = '公私分帳報表';
      break;
    case 'F0008':
      showName = '公私分帳明細';
      break;
    case 'F0009':
      showName = '其他';
      break;
  }
  return showName;
};
