import React from 'react';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as validation from '../../utils/validation';
import * as callApiUtil from '../../utils/callApiUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Dropdown from '../../components/Dropdown';

class ClfyTechContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 顯示頁面
      step: 1, //2:異動 , 3:新增
      hasSelect: false, //有選擇資料才能click異動button
      isPopOut: false, //確認是否刪除資料的提示視窗
      isResultPopOut: false, //刪除後視窗
      //修改欄位是否全為空
      empty: false,
      //驗證資料, 控制送出按鈕開關
      isValidate: false,
      nameErrMsg: '',
      telNoErrMsg: '',
      cellErrMsg: '',
      faxNoErrMsg: '',
      emailErrMsg: '',
      salutationErrMsg: '',
      idErrMsg: '',
      idPassErrMsg: '',
      oriTelLength: null, //刪除或新增前的list
      newForm: {
        siteId: null, // 用戶編號 ods_subscr.acctid
        name: null, // 聯絡人姓名
        salutation: null, // 稱謂
        idPass: '', // 證件別
        id: null, //證件號
        email: null, //新信箱
      },

      isLoading: false,
      contactInfoList: [],
      // 先放假資料
      contactInfo: {
        name: null,
        salutation: null,
        idPass: null,
        id: null,
        email: null,
        objid: null,
      },
      // 先放假資料
      telInfoList: [],
      newTelList: [{ rank: 0, type: '', text: '請選擇', phone: '' }],
      isTrustee: false,
      userNo: null,
      seqNo: null,
      returnCode: null,
      description: null,
      successPrefix: null, //成功小視窗 前綴字
      idTypeOpt: [
        { value: '身分證字號', text: '身分證字號' },
        { value: '護照號碼', text: '護照號碼' },
      ],
      telTypeOpt: [
        { value: 'office', text: '市內電話(範例:xx-xxxxxxxx)' },
        { value: 'mobile', text: '行動電話' },
        { value: 'fax', text: '傳真電話(範例:xx-xxxxxxxx)' },
        { value: 'emergency', text: '緊急電話' },
      ],
      subscrAccntId: null,
      result: null,
      oriTelList: [], //原始電話(用來比較更新時，刪除新增是否有改變)
      isTelChange: false, //電話是否有改變過(用來比較更新時，是否有改變)
    };
  }
  componentDidMount() {
    // 指定該頁面背景顏色為白色
    document.body.style.backgroundColor = 'white';
    // 將頁面高度傳至iframe鑲嵌頁面
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
    //取得ods_subscrAccntId
    let subscrAccntId = new URLSearchParams(this.props.location.search).get('acctId');
    this.setState({ subscrAccntId: subscrAccntId });
    this.queryClfyTechContact(subscrAccntId);
  }
  onChange = (name, value) => {
    let newForm = Object.assign(this.state.newForm);
    newForm[name] = value.trim();
    this.setState({ newForm: newForm });
    this.enableButton();
  };

  // 修改電話類型
  onChangeTelType = (name, select, index, telInfo) => {
    let newTelList = Object.assign(this.state[name]);
    let newTelIndex = 0;
    // 比對要修改的是哪一筆電話
    for (newTelIndex in newTelList) {
      if (newTelIndex == index) {
        newTelList[newTelIndex].text = select.text;
        newTelList[newTelIndex].type = select.value;
      }
    }
    this.setState({ name: newTelList });
    this.enableButton(index, telInfo);
  };

  // 修改電話號碼
  onChangeTel = (index, telInfo) => {
    let newTelList = Object.assign(this.state.newTelList);
    let newTelIndex = 0;
    // 判斷要修改的是哪一筆電話號碼
    for (newTelIndex in newTelList) {
      if (newTelIndex == index) {
        newTelList[newTelIndex].phone = telInfo;
      }
    }
    this.setState({ name: newTelList });
    this.enableButton(index, telInfo);
  };

  resetNewForm = (type) => {
    // 清空頁面資料
    let newForm = {
      id: null,
      name: null,
      telNo: null,
      cell: null,
      faxNo: null,
      email: null,
      sendMsgFlag: null,
    };

    // 判斷若是修改頁面，將原本電話資料恢復，若是新增頁面就直接清空資料還原預設
    let newTelList = [];
    if (type == 'edit') {
      newTelList = Object.assign(this.state.telInfoList);
    } else {
      newTelList = [{ rank: 0, type: '', text: '請選擇', phone: '' }];
    }

    this.setState({
      newForm: newForm,
      newTelList: newTelList,
      isValidate: false,
      nameErrMsg: '',
      telNoErrMsg: '',
      cellErrMsg: '',
      emailErrMsg: '',
      faxNoErrMsg: '',
      salutationErrMsg: '',
      idErrMsg: '',
      idPassErrMsg: '',
    });
    //更新重置
  };

  //驗證資料, 控制送出按鈕開關
  enableButton = (index, telInfo) => {
    let isValid = true;
    let nameErrMsg = '';
    let telNoErrMsg = '';
    let cellErrMsg = '';
    let faxNoErrMsg = '';
    let emailErrMsg = '';
    let salutationErrMsg = '';
    let idErrMsg = '';
    let idPassErrMsg = '';
    let id = '';
    let newForm = Object.assign(this.state.newForm);
    // 沒改變過前為false , 改變後維持true，代表資料有更新過
    let isTelChange = this.state.isTelChange ? this.state.isTelChange : false;
    //新增驗證
    if (this.state.step == 3) {
      if (validation.isEmpty(this.state.newForm.name)) {
        nameErrMsg = '請輸入姓名';
        isValid = false;
      }
      if (validation.isEmpty(this.state.newForm.salutation)) {
        salutationErrMsg = '請選擇稱謂';
        isValid = false;
      }
      if (validation.isEmpty(this.state.newForm.idPass)) {
        idPassErrMsg = '請選擇證件別';
        isValid = false;
      }
      //為身分證時，證號不能為空
      if (this.state.newForm.idPass == '身分證字號' && validation.isEmpty(this.state.newForm.id)) {
        idErrMsg = '請輸入身分證字號';
        isValid = false;
      }
    }

    // 修改頁面
    if (this.state.step == 2) {
      if (
        validation.isEmpty(this.state.newForm.name) &&
        validation.isEmpty(this.state.newForm.salutation) &&
        validation.isEmpty(this.state.newForm.idPass) &&
        validation.isEmpty(this.state.newForm.id) &&
        validation.isEmpty(this.state.newForm.email)
      ) {
        isValid = false;
      }
      //當刪除或新增電話時，給予可送出
      if (this.state.oriTelList.length != this.state.newTelList.length) {
        isValid = true;
      } else if (this.state.isTelChange) {
        isValid = true;
      }

      //為身分證時，證號不能為空
      if (this.state.newForm.idPass == '身分證字號' && validation.isEmpty(this.state.newForm.id)) {
        idErrMsg = '請輸入身分證字號';
        isValid = false;
      }
      if (newForm.idPass == '護照號碼') {
        idErrMsg = '若無輸入護照號碼，則將會清空原有證號';
      }
    }
    // 判斷每個電話欄位是否符合電話格式，並將錯誤訊息顯示於該欄位
    let newTelIndex = 0;
    let newTelList = this.state.newTelList;
    if (newTelList.length > 0) {
      for (newTelIndex in newTelList) {
        if (newTelIndex == index) {
          isTelChange = true; //給予電話改變過的key
          // 有更改過的欄位，則要重新驗證，並標記有更改過，讓後端存入新值，沒有更改過則使用舊有的值
          newTelList[newTelIndex].failResultMsg = '';
          newTelList[newTelIndex].modified = true;
          if (validation.notEmpty(telInfo) && !validation.phone(telInfo)) {
            newTelList[newTelIndex].failResultMsg = '請輸入正確的電話號碼';
            isValid = false;
          }
          //單獨顯示錯誤訊息
          else if (validation.isEmpty(newTelList[newTelIndex].phone)) {
            isValid = false;
            newTelList[newTelIndex].failResultMsg = '請輸入電話號碼';
            break;
          } else if (newTelList[newTelIndex].text == '請選擇') {
            isValid = false;
            break;
          }
        }
      }
    }
    //電話有修改過，再進行電話與其他欄位是否正確 做&&判斷
    if (isTelChange) {
      isValid = isValid && isTelChange;
    }
    this.setState(() => ({
      nameErrMsg: nameErrMsg,
      telNoErrMsg: telNoErrMsg,
      cellErrMsg: cellErrMsg,
      faxNoErrMsg: faxNoErrMsg,
      emailErrMsg: emailErrMsg,
      salutationErrMsg: salutationErrMsg,
      idErrMsg: idErrMsg,
      idPassErrMsg: idPassErrMsg,
      isValidate: isValid,
      isTelChange: isTelChange,
      newForm: newForm,
    }));
  };

  /** 新增電話資料*/
  add = () => {
    let list = Object.assign(this.state.newTelList);

    // 取出最後一筆的rank排序到第幾筆
    let latestRank = 0;
    if (list.length > 0) {
      latestRank = list[list.length - 1].rank;
    }
    // 新增資料
    list.push({
      rank: latestRank + 1,
      type: '',
      text: '請選擇',
      phone: null,
    });
    this.setState({
      newTelList: list,
      isValidate: false,
    });
    this.enableButton();
  };

  /** 刪除電話資料*/
  delete = (telInfo, delIndex) => {
    let list = Object.assign(this.state.newTelList);
    // 刪除該筆資料
    list.splice(delIndex, 1);
    this.setState({
      newTelList: list,
    });
    this.enableButton(delIndex);
  };

  /**Action  */
  //查詢技術聯絡人
  queryClfyTechContact = (subscrAccntId) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/queryClfyTechContact.action', {
        // Clfy從前面取到的用戶編號9碼
        siteId: subscrAccntId,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          this.setState({
            // Clfy
            contactInfo: response.contactInfo,
            telInfoList: response.telInfoList,
            oriTelList: response.telInfoList,
            isTrustee: response.isTrustee,
          });
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '技術聯絡人',
              title: '提示訊息',
              description: response.resultMessage.message,
            }
          );
        }
        this.setState({ isLoading: false });
        this.resetNewForm();
      });
  };

  //新增技術聯絡人
  insertContactInfo = () => {
    this.setState({ isLoading: true, clickInsertOrUpdate: 'insert' });
    let techContactVo = Object.assign({}, this.state.newForm);
    techContactVo.siteId = this.state.subscrAccntId;
    callApiUtil
      .callApi('/ECPAP/API/ProductController/insertClfyTechContact.action', {
        techContactVo: techContactVo,
        telInfoList: this.state.newTelList,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          this.setState({
            step: 1,
            isResultPopOut: true,
            result: response.result,
            hasSelect: false,
            successPrefix: '新增',
            description: response.result == 'S' ? '成功' : '失敗',
          });
          this.queryClfyTechContact(this.state.subscrAccntId);
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '技術聯絡人',
              title: '新增失敗',
              description: response.description,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };
  //更新技術聯絡人
  updateContactInfo = () => {
    this.setState({ isLoading: true, clickInsertOrUpdate: 'update' });
    let techContactVo = Object.assign({}, this.state.newForm);
    techContactVo.objid = this.state.contactInfo.objid;
    techContactVo.siteId = this.state.subscrAccntId;

    callApiUtil
      .callApi('/ECPAP/API/ProductController/updateClfyTechContact.action', {
        // Clfy修改的資料
        techContactVo: techContactVo,
        telInfoList: this.state.newTelList,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          this.setState({
            step: 1,
            isResultPopOut: true,
            result: response.result,
            hasSelect: false,
            successPrefix: '更新',
            description: response.result == 'S' ? '成功' : '失敗',
          });
          this.queryClfyTechContact(this.state.subscrAccntId);
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '技術聯絡人',
              title: '更新失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };

  //刪除技術聯絡人
  deleteContactInfo = () => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/deleteClfyTechContact.action', {
        // Clfy
        objid: this.state.contactInfo.objid,
        siteId: this.state.subscrAccntId,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          this.setState({
            step: 1,
            isResultPopOut: true,
            result: response.result,
            hasSelect: false,
            successPrefix: '刪除',
            description: response.result == 'S' ? '成功' : '失敗',
          });
          this.queryClfyTechContact(this.state.subscrAccntId);
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '技術聯絡人',
              title: '刪除失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };

  render() {
    return (
      <div className='w-100 p-3'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        {/* {this.state.docList == null ? (
          <h2 className='ml-3 pt-3 pb-3'>查無技術聯絡人資料</h2>
        ) : ( */}
        <React.Fragment>
          <div>
            {this.state.step == 1 ? (
              <div className='w-100 p-3'>
                <div className='title mt-4'>技術聯絡人資料</div>
                {this.state.contactInfo.name != null ? (
                  <div>
                    <table className='w-100'>
                      <div className='sub-title mt-4 ml-2 mb-2'>聯絡人資料</div>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>聯絡人姓名</td>
                        <td className='w-60 p-3 border-gray'>{this.state.contactInfo.name}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>稱謂</td>
                        <td className='w-60 p-3 border-gray'>{this.state.contactInfo.salutation}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>證件別</td>
                        <td className='w-60 p-3 border-gray'>{this.state.contactInfo.idPass}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>證號</td>
                        <td className='w-60 p-3 border-gray'>{this.state.contactInfo.id}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>Email</td>
                        <td className='w-60 p-3 border-gray'>{this.state.contactInfo.email}</td>
                      </tr>
                    </table>
                    <div className='sub-title mt-4 ml-2 mb-2'>電話</div>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        <td className='w-20 text-center p-3 border-gray'>電話類型</td>
                        {/* <td className='w-20 p-3 border-gray'>電話順序</td> */}
                        <td className='w-20 text-center p-3 border-gray'>電話號碼</td>
                      </tr>
                      {this.state.telInfoList.map((telInfo, index) => {
                        return (
                          <tr className='bg-white' key={'contacInfo' + index}>
                            <td className='w-20 text-center p-3 border-gray'>{telInfo.text}</td>
                            <td className='w-20 text-center p-3 border-gray'>{telInfo.phone}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <div className='sub-title'>
                    {/* 新增只能再無資料的情形才可顯示 需新增判斷*/}
                    <span className='red'>*</span>
                    {this.state.isTrustee ? (
                      <span>目前無技術聯絡人資料，請點擊新增按鈕來新增技術聯絡人資料</span>
                    ) : (
                      <span>目前無技術聯絡人資料，只有受任人可以新增技術聯絡人資料</span>
                    )}
                  </div>
                )}
                <div className='button-group mb-4 ml-3'>
                  {/* 受任人才能進行新增、刪除、更新 */}
                  {this.state.isTrustee ? (
                    <div className='mt-5'>
                      {/* 新增只能再無資料的情形才可顯示 需新增判斷*/}
                      {this.state.contactInfo.name == null ? (
                        <Button
                          btnStyle='primary'
                          size='large'
                          onClick={() => {
                            this.resetNewForm();
                            this.setState({
                              step: 3,
                            });
                          }}>
                          新增聯絡人
                        </Button>
                      ) : (
                        <div>
                          <Button
                            btnStyle='primary'
                            size='large'
                            onClick={() =>
                              this.setState({
                                step: 2,
                                newTelList: JSON.parse(JSON.stringify(this.state.telInfoList)),
                              })
                            }>
                            異動聯絡人
                          </Button>
                          <Button
                            btnStyle='primary'
                            size='large'
                            onClick={() =>
                              this.setState({
                                isPopOut: true,
                              })
                            }>
                            刪除聯絡人
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {this.state.step == 2 ? (
              <div>
                <div className='title mt-4 mb-5'>異動聯絡人資料</div>
                <Formsy>
                  <div className='button-group p-3'>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          聯絡人姓名
                        </td>
                        <td className='w-60 p-3 border-gray'>
                          {validation.notEmpty(this.state.contactInfo.name) ? this.state.contactInfo.name : 'N/A'}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3 is-required'
                            name='name'
                            value={this.state.newForm.name}
                            maxLength={17}
                            placeholder='請輸入欲更改的姓名'
                            onChange={this.onChange}
                            failResultMsg={this.state.nameErrMsg}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          稱謂
                        </td>
                        <td className='w-60 p-3 border-gray'>
                          {validation.notEmpty(this.state.contactInfo.salutation)
                            ? this.state.contactInfo.salutation
                            : 'N/A'}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <input
                            type='radio'
                            className='radio-button ml-4'
                            name='salutation'
                            id='salutation'
                            value='先生'
                            checked={this.state.newForm.salutation == '先生'}
                            onChange={(e) => this.onChange('salutation', e.target.value)}></input>
                          <label htmlFor='salutation' className='h5'>
                            先生
                          </label>
                          <input
                            type='radio'
                            className='radio-button ml-4'
                            name='salutation'
                            value='女士'
                            id='salutation'
                            checked={this.state.newForm.salutation == '女士'}
                            onChange={(e) => this.onChange('salutation', e.target.value)}></input>
                          <label htmlFor='salutation' className='h5'>
                            女士
                          </label>
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          證件別
                        </td>
                        <td className='w-60 p-3 border-gray'>
                          {validation.notEmpty(this.state.contactInfo.idPass) ? this.state.contactInfo.idPass : 'N/A'}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <Dropdown
                            className='is-button col-7 pl-3 pr-5 mt-4 mb-4'
                            list={this.state.idTypeOpt}
                            label={
                              this.state.newForm.idPass == null ? '請選擇欲更改的證件別' : this.state.newForm.idPass
                            }
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('idPass', e.value)}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          證號
                        </td>
                        <td className='w-60 p-3 border-gray'>
                          {' '}
                          {validation.notEmpty(this.state.contactInfo.id) ? this.state.contactInfo.id : 'N/A'}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='id'
                            value={this.state.newForm.id}
                            maxLength={17}
                            placeholder='請輸入欲更改的證號'
                            failResultMsg={this.state.idErrMsg}
                            label=''
                            onChange={this.onChange}
                          />
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                          Email
                        </td>
                        <td className='w-60 p-3 border-gray'>
                          {validation.notEmpty(this.state.contactInfo.email) ? this.state.contactInfo.email : 'N/A'}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 border-gray'>
                          <LabelInput
                            className='ml-3 mt-3'
                            name='email'
                            value={this.state.newForm.email}
                            placeholder='請輸入欲更改的Email'
                            label=''
                            onChange={this.onChange}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </Formsy>
                <div className='d-flex'>
                  <div className='sub-title mt-4 ml-2 mb-2'>電話資料</div>
                  <div className='ml-70 '>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size={'1x'}
                      color='green'
                      className='ml-10px mt-45px'
                      onClick={() => this.add()}
                    />
                    <a
                      href='javascript:void(0)'
                      className='font-bold green clfyTechAction-add'
                      onClick={() => this.add()}>
                      新增電話類型
                    </a>
                  </div>
                </div>

                <table className='w-100'>
                  <tr className='bg-white'>
                    <td className='w-30 text-center p-3 border-gray'>電話類型</td>
                    <td className='w-25 text-center p-3 border-gray'>電話號碼</td>
                  </tr>
                  {this.state.newTelList.map((telInfo, index) => {
                    return (
                      <tr className='bg-white' key={'contacInfo' + index}>
                        <td className='w-20 text-center p-3 border-gray'>
                          <Dropdown
                            className='is-button col-7 pl-0'
                            list={this.state.telTypeOpt}
                            label={telInfo.text === 'N/A' ? '請選擇' : telInfo.text}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChangeTelType('newTelList', e, index, telInfo.phone)}
                          />
                        </td>
                        <td className='w-20 text-center border-gray'>
                          <div className='d-flex'>
                            <Formsy>
                              {/* name欄位放的是index值，因要抓到index值，才有辦法知道到底是要改哪一筆電話 */}
                              <LabelInput
                                className='ml-4 mt-4 w-90'
                                name={index.toString()}
                                value={telInfo.phone == 'N/A' ? '' : telInfo.phone}
                                placeholder='請輸入欲新增的電話號碼'
                                onChange={this.onChangeTel}
                                failResultMsg={telInfo.failResultMsg}
                                index={index}
                              />
                            </Formsy>
                            <div>
                              <a
                                href='javascript:void(0)'
                                className='mt-45px red clfyTechAction-delete'
                                onClick={() => this.delete(telInfo, index)}>
                                X 刪除
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <div className='mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    // 待更新
                    onClick={() => this.updateContactInfo()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' size='large' onClick={() => this.resetNewForm('edit')}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}

            {this.state.step == 3 ? (
              <div>
                <div className='title mb-5 ml-3 mt-5 is-text-bold'>新增聯絡人資料</div>
                <div className='sub-title mt-4 ml-2 mb-2'>基本資料</div>
                <Formsy>
                  <Grid container spacing={3} className='mt-md-3 ml-1 form-group '></Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='name'
                        value={this.state.newForm.name}
                        required={true}
                        placeholder='請輸入欲新增的聯絡人姓名'
                        label='姓名'
                        onChange={this.onChange}
                        failResultMsg={this.state.nameErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className=' ml-1 form-group '>
                    <Grid item xs={12} md={8}>
                      <label className='h5 ml-4 is-required'>稱謂</label>
                      <input
                        type='radio'
                        className='radio-button ml-4'
                        name='salutationFlag'
                        id='salutationM'
                        value='先生'
                        checked={this.state.newForm.salutation == '先生'}
                        onChange={(e) => this.onChange('salutation', e.target.value)}></input>
                      <label htmlFor='salutationM' className='h5'>
                        先生
                      </label>
                      <input
                        type='radio'
                        className='radio-button ml-4'
                        name='salutationFlag'
                        value='女士'
                        id='salutationW'
                        checked={this.state.newForm.salutation == '女士'}
                        onChange={(e) => this.onChange('salutation', e.target.value)}></input>
                      <label htmlFor='salutationW' className='h5'>
                        女士
                      </label>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-5 ml-1 form-group '>
                    <Grid item xs={12} md={8}>
                      <label className=' ml-4 h5 mb-4 is-text-bold is-required'>證件別</label>
                      <Dropdown
                        className='is-button col-7 pl-3 pr-5 mb-4'
                        list={this.state.idTypeOpt}
                        label={this.state.newForm.idPass}
                        arrow={true}
                        hasCheck={false}
                        failResultMsg={this.state.idPassErrMsg}
                        onChange={(e) => this.onChange('idPass', e.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='id'
                        value={this.state.newForm.id}
                        required={false}
                        label='證號'
                        placeholder='請輸入證號'
                        failResultMsg={this.state.idErrMsg}
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className='ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='email'
                        value={this.state.newForm.email}
                        required={false}
                        label='Email'
                        placeholder='請輸入欲新增的聯絡人的Email'
                        onChange={this.onChange}
                      />
                    </Grid>
                  </Grid>
                </Formsy>

                <div className='d-flex'>
                  <div className='sub-title mt-4 ml-2 mb-2'>電話資料</div>
                  <div className='ml-70 '>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size={'1x'}
                      color='green'
                      className='ml-10px mt-45px'
                      onClick={() => this.add()}
                    />
                    <a
                      href='javascript:void(0)'
                      className='font-bold green clfyTechAction-add'
                      onClick={() => this.add()}>
                      新增電話類型
                    </a>
                  </div>
                </div>

                <table className='w-100'>
                  <tr className='bg-white'>
                    <td className='w-30 text-center p-3 border-gray'>電話類型</td>
                    <td className='w-30 text-center p-3 border-gray'>電話號碼</td>
                  </tr>
                  {this.state.newTelList.length > 0 &&
                    this.state.newTelList.map((telInfo, index) => {
                      return (
                        <tr className='bg-white' key={'contacInfo' + index}>
                          <td className='w-20 text-center p-3 border-gray'>
                            <Dropdown
                              className='is-button col-7 pl-0'
                              list={this.state.telTypeOpt}
                              label={telInfo.text}
                              arrow={true}
                              hasCheck={false}
                              onChange={(e) => this.onChangeTelType('newTelList', e, index, telInfo.phone)}
                            />
                          </td>
                          <td className='w-20 text-center border-gray'>
                            <div className='d-flex'>
                              <Formsy>
                                {/* name欄位放的是index值，因要抓到index值，才有辦法知道到底是要改哪一筆電話 */}
                                <LabelInput
                                  className='ml-4 mt-4 w-90'
                                  name={index.toString()}
                                  value={telInfo.phone}
                                  placeholder='請輸入欲新增的電話號碼'
                                  onChange={this.onChangeTel}
                                  index={index}
                                  failResultMsg={telInfo.failResultMsg}
                                />
                              </Formsy>
                              <div>
                                <a
                                  href='javascript:void(0)'
                                  className='mt-45px red clfyTechAction-delete'
                                  onClick={() => this.delete(telInfo, index)}>
                                  X 刪除
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </table>

                <div className='ml-4 mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    onClick={() => this.insertContactInfo()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' size='large' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            {/* 刪除警告視窗 */}
            <Dialog
              open={this.state.isPopOut}
              onClose={() => this.setState({ isPopOut: false })}
              fullWidth
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>請確認是否要刪除這筆聯絡人資料?</div>
              </DialogContent>
              <DialogActions>
                <Button
                  btnStyle='primary'
                  className='w5 my-2'
                  onClick={() => {
                    this.deleteContactInfo();
                    this.setState({
                      isPopOut: false,
                    });
                  }}
                  autoFocus>
                  確認
                </Button>
                <Button
                  btnStyle='secondary'
                  className='w5 my-2'
                  onClick={() =>
                    this.setState({
                      isPopOut: false,
                    })
                  }
                  autoFocus>
                  取消
                </Button>
              </DialogActions>
            </Dialog>
            {/* 結果視窗 */}
            <Dialog
              open={this.state.isResultPopOut}
              onClose={() => this.setState({ isResultPopOut: false })}
              fullWidth
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>提示訊息</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>{this.state.successPrefix + this.state.description}</div>
              </DialogContent>
              <DialogActions>
                <Button
                  btnStyle='primary'
                  className='w5 my-2'
                  onClick={() => {
                    this.setState({
                      isResultPopOut: false,
                    });
                  }}
                  autoFocus>
                  關閉
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
        {/* )} */}
      </div>
    );
  }
}

export default ClfyTechContactPage;
