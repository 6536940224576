import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import * as callApiUtil from '../../utils/callApiUtil';
import * as validation from '../../utils/validation';
import * as emptyUtil from '../../utils/emptyUtil';
import { Helmet } from 'react-helmet';

class MaintainAccountInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      // 顯示頁面
      step: 1,
      //修改欄位是否全為空
      empty: false,
      //驗證資料, 控制送出按鈕開關
      isValidate: false,
      accntTelNoErrMsg: '',
      accntMsisdnErrMsg: '',
      accntFaxNoErrMsg: '',
      form: {
        accntName: { value: '', text: '' }, //使用者姓名
        accntTelNo: { value: '', text: '' }, //公司電話
        accntMsisdn: { value: '', text: '' }, //手機電話
        accntFaxNo: { value: '', text: '' }, //傳真號碼
        accntEmail: { value: '', text: '' }, //Email
        accntType: '', //登入角色
        gender: '', //性別
      },
      systemLogin: '', //是否可以後台代登,
      newForm: {
        newAccntTelNo: { value: '', text: '' }, //新公司電話
        newAccntMsisdn: { value: '', text: '' }, //新手機電話
        newAccntFaxNo: { value: '', text: '' }, //新傳真號碼
        newSystemLogin: { value: '', text: '' }, //後台代登輸入後的
      },
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    //組成API input Value
    let CustAccntMaintainVin = {};
    callApiUtil.callApi('/ECPAP/API/CustAccntMaintainController/init.action', CustAccntMaintainVin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        let data = this.state.form;
        data.accntName.value = emptyUtil.checkVal(response.accntName);
        data.accntMsisdn.value = emptyUtil.checkVal(response.accntMsisdn);
        data.accntTelNo.value = emptyUtil.checkVal(response.accntTelNo);
        data.accntFaxNo.value = emptyUtil.checkVal(response.accntFaxNO);
        data.accntEmail.value = emptyUtil.checkVal(response.accntEmail);
        data.accntType = emptyUtil.checkVal(response.accntType);
        data.gender = emptyUtil.checkVal(response.gender);
        this.setState({
          form: data,
          isLoading: false,
          systemLogin: response.systemLogin,
          newForm: {
            newAccntTelNo: { value: '', text: '' }, //新公司電話
            newAccntMsisdn: { value: '', text: '' }, //新手機電話
            newAccntFaxNo: { value: '', text: '' }, //新傳真號碼
            newSystemLogin: { value: response.systemLogin, text: '' }, //後台代登輸入後的
          },
        });
      } else {
        //API回傳失敗
      }
    });
  };

  onChange = (name, value) => {
    let newForm = Object.assign(this.state.newForm);
    newForm[name].value = value.trim();
    this.setState({ newForm: newForm });
    this.enableButton();
  };

  // 修改資料頁-重設按鈕
  resetData = () => {
    let defaultForm = {
      newAccntTelNo: { value: '', text: '' }, //新公司電話
      newAccntMsisdn: { value: '', text: '' }, //新手機電話
      newAccntFaxNo: { value: '', text: '' }, //新傳真號碼
      newSystemLogin: { value: this.state.systemLogin, text: '' }, //後台代登輸入後的
    };
    //將資料清空/系統待登恢復原始值，再進行可否送出的驗證
    this.setState({ newForm: defaultForm }, () => this.enableButton());
  };

  // 重設帳戶資料
  resetAccountInfo = () => {
    this.setState({ step: 2 });
  };

  //驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;
    let accntTelNoErrMsg = '';
    let accntMsisdnErrMsg = '';
    let accntFaxNoErrMsg = '';
    if (
      validation.isEmpty(this.state.newForm.newAccntTelNo.value) &&
      validation.isEmpty(this.state.newForm.newAccntMsisdn.value) &&
      validation.isEmpty(this.state.newForm.newAccntFaxNo.value) &&
      this.state.systemLogin.toString() == this.state.newForm.newSystemLogin.value.toString()
    ) {
      isValid = false;
    }
    if (
      validation.notEmpty(this.state.newForm.newAccntTelNo.value) &&
      !validation.phone(this.state.newForm.newAccntTelNo.value)
    ) {
      accntTelNoErrMsg = '請輸入正確的電話';
      isValid = false;
    }
    if (
      validation.notEmpty(this.state.newForm.newAccntMsisdn.value) &&
      !validation.mobile(this.state.newForm.newAccntMsisdn.value)
    ) {
      accntMsisdnErrMsg = '請輸入正確的行動電話';
      isValid = false;
    }
    if (
      validation.notEmpty(this.state.newForm.newAccntFaxNo.value) &&
      !validation.phone(this.state.newForm.newAccntFaxNo.value)
    ) {
      accntFaxNoErrMsg = '請輸入正確的傳真';
      isValid = false;
    }
    this.setState(() => ({
      accntTelNoErrMsg: accntTelNoErrMsg,
      accntMsisdnErrMsg: accntMsisdnErrMsg,
      accntFaxNoErrMsg: accntFaxNoErrMsg,
      isValidate: isValid,
    }));
  };

  // 送出修改的帳戶資料
  submitAccountInfo = () => {
    this.setState({ isLoading: true });
    //若驗證資料成功
    if (this.state.isValidate) {
      //組成API input Value
      let UpdateCustAccntVin = {
        newAccntTelno: this.state.newForm.newAccntTelNo.value,
        newAccntMsisdn: this.state.newForm.newAccntMsisdn.value,
        newAccntFaxNo: this.state.newForm.newAccntFaxNo.value,
        systemLogin: this.state.newForm.newSystemLogin.value,
      };
      let isSuccess;
      let title;
      let table;
      let message;
      callApiUtil
        .callApi('/ECPAP/API/CustAccntMaintainController/updateCustAccnt.action', UpdateCustAccntVin)
        .then((response) => {
          if (
            response != null &&
            response != undefined &&
            response.resultMessage != null &&
            response.resultMessage != undefined &&
            response.resultMessage.message == 's'
          ) {
            //API回傳成功
            isSuccess = true;
            title = '修改成功';
            if (this.state.systemLogin.toString() == this.state.newForm.newSystemLogin.value.toString()) {
              table = {
                title: '變更明細',
                contentName: ['項目', '狀態'],
                contentList: [
                  { name: '辦公室電話', value: response.newAccntTelno },
                  { name: '行動電話', value: response.newAccntMsisdn },
                  { name: '傳真', value: response.newAccntFaxNo },
                ],
              };
            } else {
              table = {
                title: '變更明細',
                contentName: ['項目', '狀態'],
                contentList: [
                  { name: '辦公室電話', value: response.newAccntTelno },
                  { name: '行動電話', value: response.newAccntMsisdn },
                  { name: '傳真', value: response.newAccntFaxNo },
                  { name: '系統代登', value: response.systemLogin ? '開啟' : '關閉' },
                ],
              };
            }
          } else {
            //API回傳失敗
            message = response.resultMessage.message;
            isSuccess = false;
            title = '修改失敗';
          }

          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: isSuccess ? 'success' : 'fail',
              pageName: '基本資料維護',
              title: title,
              description: message,
              table: table,
              actions: [
                {
                  text: '返回基本資料維護',
                  link: '/maintainAccountInfoPage',
                  btnStyle: 'primary',
                  dataTrackingId: '',
                },
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'secondary',
                  dataTrackingId: '',
                },
              ],
            }
          );
        });
    }
  };

  render() {
    const items = [
      {
        title: '基本資料維護',
        itemId: '/maintainAccountInfoPage',
      },
      {
        title: '帳號密碼維護',
        itemId: '/maintainPasswordPage',
      },
      {
        title: '第二代理人(子帳號)權限管理',
        itemId: '/maintainSubCustAccountInfoPage',
      },
      {
        title: '公司基本資料異動申請',
        itemId: '/maintainCorpInfoPage',
      },
      {
        title: '第一代理人(管理者)變更及基本資料異動申請',
        itemId: '/maintainFirstAgentPage',
      },
    ];

    return (
      <React.Fragment>
        <Helmet>
          <title>基本資料維護</title>
        </Helmet>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              {this.state.step != 3 ? (
                <Sidebar className='col-3' items={items} title='帳號管理' history={this.props.history}></Sidebar>
              ) : null}
              <div className='container bg-white col-9 shadow pl-4 pr-4'>
                {this.state.step != 3 ? <div className='title pt-2 mt-5 ml-3'>基本資料維護</div> : null}
                {this.state.step == 1 ? (
                  <div className='w-100 p-3'>
                    <div className='sub-title mb-4 is-text-bold'>目前登記的資料</div>
                    <table className='w-100'>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>使用者姓名</td>
                        <td className='w-60 p-3 border-gray'>
                          {this.state.form.accntName.value}
                          {this.state.form.gender === 'M' ? '先生' : '女士'}
                        </td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>辦公室電話</td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.accntTelNo.value}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>行動電話</td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.accntMsisdn.value}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>傳真</td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.accntFaxNo.value}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>Email</td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.accntEmail.value}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>系統代登</td>
                        <td className='w-60 p-3 border-gray'>{this.state.systemLogin ? '開啟' : '關閉'}</td>
                      </tr>
                      <tr className='bg-white'>
                        <td className='w-40 text-center border-gray'>登入角色</td>
                        <td className='w-60 p-3 border-gray'>{this.state.form.accntType}</td>
                      </tr>
                    </table>
                    <div className='button-group mb-4'>
                      <div className='mt-5'>
                        <Button btnStyle='primary' size='large' onClick={() => this.resetAccountInfo()}>
                          變更設定
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {this.state.step == 2 ? (
                  <div>
                    <Formsy>
                      <div className='button-group p-3'>
                        <table className='w-100'>
                          <tr className='bg-white'>
                            <td className='w-40 text-center border-gray backgroundColor-gray'>使用者姓名</td>
                            <td className='w-60 p-3 border-gray'>
                              {this.state.form.accntName.value} {this.state.form.gender == 'M' ? '先生' : '女士'}
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              辦公室電話
                            </td>
                            <td className='w-60 p-3 border-gray'>{this.state.form.accntTelNo.value}</td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='newAccntTelNo'
                                value={this.state.newForm.newAccntTelNo.value}
                                maxLength={17}
                                placeholder='請輸入更改的辦公室電話'
                                label=''
                                onChange={this.onChange}
                                failResultMsg={this.state.accntTelNoErrMsg}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              行動電話
                            </td>
                            <td className='w-60 p-3 border-gray'>{this.state.form.accntMsisdn.value}</td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='newAccntMsisdn'
                                maxLength={10}
                                value={this.state.newForm.newAccntMsisdn.value}
                                placeholder='請輸入更改的行動電話'
                                label=''
                                onChange={this.onChange}
                                failResultMsg={this.state.accntMsisdnErrMsg}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td rowspan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                              傳真
                            </td>
                            <td className='w-60 p-3 border-gray'>{this.state.form.accntFaxNo.value}</td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 border-gray'>
                              <LabelInput
                                className='ml-3 mt-3'
                                name='newAccntFaxNo'
                                maxLength={17}
                                value={this.state.newForm.newAccntFaxNo.value}
                                placeholder='請輸入更改的傳真號碼'
                                label=''
                                onChange={this.onChange}
                                failResultMsg={this.state.accntFaxNoErrMsg}
                              />
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 text-center border-gray backgroundColor-gray'>系統代登</td>
                            <td className='w-60 p-3 border-gray'>
                              <div className='radio-group'>
                                <label className='radio'>
                                  <input
                                    type='radio'
                                    className='ml-3'
                                    value={true}
                                    checked={this.state.newForm.newSystemLogin.value.toString() == 'true'}
                                    onChange={(e) => this.onChange('newSystemLogin', e.target.value)}
                                  />
                                  開啟
                                </label>
                                <label className='radio'>
                                  <input
                                    type='radio'
                                    className='ml-5'
                                    value={false}
                                    checked={this.state.newForm.newSystemLogin.value.toString() == 'false'}
                                    onChange={(e) => this.onChange('newSystemLogin', e.target.value)}
                                  />
                                  關閉
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr className='bg-white'>
                            <td className='w-40 text-center border-gray backgroundColor-gray'>登入角色</td>
                            <td className='w-60 p-3 border-gray'>{this.state.form.accntType}</td>
                          </tr>
                        </table>
                      </div>
                    </Formsy>
                    <div className='mt-4 two-buttons mb-5 pl-3'>
                      <Button
                        btnStyle='primary'
                        size='large'
                        onClick={() => this.submitAccountInfo()}
                        disabled={!this.state.isValidate}>
                        送出
                      </Button>
                      <Button btnStyle='secondary' size='large' onClick={this.resetData}>
                        重填
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MaintainAccountInfoPage);
