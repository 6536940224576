import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';
import * as callApiUtil from '../../utils/callApiUtil';
import MobileInternetForm from './MobileInternetForm';
import LandlineVoiceForm from './LandlineVoiceForm';
import DataNetworkForm from './DataNetworkForm';
import * as validation from '../../utils/validation';

class ObstacleApplyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      contactOthers: false, //聯絡其他人
      hasProblemCategory: false, //選擇服務類別
      /**  產品分類清單*/
      productCategoryList: [
        { value: 'MPDC', text: '行動電話與行動上網服務' },
        { value: 'FPDC', text: '固網語音服務' },
        { value: 'NPDC', text: '數據與網路應用服務' },
      ],
      /**  服務類別清單*/
      serviceTypeList: [],
      /**  問題分類清單*/
      problemCategoryList: [],
      /**  聯絡時間清單*/
      availableTimeList: [
        { value: '01', text: '上午 09:00~12:00' },
        { value: '02', text: '下午 12:00~18:00' },
        { value: '03', text: '晚上 18:00~22:00' },
        { value: '04', text: '不限時段' },
      ],
      /**MPDC 行動電話與行動上網服務 會用到的下拉選單 */
      // 手機通訊格數
      mocSelectList: {
        signalList: [
          { value: '0格', text: '0格' },
          { value: '1格', text: '1格' },
          { value: '2格', text: '2格' },
          { value: '3格', text: '3格' },
          { value: '4格', text: '4格' },
          { value: '5格', text: '5格' },
        ],
        // 發生的位置
        happenAreaList: [
          { value: '室內', text: '室內' },
          { value: '室外', text: '室外' },
          { value: '車內', text: '車內' },
          { value: '其他', text: '其他' },
        ],

        /**
         *以下皆為預設是/否選項可共用
         *是否允許工程人員進屋
         *同樣的地方是否有其他同為遠傳的用戶有一樣的問題
         *接收發話是否正常
         *手機是否有出現GPRS訊號或字樣
         *漫遊時收發話是否正常
         *接收容量信封是否閃爍
         *
         *
         *  */
        defauleOptionList: [
          { value: '是', text: '是' },
          { value: '否', text: '否' },
        ],

        // 是否願意提供加設基地台的地點
        addCellSiteList: [
          { value: 'Y', text: '是' },
          { value: 'N', text: '否，無法確認，可與其他人聯絡' },
        ],

        // 國際撥接代碼
        dialPrefixList: [
          { value: '002', text: '002' },
          { value: '005', text: '005' },
          { value: '006', text: '006' },
          { value: '007', text: '007' },
          { value: '009', text: '009' },
          { value: '017', text: '017' },
          { value: 'NA', text: 'NA' },
          { value: '其他', text: '其他' },
        ],

        //撥接網站
        dialUpSiteList: [
          { value: 'N/A', text: 'N/A' },
          { value: 'FETnet', text: 'FETnet' },
          { value: 'Kimo', text: 'Kimo' },
          { value: 'Other', text: 'Other' },
        ],

        // 發話方為桌機或手機
        speakingPartyList: [
          { value: '桌機', text: '桌機' },
          { value: '手機', text: '手機' },
        ],
        //任意郵+版本
        anyPostList: [
          { value: '專業版', text: '專業版' },
          { value: '企業版', text: '企業版' },
        ],

        // 使用產品
        useProductList: [
          { value: 'SMS', text: 'SMS' },
          { value: 'MIoD', text: 'MIoD' },
          { value: 'ADE', text: 'ADE' },
          { value: 'Fleet', text: 'Fleet' },
        ],
      },

      /**FPDC-固網語音服務 用的下拉式選單 */
      // 網路是否可正常連線
      networkNormalList: [
        { value: '是', text: '是' },
        { value: '否', text: '否' },
      ],

      cmpName: '', // 後端取到的公司名稱
      accntName: '', // 後端取到的帳戶名稱
      gender: '', // 後端取到的稱謂
      isLoading: false,
      hasObstacleType: false, //是否有障礙申告類型?
      isValidate: false, // 是否經過驗證

      obstaclesTime: {
        year: '', //查詢起始時間
        month: '',
        day: '',
        hours: '',
        mins: '',
        yearList: [],
        monthList: [],
        dayList: [],
        hoursList: [],
        minsList: [],
      },

      // 驗證後的錯誤訊息
      errMsg: {
        // mainContact
        mainPhoneLocal: '',
        mainMobile: '',
        mainEmail: '',
        // secondContact
        secondName: '',
        secondTitle: '先生',
        secondPhoneLocal: '',
        secondMobile: '',
        secondEmail: '',
        // thirdContent
        thirdName: '',
        thirdTitle: '先生',
        thirdPhoneLocal: '',
        thirdMobile: '',
        thirdEmail: '',
        // 障礙申告內容
        productCategory: '', // 產品類別
        serviceType: '', //服務類型
        problemCategory: '', // 問題類別
        moc0001: '', // 障礙狀況/其他補充
        moc0002: '', // 手機門號
        moc0003: '', // 發話方電話號碼
        moc0004: '', // 受話方電話號碼
        moc0005: '', // 手機廠牌
        moc0006: '', // 手機型號
        moc0007: '', // 手機訊號格數
        moc0008: '', // 是否願意讓工程師，進入屋內測試
        moc0009: '', //  發生區域之實際地址或地標
        moc0010: '', //  是否願意提供加設 基地台的地點
        moc0011: '', // 同一地點是否其他遠傳客戶 有同樣的情況？
        moc0012: '', // 撥打國家名稱
        moc0013: '', // 一般收發話是否正常
        moc0014: '', // 撥打之固網國際撥號冠碼
        moc0015: '', // 加值服務名稱
        moc0016: '', // 手機是否有 出現GPRS訊號或字樣
        moc0017: '', // 錯誤訊息提示
        moc0018: '', // 撥接網站
        moc0019: '', // 無法進入或出現的錯誤訊息
        moc0020: '', // 漫遊時註冊網路業者 (和信、遠傳)
        moc0021: '', // 漫遊國家
        moc0022: '', // 漫遊時收發話是否正常
        moc0023: '', // 漫遊時連上GPRS 出現的錯誤訊息提示
        moc0024: '', // 手機門號/統一編號
        moc0025: '', // 發話方為桌機或手機
        moc0026: '', // 任意郵+版本
        moc0027: '', // 連線設備(廠牌/型號)
        moc0028: '', // GPRS/3G連線門號
        moc0029: '', // APN設定
        moc0030: '', // 欲查詢時間
        moc0031: '', // 使用產品
        moc0032: '', // 發訊人代碼
        moc0033: '', // 接收簡訊之手機門號
        moc0034: '', // 客戶名稱
        moc0035: '', // 聯絡人/聯絡電話
        moc0036: '', // 節費器電話號碼
        moc0037: '', // 節費器安裝地址
        moc0038: '', // 傳送國家名稱
        moc0039: '', // 簡訊中心代碼
        moc0040: '', // 簡訊發送後的提示
        moc0041: '', // 所聽到語音訊息
        moc0042: '', // 接收容量信封是否閃爍
        moc0043: '', // 發送後所出現訊息或錯誤訊息

        // 固網語音服務
        foc0001: '', //  發話方號碼
        foc0002: '', // 受話方號碼
        foc0003: '', // 撥號時間 (裡面又有時分??)
        foc0004: '', // 有無撥通過
        foc0005: '', // 障礙狀況/其他補充
        foc0006: '', // 網路是否可正常連線
        foc0007: '', // 070電話號碼
        foc0008: '', // 登入密碼
        foc0009: '', // 耳麥/Web Cam設備是否正常

        noc0001: '', // IP位址及裝機地址
        noc0002: '', // 數據機燈號
        noc0003: '', // 障礙發生時間 (有時跟分??)
        noc0004: '', // 專線編號
        noc0005: '', // 裝機地址
        noc0006: '', // 線路號碼
        noc0007: '', // 卡機錯誤訊息
        noc0008: '', // 登入網址/帳號/密碼
        noc0009: '', // 錯誤訊息
        noc0010: '', // 所在機房
        noc0011: '', // 發信帳號
        noc0012: '', // 收信帳號
        noc0013: '', // 錯誤訊息
        noc0014: '', // POP3及SMTP似服器設定
        noc0015: '', // 域名帳號
        noc0016: '', // 域名帳號/網址
        noc0017: '', // 網頁錯誤訊息
        noc0018: '', // 障礙狀況/其他補充
        noc0019: '', // 裝機地址
      },

      /**要傳到後端的資料 start */
      // 1.主要聯絡人
      mainContact: {
        name: '',
        title: '',
        phoneArea: null,
        phoneLocal: '',
        phoneExt: null,
        mobile: '',
        email: '',
      },
      // 2.第一聯絡人
      secondContact: {
        name: '',
        title: '先生',
        phoneArea: null,
        phoneLocal: '',
        phoneExt: null,
        mobile: '',
        email: '',
      },
      // 3.第二聯絡人
      thirdContact: {
        name: '',
        title: '先生',
        phoneArea: null,
        phoneLocal: '',
        phoneExt: null,
        mobile: '',
        email: '',
      },

      // 4.障礙申告內容
      obstaclesContent: {
        productCategory: '', // 產品類別
        serviceType: '', //服務類型
        problemCategory: '', // 問題類別
        moc0001: '', // 障礙狀況/其他補充
        moc0002: '', // 手機門號
        moc0003: '', // 發話方電話號碼
        moc0004: '', // 受話方電話號碼
        moc0005: '', // 手機廠牌
        moc0006: '', // 手機型號
        moc0007: '', // 手機訊號格數
        moc0008: '', // 是否願意讓工程師，進入屋內測試
        moc0009: '', //  發生區域之實際地址或地標
        moc0010: '', //  是否願意提供加設 基地台的地點
        moc0011: '', // 同一地點是否其他遠傳客戶 有同樣的情況？
        moc0012: '', // 撥打國家名稱
        moc0013: '', // 一般收發話是否正常
        moc0014: '', // 撥打之固網國際撥號冠碼
        moc0015: '', // 加值服務名稱
        moc0016: '', // 手機是否有 出現GPRS訊號或字樣
        moc0017: '', // 錯誤訊息提示
        moc0018: '', // 撥接網站
        moc0019: '', // 無法進入或出現的錯誤訊息
        moc0020: '', // 漫遊時註冊網路業者 (和信、遠傳)
        moc0021: '', // 漫遊國家
        moc0022: '', // 漫遊時收發話是否正常
        moc0023: '', // 漫遊時連上GPRS 出現的錯誤訊息提示
        moc0024: '', // 手機門號/統一編號
        moc0025: '', // 發話方為桌機或手機
        moc0026: '', // 任意郵+版本
        moc0027: '', // 連線設備(廠牌/型號)
        moc0028: '', // GPRS/3G連線門號
        moc0029: '', // APN設定
        moc0030: '', // 欲查詢時間
        moc0031: '', // 使用產品
        moc0032: '', // 發訊人代碼
        moc0033: '', // 接收簡訊之手機門號
        moc0034: '', // 客戶名稱
        moc0035: '', // 聯絡人/聯絡電話
        moc0036: '', // 節費器電話號碼
        moc0037: '', // 節費器安裝地址
        moc0038: '', // 傳送國家名稱
        moc0039: '', // 簡訊中心代碼
        moc0040: '', // 簡訊發送後的提示
        moc0041: '', // 所聽到語音訊息
        moc0042: '', // 接收容量信封是否閃爍
        moc0043: '', // 發送後所出現訊息或錯誤訊息

        // 固網語音服務
        foc0001: '', //  發話方號碼
        foc0002: '', // 受話方號碼
        foc0003: '', // 撥號時間 (裡面又有時分??)
        foc0004: '', // 有無撥通過
        foc0005: '', // 障礙狀況/其他補充
        foc0006: '', // 網路是否可正常連線
        foc0007: '', // 070電話號碼
        foc0008: '', // 登入密碼
        foc0009: '', // 耳麥/Web Cam設備是否正常

        noc0001: '', // IP位址及裝機地址
        noc0002: '', // 數據機燈號
        noc0003: '', // 障礙發生時間 (有時跟分??)
        noc0004: '', // 專線編號
        noc0005: '', // 裝機地址
        noc0006: '', // 線路號碼
        noc0007: '', // 卡機錯誤訊息
        noc0008: '', // 登入網址/帳號/密碼
        noc0009: '', // 錯誤訊息
        noc0010: '', // 所在機房
        noc0011: '', // 發信帳號
        noc0012: '', // 收信帳號
        noc0013: '', // 錯誤訊息
        noc0014: '', // POP3及SMTP似服器設定
        noc0015: '', // 域名帳號
        noc0016: '', // 域名帳號/網址
        noc0017: '', // 網頁錯誤訊息
        noc0018: '', // 障礙狀況/其他補充
        noc0019: '', // 裝機地址
      },

      // 5.聯絡類型
      contactType: '01',
      // 6.聯絡時間
      availableTime: '04',
      // 7.障礙申告類別
      processingUnit: '',
      /**要傳到後端的資料 end */

      hasContactOther: false, // 是否有其他聯絡人
      showAvailableTime: { value: '04', text: '不限時段' }, // 顯示選擇的可聯絡時間
      showProductCategory: { value: '', text: '' }, // 顯示選擇的產品分類
      showServiceType: { value: '', text: '' }, // 顯示選擇的服務類別
      showProblemCategory: { value: '', text: '' }, // 顯示選擇的問題類別
      showAddCellSite: { value: '', text: '' }, // 顯示選擇的是否新增基地台選項
      showObstaclesForm: '', // 依產品類別判斷要顯示哪一個component的畫面輸入框  分為MPDC行動電話與行動上網服務、FPDC固網語音服務、NPDC數據與網路應用服務
      showMocForm: false, // 判斷在行動電話與行動上網服務頁面中是否顯示輸入框
      showFocForm: false, // 判斷在固網語音服務中是否顯示輸入框
      showNocForm: false, // 判斷在數據與網路應用服務頁面中是否顯示輸入框

      /**控制輸入框是否顯示的判斷 */
      showForm: {
        moc0001: false, // 障礙狀況/其他補充
        moc0002: false, // 手機門號
        moc0003: false, // 發話方電話號碼
        moc0004: false, // 受話方電話號碼
        moc0005: false, // 手機廠牌
        moc0006: false, // 手機型號
        moc0007: false, // 手機訊號格數
        moc0008: false, // 是否願意讓工程師，進入屋內測試
        moc0009: false, //  發生區域之實際地址或地標
        moc0010: false, //  是否願意提供加設 基地台的地點
        moc0011: false, // 同一地點是否其他遠傳客戶 有同樣的情況？
        moc0012: false, // 撥打國家名稱
        moc0013: false, // 一般收發話是否正常
        moc0014: false, // 撥打之固網國際撥號冠碼
        moc0015: false, // 加值服務名稱
        moc0016: false, // 手機是否有 出現GPRS訊號或字樣
        moc0017: false, // 錯誤訊息提示
        moc0018: false, // 撥接網站
        moc0019: false, // 無法進入或出現的錯誤訊息
        moc0020: false, // 漫遊時註冊網路業者 (和信、遠傳)
        moc0021: false, // 漫遊國家
        moc0022: false, // 漫遊時收發話是否正常
        moc0023: false, // 漫遊時連上GPRS 出現的錯誤訊息提示
        moc0024: false, // 手機門號/統一編號
        moc0025: false, // 發話方為桌機或手機
        moc0026: false, // 任意郵+版本
        moc0027: false, // 連線設備(廠牌/型號)
        moc0028: false, // GPRS/3G連線門號
        moc0029: false, // APN設定
        moc0030: false, // 欲查詢時間
        moc0031: false, // 使用產品
        moc0032: false, // 發訊人代碼
        moc0033: false, // 接收簡訊之手機門號
        moc0034: false, // 客戶名稱
        moc0035: false, // 聯絡人/聯絡電話
        moc0036: false, // 節費器電話號碼
        moc0037: false, // 節費器安裝地址
        moc0038: false, // 傳送國家名稱
        moc0039: false, // 簡訊中心代碼
        moc0040: false, // 簡訊發送後的提示
        moc0041: false, // 所聽到語音訊息
        moc0042: false, // 接收容量信封是否閃爍
        moc0043: false, // 發送後所出現訊息或錯誤訊息

        // 固網語音服務
        foc0001: false, //  發話方號碼
        foc0002: false, // 受話方號碼
        foc0003: false, // 撥號時間 (裡面又有時分??)
        foc0004: false, // 有無撥通過
        foc0005: false, // 障礙狀況/其他補充
        foc0006: false, // 網路是否可正常連線
        foc0007: false, // 070電話號碼
        foc0008: false, // 登入密碼
        foc0009: false, // 耳麥/Web Cam設備是否正常

        noc0001: false, // IP位址及裝機地址
        noc0002: false, // 數據機燈號
        noc0003: false, // 障礙發生時間 (有時跟分??)
        noc0004: false, // 專線編號
        noc0005: false, // 裝機地址
        noc0006: false, // 線路號碼
        noc0007: false, // 卡機錯誤訊息
        noc0008: false, // 登入網址/帳號/密碼
        noc0009: false, // 錯誤訊息
        noc0010: false, // 所在機房
        noc0011: false, // 發信帳號
        noc0012: false, // 收信帳號
        noc0013: false, // 錯誤訊息
        noc0014: false, // POP3及SMTP似服器設定
        noc0015: false, // 域名帳號
        noc0016: false, // 域名帳號/網址
        noc0017: false, // 網頁錯誤訊息
        noc0018: false, // 障礙狀況/其他補充
        noc0019: false, // 裝機地址
      },
    };
  }

  // 初始化
  componentDidMount = () => {
    /**計算系統日期 start*/
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let systemDate = year + '/' + month + '/' + day;

    this.setState({
      date: systemDate,
      obstaclesTime: {
        yearList: this.getYearList(year),
        monthList: this.getMonthList(),
        dayList: this.getDayList(year, month),
        hoursList: this.getHoursList(),
        minsList: this.getMinsList(),
      },
    });
    /**計算系統日期 end*/

    // 查詢線上申告初始化資料
    this.queryApplyObstacleInitData();
  };

  /*查詢線上申告初始化資料 Action start*/
  queryApplyObstacleInitData = () => {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/ObstacleController/queryApplyObstacleInit.action', {}).then((response) => {
      // 判斷回傳資料
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success
      ) {
        //API回傳成功，將資料顯示在頁面上
        this.setState({ isLoading: false });
        //API回傳成功
        this.setState(() => ({
          cmpName: response.cmpName,
          accntName: response.accntName,
          gender: response.gender,
          mainContact: {
            name: response.accntName,
            title: response.gender,
          },
        }));
      } else {
        //API回傳失敗
        this.setState({ isLoading: false });
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: '線上申告',
            title: '查詢失敗',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回企業用戶專區',
                link: '/index',
                btnStyle: 'primary',
              },
            ],
          }
        );
      }
    });
  };
  /*查詢線上申告初始化資料 Action end*/

  /*驗證線路編號 Action start*/
  validUserNo = () => {
    let userNo = null;
    if (this.state.showForm.noc0004) {
      userNo = this.state.obstaclesContent.noc0004;
    } else if (this.state.showForm.noc0006) {
      userNo = this.state.obstaclesContent.noc0006;
    }

    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ObstacleController/validUserNo.action', {
        userNo: userNo,
      })
      .then((response) => {
        // 判斷回傳資料
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({ isLoading: false });

          let errorMsg;

          if (!response.validUserNo) {
            // 若該線路編號不合法，則顯示提醒文字
            if (this.state.showForm.noc0004) {
              errorMsg = '請輸入正確的線路號碼/專線編號';
              this.setState(() => ({
                errMsg: {
                  noc0004: errorMsg,
                },
              }));
            } else if (this.state.showForm.noc0006) {
              errorMsg = '請輸入正確的線路號碼/專線編號';
              this.setState(() => ({
                errMsg: {
                  noc0006: errorMsg,
                },
              }));
            }
          } else {
            if (this.state.showForm.noc0004) {
              errorMsg = '';
              this.setState(() => ({
                errMsg: {
                  noc0004: errorMsg,
                },
              }));
            } else if (this.state.showForm.noc0006) {
              errorMsg = '';
              this.setState(() => ({
                errMsg: {
                  noc0006: errorMsg,
                },
              }));
            }
          }

          //API回傳成功
        } else {
          //API回傳失敗
          this.setState({ isLoading: false });
        }
      });
  };
  /*驗證線路編號 Action end*/

  /*送出修改的帳戶資料 Action start*/
  saveObstacles = () => {
    this.validInputData();
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ObstacleController/saveObstacle.action', {
        obstaclesContent: this.state.obstaclesContent,
        mainContact: this.state.mainContact,
        secondContact: validation.notEmpty(this.state.secondContact.name) ? this.state.secondContact : null,
        thirdContact: validation.notEmpty(this.state.thirdContact.name) ? this.state.thirdContact : null,
        availableTime: this.state.availableTime,
        contactType: this.state.contactType,
        processingUnit: this.state.processingUnit,
        contactOthers: this.state.contactOthers,
      })
      .then((response) => {
        // 判斷回傳資料
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({ isLoading: false });
          //API回傳成功
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'success',
              pageName: '線上申告申請',
              title: '報修單已成功送出',
              description: '報修單號：' + response.obstacleId,
              actions: [
                {
                  text: '返回障礙申告及查詢',
                  link: '/queryObstacleRecordPage',
                  btnStyle: 'primary',
                },
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'secondary',
                },
              ],
            }
          );
        } else {
          //API回傳失敗
          this.setState({ isLoading: false });
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '線上申告申請',
              title: '申請失敗',
              description: response.resultMessage.message,
              actions: [
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'primary',
                },
              ],
            }
          );
        }
      });
  };
  /*送出修改的帳戶資料 Action end*/

  /*是否勾選聯絡其他人*/
  contactOthers = () => {
    this.setState(() => ({
      contactOthers: !this.state.contactOthers,
    }));
  };

  /*更改主要聯絡人資訊 */
  changeMainContact = (name, value) => {
    let newForm = Object.assign(this.state.mainContact);
    newForm[name] = value.trim();
    this.setState({ mainContact: newForm });
  };
  /*更改第一聯絡人資訊*/
  changeSecondContact = (name, value) => {
    let newForm = Object.assign(this.state.secondContact);
    newForm[name] = value.trim();
    this.setState({ secondContact: newForm });
  };
  /*更改第二聯絡人資訊*/
  changeThirdContact = (name, value) => {
    let newForm = Object.assign(this.state.thirdContact);
    newForm[name] = value.trim();
    this.setState({ thirdContact: newForm });
  };

  /*更改聯絡方式 */
  changeContentType = (value) => {
    this.setState({ contactType: value });
  };

  /*更改障礙申告資訊*/
  changeObstaclesContent = (name, value) => {
    let newForm = Object.assign(this.state.obstaclesContent);
    newForm[name] = value.trim();
    this.setState({ obstaclesContent: newForm });
  };

  /*更改下拉選單*/
  changeSelect = (name, data) => {
    // 更改聯絡時間
    if (name == 'availableTime') {
      this.setState({ availableTime: data.value, showAvailableTime: { value: data.value, text: data.text } });

      // 更改產品分類
    } else if (name == 'productCategory') {
      let newForm = Object.assign(this.state.obstaclesContent);
      newForm[name] = data.value.trim();
      this.setState({ obstaclesContent: newForm, showProductCategory: { value: data.value, text: data.text } });

      // 更改服務類別
    } else if (name == 'serviceType') {
      let newForm = Object.assign(this.state.obstaclesContent);
      newForm[name] = data.value.trim();
      this.setState({ obstaclesContent: newForm, showServiceType: { value: data.value, text: data.text } });

      // 更改問題類別
    } else if (name == 'problemCategory') {
      let newForm = Object.assign(this.state.obstaclesContent);
      newForm[name] = data.value.trim();
      this.setState({
        obstaclesContent: newForm,
        showProblemCategory: { value: data.value, text: data.text },
        showMocForm: true,
      });
      // 判斷選擇問題類別後要顯示的輸入框
      this.checkShowForm();
      // 重置輸入框內容
      this.resetObstaclesContent();

      // 更改"是否願意提供加設基地台的地點"的選項
    } else if (name == 'moc0010') {
      let newForm = Object.assign(this.state.obstaclesContent);
      newForm[name] = data.value.trim();
      this.setState({ obstaclesContent: newForm, showAddCellSite: { value: data.value, text: data.text } });
    }
  };

  /*更改產品分類時
   *1.將選擇的選項加進productCategory參數中
   *2.依產品分類來判斷服務分類要顯示的清單
   */
  changeProductCategory = (data) => {
    this.changeSelect('productCategory', data);
    let list = [];
    switch (data.value) {
      case 'MPDC':
        list.push(
          { value: 'MST001', text: '行動電話問題' },
          { value: 'MST002', text: 'GPRS連線問題' },
          { value: 'MST003', text: 'MVPN+VoIP相關問題' },
          { value: 'MST004', text: 'MVPN相關問題' },
          // { value: 'MST005', text: '任意郵3.0╱任意郵+相關問題' },
          // { value: 'MST006', text: '企業客戶GPRS問題' },
          { value: 'MST007', text: '企業客戶資料分流計算問題' },
          { value: 'MST008', text: '企業整合簡訊(SMS/MIoD)' },
          { value: 'MST009', text: '企業簡訊服務網路版(DB3)' },
          // { value: 'MST010', text: '車訊速/刷訊速' },
          // { value: 'MST011', text: '行動節費器(MT) 相關問題' },
          { value: 'MST000', text: '未分類/其他問題' }
        );
        break;
      case 'FPDC':
        list.push(
          { value: 'FST001', text: 'TRUNK' },
          { value: 'FST002', text: '市內電話服務' },
          // { value: 'FST003', text: '新樸網路電話服務' },
          // { value: 'FST004', text: '軟體網路電話 Wagaly Show' },
          { value: 'FST005', text: 'FET 070 11碼障礙' },
          // { value: 'FST006', text: 'FET 070 9碼障礙' },
          { value: 'FST000', text: '未分類/其他問題' }
        );
        break;
      case 'NPDC':
        list.push(
          { value: 'NST001', text: '速博網ADSL(網內)' },
          { value: 'NST002', text: '速博網ADSL(網外)' },
          { value: 'NST003', text: 'Seednet固定制ADSL' },
          { value: 'NST004', text: 'Seednet企業數位光纖' },
          { value: 'NST005', text: '國內數據專線' },
          { value: 'NST006', text: '國際數據專線' },
          { value: 'NST007', text: 'ATM Network Hosting' },
          { value: 'NST008', text: '企業虛擬網路 IPVPN' },
          { value: 'NST009', text: '國際企業虛擬網路 IIPVPN' },
          { value: 'NST010', text: 'MPLS VPN 線路障礙問題' },
          { value: 'NST011', text: 'MPLS VPN 金流障礙問題' },
          { value: 'NST012', text: 'SSL VPN 障礙問題' },
          { value: 'NST013', text: '主機代管(IDC)障礙' },
          // { value: 'NST014', text: 'E-Mail / Vmail 障礙申告' },
          { value: 'NST015', text: '域名障礙申告' },
          { value: 'NST016', text: '網站代管障礙申告' },
          { value: 'NST017', text: 'IP-Transit' },
          { value: 'NST000', text: '未分類/其他問題' }
        );
        break;
      default:
      // do nothing
    }
    // 將資料帶回去
    this.setState({
      serviceTypeList: list,
      showObstaclesForm: data.value,
      showServiceType: { value: '', text: '' }, // 重置服務類別資料
      showProblemCategory: { value: '', text: '' }, // 重置問題類別
      showFocForm: false,
      showMocForm: false,
      showNocForm: false,
    });
    // 清空障礙申告輸入框內容
    this.resetObstaclesContent();

    this.validInputData();
  };

  /*
   *選擇服務類別時
   *1.將選擇的選項加進serviceType參數中
   *2.只有MPDC行動電話與行動上網服務> MST001行動電話問題、MST002GPRS連線問題，會有問題類別的下拉式選單
   */
  changeServiceType = (data) => {
    this.changeSelect('serviceType', data);

    let list = [];
    if (data.value == 'MST001' || data.value == 'MST002') {
      switch (data.value) {
        case 'MST001':
          list.push(
            { value: 'MPBC001', text: '通話中異常相關問題' },
            { value: 'MPBC002', text: '無法收/發話' },
            { value: 'MPBC003', text: '訊號涵蓋不穩定' },
            { value: 'MPBC004', text: '撥打國際電話問題' },
            { value: 'MPBC005', text: '國際簡訊問題' },
            { value: 'MPBC006', text: '漫遊相關問題' },
            { value: 'MPBC007', text: 'MMS相關問題' },
            { value: 'MPBC008', text: '國內簡訊/USSD相關問題' },
            { value: 'MPBC009', text: '其他加值服務相關問題' }
          );
          break;
        case 'MST002':
          list.push(
            { value: 'MPBC010', text: 'GPRS訊號相關問題' },
            { value: 'MPBC011', text: '手機無法下載 , 訊息錯誤相關問題' },
            { value: 'MPBC012', text: '漫遊時使用GPRS問題' }
          );
          break;
        default:
        // do nothing
      }
    }
    // 將資料塞回problemCategoryList中
    this.setState({ problemCategoryList: list });

    if (data != null) {
      this.setState({ hasProblemCategory: true });
    } else {
      this.setState({ hasProblemCategory: false });
    }

    let newObstaclesContent = Object.assign(this.state.obstaclesContent);

    // 判斷要顯示的畫面類別。
    //若選擇的是MPDC行動電話與行動上網服務> MST001行動電話問題、MST002GPRS連線問題，需再選擇問題類別才能顯示輸入框
    if (data.value == 'MST001' || data.value == 'MST002') {
      this.setState({ hasObstacleType: true, showMocForm: false, showProblemCategory: { value: '', text: '' } });
    } else if (data.value.substring(0, 3) == 'MST' && data.value != 'MST001' && data.value != 'MST002') {
      newObstaclesContent.problemCategory = 'MPBC000';
      this.setState({ hasObstacleType: false, showMocForm: true, obstaclesContent: newObstaclesContent });
    }

    // 若選擇固網語音服務FPDC>服務類別為FST開頭
    if (data.value.substring(0, 3) == 'FST') {
      newObstaclesContent.problemCategory = 'FPBC000';
      this.setState({ showFocForm: true, obstaclesContent: newObstaclesContent });

      // 若選擇數據與網路應用服務NPDC>服務類別為NST開頭
    } else if (data.value.substring(0, 3) == 'NST') {
      newObstaclesContent.problemCategory = 'NPBC000';
      this.setState({ showNocForm: true, obstaclesContent: newObstaclesContent });
    }
    // 判斷要顯示的輸入框內容是哪些
    this.checkShowForm();
    // 清空輸入框內容
    this.resetObstaclesContent();

    this.validInputData();
  };

  /*更改日期選單時 */
  changeDate = (formName, name, value) => {
    let newObstaclesTime = Object.assign(this.state.obstaclesTime);
    newObstaclesTime[name] = value.trim();
    this.setState({ obstaclesTime: newObstaclesTime });

    let year = this.state.obstaclesTime.year;
    let month = this.state.obstaclesTime.month;
    let day = this.state.obstaclesTime.day;
    let hours = this.state.obstaclesTime.hours;
    let mins = this.state.obstaclesTime.mins;

    if (name == 'year') {
      let newObstaclesTime = Object.assign(this.state.obstaclesTime);
      newObstaclesTime.month = '';
      newObstaclesTime.day = '';
      this.setState({
        obstaclesTime: newObstaclesTime,
      });
    }

    if (name == 'month') {
      let newObstaclesTime = Object.assign(this.state.obstaclesTime);
      newObstaclesTime.dayList = this.getDayList(year, month);
      newObstaclesTime.day = '';
      this.setState({
        obstaclesTime: newObstaclesTime,
      });
    }

    let newObstaclesContent = Object.assign(this.state.obstaclesContent);

    // 要年月日時分都有值才可組成日期字串，缺一不可
    if (
      !validation.isEmpty(year) &&
      !validation.isEmpty(month) &&
      !validation.isEmpty(day) &&
      !validation.isEmpty(hours) &&
      !validation.isEmpty(mins)
    ) {
      // 障礙發生時間
      if (formName == 'NOC') {
        newObstaclesContent.noc0003 = year + '/' + month + '/' + day + ' ' + hours + ':' + mins;
        // 撥號時間
      } else if (formName == 'FOC') {
        newObstaclesContent.foc0003 = year + '/' + month + '/' + day + ' ' + hours + ':' + mins;
      }
    } else {
      newObstaclesContent.noc0003 = '';
      newObstaclesContent.foc0003 = '';
    }

    this.setState({
      obstaclesContent: newObstaclesContent,
    });
  };

  /*初始化表單資料*/
  resetData = () => {
    // 主要聯絡人
    let mainContact = {
      name: '',
      title: '',
      phoneLocal: '',
      mobile: '',
      email: '',
    };
    // 第一聯絡人
    let secondContact = {
      name: '',
      title: '',
      phoneLocal: '',
      mobile: '',
      email: '',
    };
    // 第二聯絡人
    let thirdContact = {
      name: '',
      title: '',
      phoneLocal: '',
      mobile: '',
      email: '',
    };

    // 輸入框內容
    let obstaclesContent = {
      productCategory: '',
      serviceType: '',
      problemCategory: '',
      moc0001: '', // 障礙狀況/其他補充
      moc0002: '', // 手機門號
      moc0003: '', // 發話方電話號碼
      moc0004: '', // 受話方電話號碼
      moc0005: '', // 手機廠牌
      moc0006: '', // 手機型號
      moc0007: '', // 手機訊號格數
      moc0008: '', // 是否願意讓工程師，進入屋內測試
      moc0009: '', //  發生區域之實際地址或地標
      moc0010: '', //  是否願意提供加設 基地台的地點
      moc0011: '', // 同一地點是否其他遠傳客戶 有同樣的情況？
      moc0012: '', // 撥打國家名稱
      moc0013: '', // 一般收發話是否正常
      moc0014: '', // 撥打之固網國際撥號冠碼
      moc0015: '', // 加值服務名稱
      moc0016: '', // 手機是否有 出現GPRS訊號或字樣
      moc0017: '', // 錯誤訊息提示
      moc0018: '', // 撥接網站
      moc0019: '', // 無法進入或出現的錯誤訊息
      moc0020: '', // 漫遊時註冊網路業者 (和信、遠傳)
      moc0021: '', // 漫遊國家
      moc0022: '', // 漫遊時收發話是否正常
      moc0023: '', // 漫遊時連上GPRS 出現的錯誤訊息提示
      moc0024: '', // 手機門號/統一編號
      moc0025: '', // 發話方為桌機或手機
      moc0026: '', // 任意郵+版本
      moc0027: '', // 連線設備(廠牌/型號)
      moc0028: '', // GPRS/3G連線門號
      moc0029: '', // APN設定
      moc0030: '', // 欲查詢時間
      moc0031: '', // 使用產品
      moc0032: '', // 發訊人代碼
      moc0033: '', // 接收簡訊之手機門號
      moc0034: '', // 客戶名稱
      moc0035: '', // 聯絡人/聯絡電話
      moc0036: '', // 節費器電話號碼
      moc0037: '', // 節費器安裝地址
      moc0038: '', // 傳送國家名稱
      moc0039: '', // 簡訊中心代碼
      moc0040: '', // 簡訊發送後的提示
      moc0041: '', // 所聽到語音訊息
      moc0042: '', // 接收容量信封是否閃爍
      moc0043: '', // 發送後所出現訊息或錯誤訊息

      // 固網語音服務
      foc0001: '', //  發話方號碼
      foc0002: '', // 受話方號碼
      foc0003: '', // 撥號時間 (裡面又有時分??)
      foc0004: '', // 有無撥通過
      foc0005: '', // 障礙狀況/其他補充
      foc0006: '', // 網路是否可正常連線
      foc0007: '', // 070電話號碼
      foc0008: '', // 登入密碼
      foc0009: '', // 耳麥/Web Cam設備是否正常

      noc0001: '', // IP位址及裝機地址
      noc0002: '', // 數據機燈號
      noc0003: '', // 障礙發生時間 (有時跟分??)
      noc0004: '', // 專線編號
      noc0005: '', // 裝機地址
      noc0006: '', // 線路號碼
      noc0007: '', // 卡機錯誤訊息
      noc0008: '', // 登入網址/帳號/密碼
      noc0009: '', // 錯誤訊息
      noc0010: '', // 所在機房
      noc0011: '', // 發信帳號
      noc0012: '', // 收信帳號
      noc0013: '', // 錯誤訊息
      noc0014: '', // POP3及SMTP似服器設定
      noc0015: '', // 域名帳號
      noc0016: '', // 域名帳號/網址
      noc0017: '', // 網頁錯誤訊息
      noc0018: '', // 障礙狀況/其他補充
      noc0019: '', // 裝機地址
    };

    // 清空障礙發生時間/撥號時間
    let resetObstaclesTime = Object.assign(this.state.obstaclesTime);
    resetObstaclesTime.year = '';
    resetObstaclesTime.month = '';
    resetObstaclesTime.day = '';
    resetObstaclesTime.hours = '';
    resetObstaclesTime.mins = '';

    // 清空資料後塞回去
    this.setState({
      mainContact: mainContact,
      secondContact: secondContact,
      thirdContact: thirdContact,
      obstaclesContent: obstaclesContent,
      contactType: '01',
      availableTime: '04',
      hasContactOther: false,
      showAvailableTime: { value: '04', text: '不限時段' },
      showProductCategory: { value: '', text: '' },
      showServiceType: { value: '', text: '' },
      showProblemCategory: { value: '', text: '' },
      showAddCellSite: { value: '', text: '' },
      showObstaclesForm: '',
      showMocForm: false,
      showFocForm: false,
      showNocForm: false,
      contactOthers: false, //聯絡其他人
      obstaclesTime: resetObstaclesTime,
    });

    // 清空顯示表單的控制判斷
    this.resetShowForm();
    // 清空錯誤訊息
    this.resetErrMsg();
  };

  /*初始化障礙申告輸入框內容 */
  resetObstaclesContent = () => {
    let obstacles = Object.assign(this.state.obstaclesContent);

    obstacles.moc0001 = '';
    obstacles.moc0002 = '';
    obstacles.moc0003 = '';
    obstacles.moc0004 = '';
    obstacles.moc0005 = '';
    obstacles.moc0006 = '';
    obstacles.moc0007 = '';
    obstacles.moc0008 = '';
    obstacles.moc0009 = '';
    obstacles.moc0010 = '';
    obstacles.moc0011 = '';

    obstacles.moc0012 = ''; // 撥打國家名稱
    obstacles.moc0013 = ''; // 一般收發話是否正常
    obstacles.moc0014 = ''; // 撥打之固網國際撥號冠碼
    obstacles.moc0015 = ''; // 加值服務名稱
    obstacles.moc0016 = ''; // 手機是否有 出現GPRS訊號或字樣
    obstacles.moc0017 = ''; // 錯誤訊息提示
    obstacles.moc0018 = ''; // 撥接網站
    obstacles.moc0019 = ''; // 無法進入或出現的錯誤訊息
    obstacles.moc0020 = ''; // 漫遊時註冊網路業者 (和信、遠傳)
    obstacles.moc0021 = ''; // 漫遊國家
    obstacles.moc0022 = ''; // 漫遊時收發話是否正常
    obstacles.moc0023 = ''; // 漫遊時連上GPRS 出現的錯誤訊息提示
    obstacles.moc0024 = ''; // 手機門號/統一編號
    obstacles.moc0025 = ''; // 發話方為桌機或手機
    obstacles.moc0026 = ''; // 任意郵+版本
    obstacles.moc0027 = ''; // 連線設備(廠牌/型號)
    obstacles.moc0028 = ''; // GPRS/3G連線門號
    obstacles.moc0029 = ''; // APN設定
    obstacles.moc0030 = ''; // 欲查詢時間
    obstacles.moc0031 = ''; // 使用產品
    obstacles.moc0032 = ''; // 發訊人代碼
    obstacles.moc0033 = ''; // 接收簡訊之手機門號
    obstacles.moc0034 = ''; // 客戶名稱
    obstacles.moc0035 = ''; // 聯絡人/聯絡電話
    obstacles.moc0036 = ''; // 節費器電話號碼
    obstacles.moc0037 = ''; // 節費器安裝地址
    obstacles.moc0038 = ''; // 傳送國家名稱
    obstacles.moc0039 = ''; // 簡訊中心代碼
    obstacles.moc0040 = ''; // 簡訊發送後的提示
    obstacles.moc0041 = ''; // 所聽到語音訊息
    obstacles.moc0042 = ''; // 接收容量信封是否閃爍
    obstacles.moc0043 = ''; // 發送後所出現訊息或錯誤訊息

    // 固網語音服務
    obstacles.foc0001 = ''; //  發話方號碼
    obstacles.foc0002 = ''; // 受話方號碼
    obstacles.foc0003 = ''; // 撥號時間 (裡面又有時分??)
    obstacles.foc0004 = ''; // 有無撥通過
    obstacles.foc0005 = ''; // 障礙狀況/其他補充
    obstacles.foc0006 = ''; // 網路是否可正常連線
    obstacles.foc0007 = ''; // 070電話號碼
    obstacles.foc0008 = ''; // 登入密碼
    obstacles.foc0009 = ''; // 耳麥/Web Cam設備是否正常

    obstacles.noc0001 = ''; // IP位址及裝機地址
    obstacles.noc0002 = ''; // 數據機燈號
    obstacles.noc0003 = ''; // 障礙發生時間 (有時跟分??)
    obstacles.noc0004 = ''; // 專線編號
    obstacles.noc0005 = ''; // 裝機地址
    obstacles.noc0006 = ''; // 線路號碼
    obstacles.noc0007 = ''; // 卡機錯誤訊息
    obstacles.noc0008 = ''; // 登入網址/帳號/密碼
    obstacles.noc0009 = ''; // 錯誤訊息
    obstacles.noc0010 = ''; // 所在機房
    obstacles.noc0011 = ''; // 發信帳號
    obstacles.noc0012 = ''; // 收信帳號
    obstacles.noc0013 = ''; // 錯誤訊息
    obstacles.noc0014 = ''; // POP3及SMTP似服器設定
    obstacles.noc0015 = ''; // 域名帳號
    obstacles.noc0016 = ''; // 域名帳號/網址
    obstacles.noc0017 = ''; // 網頁錯誤訊息
    obstacles.noc0018 = ''; // 障礙狀況/其他補充
    obstacles.noc0019 = ''; // 裝機地址

    // 清空資料後塞回去
    this.setState({
      obstaclesContent: obstacles,
    });

    this.resetErrMsg();
  };

  /*初始化顯示輸入框的控制參數*/
  resetShowForm = () => {
    let resetShowForm = Object.assign(this.state.showForm);

    resetShowForm.moc0001 = false;
    resetShowForm.moc0002 = false;
    resetShowForm.moc0003 = false;
    resetShowForm.moc0004 = false;
    resetShowForm.moc0005 = false;
    resetShowForm.moc0006 = false;
    resetShowForm.moc0007 = false;
    resetShowForm.moc0008 = false;
    resetShowForm.moc0009 = false;
    resetShowForm.moc0010 = false;
    resetShowForm.moc0011 = false;

    resetShowForm.moc0012 = false; // 撥打國家名稱
    resetShowForm.moc0013 = false; // 一般收發話是否正常
    resetShowForm.moc0014 = false; // 撥打之固網國際撥號冠碼
    resetShowForm.moc0015 = false; // 加值服務名稱
    resetShowForm.moc0016 = false; // 手機是否有 出現GPRS訊號或字樣
    resetShowForm.moc0017 = false; // 錯誤訊息提示
    resetShowForm.moc0018 = false; // 撥接網站
    resetShowForm.moc0019 = false; // 無法進入或出現的錯誤訊息
    resetShowForm.moc0020 = false; // 漫遊時註冊網路業者 (和信、遠傳)
    resetShowForm.moc0021 = false; // 漫遊國家
    resetShowForm.moc0022 = false; // 漫遊時收發話是否正常
    resetShowForm.moc0023 = false; // 漫遊時連上GPRS 出現的錯誤訊息提示
    resetShowForm.moc0024 = false; // 手機門號/統一編號
    resetShowForm.moc0025 = false; // 發話方為桌機或手機
    resetShowForm.moc0026 = false; // 任意郵+版本
    resetShowForm.moc0027 = false; // 連線設備(廠牌/型號)
    resetShowForm.moc0028 = false; // GPRS/3G連線門號
    resetShowForm.moc0029 = false; // APN設定
    resetShowForm.moc0030 = false; // 欲查詢時間
    resetShowForm.moc0031 = false; // 使用產品
    resetShowForm.moc0032 = false; // 發訊人代碼
    resetShowForm.moc0033 = false; // 接收簡訊之手機門號
    resetShowForm.moc0034 = false; // 客戶名稱
    resetShowForm.moc0035 = false; // 聯絡人/聯絡電話
    resetShowForm.moc0036 = false; // 節費器電話號碼
    resetShowForm.moc0037 = false; // 節費器安裝地址
    resetShowForm.moc0038 = false; // 傳送國家名稱
    resetShowForm.moc0039 = false; // 簡訊中心代碼
    resetShowForm.moc0040 = false; // 簡訊發送後的提示
    resetShowForm.moc0041 = false; // 所聽到語音訊息
    resetShowForm.moc0042 = false; // 接收容量信封是否閃爍
    resetShowForm.moc0043 = false; // 發送後所出現訊息或錯誤訊息

    // 固網語音服務
    resetShowForm.foc0001 = false; //  發話方號碼
    resetShowForm.foc0002 = false; // 受話方號碼
    resetShowForm.foc0003 = false; // 撥號時間 (裡面又有時分??)
    resetShowForm.foc0004 = false; // 有無撥通過
    resetShowForm.foc0005 = false; // 障礙狀況/其他補充
    resetShowForm.foc0006 = false; // 網路是否可正常連線
    resetShowForm.foc0007 = false; // 070電話號碼
    resetShowForm.foc0008 = false; // 登入密碼
    resetShowForm.foc0009 = false; // 耳麥/Web Cam設備是否正常

    resetShowForm.noc0001 = false; // IP位址及裝機地址
    resetShowForm.noc0002 = false; // 數據機燈號
    resetShowForm.noc0003 = false; // 障礙發生時間 (有時跟分??)
    resetShowForm.noc0004 = false; // 專線編號
    resetShowForm.noc0005 = false; // 裝機地址
    resetShowForm.noc0006 = false; // 線路號碼
    resetShowForm.noc0007 = false; // 卡機錯誤訊息
    resetShowForm.noc0008 = false; // 登入網址/帳號/密碼
    resetShowForm.noc0009 = false; // 錯誤訊息
    resetShowForm.noc0010 = false; // 所在機房
    resetShowForm.noc0011 = false; // 發信帳號
    resetShowForm.noc0012 = false; // 收信帳號
    resetShowForm.noc0013 = false; // 錯誤訊息
    resetShowForm.noc0014 = false; // POP3及SMTP似服器設定
    resetShowForm.noc0015 = false; // 域名帳號
    resetShowForm.noc0016 = false; // 域名帳號/網址
    resetShowForm.noc0017 = false; // 網頁錯誤訊息
    resetShowForm.noc0018 = false; // 障礙狀況/其他補充
    resetShowForm.noc0019 = false; // 裝機地址

    this.setState({ showForm: resetShowForm });
  };

  /*初始化錯誤訊息內容 */
  resetErrMsg = () => {
    // 錯誤訊息清空
    let resetErrMsg = {
      mainPhoneLocal: '',
      mainMobile: '',
      mainEmail: '',
      // secondContact
      secondName: '',
      secondTitle: '先生',
      secondPhoneLocal: '',
      secondMobile: '',
      secondEmail: '',
      // thirdContent
      thirdName: '',
      thirdTitle: '先生',
      thirdPhoneLocal: '',
      thirdMobile: '',
      thirdEmail: '',
      productCategory: '',
      serviceType: '',
      problemCategory: '',
      moc0001: '', // 障礙狀況/其他補充
      moc0002: '', // 手機門號
      moc0003: '', // 發話方電話號碼
      moc0004: '', // 受話方電話號碼
      moc0005: '', // 手機廠牌
      moc0006: '', // 手機型號
      moc0007: '', // 手機訊號格數
      moc0008: '', // 是否願意讓工程師，進入屋內測試
      moc0009: '', //  發生區域之實際地址或地標
      moc0010: '', //  是否願意提供加設 基地台的地點
      moc0011: '', // 同一地點是否其他遠傳客戶 有同樣的情況？
      moc0012: '', // 撥打國家名稱
      moc0013: '', // 一般收發話是否正常
      moc0014: '', // 撥打之固網國際撥號冠碼
      moc0015: '', // 加值服務名稱
      moc0016: '', // 手機是否有 出現GPRS訊號或字樣
      moc0017: '', // 錯誤訊息提示
      moc0018: '', // 撥接網站
      moc0019: '', // 無法進入或出現的錯誤訊息
      moc0020: '', // 漫遊時註冊網路業者 (和信、遠傳)
      moc0021: '', // 漫遊國家
      moc0022: '', // 漫遊時收發話是否正常
      moc0023: '', // 漫遊時連上GPRS 出現的錯誤訊息提示
      moc0024: '', // 手機門號/統一編號
      moc0025: '', // 發話方為桌機或手機
      moc0026: '', // 任意郵+版本
      moc0027: '', // 連線設備(廠牌/型號)
      moc0028: '', // GPRS/3G連線門號
      moc0029: '', // APN設定
      moc0030: '', // 欲查詢時間
      moc0031: '', // 使用產品
      moc0032: '', // 發訊人代碼
      moc0033: '', // 接收簡訊之手機門號
      moc0034: '', // 客戶名稱
      moc0035: '', // 聯絡人/聯絡電話
      moc0036: '', // 節費器電話號碼
      moc0037: '', // 節費器安裝地址
      moc0038: '', // 傳送國家名稱
      moc0039: '', // 簡訊中心代碼
      moc0040: '', // 簡訊發送後的提示
      moc0041: '', // 所聽到語音訊息
      moc0042: '', // 接收容量信封是否閃爍
      moc0043: '', // 發送後所出現訊息或錯誤訊息

      // 固網語音服務
      foc0001: '', //  發話方號碼
      foc0002: '', // 受話方號碼
      foc0003: '', // 撥號時間 (裡面又有時分??)
      foc0004: '', // 有無撥通過
      foc0005: '', // 障礙狀況/其他補充
      foc0006: '', // 網路是否可正常連線
      foc0007: '', // 070電話號碼
      foc0008: '', // 登入密碼
      foc0009: '', // 耳麥/Web Cam設備是否正常

      noc0001: '', // IP位址及裝機地址
      noc0002: '', // 數據機燈號
      noc0003: '', // 障礙發生時間 (有時跟分??)
      noc0004: '', // 專線編號
      noc0005: '', // 裝機地址
      noc0006: '', // 線路號碼
      noc0007: '', // 卡機錯誤訊息
      noc0008: '', // 登入網址/帳號/密碼
      noc0009: '', // 錯誤訊息
      noc0010: '', // 所在機房
      noc0011: '', // 發信帳號
      noc0012: '', // 收信帳號
      noc0013: '', // 錯誤訊息
      noc0014: '', // POP3及SMTP似服器設定
      noc0015: '', // 域名帳號
      noc0016: '', // 域名帳號/網址
      noc0017: '', // 網頁錯誤訊息
      noc0018: '', // 障礙狀況/其他補充
      noc0019: '', // 裝機地址
    };
    // 清空資料後塞回去
    this.setState({
      errMsg: resetErrMsg,
      isValidate: false,
    });
  };

  /* 判斷選擇的產品分類>服務類型 > 問題分類要顯示的輸入框 */
  checkShowForm = () => {
    // 先把顯示的輸入框都還原預設。
    this.resetShowForm();
    let processingUnit = null;
    let newShowForm = Object.assign(this.state.showForm);
    // 選擇行動電話與行動上網服務
    if (this.state.obstaclesContent.productCategory == 'MPDC') {
      // 選擇行動電話與行動上網服務>行動電話問題
      if (this.state.obstaclesContent.serviceType == 'MST001') {
        // 1.選擇行動電話與行動上網服務>行動電話問題>通話中異常相關問題
        if (this.state.obstaclesContent.problemCategory == 'MPBC001') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0003 = true;
          newShowForm.moc0004 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0007 = true;

          // 2.選擇行動電話與行動上網服務>行動電話問題>選擇無法收/發話
        } else if (this.state.obstaclesContent.problemCategory == 'MPBC002') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0003 = true;
          newShowForm.moc0004 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0007 = true;
          newShowForm.moc0008 = true;

          //3.選擇行動電話與行動上網服務>行動電話問題>選擇訊號涵蓋不穩定
        } else if (this.state.obstaclesContent.problemCategory == 'MPBC003') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0007 = true;
          newShowForm.moc0008 = true;
          newShowForm.moc0009 = true;
          newShowForm.moc0010 = true;
          newShowForm.moc0011 = true;

          //4.選擇行動電話與行動上網服務>行動電話問題>選擇撥打國際電話問題
        } else if (this.state.obstaclesContent.problemCategory == 'MPBC004') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0003 = true;
          newShowForm.moc0004 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0007 = true;
          newShowForm.moc0012 = true;
          newShowForm.moc0013 = true;
          newShowForm.moc0014 = true;

          // 5.選擇行動電話與行動上網服務>行動電話問題>選擇國際簡訊問題
        } else if (this.state.obstaclesContent.problemCategory == 'MPBC005') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0003 = true;
          newShowForm.moc0004 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0014 = true;
          newShowForm.moc0038 = true;
          newShowForm.moc0039 = true;
          newShowForm.moc0040 = true;

          // 6.選擇行動電話與行動上網服務>行動電話問題>選擇漫遊相關問題
        } else if (this.state.obstaclesContent.problemCategory == 'MPBC006') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0003 = true;
          newShowForm.moc0004 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0007 = true;
          newShowForm.moc0020 = true;
          newShowForm.moc0041 = true;

          // 7.選擇行動電話與行動上網服務>行動電話問題>選擇MMS相關問題 or 國內簡訊/USSD相關問題
        } else if (
          this.state.obstaclesContent.problemCategory == 'MPBC007' ||
          this.state.obstaclesContent.problemCategory == 'MPBC008'
        ) {
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0003 = true;
          newShowForm.moc0004 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0007 = true;
          newShowForm.moc0042 = true;
          newShowForm.moc0043 = true;

          // 8.選擇行動電話與行動上網服務>行動電話問題>選擇其他加值服務相關問題
        } else if (this.state.obstaclesContent.problemCategory == 'MPBC009') {
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0007 = true;
          newShowForm.moc0015 = true;
        }

        //選擇行動電話與行動上網服務>選擇GPRS連線問題
      } else if (this.state.obstaclesContent.serviceType == 'MST002') {
        // 1.選擇行動電話與行動上網服務>選擇GPRS連線問題>GPRS訊號相關問題
        if (this.state.obstaclesContent.problemCategory == 'MPBC010') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0007 = true;
          newShowForm.moc0016 = true;
          newShowForm.moc0017 = true;

          // 2.選擇行動電話與行動上網服務>選擇GPRS連線問題>手機無法下載 , 訊息錯誤相關問題
        } else if (this.state.obstaclesContent.problemCategory == 'MPBC011') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0006 = true;
          newShowForm.moc0017 = true;
          newShowForm.moc0018 = true;
          newShowForm.moc0019 = true;

          // 3.選擇行動電話與行動上網服務>選擇GPRS連線問題>漫遊時使用GPRS問題
        } else if (this.state.obstaclesContent.problemCategory == 'MPBC012') {
          processingUnit = 'F1';
          newShowForm.moc0001 = true;
          newShowForm.moc0002 = true;
          newShowForm.moc0005 = true;
          newShowForm.moc0007 = true;
          newShowForm.moc0016 = true;
          newShowForm.moc0020 = true;
          newShowForm.moc0021 = true;
          newShowForm.moc0022 = true;
          newShowForm.moc0023 = true;
        }

        //選擇行動電話與行動上網服務>MVPN+VoIP相關問題
      } else if (this.state.obstaclesContent.serviceType == 'MST003') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0003 = true;
        newShowForm.moc0004 = true;
        newShowForm.moc0024 = true;
        //選擇行動電話與行動上網服務>MVPN相關問題
      } else if (this.state.obstaclesContent.serviceType == 'MST004') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0003 = true;
        newShowForm.moc0004 = true;
        newShowForm.moc0007 = true;
        newShowForm.moc0024 = true;
        newShowForm.moc0025 = true;
        //選擇行動電話與行動上網服務>任意郵3.0╱任意郵+相關問題
      } else if (this.state.obstaclesContent.serviceType == 'MST005') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0024 = true;
        newShowForm.moc0026 = true;
        newShowForm.moc0027 = true;
        newShowForm.moc0028 = true;
        newShowForm.moc0029 = true;

        //選擇行動電話與行動上網服務>企業客戶GPRS問題
      } else if (this.state.obstaclesContent.serviceType == 'MST006') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0024 = true;
        newShowForm.moc0027 = true;
        newShowForm.moc0028 = true;
        newShowForm.moc0029 = true;
        //選擇行動電話與行動上網服務>企業客戶資料分流計算問題
      } else if (this.state.obstaclesContent.serviceType == 'MST007') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0024 = true;
        newShowForm.moc0027 = true;
        newShowForm.moc0029 = true;
        newShowForm.moc0030 = true;
        //選擇行動電話與行動上網服務>企業整合簡訊(SMS/MIoD)
      } else if (this.state.obstaclesContent.serviceType == 'MST008') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0024 = true;
        newShowForm.moc0031 = true;
        newShowForm.moc0032 = true;
        newShowForm.moc0033 = true;
        //選擇行動電話與行動上網服務>企業簡訊服務網路版(DB3)
      } else if (this.state.obstaclesContent.serviceType == 'MST009') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0024 = true;
        newShowForm.moc0032 = true;
        newShowForm.moc0033 = true;

        //選擇行動電話與行動上網服務>車訊速/刷訊速
      } else if (this.state.obstaclesContent.serviceType == 'MST010') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0024 = true;
        newShowForm.moc0031 = true;
        //選擇行動電話與行動上網服務>行動節費器(MT) 相關問題
      } else if (this.state.obstaclesContent.serviceType == 'MST011') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
        newShowForm.moc0024 = true;
        newShowForm.moc0034 = true;
        newShowForm.moc0035 = true;
        newShowForm.moc0036 = true;
        newShowForm.moc0037 = true;
        //選擇行動電話與行動上網服務>未分類/其他問題
      } else if (this.state.obstaclesContent.serviceType == 'MST000') {
        processingUnit = 'F1';
        newShowForm.moc0001 = true;
      }

      // 固網語音服務
    } else if (this.state.obstaclesContent.productCategory == 'FPDC') {
      // 固網語音服務 > RUNK or 市內電話服務
      if (this.state.obstaclesContent.serviceType == 'FST001' || this.state.obstaclesContent.serviceType == 'FST002') {
        processingUnit = 'F5';
        newShowForm.foc0001 = true;
        newShowForm.foc0002 = true;
        newShowForm.foc0003 = true;
        newShowForm.foc0004 = true;
        newShowForm.foc0005 = true;

        // 固網語音服務 > 新樸網路電話服務 or FET 070 11碼障礙
      } else if (
        this.state.obstaclesContent.serviceType == 'FST003' ||
        this.state.obstaclesContent.serviceType == 'FST005'
      ) {
        processingUnit = 'F5';
        newShowForm.foc0001 = true;
        newShowForm.foc0002 = true;
        newShowForm.foc0003 = true;
        newShowForm.foc0004 = true;
        newShowForm.foc0005 = true;
        newShowForm.foc0006 = true;

        //  固網語音服務 > 軟體網路電話 Wagaly Show
      } else if (this.state.obstaclesContent.serviceType == 'FST004') {
        processingUnit = 'F5';
        newShowForm.foc0001 = true;
        newShowForm.foc0002 = true;
        newShowForm.foc0003 = true;
        newShowForm.foc0004 = true;
        newShowForm.foc0005 = true;
        newShowForm.foc0006 = true;
        newShowForm.foc0007 = true;
        newShowForm.foc0008 = true;
        newShowForm.foc0009 = true;

        // 固網語音服務 > FET 070 9碼障礙
      } else if (this.state.obstaclesContent.serviceType == 'FST006') {
        processingUnit = 'F6';
        newShowForm.foc0001 = true;
        newShowForm.foc0002 = true;
        newShowForm.foc0003 = true;
        newShowForm.foc0004 = true;
        newShowForm.foc0005 = true;
        newShowForm.foc0006 = true;

        // 固網語音服務 > 未分類/其他問題
      } else if (this.state.obstaclesContent.serviceType == 'FST000') {
        processingUnit = 'F1';
        newShowForm.foc0005 = true;
      }

      //數據與網路應用服務
    } else if (this.state.obstaclesContent.productCategory == 'NPDC') {
      // 數據與網路應用服務 > 速博網ADSL(網內)
      if (this.state.obstaclesContent.serviceType == 'NST001') {
        alert('此選項適用於新世紀資通電路用戶');

        processingUnit = 'F5';
        newShowForm.noc0001 = true;
        newShowForm.noc0002 = true;
        newShowForm.noc0003 = true;
        newShowForm.noc0006 = true;
        newShowForm.noc0018 = true;

        // 數據與網路應用服務 > 速博網ADSL(網外)
      } else if (this.state.obstaclesContent.serviceType == 'NST002') {
        alert('此選項適用於非新世紀資通電路用戶');

        processingUnit = 'F5';
        newShowForm.noc0001 = true;
        newShowForm.noc0002 = true;
        newShowForm.noc0003 = true;
        newShowForm.noc0006 = true;
        newShowForm.noc0018 = true;

        // 數據與網路應用服務 > Seednet固定制ADSL or Seednet企業數位光纖
      } else if (
        this.state.obstaclesContent.serviceType == 'NST003' ||
        this.state.obstaclesContent.serviceType == 'NST004'
      ) {
        processingUnit = 'F6';
        newShowForm.noc0001 = true;
        newShowForm.noc0002 = true;
        newShowForm.noc0003 = true;
        newShowForm.noc0006 = true;
        newShowForm.noc0018 = true;

        //數據與網路應用服務 > 國內數據專線 or 國際數據專線
      } else if (
        this.state.obstaclesContent.serviceType == 'NST005' ||
        this.state.obstaclesContent.serviceType == 'NST006'
      ) {
        processingUnit = 'F5';
        newShowForm.noc0003 = true;
        newShowForm.noc0004 = true;
        newShowForm.noc0005 = true;
        newShowForm.noc0018 = true;

        // 數據與網路應用服務 > ATM Network Hosting or 企業虛擬網路 IPVPN or 國際企業虛擬網路 IIPVPN
      } else if (
        this.state.obstaclesContent.serviceType == 'NST007' ||
        this.state.obstaclesContent.serviceType == 'NST008' ||
        this.state.obstaclesContent.serviceType == 'NST009'
      ) {
        processingUnit = 'F5';
        newShowForm.noc0003 = true;
        newShowForm.noc0006 = true;
        newShowForm.noc0018 = true;
        newShowForm.noc0019 = true;

        //數據與網路應用服務 > MPLS VPN 線路障礙問題 or MPLS VPN 金流障礙問題
      } else if (
        this.state.obstaclesContent.serviceType == 'NST010' ||
        this.state.obstaclesContent.serviceType == 'NST011'
      ) {
        processingUnit = 'F6';
        newShowForm.noc0003 = true;
        newShowForm.noc0006 = true;
        newShowForm.noc0018 = true;
        newShowForm.noc0019 = true;

        //數據與網路應用服務 > SSL VPN 障礙問題
      } else if (this.state.obstaclesContent.serviceType == 'NST012') {
        processingUnit = 'F6';
        newShowForm.noc0003 = true;
        newShowForm.noc0008 = true;
        newShowForm.noc0009 = true;
        newShowForm.noc0018 = true;

        //數據與網路應用服務 > 主機代管(IDC)障礙
      } else if (this.state.obstaclesContent.serviceType == 'NST013') {
        processingUnit = 'F6';
        newShowForm.noc0003 = true;
        newShowForm.noc0010 = true;
        newShowForm.noc0018 = true;

        //數據與網路應用服務 > E-Mail / Vmail 障礙申告
      } else if (this.state.obstaclesContent.serviceType == 'NST014') {
        processingUnit = 'F6';
        newShowForm.noc0003 = true;
        newShowForm.noc0011 = true;
        newShowForm.noc0012 = true;
        newShowForm.noc0013 = true;
        newShowForm.noc0014 = true;
        newShowForm.noc0018 = true;

        //數據與網路應用服務 > 域名障礙申告
      } else if (this.state.obstaclesContent.serviceType == 'NST015') {
        processingUnit = 'F6';
        newShowForm.noc0003 = true;
        newShowForm.noc0009 = true;
        newShowForm.noc0015 = true;
        newShowForm.noc0018 = true;

        //數據與網路應用服務 > 網站代管障礙申告
      } else if (this.state.obstaclesContent.serviceType == 'NST016') {
        processingUnit = 'F6';
        newShowForm.noc0003 = true;
        newShowForm.noc0016 = true;
        newShowForm.noc0017 = true;
        newShowForm.noc0018 = true;

        //數據與網路應用服務 > IP-Transit
      } else if (this.state.obstaclesContent.serviceType == 'NST017') {
        processingUnit = 'F6';
        newShowForm.noc0003 = true;
        newShowForm.noc0004 = true;
        newShowForm.noc0005 = true;
        newShowForm.noc0018 = true;

        //數據與網路應用服務 > 未分類/其他問題
      } else if (this.state.obstaclesContent.serviceType == 'NST000') {
        processingUnit = 'F1';
        newShowForm.noc0018 = true;
      }
    }

    this.setState({
      processingUnit: processingUnit,
      showForm: newShowForm,
    });
  };

  //取得年份清單
  getYearList(year) {
    let yearList = [];

    for (let i = 0; i < 2; i++) {
      yearList.push({ value: (year - i).toString(), text: (year - i).toString() });
    }
    return yearList;
  }
  //取得月份清單
  getMonthList() {
    let monthList = [];
    for (let i = 1; i < 13; i++) {
      // 月份小於10前面補0
      i = i < 10 ? '0' + i : i;
      monthList.push({ value: i.toString(), text: i.toString() });
    }
    return monthList;
  }
  //取得日期清單
  getDayList(year, month) {
    let dayList = [];

    // 判斷日期天數
    let lastDay = new Date(year, month, 0);
    let day = lastDay.getDate();
    // 取出該年分該月的最後一天日期

    // 將日期加進dayList中
    for (let i = 1; i < day + 1; i++) {
      i = i < 10 ? '0' + i : i;
      dayList.push({ value: i.toString(), text: i.toString() });
    }

    return dayList;
  }

  //取得時間清單
  getHoursList() {
    let hoursList = [];
    let hours;
    for (let i = 1; i < 25; i++) {
      if (i < 10) {
        hours = '0' + i.toString();
      } else {
        hours = i.toString();
      }
      hoursList.push({ value: hours, text: hours });
    }
    return hoursList;
  }

  //取得分清單
  getMinsList() {
    let minsList = [];
    for (let i = 0; i < 60; i++) {
      // 先將00塞進去
      // 只取10分鐘為單位的分鐘數  10/20/30/40/50
      if (i == 0 || i % 10 == 0) {
        if (i == 0) {
          minsList.push({ value: '00', text: '00' });
        } else {
          minsList.push({ value: i.toString(), text: i.toString() });
        }
      }
    }
    return minsList;
  }

  resetMsg = (name) => {
    let newErrMsg = Object.assign(this.state.errMsg);

    newErrMsg[name] = '';

    this.setState({ errMsg: newErrMsg });
  };

  /*  驗證傳入參數*/
  validInputData = () => {
    let newErrMsg = Object.assign(this.state.errMsg);
    let isValid = true;

    // 驗證主要聯絡人電話
    if (validation.notEmpty(this.state.mainContact.phoneLocal)) {
      if (!validation.phone(this.state.mainContact.phoneLocal)) {
        newErrMsg.mainPhoneLocal = '電話格式錯誤請重新輸入';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('mainPhoneLocal');
      }
    } else {
      newErrMsg.mainPhoneLocal = '請輸入電話號碼';
      isValid = false;
    }

    // 驗證主要聯絡人手機
    if (validation.notEmpty(this.state.mainContact.mobile)) {
      if (!validation.mobile(this.state.mainContact.mobile)) {
        newErrMsg.mainMobile = '手機格式錯誤請重新輸入';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('mainMobile');
      }
    } else {
      newErrMsg.mainMobile = '請輸入手機號碼';
      isValid = false;
    }

    // 驗證主要聯絡人Email
    if (validation.notEmpty(this.state.mainContact.email)) {
      if (!validation.email(this.state.mainContact.email)) {
        newErrMsg.mainEmail = 'Email格式錯誤請重新輸入';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('mainEmail');
      }
    } else {
      newErrMsg.mainEmail = '請輸入Email信箱';
      isValid = false;
    }

    // 若有勾選其他聯絡人
    if (this.state.contactOthers) {
      if (validation.isEmpty(this.state.secondContact.name)) {
        newErrMsg.secondName = '請輸入第一聯絡人姓名';
        isValid = false;
      }
      // 驗證第一聯絡人電話
      if (validation.notEmpty(this.state.secondContact.phoneLocal)) {
        if (!validation.phone(this.state.secondContact.phoneLocal)) {
          newErrMsg.secondPhoneLocal = '電話格式錯誤請重新輸入';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('secondPhoneLocal');
        }
      } else {
        newErrMsg.secondPhoneLocal = '請輸入電話號碼';
        isValid = false;
      }

      // 驗證第一聯絡人手機
      if (validation.notEmpty(this.state.secondContact.mobile)) {
        if (!validation.mobile(this.state.secondContact.mobile)) {
          newErrMsg.secondMobile = '手機格式錯誤請重新輸入';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('secondMobile');
        }
      } else {
        newErrMsg.secondMobile = '請輸入手機號碼';
        isValid = false;
      }

      // 驗證第一聯絡人Email
      if (validation.notEmpty(this.state.secondContact.email)) {
        if (!validation.email(this.state.secondContact.email)) {
          newErrMsg.secondEmail = 'Email格式錯誤請重新輸入';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('secondEmail');
        }
      } else {
        newErrMsg.secondEmail = '請輸入Email信箱';
        isValid = false;
      }

      // 若有填寫第二聯絡人資訊
      if (validation.notEmpty(this.state.thirdContact.name)) {
        // 驗證第二聯絡人電話
        if (validation.notEmpty(this.state.thirdContact.phoneLocal)) {
          if (!validation.phone(this.state.thirdContact.phoneLocal)) {
            newErrMsg.thirdPhoneLocal = '電話格式錯誤請重新輸入';
            isValid = false;
          } else {
            // 驗證成功則將錯誤訊息拿掉
            this.resetMsg('thirdPhoneLocal');
          }
        } else {
          newErrMsg.thirdPhoneLocal = '請輸入電話號碼';
          isValid = false;
        }

        // 驗證第二聯絡人手機
        if (validation.notEmpty(this.state.thirdContact.mobile)) {
          if (!validation.mobile(this.state.thirdContact.mobile)) {
            newErrMsg.thirdMobile = '手機格式錯誤請重新輸入';
            isValid = false;
          } else {
            // 驗證成功則將錯誤訊息拿掉
            this.resetMsg('thirdMobile');
          }
        } else {
          newErrMsg.thirdMobile = '請輸入手機號碼';
          isValid = false;
        }

        // 驗證第二聯絡人Email
        if (validation.notEmpty(this.state.thirdContact.email)) {
          if (!validation.email(this.state.thirdContact.email)) {
            newErrMsg.thirdEmail = 'Email格式錯誤請重新輸入';
            isValid = false;
          } else {
            // 驗證成功則將錯誤訊息拿掉
            this.resetMsg('thirdEmail');
          }
        } else {
          newErrMsg.thirdEmail = '請輸入Email信箱';
          isValid = false;
        }
      }
    }

    // 驗證障礙申告內容
    if (validation.isEmpty(this.state.obstaclesContent.productCategory)) {
      newErrMsg.productCategory = '請選擇障礙申告產品分類';
      isValid = false;
    } else {
      // 驗證成功則將錯誤訊息拿掉
      this.resetMsg('productCategory');
    }

    if (validation.isEmpty(this.state.obstaclesContent.serviceType)) {
      newErrMsg.serviceType = '請選擇障礙申告服務類別';
      isValid = false;
    } else {
      // 驗證成功則將錯誤訊息拿掉
      this.resetMsg('serviceType');
    }

    if (this.state.obstaclesContent.serviceType == 'MST001' || this.state.obstaclesContent.serviceType == 'MST002') {
      if (validation.isEmpty(this.state.obstaclesContent.problemCategory)) {
        newErrMsg.problemCategory = '請選擇障礙申告問題類別';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('problemCategory');
      }
    }
    // 障礙狀況
    if (this.state.showForm.moc0001) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0001)) {
        newErrMsg.moc0001 = '請輸入障礙狀況/其他補充';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0001');
      }
    }
    // 手機號碼
    if (this.state.showForm.moc0002) {
      if (validation.notEmpty(this.state.obstaclesContent.moc0002)) {
        if (!validation.mobile(this.state.obstaclesContent.moc0002)) {
          newErrMsg.moc0002 = '手機號碼格式錯誤';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('moc0002');
        }
      } else {
        newErrMsg.moc0002 = '請輸入手機號碼';
        isValid = false;
      }
    }
    // 發話方電話號碼
    if (this.state.showForm.moc0003) {
      if (validation.notEmpty(this.state.obstaclesContent.moc0003)) {
        if (!validation.phone(this.state.obstaclesContent.moc0003)) {
          newErrMsg.moc0003 = '發話方電話號碼格式錯誤';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('moc0003');
        }
      } else {
        newErrMsg.moc0003 = '請輸入發話方電話號碼';
        isValid = false;
      }
    }

    // 受話方電話號碼
    if (this.state.showForm.moc0004) {
      if (validation.notEmpty(this.state.obstaclesContent.moc0004)) {
        if (!validation.phone(this.state.obstaclesContent.moc0004)) {
          newErrMsg.moc0004 = '受話方電話號碼格式錯誤';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('moc0004');
        }
      } else {
        newErrMsg.moc0004 = '請輸入受話方號碼';
        isValid = false;
      }
    }

    // 手機廠牌
    if (this.state.showForm.moc0005) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0006)) {
        newErrMsg.moc0005 = '請輸入手機廠牌';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0005');
      }
    }

    // 手機型號
    if (this.state.showForm.moc0006) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0006)) {
        newErrMsg.moc0006 = '請輸入手機型號';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0006');
      }
    }

    // 手機格數
    if (this.state.showForm.moc0007) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0007)) {
        newErrMsg.moc0007 = '請選擇「手機格數」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0007');
      }
    }

    // 是否願意讓工程師進入屋內測試
    if (this.state.showForm.moc0008) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0008)) {
        newErrMsg.moc0008 = '請選擇「是否願意讓工程師進入屋內測試」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0008');
      }
    }

    // 發生區域之實際地址或地標
    if (this.state.showForm.moc0009) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0009)) {
        newErrMsg.moc0009 = '請選擇「發生區域之實際地址或地標」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0009');
      }
    }

    // 是否願意提供加設基地台的地點
    if (this.state.showForm.moc0010) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0010)) {
        newErrMsg.moc0010 = '請選擇「是否願意提供加設基地台的地點」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0010');
      }
    }

    // 同一地點是否其他遠傳客戶有同樣情況？
    if (this.state.showForm.moc0011) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0011)) {
        newErrMsg.moc0011 = '請選擇「同一地點是否其他遠傳客戶有同樣情況？」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0011');
      }
    }

    // 撥打國家名稱
    if (this.state.showForm.moc0012) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0012)) {
        newErrMsg.moc0012 = '請輸入撥打國家名稱';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0012');
      }
    }
    // 一般收發話是否正常
    if (this.state.showForm.moc0013) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0013)) {
        newErrMsg.moc0013 = '請選擇「一般收發話是否正常」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0013');
      }
    }

    // 撥打之固網國際撥號冠碼
    if (this.state.showForm.moc0014) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0014)) {
        newErrMsg.moc0014 = '請選擇「撥打之固網國際撥號冠碼」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0014');
      }
    }

    // 加值服務名稱
    if (this.state.showForm.moc0015) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0015)) {
        newErrMsg.moc0015 = '請輸入加值服務名稱';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0015');
      }
    }

    // 手機是否有出現GPRS訊號或字樣
    if (this.state.showForm.moc0016) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0016)) {
        newErrMsg.moc0016 = '請選擇「手機是否有出現GPRS訊號或字樣」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0016');
      }
    }

    // 錯誤訊息提示
    if (this.state.showForm.moc0017) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0017)) {
        newErrMsg.moc0017 = '請輸入錯誤訊息提示';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0017');
      }
    }

    // 撥接網站
    if (this.state.showForm.moc0018) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0018)) {
        newErrMsg.moc0018 = '請選擇「撥接網站」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0018');
      }
    }

    // 無法進入或出現的錯誤訊息
    if (this.state.showForm.moc0019) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0019)) {
        newErrMsg.moc0019 = '請輸入無法進入或出現的錯誤訊息';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0019');
      }
    }

    // 漫遊時註冊網路業者(和信、遠傳)
    if (this.state.showForm.moc0020) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0020)) {
        newErrMsg.moc0020 = '請輸入漫遊時註冊網路業者(和信、遠傳)';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0020');
      }
    }

    // 漫遊國家
    if (this.state.showForm.moc0021) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0021)) {
        newErrMsg.moc0021 = '請輸入漫遊國家';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0021');
      }
    }

    // 漫遊時收發話是否正常
    if (this.state.showForm.moc0022) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0022)) {
        newErrMsg.moc0022 = '請選擇「漫遊時收發話是否正常」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0022');
      }
    }

    // 漫遊時連上GPRS出現的錯誤訊息提示
    if (this.state.showForm.moc0023) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0023)) {
        newErrMsg.moc0023 = '請輸入漫遊時連上GPRS出現的錯誤訊息提示';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0023');
      }
    }

    // 手機門號/統一編號
    if (this.state.showForm.moc0024) {
      if (validation.notEmpty(this.state.obstaclesContent.moc0024)) {
        if (
          !validation.mobile(this.state.obstaclesContent.moc0024) &&
          !validation.comId(this.state.obstaclesContent.moc0024)
        ) {
          newErrMsg.moc0024 = '手機門號/統一編號格式錯誤請重新輸入';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('moc0024');
        }
      } else {
        newErrMsg.moc0024 = '請輸入手機門號/統一編號';
        isValid = false;
      }
    }

    // 發話方為桌機或手機
    if (this.state.showForm.moc0025) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0025)) {
        newErrMsg.moc0025 = '請選擇「發話方為桌機或手機」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0025');
      }
    }

    // 任意郵+版本
    if (this.state.showForm.moc0026) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0026)) {
        newErrMsg.moc0026 = '請選擇「任意郵+版本」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0026');
      }
    }

    // 連線設備(廠牌/型號)
    if (this.state.showForm.moc0027) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0027)) {
        newErrMsg.moc0027 = '請輸入連線設備(廠牌/型號)';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0027');
      }
    }

    // GPRS/3G連線門號
    if (this.state.showForm.moc0028) {
      if (validation.notEmpty(this.state.obstaclesContent.moc0028)) {
        if (!validation.digit(this.state.obstaclesContent.moc0028)) {
          newErrMsg.moc0028 = 'GPRS/3G連線門號需為數字';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('moc0028');
        }
      } else {
        newErrMsg.moc0028 = '請輸入GPRS/3G連線門號';
        isValid = false;
      }
    }

    // APN設定
    if (this.state.showForm.moc0029) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0029)) {
        newErrMsg.moc0029 = '請輸入APN設定';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0029');
      }
    }

    // 欲查詢時間
    if (this.state.showForm.moc0029) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0029)) {
        newErrMsg.moc0029 = '請輸入欲查詢時間';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0029');
      }
    }

    // 使用產品
    if (this.state.showForm.moc0031) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0031)) {
        newErrMsg.moc0031 = '請選擇「使用產品」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0031');
      }
    }

    // 發訊人代碼
    if (this.state.showForm.moc0032) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0032)) {
        newErrMsg.moc0032 = '請輸入發訊人代碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0032');
      }
    }

    // 接收簡訊之手機門號
    if (this.state.showForm.moc0033) {
      if (validation.notEmpty(this.state.obstaclesContent.moc0033)) {
        if (!validation.mobile(this.state.obstaclesContent.moc0033)) {
          newErrMsg.moc0033 = '手機門號格式錯誤';
          isValid = false;
        } else {
          // 驗證成功則將錯誤訊息拿掉
          this.resetMsg('moc0033');
        }
      } else {
        newErrMsg.moc0033 = '請輸入接收簡訊之手機門號';
        isValid = false;
      }
    }

    // 客戶名稱
    if (this.state.showForm.moc0034) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0034)) {
        newErrMsg.moc0034 = '請輸入客戶名稱';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0034');
      }
    }

    // 聯絡人/聯絡電話
    if (this.state.showForm.moc0035) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0035)) {
        newErrMsg.moc0034 = '請輸入聯絡人/聯絡電話';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0035');
      }
    }
    // 節費器電話號碼
    if (this.state.showForm.moc0036) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0036)) {
        newErrMsg.moc0036 = '請輸入節費器電話號碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0036');
      }
    }

    // 節費器安裝地址
    if (this.state.showForm.moc0037) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0037)) {
        newErrMsg.moc0037 = '請輸入節費器安裝地址';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0037');
      }
    }

    // 傳送國家名稱
    if (this.state.showForm.moc0038) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0038)) {
        newErrMsg.moc0038 = '請輸入傳送國家名稱';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0038');
      }
    }

    // 傳送國家名稱
    if (this.state.showForm.moc0039) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0039)) {
        newErrMsg.moc0039 = '請輸入簡訊中心代碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0039');
      }
    }

    // 簡訊發送後的提示
    if (this.state.showForm.moc0040) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0040)) {
        newErrMsg.moc0040 = '請輸入簡訊發送後的提示';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0040');
      }
    }
    // 所聽到語音訊息
    if (this.state.showForm.moc0041) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0041)) {
        newErrMsg.moc0041 = '請輸入所聽到語音訊息';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0041');
      }
    }

    // 接收容量信封是否閃爍
    if (this.state.showForm.moc0042) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0042)) {
        newErrMsg.moc0042 = '請選擇「接收容量信封是否閃爍」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0042');
      }
    }

    // 發送後所出現訊息或錯誤訊息
    if (this.state.showForm.moc0043) {
      if (validation.isEmpty(this.state.obstaclesContent.moc0043)) {
        newErrMsg.moc0043 = '請輸入發送後所出現訊息或錯誤訊息';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('moc0043');
      }
    }

    // 線路號碼(Seednet客戶請填客戶編號)
    if (this.state.showForm.noc0006) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0006)) {
        newErrMsg.noc0006 = '請輸入線路號碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0006');
      }
    }

    // IP位址及裝機地址
    if (this.state.showForm.noc0001) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0001)) {
        newErrMsg.noc0001 = '請輸入IP位址及裝機地址';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0001');
      }
    }

    // 數據機燈號
    if (this.state.showForm.noc0002) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0002)) {
        newErrMsg.noc0002 = '請輸入數據機燈號';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0002');
      }
    }

    // 專線編號
    if (this.state.showForm.noc0004) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0004)) {
        newErrMsg.noc0004 = '請輸入專線編號';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0004');
      }
    }

    // 裝機地址
    if (this.state.showForm.noc0005) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0005)) {
        newErrMsg.noc0005 = '請輸入裝機地址';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0005');
      }
    }

    // 障礙發生時間
    if (this.state.showForm.noc0003) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0003)) {
        newErrMsg.noc0003 = '請選擇障礙發生時間';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0003');
      }
    }

    // 卡機錯誤訊息
    if (this.state.showForm.noc0007) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0007)) {
        newErrMsg.noc0007 = '請輸入卡機錯誤訊息';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0007');
      }
    }

    // 登入網址/帳號/密碼
    if (this.state.showForm.noc0008) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0008)) {
        newErrMsg.noc0008 = '請輸入登入網址/帳號/密碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0008');
      }
    }

    // 錯誤訊息
    if (this.state.showForm.noc0009) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0009)) {
        newErrMsg.noc0009 = '請輸入錯誤訊息';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0009');
      }
    }

    // 所在機房
    if (this.state.showForm.noc0010) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0010)) {
        newErrMsg.noc0010 = '請輸入所在機房';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0010');
      }
    }

    // 發信帳號
    if (this.state.showForm.noc0011) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0011)) {
        newErrMsg.noc0011 = '請輸入發信帳號';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0011');
      }
    }

    // 收信帳號
    if (this.state.showForm.noc0012) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0012)) {
        newErrMsg.noc0012 = '請輸入收信帳號';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0012');
      }
    }
    // 錯誤訊息
    if (this.state.showForm.noc0013) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0013)) {
        newErrMsg.noc0013 = '請輸入錯誤訊息';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0013');
      }
    }

    // POP3及SMTP似服器設定
    if (this.state.showForm.noc0014) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0014)) {
        newErrMsg.noc0014 = '請輸入POP3及SMTP似服器設定';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0014');
      }
    }

    // 域名帳號
    if (this.state.showForm.noc0015) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0015)) {
        newErrMsg.noc0015 = '請輸入域名帳號';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0015');
      }
    }

    // 域名帳號/網址
    if (this.state.showForm.noc0016) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0016)) {
        newErrMsg.noc0016 = '請輸入域名帳號/網址';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0016');
      }
    }

    // 網頁錯誤訊息
    if (this.state.showForm.noc0017) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0017)) {
        newErrMsg.noc0017 = '請輸入網頁錯誤訊息';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0017');
      }
    }

    // 裝機地址
    if (this.state.showForm.noc0019) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0019)) {
        newErrMsg.noc0019 = '請輸入裝機地址';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0019');
      }
    }

    // 障礙狀況/其他補充
    if (this.state.showForm.noc0018) {
      if (validation.isEmpty(this.state.obstaclesContent.noc0018)) {
        newErrMsg.noc0018 = '請輸入障礙狀況/其他補充';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('noc0018');
      }
    }

    // 發話方號碼
    if (this.state.showForm.foc0001) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0001)) {
        newErrMsg.foc0001 = '請輸入發話方號碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0001');
      }
    }

    // 受話方號碼
    if (this.state.showForm.foc0002) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0002)) {
        newErrMsg.foc0002 = '請輸入受話方號碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0002');
      }
    }

    // 撥號時間
    if (this.state.showForm.foc0003) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0003)) {
        newErrMsg.foc0003 = '請選擇撥號時間';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0003');
      }
    }

    // 有無撥通過
    if (this.state.showForm.foc0004) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0004)) {
        newErrMsg.foc0004 = '請輸入有無撥通過';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0004');
      }
    }

    // 網路是否可正常連線
    if (this.state.showForm.foc0006) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0006)) {
        newErrMsg.foc0006 = '請選擇「撥號時間」';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0006');
      }
    }
    // 070電話號碼
    if (this.state.showForm.foc0007) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0007)) {
        newErrMsg.foc0007 = '請輸入070電話號碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0007');
      }
    }

    // 登入密碼
    if (this.state.showForm.foc0008) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0008)) {
        newErrMsg.foc0008 = '請輸入登入密碼';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0008');
      }
    }

    // 耳麥/Web Cam設備是否正常
    if (this.state.showForm.foc0009) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0009)) {
        newErrMsg.foc0009 = '請輸入耳麥/Web Cam設備是否正常';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0009');
      }
    }

    // 障礙狀況/其他補充
    if (this.state.showForm.foc0005) {
      if (validation.isEmpty(this.state.obstaclesContent.foc0005)) {
        newErrMsg.foc0005 = '請輸入障礙狀況/其他補充';
        isValid = false;
      } else {
        // 驗證成功則將錯誤訊息拿掉
        this.resetMsg('foc0005');
      }
    }

    // 將錯誤訊息放回去
    this.setState({
      errMsg: newErrMsg,
    });

    this.setState({ isValidate: isValid });

    return isValid;
  };

  submit = () => {
    if (this.validInputData()) {
      this.saveObstacles();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>線上申告</title>
        </Helmet>
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='障礙申告及查詢' history={this.props.history}></Sidebar>

              <div className='container bg-white col-9 shadow pl-4 pr-4'>
                <div className='title pt-2 mt-5 ml-3'>線上申告</div>
                <div className='h5 pl-3 ml-4 is-text-bold'>申請日期 : {this.state.date}</div>
                <div className='h5 pt-3 pl-3 ml-4 is-text-bold'>
                  {`姓名 :  ${this.state.accntName} ${this.state.gender}`}
                </div>
                <div className='h5 pt-3 pl-3 ml-4 mb-5 is-text-bold'>{`公司名稱 :  ${this.state.cmpName}`}</div>
                <Formsy className='pl-3 ml-4' onValid={this.validInputData}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={10}>
                      <LabelInput
                        className='ml-0'
                        name='phoneLocal'
                        value={this.state.mainContact.phoneLocal}
                        required={true}
                        placeholder='請輸入電話'
                        label='電話'
                        onChange={this.changeMainContact}
                        failResultMsg={this.state.errMsg.mainPhoneLocal}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={10}>
                      <LabelInput
                        className='ml-0'
                        name='mobile'
                        value={this.state.mainContact.mobile}
                        required={true}
                        placeholder='請輸入行動電話'
                        label='行動電話'
                        onChange={this.changeMainContact}
                        failResultMsg={this.state.errMsg.mainMobile}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={10}>
                      <LabelInput
                        className='ml-0'
                        name='email'
                        value={this.state.mainContact.email}
                        required={true}
                        placeholder='請輸入E-mail'
                        label='E-mail'
                        onChange={this.changeMainContact}
                        failResultMsg={this.state.errMsg.mainEmail}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={10} className='form-group'>
                      <label className='h5 ml-0 is-text-bold is-required'>希望聯絡方式</label>
                      <div className='ml-3 mb-4 radio-group'>
                        <div className='keep-all  mt-3'>
                          <input
                            type='radio'
                            value='01'
                            id='phoneType'
                            name='contactType'
                            className='radio-button'
                            defaultChecked
                            onChange={(e) => this.changeContentType(e.target.value)}
                          />
                          <label htmlFor='phoneType' className='h5'>
                            電話
                          </label>
                        </div>
                        <div className='keep-all  mt-3'>
                          <input
                            type='radio'
                            value='02'
                            id='mailType'
                            name='contactType'
                            className='radio-button'
                            onChange={(e) => this.changeContentType(e.target.value)}
                          />
                          <label htmlFor='mailType' className='h5'>
                            E-mail
                          </label>
                        </div>
                        <div className='keep-all  mt-3'>
                          <input
                            type='radio'
                            value='03'
                            id='msgType'
                            name='contactType'
                            className='radio-button'
                            onChange={(e) => this.changeContentType(e.target.value)}
                          />
                          <label htmlFor='msgType' className='h5'>
                            簡訊
                          </label>
                        </div>
                        <div className='keep-all  mt-3'>
                          <input
                            type='radio'
                            value='00'
                            id='allType'
                            name='contactType'
                            className='radio-button'
                            onChange={(e) => this.changeContentType(e.target.value)}
                          />
                          <label htmlFor='allType' className='h5'>
                            以上皆可(若勾選此項，將優先以電話通知)
                          </label>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={10} className='form-group'>
                      <label className='h5 ml-0 mb-4 is-text-bold is-required'>可聯絡時間</label>
                      <div className='row'>
                        <Dropdown
                          className='is-button col-5 pl-0'
                          list={this.state.availableTimeList}
                          label={this.state.showAvailableTime.text ? this.state.showAvailableTime.text : '不限時段'}
                          arrow={true}
                          hasCheck={false}
                          onChange={(e) => this.changeSelect('availableTime', e)}
                        />
                      </div>
                      {this.state.errMsg.availableTime}
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={10}>
                      <div className='ml-0'>
                        <input
                          type='checkbox'
                          id='contactOthers'
                          name='contactOthers'
                          value={this.state.contactOthers}
                          className='mr-2 mt-4 mb-4'
                          onChange={this.contactOthers}
                          checked={this.state.contactOthers}
                          onMouseDown={this.validInputData}></input>
                        <label htmlFor='contactOthers' className='checkbox-label'>
                          聯絡其他人
                        </label>
                      </div>
                      {this.state.contactOthers && (
                        <div className='mt-4'>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className='ml-0'
                                name='name'
                                value={this.state.secondContact.name}
                                required={true}
                                placeholder='請輸入欲申請的公司第一聯絡人姓名'
                                label='第一聯絡人姓名'
                                onChange={this.changeSecondContact}
                                failResultMsg={this.state.errMsg.secondName}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <label className='h5 ml-0 mb-4 is-text-bold is-required'>稱謂</label>
                              <div className='ml-2 mt-2 p-1'>
                                <input
                                  type='radio'
                                  value='先生'
                                  id='secondTitle'
                                  name='secondTitle'
                                  className='radio-button'
                                  defaultChecked
                                  onChange={(e) => this.changeSecondContact('title', e.target.value)}
                                />
                                <label htmlFor='先生'>先生</label>

                                <input
                                  type='radio'
                                  value='小姐'
                                  id='secondTitle'
                                  name='secondTitle'
                                  className='radio-button ml-3'
                                  onChange={(e) => this.changeSecondContact('title', e.target.value)}
                                />
                                <label htmlFor='小姐'>小姐</label>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className='ml-0'
                                name='phoneLocal'
                                required={true}
                                value={this.state.secondContact.phoneLocal}
                                placeholder='請輸入欲申請的公司第一聯絡人聯絡電話'
                                label='聯絡電話'
                                onChange={this.changeSecondContact}
                                failResultMsg={this.state.errMsg.secondPhoneLocal}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className='ml-0'
                                name='mobile'
                                required={true}
                                value={this.state.secondContact.mobile}
                                placeholder='請輸入欲申請的公司第一聯絡人行動電話'
                                label='行動電話'
                                onChange={this.changeSecondContact}
                                failResultMsg={this.state.errMsg.secondMobile}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className='ml-0'
                                name='email'
                                required={true}
                                value={this.state.secondContact.email}
                                placeholder='請輸入欲申請的公司第一聯絡人Email'
                                label='聯絡Email'
                                onChange={this.changeSecondContact}
                                failResultMsg={this.state.errMsg.secondEmail}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className='ml-0'
                                name='name'
                                value={this.state.thirdContact.name}
                                placeholder='請輸入欲申請的公司第二聯絡人姓名'
                                label='第二聯絡人姓名'
                                onChange={this.changeThirdContact}
                                failResultMsg={this.state.errMsg.thirdName}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <label className='h5 ml-0 mb-4 is-text-bold is-required'>稱謂</label>
                              <div className='ml-2 mt-2 p-1'>
                                <input
                                  type='radio'
                                  value='先生'
                                  id='thirdTitle'
                                  name='thirdTitle'
                                  className='radio-button'
                                  defaultChecked
                                  onChange={(e) => this.changeThirdContact('title', e.target.value)}
                                />
                                <label htmlFor='先生'>先生</label>

                                <input
                                  type='radio'
                                  value='小姐'
                                  id='thirdTitle'
                                  name='thirdTitle'
                                  className='radio-button ml-3'
                                  onChange={(e) => this.changeThirdContact('title', e.target.value)}
                                />
                                <label htmlFor='小姐'>小姐</label>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className='ml-0'
                                name='phoneLocal'
                                value={this.state.thirdContact.phoneLocal}
                                placeholder='請輸入欲申請的公司第二聯絡人聯絡電話'
                                label='聯絡電話'
                                onChange={this.changeThirdContact}
                                failResultMsg={this.state.errMsg.thirdPhoneLocal}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className='ml-0'
                                name='mobile'
                                value={this.state.thirdContact.mobile}
                                placeholder='請輸入欲申請的公司第二聯絡人行動電話'
                                label='行動電話'
                                onChange={this.changeThirdContact}
                                failResultMsg={this.state.errMsg.thirdMobile}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>
                            <Grid item xs={12} md={8}>
                              <LabelInput
                                className='ml-0'
                                name='email'
                                value={this.state.thirdContact.email}
                                placeholder='請輸入欲申請的公司第二聯絡人Email'
                                label='聯絡Email'
                                onChange={this.changeThirdContact}
                                failResultMsg={this.state.errMsg.thirdEmail}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={10} className='form-group'>
                      <label className='h5 ml-0 mb-4 is-text-bold is-required'>產品/服務類別</label>
                      <div className='row mb-5'>
                        <Dropdown
                          className='is-button col-5 pl-0'
                          list={this.state.productCategoryList}
                          name='productType'
                          label={this.state.showProductCategory.text ? this.state.showProductCategory.text : '請選擇'}
                          arrow={true}
                          hasCheck={false}
                          onChange={(e) => this.changeProductCategory(e)}
                        />
                        <Dropdown
                          className='is-button col-7 pl-3'
                          list={this.state.serviceTypeList}
                          label={this.state.showServiceType.text ? this.state.showServiceType.text : '請選擇'}
                          arrow={true}
                          hasCheck={false}
                          onChange={(e) => this.changeServiceType(e)}
                        />
                        <span className='error-message'>
                          {' '}
                          {validation.notEmpty(this.state.errMsg.productCategory)
                            ? this.state.errMsg.productCategory
                            : null}
                          {validation.notEmpty(this.state.errMsg.productCategory) &&
                          validation.notEmpty(this.state.errMsg.serviceType)
                            ? '/'
                            : null}
                          {validation.notEmpty(this.state.errMsg.serviceType) ? this.state.errMsg.serviceType : null}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  {/* 顯示「MPDC-行動電話與行動上網服務」輸入框畫面 */}
                  {this.state.showObstaclesForm == 'MPDC' && this.state.hasProblemCategory ? (
                    <div>
                      <MobileInternetForm
                        problemCategoryList={this.state.problemCategoryList}
                        hasObstacleType={this.state.hasObstacleType}
                        changeSelect={this.changeSelect}
                        changeObstaclesContent={this.changeObstaclesContent}
                        showProblemCategory={this.state.showProblemCategory}
                        obstaclesContent={this.state.obstaclesContent}
                        mocSelectList={this.state.mocSelectList}
                        showAddCellSite={this.state.showAddCellSite}
                        showMocForm={this.state.showMocForm}
                        showForm={this.state.showForm}
                        errMsg={this.state.errMsg}
                        validInputData={this.validInputData}></MobileInternetForm>
                    </div>
                  ) : null}
                  {/*顯示「FPDC-固網語音服務」輸入框畫面  */}
                  {this.state.showObstaclesForm == 'FPDC' ? (
                    <div>
                      <LandlineVoiceForm
                        changeObstaclesContent={this.changeObstaclesContent}
                        obstaclesContent={this.state.obstaclesContent}
                        networkNormalList={this.state.networkNormalList}
                        showFocForm={this.state.showFocForm}
                        showForm={this.state.showForm}
                        obstaclesTime={this.state.obstaclesTime}
                        changeDate={this.changeDate}
                        errMsg={this.state.errMsg}
                        validInputData={this.validInputData}></LandlineVoiceForm>
                    </div>
                  ) : null}
                  {/*顯示「NPDC-數據與網路應用服務」輸入框畫面  */}
                  {this.state.showObstaclesForm == 'NPDC' ? (
                    <div>
                      <DataNetworkForm
                        changeObstaclesContent={this.changeObstaclesContent}
                        validUserNo={this.validUserNo}
                        obstaclesContent={this.state.obstaclesContent}
                        showNocForm={this.state.showNocForm}
                        showForm={this.state.showForm}
                        errMsg={this.state.errMsg}
                        obstaclesTime={this.state.obstaclesTime}
                        changeDate={this.changeDate}
                        validInputData={this.validInputData}></DataNetworkForm>
                    </div>
                  ) : null}
                  <div className='mt-5 two-buttons mb-5'>
                    <Button btnStyle='primary' size='large' onClick={this.submit} disabled={!this.state.isValidate}>
                      送出
                    </Button>
                    <Button btnStyle='secondary' size='large' onClick={this.resetData}>
                      重填
                    </Button>
                  </div>
                </Formsy>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ObstacleApplyPage);
