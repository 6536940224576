import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';
import * as callApiUtil from '../../utils/callApiUtil';
import * as emptyUtil from '../../utils/emptyUtil';
import Pagination from '../../components/Pagination';
import Sidebar from '../../components/Sidebar';

class SysUsedRecordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchBar: false, //是否彈出搜尋欄位
      searchSubmit: false, //送出查詢條件與否
      accountTypeList: [
        { value: '1', text: '管理員' },
        { value: 'ALL', text: '管理員及子帳號' },
      ],
      accountType: '', //登入者帳號類別
      yearList: [],
      monthList: [],
      startDayList: [],
      endDayList: [],
      startYear: '', //查詢起始時間
      startMonth: '',
      startDay: '',
      endYear: '', //結束時間
      endMonth: '',
      endDay: '',
      form: {
        startYear: { value: '', text: '' }, //查詢起始時間
        startMonth: { value: '', text: '' },
        startDay: { value: '', text: '' },
        endYear: { value: '', text: '' }, //結束時間
        endMonth: { value: '', text: '' },
        endDay: { value: '', text: '' },
        userType: { value: '', text: '' }, //使用者帳號類別
      },
      searchResultList: [],
      errmsg: '',
      //分頁
      currentPage: 1,
      maxlength: 10, //一頁幾筆資料
      showMax: 6, //分頁欄顯示頁數
    };
  }

  //是否彈出搜尋欄位
  searchBar = () => {
    this.setState(() => ({
      searchBar: !this.state.searchBar,
    }));
  };

  //送出查詢條件(依條件抓回資料更新table)
  searchSubmit = () => {
    //組成API input Value
    let Vin = {
      startDate: new Date(
        this.state.form.startYear.value,
        this.state.form.startMonth.value - 1,
        this.state.form.startDay.value
      ),
      endDate: new Date(
        new Date(
          new Date(
            this.state.form.endYear.value,
            this.state.form.endMonth.value - 1,
            this.state.form.endDay.value
          ).toLocaleDateString()
        ).getTime() +
          24 * 60 * 60 * 1000 -
          1
      ),
      userType: this.state.form.userType.value, //使用者帳號類別
    };
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/CustomerUsedRecordController/queryUsedRecord.action', Vin).then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        this.setState(() => ({
          searchResultList: response.customerUseRecordList,
          currentPage: 1,
          isLoading: false,
        }));
      } else {
        //API回傳失敗
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: '系統使用紀錄查詢',
            title: '系統使用紀錄查詢',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回系統使用紀錄查詢頁',
                link: '/sysUsedRecordPage',
                btnStyle: 'primary',
              },
            ],
          }
        );
      }
    });
    this.searchBar();
    this.setState(() => ({
      searchSubmit: true,
    }));
  };

  onChange = (name, selected) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = selected.value;
    newForm[name].text = selected.text;
    let startDayList = this.state.startDayList;
    let endDayList = this.state.endDayList;
    // 2021/08/11 修改日期沒有隨月份變更問題
    if (name == 'startMonth') {
      startDayList = this.getDayList(this.state.form.startYear.value, selected.value);
      newForm['startDay'] = { value: '', text: '' };
      this.setState(() => ({
        startDayList: startDayList,
      }));
    } else if (name == 'endMonth') {
      endDayList = this.getDayList(this.state.form.endYear.value, selected.value);
      newForm['endDay'] = { value: '', text: '' };
      this.setState(() => ({
        endDayList: endDayList,
      }));
    } else if (name == 'startYear') {
      startDayList = this.getDayList(selected.value, this.state.form.startMonth.value);
      newForm['startDay'] = { value: '', text: '' };
      this.setState(() => ({
        startDayList: startDayList,
      }));
    } else if (name == 'endYear') {
      endDayList = this.getDayList(selected.value, this.state.form.endMonth.value);
      newForm['endDay'] = { value: '', text: '' };
      this.setState(() => ({
        endDayList: endDayList,
      }));
    }
    this.setState({ form: newForm, startDayList: startDayList, endDayList: endDayList });
  };
  //分頁換頁
  handleChangePage = (newPage) => {
    this.setState({ currentPage: newPage });
  };
  // 初始化表單資料
  resetData = () => {
    let newForm = {
      startYear: { value: this.state.startYear, text: this.state.startYear }, //查詢起始時間
      startMonth: { value: this.state.startMonth, text: this.state.startMonth },
      startDay: { value: this.state.startDay, text: this.state.startDay },

      endYear: { value: this.state.endYear, text: this.state.endYear }, //結束時間
      endMonth: { value: this.state.endMonth, text: this.state.endMonth },
      endDay: { value: this.state.endDay, text: this.state.endDay },

      userType: { value: '1', text: '管理員' }, //使用者帳號類別
    };
    this.setState({ form: newForm });
  };
  getYearList(thisYear) {
    let yearList = [];
    for (let i = 0; i < 3; i++) {
      yearList.push({ value: (thisYear - i).toString(), text: (thisYear - i).toString() });
    }
    return yearList;
  }
  getMonthList() {
    let monthList = [];
    for (let i = 1; i < 13; i++) {
      monthList.push({ value: i.toString(), text: i.toString() });
    }
    return monthList;
  }
  getDayList(year, month) {
    // 2021/08/11 修改日期沒有隨月份變更問題
    let dayList = [];
    let day = new Date(year, month, 0).getDate();
    for (let i = 1; i <= day; i++) {
      dayList.push({ value: i.toString(), text: i.toString() });
    }
    return dayList;
  }
  componentDidMount() {
    this.setState(() => ({ isLoading: true }));
    callApiUtil.callApi('/ECPAP/API/CustomerUsedRecordController/initUsedRecord.action').then((response) => {
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.message == 's'
      ) {
        //API回傳成功
        let startYear = new Date(response.startDate).getFullYear();
        let startMonth = new Date(response.startDate).getMonth() + 1;
        let startDay = new Date(response.startDate).getDate();
        let endYear = new Date(response.endDate).getFullYear();
        let endMonth = new Date(response.endDate).getMonth() + 1;
        let endDay = new Date(response.endDate).getDate();
        this.setState(() => ({
          isLoading: false,
          accountType: response.accntType, //使用者帳號類別
          yearList: this.getYearList(endYear),
          monthList: this.getMonthList(),
          startDayList: this.getDayList(startYear, startMonth),
          endDayList: this.getDayList(endYear, endMonth),
          startYear: startYear, //查詢起始時間
          startMonth: startMonth,
          startDay: startDay,
          endYear: endYear, //結束時間
          endMonth: endMonth,
          endDay: endDay,
          form: {
            startYear: { value: startYear, text: startYear }, //查詢起始時間
            startMonth: { value: startMonth, text: startMonth },
            startDay: { value: startDay, text: startDay },
            endYear: { value: endYear, text: endYear }, //結束時間
            endMonth: { value: endMonth, text: endMonth },
            endDay: { value: endDay, text: endDay },
            userType: { value: '1', text: '管理員' }, //使用者帳號類別
          },
        }));
      } else {
        //API回傳失敗
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: '系統使用紀錄查詢',
            title: '系統使用紀錄查詢',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回系統使用紀錄查詢頁',
                link: '/sysUsedRecordPage',
                btnStyle: 'primary',
              },
            ],
          }
        );
      }
    });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>系統使用紀錄查詢</title>
        </Helmet>
        <div className={this.state.isLoading ? 'is-loading' : ''} />

        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' history={this.props.history}></Sidebar>
              <div className='col-9 container bg-white shadow pl-4 pr-4'>
                <div className='title pt-2 mt-5 ml-3'>
                  系統使用紀錄查詢
                  <div className='h5 mt-4 is-text-bold text-black'>*本服務之查詢資料僅保留24個月內之資料</div>
                </div>
                {this.state.searchBar ? (
                  <div style={{ position: 'absolute', top: '50%', left: '90%' }}>
                    <button className='is-text-bold bg-white title no-border' onClick={this.searchBar}>
                      +
                    </button>
                  </div>
                ) : null}

                {!this.state.searchBar ? (
                  <Formsy className='pl-3 ml-4'>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10} className='form-group'>
                        <label className='h5 ml-0 mb-4 is-text-bold is-required'>查詢起始時間</label>
                        <div className='row h5 is-text-bold align-items-center'>
                          <Dropdown
                            className='is-button col-3 pl-0'
                            list={this.state.yearList}
                            label={this.state.form.startYear.value ? this.state.form.startYear.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('startYear', e)}
                          />
                          年
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.monthList}
                            label={this.state.form.startMonth.value ? this.state.form.startMonth.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('startMonth', e)}
                          />
                          月
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.startDayList}
                            label={this.state.form.startDay.value ? this.state.form.startDay.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('startDay', e)}
                          />
                          日
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10} className='form-group'>
                        <label className='h5 ml-0 mb-4 is-text-bold is-required'>結束時間</label>
                        <div className='row h5 is-text-bold align-items-center'>
                          <Dropdown
                            className='is-button col-3 pl-0'
                            list={this.state.yearList}
                            label={this.state.form.endYear.value ? this.state.form.endYear.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('endYear', e)}
                          />
                          年
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.monthList}
                            label={this.state.form.endMonth.value ? this.state.form.endMonth.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('endMonth', e)}
                          />
                          月
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.endDayList}
                            label={this.state.form.endDay.value ? this.state.form.endDay.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('endDay', e)}
                          />
                          日
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10}>
                        {this.state.accountType == '1' || this.state.accountType == '5' ? (
                          <Fragment>
                            <label className='h5 ml-0 mb-4 is-text-bold'>使用者帳號類別</label>
                            <div className='row  mb-5'>
                              <Dropdown
                                className='is-button col-5 pl-0'
                                list={this.state.accountTypeList}
                                label={this.state.form.userType.text ? this.state.form.userType.text : '<尚未確認新增>'}
                                arrow={true}
                                hasCheck={false}
                                onChange={(e) => this.onChange('userType', e)}
                              />
                            </div>
                          </Fragment>
                        ) : null}
                      </Grid>
                    </Grid>
                    <div className='two-buttons mb-5'>
                      <Button btnStyle='primary' size='large' onClick={this.searchSubmit}>
                        查詢
                      </Button>
                      <Button btnStyle='secondary' size='large' onClick={this.resetData}>
                        重填
                      </Button>
                    </div>
                  </Formsy>
                ) : null}
              </div>

              {/* 查詢結果 */}
              {this.state.searchSubmit ? (
                <React.Fragment>
                  <div className='col-3' style={{ 'margin-left': '-1%' }} />
                  <div className=' col-9 container bg-white shadow mt-5'>
                    <div className='pl-5 pr-5'>
                      <div className='title pt-2 mt-5'>查詢結果</div>
                      <table className='w-100 mb-5'>
                        <tr className='white bg-dropdown'>
                          <td className='h5 w-30 text-center border-gray'>使用者帳號</td>
                          <td className='h5 w-35 text-center border-gray'>系統使用日期時間</td>
                          <td className='h5 w-35 text-center border-gray'>登出日期時間</td>
                        </tr>
                        {this.state.searchResultList
                          .slice(
                            (this.state.currentPage - 1) * this.state.maxlength,
                            this.state.currentPage * this.state.maxlength
                          )
                          .map((item, idx) => (
                            <tr className='bg-white' key={'searchResultList' + idx}>
                              <td className='w-30 p-3 text-center border-gray'>{emptyUtil.checkVal(item.loginId)}</td>
                              <td className='w-35 p-3 text-center border-gray'>{emptyUtil.checkVal(item.loginTime)}</td>
                              <td className='w-35 p-3 text-center border-gray'>
                                {emptyUtil.checkVal(item.logoutTime)}
                              </td>
                            </tr>
                          ))}
                      </table>
                      {this.state.searchResultList.length > this.state.maxlength ? (
                        <div>
                          <hr></hr>
                          <Pagination
                            changePage={this.handleChangePage}
                            currentPage={this.state.currentPage}
                            totalPage={Math.ceil(this.state.searchResultList.length / this.state.maxlength)}
                            showMax={this.state.showMax}
                          />{' '}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SysUsedRecordPage);
