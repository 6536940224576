import React from 'react';
import { Navbar } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        {'/howtoPage' == this.props.location.pathname ||
        // noheader結果頁
        '/basicResultNoHeaderPage' == this.props.location.pathname ||
        '/test' == this.props.location.pathname ||
        this.props.location.pathname.includes('/productService/') ? (
          ''
        ) : (
          <footer className='fui-footer blank-footer no-print'>
            <div className='fui-container'>
              <div className='MuiGrid-root' space='1'>
                <div className='row'>
                  <div className='MuiGrid-root footer-fetnet MuiGrid-item col-4'>
                    <div>
                      <p className='phone-promo'>
                        0809-080-080 (免費)
                        <br />
                        MVPN 用戶遠傳手機直撥 99888 (免費)
                        <br />
                        市話直撥 449-9365(市話計費)
                        <br />
                        * 市話請直撥不加區碼，手機撥打請加 02
                        <br />
                        <br />
                      </p>
                      <a
                        href='https://www.fetnet.net/content/cbu/tw/help-center/contact-us.html'
                        rel='noopener noreferrer'
                        to='https://www.fetnet.net/content/cbu/tw/help-center/contact-us.html'
                        target='_self'
                        className='fui-item'>
                        <span className='text'>更多聯絡方式</span>
                      </a>
                    </div>
                  </div>
                  <div className='MuiGrid-root footer-fetnet MuiGrid-item col-8'>
                    <div className='row'>
                      <div className='col-4 pl-5'>
                        <h6 className='collapse-header'>關於</h6>
                        <div>
                          <a
                            href='https://www.fetnet.net/corporate/index.html'
                            rel='noopener noreferrer'
                            to='https://www.fetnet.net/corporate/index.html'
                            target='_self'
                            className='fui-item'>
                            <span>關於遠傳</span>
                          </a>
                          <a
                            href='https://www.fetnet.net/corporate/SocietyManage.html'
                            rel='noopener noreferrer'
                            to='https://www.fetnet.net/corporate/SocietyManage.html'
                            target='_self'
                            className='fui-item'>
                            <span>企業社會責任</span>
                          </a>
                          <a
                            href='https://www.fetnet.net/corporate/hr/index.html'
                            rel='noopener noreferrer'
                            to='https://www.fetnet.net/corporate/hr/index.html'
                            target='_self'
                            className='fui-item'>
                            <span>加入我們</span>
                          </a>
                        </div>
                      </div>
                      <div className='col-4  pl-5'>
                        <h6 className='collapse-header'>合作</h6>
                        <a
                          href='https://www.fetnet.net/online_proposal/home.jsp'
                          rel='noopener noreferrer'
                          to='https://www.fetnet.net/online_proposal/home.jsp'
                          target='_self'
                          className='fui-item'>
                          <span>合作提案</span>
                        </a>
                        <a
                          href='https://www.fetnet.net/mediad/'
                          rel='noopener noreferrer'
                          to='https://www.fetnet.net/mediad/'
                          target='_self'
                          className='fui-item'>
                          <span>媒體行銷服務</span>
                        </a>
                      </div>
                      <div className='col-4  pl-5'>
                        <h6 className='collapse-header'>條款相關</h6>
                        <a
                          href='https://corporate.fetnet.net/content/corp/tw/AboutUs/FETnetRegulations/FETnetContract.html'
                          rel='noopener noreferrer'
                          to='https://corporate.fetnet.net/content/corp/tw/AboutUs/FETnetRegulations/FETnetContract.html'
                          target='_self'
                          className='fui-item'>
                          <span>服務契約</span>
                        </a>
                        <a
                          href='https://cpc.ey.gov.tw/'
                          rel='noopener noreferrer'
                          to='https://cpc.ey.gov.tw/'
                          target='_blank'
                          className='fui-item'>
                          <span>行政院消保會</span>
                        </a>
                        <a
                          href='https://www.fetnet.net/content/cbu/tw/information/fetprivacy.html'
                          rel='noopener noreferrer'
                          to='https://www.fetnet.net/content/cbu/tw/information/fetprivacy.html'
                          target='_self'
                          className='fui-item'>
                          <span>隱私權政策</span>
                        </a>
                        <a
                          href='https://www.fetnet.net/information/copyright.html'
                          rel='noopener noreferrer'
                          to='https://www.fetnet.net/information/copyright.html'
                          target='_self'
                          className='fui-item'>
                          <span>著作權政策</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='MuiGrid-root .fui-footer copyright' space='1'>
                <div className='MuiGrid-root footer-fetnet MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-12'>
                  <div className='d-flex'>
                    <a className='footer-icon' href='https://enterprise.fetnet.net' to='https://enterprise.fetnet.net'>
                      <img
                        src={process.env.PUBLIC_URL + '/resources/common/images/fetnet-footer-logo.png'}
                        alt='FetnetLogo'
                        height='30'
                      />
                    </a>
                    <div className='links'>
                      <p className='text-sm is-text-gray100 mt-2'>
                        遠傳電信版權所有 © Far EasTone Telecommunications Co., Ltd.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Footer);
