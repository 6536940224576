import React from 'react';
import { withRouter } from 'react-router-dom';
import * as callApiUtil from '../../utils/callApiUtil';
import '../../css/print.css';
import * as emptyUtil from '../../utils/emptyUtil';
import { Helmet } from 'react-helmet';
class PrintApplyAccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: {
        applyTrustee: true, //是否申請受任人
        //訂單資訊
        applyCode: '',
        createDate: '',
        //公司資料
        cmpName: '',
        cmpFaxNo: '',
        cmpTelNo: '',
        cmpAddress1: '',
        cmpAddress2: '',
        userId: '',
        cmpOwner: '',
        cmpOwnerId: '',
        // 第一代理人資料
        accntName: '',
        gender: '',
        accntEmail: '',
        accntFaxNo: '',
        accntMsisdn: '',
        accntTelNo: '',
        accntId: '',
        loginId: '',
        // 受任人資料
        trusteeAccntName: '',
        trusteeGender: '',
        trusteeAccntEmail: '',
        trusteeAccntFaxNo: '',
        trusteeAccntMsisdn: '',
        trusteeAccntTelNo: '',
        trusteeAccntId: '',
        trusteeLoginId: '',
      },
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/CustAccountApplyController/print.action', null, '/ECP/applyAccountPage', true)
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.message == 's'
        ) {
          //API回傳成功
          let createDate = new Date(response.firstAcnt.createDate);
          let newForm = {
            applyTrustee: response.applyTrustee,
            //訂單資訊
            applyCode: emptyUtil.checkVal(response.applyCode),
            createDate: `${createDate.getFullYear()}/${createDate.getMonth() + 1}/${createDate.getDate()}`,
            // 公司基本資料
            cmpName: emptyUtil.checkVal(response.companyBean.cmpName),
            cmpFaxNo: emptyUtil.checkVal(response.companyBean.cmpFaxNo),
            cmpTelNo: emptyUtil.checkVal(response.companyBean.cmpTelNo),
            cmpAddress1: emptyUtil.checkVal(response.companyBean.cmpAddress1),
            cmpAddress2: emptyUtil.checkVal(response.companyBean.cmpAddress2),
            userId: emptyUtil.checkVal(response.companyBean.userId),
            cmpOwner: emptyUtil.checkVal(response.companyBean.cmpOwner),
            cmpOwnerId: emptyUtil.checkVal(response.companyBean.cmpOwnerId),
            // 第一代理人資料
            accntName: emptyUtil.checkVal(response.firstAcnt.accntName),
            gender: emptyUtil.checkVal(response.firstAcnt.gender),
            accntEmail: emptyUtil.checkVal(response.firstAcnt.accntEmail),
            accntFaxNo: emptyUtil.checkVal(response.firstAcnt.accntFaxNO),
            accntMsisdn: emptyUtil.checkVal(response.firstAcnt.accntMsisdn),
            accntTelNo: emptyUtil.checkVal(response.firstAcnt.accntTelNO),
            accntId: emptyUtil.checkVal(response.firstAcnt.accntID),
            loginId: emptyUtil.checkVal(response.firstAcnt.loginID),
            // 受任人資料
            trusteeAccntName: emptyUtil.checkVal(response.trusteeAcnt.accntName),
            trusteeGender: emptyUtil.checkVal(response.trusteeAcnt.gender),
            trusteeAccntEmail: emptyUtil.checkVal(response.trusteeAcnt.accntEmail),
            trusteeAccntFaxNo: emptyUtil.checkVal(response.trusteeAcnt.accntFaxNO),
            trusteeAccntMsisdn: emptyUtil.checkVal(response.trusteeAcnt.accntMsisdn),
            trusteeAccntTelNo: emptyUtil.checkVal(response.trusteeAcnt.accntTelNO),
            trusteeAccntId: emptyUtil.checkVal(response.trusteeAcnt.accntID),
            trusteeLoginId: emptyUtil.checkVal(response.trusteeAcnt.loginID),
          };
          this.setState({ form: newForm, isLoading: false });
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '帳號申請',
              title: '帳號申請',
              description: response.resultMessage.message,
              actions: [
                {
                  text: '返回帳號申請頁',
                  link: '/applyAccountPage',
                  btnStyle: 'primary',
                },
              ],
            }
          );
        }
      });
  }

  print() {
    var printStr = `<html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'><style>
    .no-print {
        display: none;
      } 

    </style></head><body >`;

    var content = '';
    var str = document.getElementById('page1').innerHTML;
    content = content + str;
    str = document.getElementById('page2').innerHTML;
    content = content + str;
    printStr = printStr + content + '</body></html>';
    var pwin = window.open('', '', 'left=0,top=0,fullscreen,toolbar=0,scrollbars=1,status=0');
    pwin.document.write(printStr);
    pwin.document.close();
    pwin.focus();
    pwin.print();
    pwin.close();
  }

  render() {
    /* 禁止右鍵 */
    window.addEventListener('contextmenu', (e) => e.preventDefault());

    return (
      <div className='printPage'>
        <Helmet>
          <title>企業用戶網路服務申請書</title>
        </Helmet>
        <div className={this.state.isLoading ? 'is-loading' : ''} />
        <div className='printPage' id='page1'>
          <table
            width='100%'
            cellpadding='0'
            cellspacing='0'
            style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto' }}
            className=' bg-white'>
            <div style={{ 'margin-right': 'auto', 'margin-left': 'auto' }} className='container' align='center'>
              <table
                width='611'
                style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto' }}
                cellPadding='0'
                cellSpacing='0'
                className='center'>
                <tr>
                  <td className='ml-5'>
                    <img
                      src={process.env.PUBLIC_URL + '/resources/common/images/logo.png'}
                      width='120'
                      height='40'
                      className='ml-5'
                    />
                    <strong style={{ 'margin-left': '5rem', 'font-size': '14px' }}>企業用戶網路服務申請書</strong>
                  </td>
                </tr>
                <tr>
                  <td align='right'> 營業機密：客戶資料不得外洩或複製</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={process.env.PUBLIC_URL + '/resources/common/images/printimg01.jpg'}
                      width='631'
                      height='53'
                      alt=''
                    />
                  </td>
                </tr>
              </table>
              <table
                width='611'
                style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto', 'font-size': '12px' }}
                cellPadding='5'
                cellSpacing='0'
                className='printtable'>
                <tr>
                  <td align='right' className='titletd02'>
                    表單序號：
                  </td>
                  <td colSpan='3' className='contenttd03'>
                    {this.state.form.applyCode}
                  </td>
                </tr>
                <tr>
                  <td width='24%' align='right' className='titletd02'>
                    申請日期：
                  </td>
                  <td width='28%' className='contenttd03'>
                    {this.state.form.createDate}
                  </td>
                  <td width='24%' align='right' className='titletd02'>
                    申請事由：
                  </td>
                  <td className='contenttd03'> 新增帳號</td>
                </tr>
              </table>
              <table
                width='611'
                style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto' }}
                cellPadding='0'
                cellSpacing='0'
                className='center'>
                <tr>
                  <td>
                    <table
                      width='100%'
                      style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto' }}
                      cellPadding='5'
                      cellSpacing='0'>
                      <tr>
                        <td width='10'>
                          <img
                            src={process.env.PUBLIC_URL + '/resources/common/images/printimg03.gif'}
                            width='10'
                            height='9'
                            alt=''
                          />
                        </td>
                        <td>
                          <strong>公司基本資料</strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={process.env.PUBLIC_URL + '/resources/common/images/printimg02.jpg'}
                      width='611'
                      height='20'
                      alt=''
                    />
                  </td>
                </tr>
              </table>
              <table
                width='611'
                style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto', 'font-size': '12px' }}
                cellPadding='5'
                cellSpacing='0'
                className='printtable'>
                <tr>
                  <td width='24%' align='right' className='titletd02'>
                    公司/機關名稱/用戶名稱：
                  </td>
                  <td width='28%' className='contenttd03'>
                    {this.state.form.cmpName}
                  </td>
                  <td width='24%' align='right' className='titletd02'>
                    統一編號/其他：
                  </td>
                  <td className='contenttd03'>{this.state.form.userId}</td>
                </tr>
                <tr>
                  <td align='right' className='titletd02'>
                    公司負責人：
                  </td>
                  <td className='contenttd03'>{this.state.form.cmpOwner}</td>
                  <td align='right' className='titletd02'>
                    公司負責人證號：
                  </td>
                  <td className='contenttd03'>{this.state.form.cmpOwnerId}</td>
                </tr>
                <tr>
                  <td align='right' className='titletd02'></td>
                  <td className='contenttd03'></td>
                  <td align='right' className='titletd02'>
                    公司/室內電話：
                  </td>
                  <td className='contenttd03'>{this.state.form.cmpTelNo}</td>
                </tr>
              </table>

              {/* ==================== 第一代理人 begin ================== */}
              {/* 第一代理人,標題區塊 */}
              <table
                width='611'
                style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto' }}
                cellPadding='0'
                cellSpacing='0'>
                <tr>
                  <td>
                    <table
                      width='100%'
                      style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto' }}
                      cellPadding='5'
                      cellSpacing='0'>
                      <tr>
                        <td width='10'>
                          <img
                            src={process.env.PUBLIC_URL + '/resources/common/images/printimg03.gif'}
                            width='10'
                            height='9'
                            alt=''
                          />
                        </td>
                        <td>
                          <strong>公司第一代理人基本資料</strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={process.env.PUBLIC_URL + '/resources/common/images/printimg02.jpg'}
                      width='611'
                      height='20'
                      alt=''
                    />
                  </td>
                </tr>
              </table>
              <table
                width='611'
                style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto', 'font-size': '12px' }}
                cellPadding='5'
                cellSpacing='0'
                className='printtable'>
                <tr>
                  <td width='24%' align='right' className='titletd02'>
                    使用者姓名：
                  </td>
                  <td width='28%' className='contenttd03'>
                    {this.state.form.accntName}
                    {this.state.form.gender == 'M' ? '先生' : '小姐'}
                  </td>
                  <td width='24%' align='right' className='titletd02'>
                    身份證字號：
                  </td>
                  <td width='28%' className='contenttd03'>
                    {this.state.form.accntId}
                  </td>
                </tr>
                <tr>
                  <td align='right' className='titletd02'>
                    連絡Email：
                  </td>
                  <td className='contenttd03'>{this.state.form.accntEmail}</td>
                  <td align='right' className='titletd02'>
                    連絡電話：
                  </td>
                  <td width='28%' className='contenttd03'>
                    {this.state.form.accntTelNo}
                  </td>
                </tr>
                <tr>
                  <td align='right' className='titletd02'>
                    連絡傳真：
                  </td>
                  <td className='contenttd03'>{this.state.form.accntFaxNo}</td>
                  <td align='right' className='titletd02'>
                    連絡行動電話：
                  </td>
                  <td width='28%' className='contenttd03'>
                    {this.state.form.accntMsisdn}
                  </td>
                </tr>
              </table>
              <div
                style={{
                  width: '601px',
                  'margin-right': 'auto',
                  'margin-left': 'auto',
                  border: '2px solid #F00',
                  display: 'block',
                  padding: '5px',
                  'font-weight': 'bold',
                  color: '#666',
                  height: '100px',
                }}>
                用印處(公司大小章)
              </div>
              <table
                width='611'
                style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto', 'font-size': '12px' }}
                cellPadding='5'
                cellSpacing='0'
                className='printtable'>
                <tr>
                  <td width='24%' align='right' valign='top' className='titletd02'>
                    帳號類別：
                  </td>
                  <td width='28%' valign='top' className='contenttd03'>
                    第一代理人(管理者)帳號
                  </td>
                  <td width='24%' align='right' valign='top' className='titletd02'>
                    使用者帳號：
                  </td>
                  <td width='28%' valign='top' className='contenttd03'>
                    {this.state.form.loginId}
                  </td>
                </tr>
              </table>
              <div
                style={{
                  width: '601px',
                  'margin-right': 'auto',
                  'margin-left': 'auto',
                  border: '2px solid #F00',
                  display: 'block',
                  padding: '5px',
                  'font-weight': 'bold',
                  color: '#666',
                  height: '100px',
                }}>
                第一代理人簽名
              </div>
              {/* ==================== 第一代理人 end ================== */}
            </div>
          </table>
        </div>
        <div className='printPage' id='page2'>
          <table
            width='100%'
            cellpadding='0'
            cellspacing='0'
            style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto' }}
            className=' bg-white'>
            <div style={{ 'margin-right': 'auto', 'margin-left': 'auto' }} className='container' align='center'>
              {/* 受任人,標題區塊 */}
              {this.state.form.applyTrustee ? (
                <div>
                  <table
                    width='611'
                    style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto' }}
                    cellPadding='0'
                    cellSpacing='0'
                    className='center'>
                    <tbody>
                      <tr>
                        <td>
                          <table width='100%' style={{ border: '0' }} cellPadding='5' cellSpacing='0'>
                            <tr>
                              <td width='10'>
                                <img
                                  src={process.env.PUBLIC_URL + '/resources/common/images/printimg03.gif'}
                                  width='10'
                                  height='9'
                                  alt=''
                                />
                              </td>
                              <td>
                                <strong>公司受任人基本資料</strong>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src={process.env.PUBLIC_URL + '/resources/common/images/printimg02.jpg'}
                            width='611'
                            height='20'
                            alt=''
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    width='611'
                    style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto', 'font-size': '12px' }}
                    cellPadding='5'
                    cellSpacing='0'
                    className='printtable'>
                    <tbody>
                      <tr>
                        <td width='24%' align='right' className='titletd02'>
                          使用者姓名：
                        </td>
                        <td width='28%' className='contenttd03'>
                          {this.state.form.trusteeAccntName}
                          {this.state.form.trusteeGender == 'M' ? '先生' : '小姐'}
                        </td>
                        <td width='24%' align='right' className='titletd02'>
                          身份證字號：
                        </td>
                        <td width='28%' className='contenttd03'>
                          {this.state.form.trusteeAccntId}
                        </td>
                      </tr>
                      <tr>
                        <td align='right' className='titletd02'>
                          連絡Email：
                        </td>
                        <td className='contenttd03'>{this.state.form.trusteeAccntEmail}</td>
                        <td align='right' className='titletd02'>
                          連絡電話：
                        </td>
                        <td width='28%' className='contenttd03'>
                          {this.state.form.trusteeAccntTelNo}
                        </td>
                      </tr>
                      <tr>
                        <td align='right' className='titletd02'>
                          連絡傳真：
                        </td>
                        <td className='contenttd03'>{this.state.form.trusteeAccntFaxNo}</td>
                        <td align='right' className='titletd02'>
                          連絡行動電話：
                        </td>
                        <td width='28%' className='contenttd03'>
                          {this.state.form.trusteeAccntMsisdn}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    style={{
                      width: '601px',
                      'margin-right': 'auto',
                      'margin-left': 'auto',
                      border: '2px solid #F00',
                      display: 'block',
                      padding: '5px',
                      'font-weight': 'bold',
                      color: '#666',
                      height: '100px',
                    }}>
                    用印處(公司大小章)
                  </div>
                  <table
                    width='611'
                    style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto', 'font-size': '12px' }}
                    cellPadding='5'
                    cellSpacing='0'
                    className='printtable'>
                    <tbody>
                      <tr>
                        <td width='24%' align='right' valign='top' className='titletd02'>
                          帳號類別：
                        </td>
                        <td width='28%' valign='top' className='contenttd03'>
                          受任人帳號
                        </td>
                        <td width='24%' align='right' valign='top' className='titletd02'>
                          使用者帳號：
                        </td>
                        <td width='28%' valign='top' className='contenttd03'>
                          {this.state.form.trusteeLoginId}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    style={{
                      width: '601px',
                      'margin-right': 'auto',
                      'margin-left': 'auto',
                      border: '2px solid #F00',
                      display: 'block',
                      padding: '5px',
                      'font-weight': 'bold',
                      color: '#666',
                      height: '100px',
                    }}>
                    受任人簽名
                  </div>
                </div>
              ) : null}
            </div>
            <table
              width='611'
              style={{ border: '0', 'margin-right': 'auto', 'margin-left': 'auto', 'font-size': '12px' }}
              cellPadding='5'
              cellSpacing='0'
              className='printtable'>
              <tr>
                <td width='5%' height='33' align='right' valign='middle' className='text_size_12'>
                  <input name='checkbox' type='checkbox' checked disabled style={{ cursor: 'none' }} />
                </td>
                <td valign='middle' className='text_size_12'>
                  我已詳閱約定條款內容及遠傳電信依個人資料保護法第8條告知事項
                </td>
              </tr>
              <tr>
                <td colSpan='2' valign='middle' className='color_09 text_size_12' style={{ color: 'red' }}>
                  ( 條款內容可詳見企業用戶網路服務平台-ECP首頁 → 點選『立即註冊』→ 帳號申請說明『會員條款』)
                </td>
              </tr>
              <tr>
                <td colSpan='4' valign='top' className='contenttd03'>
                  1.請確認申請書已用印無誤，並檢附相關證件：
                  <div className='ml-4 mt-1 mb-1'>公司營業登記證+負責人身分證正反面影本。</div>
                  {this.state.form.applyTrustee ? (
                    <div className='ml-4 mt-1 mb-1'>受任人: 身分證正反面影本+健保卡/駕照影本。</div>
                  ) : null}
                  <div className='ml-4 mt-1 mb-1'>第一代理人:身分證正反面影本。</div>
                  2.郵寄至『台北市114內湖區港墘路220號10樓』遠傳電信股份有限公司 訂單管理組 收。
                </td>
              </tr>
            </table>
            <div className='bot_location no-print'>
              <input name='Submit' type='button' className='btn_3 no-print' value='列印' onClick={this.print} />
              <input
                name='Submit22'
                type='button'
                className='btn_3 left_width_5 no-print'
                value='關閉視窗'
                onClick={window.close}
              />
            </div>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(PrintApplyAccountPage);
