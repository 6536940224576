import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Banner from '../../../components/partials/banner/Banner';
import Sidebar from './CommonProblemSideBar';
import { Collapse } from 'react-bootstrap';

class RegisterProblemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapse: '',
      open: false,
    };
  }

  //是否彈出搜尋欄位
  openCollapse = (openCollapse) => {
    let curCollapse = this.state.openCollapse;
    this.setState(() => ({
      openCollapse: curCollapse === openCollapse ? '' : openCollapse,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>帳號申請常見問題</title>
        </Helmet>
        <Banner title='帳號申請常見問題' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <Sidebar history={this.props.history} />
              <div className='bg-white col-9 shadow p-2'>
                <div className='title mt-5 ml-4'>註冊問題</div>
                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '1' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>如何申請ECP帳號</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('1')}
                        aria-controls='collapse-top'
                        aria-expanded={this.state.openCollapse == '1'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '1'}>
                    <div className='ml-3 mt-3 h5 collapse-top'>
                      請至FETnet-企業用戶-企業用戶專區-
                      <a className='red pointer' onClick={() => this.props.history.push('/applyAccountPage')}>
                        <u>立即註冊</u>
                      </a>
                      ，或透過業務/客服，取得/填寫企業用戶網路服務(ECP)申請書，填寫完成並用印後，將紙本申請書郵寄至本公司，即可進行ECP帳號申請。
                    </div>
                  </Collapse>
                </div>

                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '2' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>註冊帳號需要任何費用嗎？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('2')}
                        aria-controls='collapse-mid'
                        aria-expanded={this.state.openCollapse == '2'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '2'}>
                    <div className='ml-3 mt-4 h5'>
                      加入本網站帳號不需任何費用。但需麻煩您完成閱讀並同意遠傳電信企業用戶專區資訊服務使用條款。
                    </div>
                  </Collapse>
                </div>

                <div
                  className={`gray-block p-5 mt-2 mb-5 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '3' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>申請註冊後，多久會生效？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('3')}
                        aria-controls='collapse-bot'
                        aria-expanded={this.state.openCollapse == '3'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '3'}>
                    <div className='mt-4 h5'>
                      <ol>
                        <li>
                          申請第一代理人/受任人帳號：填寫會員註冊資料後，請列印出申請書後寄送至申請書下方之寄送地址。遠傳電信在完成資料確認後會寄送到您的電子信箱通知您開啟帳號。
                        </li>
                        <li>
                          申請第二代理人帳號：您可以洽貴公司的第一代理人/受任人，請其協助於系統中完成申請(於帳號管理_子帳號權限管理中設定)，帳號將立即開通生效。
                        </li>
                      </ol>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterProblemPage);
