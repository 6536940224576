import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Helmet from 'react-helmet';
import { Navigation } from 'react-minimal-side-navigation';
import * as callApiUtil from '../../utils/callApiUtil';
import { validationRules } from 'formsy-react';
import Button from '../../components/Button';

class BillInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 顯示頁面
      iframeHigh: '',
      redirectUrl: '',
      //導連URL參數
      referService: {
        title: '',
        itemId: '', //url
        frameFlag: '',
        flagType: '',
        serviceId: '',
        productId: '',
        sysApiUrl: '',
      },
    };
  }
  componentDidMount = () => {
    this.setState({ isLoading: true });
    // 取得頁面初始化資料
    // 打API撈取資料
    callApiUtil.callApi('/ECPAP/API/BillInfoController/BillInfoInit.action', {}).then((response) => {
      // 判斷回傳資料
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success &&
        response.url != null
      ) {
        //API回傳成功，將資料顯示在頁面上
        window.addEventListener('message', this.receiveMessage, false);
        this.setState({ isLoading: false, redirectUrl: response.url });
        // this.setState({ isLoading: false, prodInfo: response.prodInfo, prodName: response.prodName });
      } else {
        //API回傳失敗
        this.setState({ isLoading: false });
        this.props.history.push({
          pathname: '/basicResultPage',
        });
      }
    });
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }
  //監聽器事件:取得iframe傳出來的高度
  receiveMessage = (event) => {
    //取得高度
    if (event !== undefined && event.data.type === 'high') {
      this.setState({ iframeHigh: event.data.bodyHight });
    }
  };
  render() {
    let page = (
      <div className='w-100'>
        <iframe
          style={{
            width: '100%',
            border: '0px',
            height: this.state.iframeHigh != '' ? this.state.iframeHigh : '700px',
          }}
          sandbox='allow-scripts allow-forms allow-same-origin allow-popups
            allow-modals allow-popups-to-escape-sandbox 
            allow-top-navigation-by-user-activation
            allow-top-navigation allow-downloads'
          scrolling='auto'
          src={this.state.redirectUrl}
        />
      </div>
    );

    return (
      <div className='bg-img'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />

        <Helmet>
          <title>帳務資訊</title>
        </Helmet>
        <div className='container-xl'>
          <div className='bg-white shadow pt-5'>{page}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(BillInfoPage);
