import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Pagination from '../../../components/Pagination';
import { Table } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
import * as validation from '../../../utils/validation';
class UserNoUserNameNoBtnPage extends React.Component {
  constructor(props) {
    super(props);
    this.body = React.createRef();
    this.state = {
      prodInfo: this.props.prodInfo,
      title: this.props.title,
      prodId: this.props.prodId,
      userNo: this.props.userNo,
      //分頁
      currentPage: 1,
      maxlength: 10, //一頁幾筆資料
      showMax: 10, //分頁欄顯示頁數
    };
  }

  getItemInfo = (item) => {
    let result = [];
    for (const [key, value] of Object.entries(item)) {
      result.push(
        <td className='p-3 border-gray' key={key + value}>
          {!validation.isEmpty(value) ? value : <span>N/A</span>}
        </td>
      );
      result.push();
    }
    return result;
  };

  getTitle = (item) => {
    // 先取得最上面說明列
    let result = [];
    result.push(<th className='p-3 border-gray keep-all'>項次</th>);
    for (const [key, value] of Object.entries(item)) {
      result.push(<th className='p-3 border-gray keep-all'>{key}</th>);
    }
    return result;
  };

  handleChangePage = (newPage) => {
    this.setState({ currentPage: newPage });
  };

  handleClick = () => {
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  };

  render() {
    return (
      <React.Fragment>
        <table className='w-100'>
          <tr className='bg-white mb-3'>
            <td className='w-40 text-center border-gray'>客戶編號與客戶名稱</td>
            <td className='w-60 p-3 border-gray'>
              {!validation.isEmpty(this.props.title) ? this.props.title : <span>N/A</span>}
            </td>
          </tr>
        </table>
        <div className='h4 title-blue is-text-bold mt-5'>使用中的服務品項</div>
        <ScrollContainer className='mb-4 scroll-container' hideScrollbars='false'>
          <Table responsive className='w-100 mt-4 text-center'>
            <tr className='bg-white'>
              {/* 先取header */}
              {this.props.prodInfo != null && this.props.prodInfo.length > 0 ? (
                this.getTitle(this.props.prodInfo[0])
              ) : (
                <td className='p-3 border-gray is-text-bold red'>查無產品基本資料，請洽客服人員!</td>
              )}
            </tr>
            {this.props.prodInfo != null && this.props.prodInfo.length > 0
              ? this.props.prodInfo
                  .slice(
                    (this.state.currentPage - 1) * this.state.maxlength,
                    this.state.currentPage * this.state.maxlength
                  )
                  .map((item, idx) => (
                    <tr className='bg-white' key={'item' + idx}>
                      <td className='p-3 border-gray'>
                        {(this.state.currentPage - 1) * this.state.maxlength + idx + 1}
                      </td>
                      {item != null ? this.getItemInfo(item) : null}
                    </tr>
                  ))
              : null}
          </Table>
        </ScrollContainer>
        {this.state.prodInfo != null && this.state.prodInfo.length > this.state.maxlength ? (
          <Pagination
            changePage={this.handleChangePage}
            currentPage={this.state.currentPage}
            totalPage={Math.ceil(this.state.prodInfo.length / this.state.maxlength)}
            showMax={this.state.showMax}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(UserNoUserNameNoBtnPage);
