import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Banner from '../../../components/partials/banner/Banner';
import CommonServiceSidebar from './CommonServiceSidebar';

class AreaServer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>各地區服務主機</title>
        </Helmet>
        <Banner title='企業用戶專區' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <CommonServiceSidebar
                className='col-3'
                title='常用服務'
                history={this.props.history}></CommonServiceSidebar>
              <div className='bg-white col-9 shadow p-2 pb-5'>
                <div className='title mt-5 ml-4'>各地區服務主機</div>
                <div className='sub-title mt-3 ml-4 p-3 mb-2'>ADSL服務主機</div>
                <table className='m-auto w-90 text-center'>
                  <tr className='bg-white'>
                    <td className='w-20 border-gray gray-block'>主機類別</td>
                    <td className='w-60 border-gray gray-block'>主機名稱(DomainName)</td>
                    <td className='w-20 border-gray gray-block'>適用地區範圍</td>
                  </tr>
                  <tr className='bg-white'>
                    <td className='border-gray'>DNS</td>
                    <td className='border-gray'>139.175.1.1</td>
                    <td className='border-gray'>均適用</td>
                  </tr>
                  <tr className='bg-white'>
                    <td className='border-gray'>Proxy主機</td>
                    <td className='border-gray'>proxy.seed.net.tw(port:8080)</td>
                    <td className='border-gray'>均適用</td>
                  </tr>
                  <tr className='bg-white'>
                    <td className='border-gray'>WWW主機</td>
                    <td className='border-gray'>www.seed.net.tw</td>
                    <td className='border-gray'>均適用</td>
                  </tr>
                </table>
                <div className='sub-title mt-5 ml-4 p-3 mb-2'>郵件服務主機</div>
                <table className='m-auto w-90 text-center'>
                  <tr className='bg-white'>
                    <td className='border-gray gray-block'>類別</td>
                    <td className='border-gray gray-block'>主機類別</td>
                    <td className='border-gray gray-block'>主機名稱(DomainName)</td>
                  </tr>
                  <tr className='bg-white'>
                    <td className='border-gray'>@seed.net.tw一般郵件服務用戶</td>
                    <td className='border-gray'>POP3/SMTP</td>
                    <td className='border-gray'>seed.net.tw</td>
                  </tr>
                </table>
                <div className='sub-title mt-5 ml-4 p-3 mb-2'>DNS代管服務主機</div>
                <table className='m-auto w-90 text-center'>
                  <tr className='bg-white'>
                    <td className='border-gray gray-block'>類別</td>
                    <td className='border-gray gray-block'>主機類別</td>
                    <td className='border-gray gray-block'>主機名稱(DomainName)</td>
                    <td className='border-gray gray-block'>IP位置</td>
                  </tr>
                  <tr className='bg-white'>
                    <td rowspan='2' className='border-gray'>
                      台灣網站註冊申請(com/net/org/idv.tw)
                    </td>
                    <td className='border-gray'>主要名稱伺服器</td>
                    <td className='border-gray'>vdns1.seed.net.tw</td>
                    <td className='border-gray'>61.20.46.203</td>
                  </tr>
                  <tr className='bg-white'>
                    <td className='border-gray'>次要名稱伺服器</td>
                    <td className='border-gray'>vdns2.seed.net.tw</td>
                    <td className='border-gray'>61.20.46.204</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AreaServer);
