import React from "react";
import Link from "../Link";
import PropTypes from "prop-types";

const LinkItem = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (props.onClick) props.onClick(props.value);
  };

  return props.link ? (
    <Link
      role="button"
      to={props.link}
      target={props.target}
      title={props.children}
      className={`fui-item ${props.className ? props.className : ""}`}
      id={props.id}
    >
      {props.img ? (
        <img src={process.env.PUBLIC_URL + props.img} alt={props.children} />
      ) : (
        ""
      )}
      {props.prefixIcon ? (
        <span className="prefix">
          <i className={`icon-${props.prefixIcon}`}></i>
        </span>
      ) : (
        ""
      )}
      <span className="content">{props.children}</span>
      {props.icon ? (
        <span className="extra">
          <i className={`icon-${props.icon}`}></i>
        </span>
      ) : (
        ""
      )}
    </Link>
  ) : (
    <div
      role="button"
      onClick={handleClick}
      className={`fui-item ${props.className ? props.className : ""}`}
      id={props.id}
    >
      {props.img ? (
        <img src={process.env.PUBLIC_URL + props.img} alt={props.children} />
      ) : (
        ""
      )}
      {props.prefixIcon ? (
        <span className="prefix">
          <i className={`icon-${props.prefixIcon}`}></i>
        </span>
      ) : (
        ""
      )}
      <span className="content">{props.children}</span>
      {props.icon ? (
        <span className="extra">
          <i className={`icon-${props.icon}`}></i>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

LinkItem.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  link: PropTypes.string,
  number: PropTypes.number,
  prefix: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.string.isRequired,
  img: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
};

export default LinkItem;
