import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Button from '../../components/Button';
import Formsy from 'formsy-react';
import LabelInput from '../../components/form/LabelInput';
import { Grid } from '@material-ui/core';
import * as callApiUtil from '../../utils/callApiUtil';
import * as emptyUtil from '../../utils/emptyUtil';
import { isEmpty, notEmpty } from '../../utils/validation';
class ReplaceSimPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchMsisdn: false, //控制顯示查詢門號頁/查詢門號結果頁
      search: {
        msisdn: '', //sim卡頁搜尋門號
        newSim: '', //新sim卡號
      },
      //門號狀態(若門號狀態非正常使用中，將不會進入查詢結果頁，故必定為正常使用中)
      msisdnStatus: '正常使用中',
      //查詢門號結果
      oriSimNum: '', //原sim卡卡號
      auth: true, //驗卡號 可否換卡 (false=擋件)
      errMsg: '', //擋件訊息
    };
  }

  componentDidMount() {
    //將頁面高度傳至iframe鑲嵌頁面
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  }

  componentDidUpdate() {
    //將頁面高度傳至iframe鑲嵌頁面
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
  }

  onChange = (name, value) => {
    let searchForm = Object.assign(this.state.search);
    searchForm[name] = value.trim();
    this.setState({ search: searchForm, auth: true });
    //顯示提示訊息
    if (name == 'msisdn') {
      if (!this.validMsisdn()) {
        this.setState({ errMsg: '門號輸入不正確，請重新輸入。' });
      } else {
        this.setState({ errMsg: '' });
      }
    }
    if (name == 'newSim') {
      if (!this.validNewSim()) {
        this.setState({ errMsg: '新SIM卡號不正確，請重新輸入。' });
      } else {
        this.setState({ errMsg: '' });
      }
    }
  };

  //門號前端驗證: 門號為空或不足10碼時將查詢鍵disabled並顯示提示訊息
  validMsisdn = () => {
    return notEmpty(this.state.search.msisdn) && this.state.search.msisdn.length >= 10;
  };
  //查詢門號 action
  searchByMsisdn = () => {
    if (this.validMsisdn()) {
      this.setState({ isLoading: true });
      callApiUtil
        .callApi('/ECPAP/API/ChangeSimController/searchByPhoneNum.action', {
          msisdn: this.state.search.msisdn,
        })
        .then((response) => {
          if (
            response == null ||
            response == undefined ||
            response.resultMessage == null ||
            response.resultMessage == undefined
          ) {
            //API回傳為空
            this.toErrPage();
          } else if (response.resultMessage.success) {
            //API回傳成功
            this.setState({
              searchMsisdn: response.auth,
              errMsg: response.resultMessage.message,
              oriSimNum: emptyUtil.checkVal(response.simNum),
              isLoading: false,
            });
          } else {
            //API回傳失敗
            this.toErrPage(response.resultMessage.message);
          }
        });
    }
  };

  //返回查詢
  backSearch = () => {
    this.setState({
      searchMsisdn: false,
      search: {
        msisdn: '', //sim卡頁搜尋門號
        newSim: '', //新sim卡號
      },
      oriSimNum: '', //原sim卡卡號
      auth: true, //可否換卡 (false=擋件)
      errMsg: '', //擋件訊息
    });
  };

  //驗證卡號 : 門號卡號為空或卡號小於20碼時，將查詢鍵disabled並顯示提示訊息
  validNewSim = () => {
    return (
      notEmpty(this.state.search.msisdn) && notEmpty(this.state.search.newSim) && this.state.search.newSim.length >= 20
    );
  };

  /* 送出更換SIM卡 前端驗證: 
    1.門號、卡號非空
    2.卡號不小於20碼
    3.門號狀態類型未擋件
    4.卡號驗證未擋件
  */
  validSubmit = () => {
    return (
      notEmpty(this.state.search.msisdn) &&
      notEmpty(this.state.search.newSim) &&
      this.state.search.newSim.length >= 20 &&
      this.state.auth
    );
  };
  // 更換SIM卡送出 action
  submitSim = () => {
    if (this.validSubmit()) {
      this.setState({ isLoading: true });
      callApiUtil
        .callApi('/ECPAP/API/ChangeSimController/submitChangeSim.action', {
          msisdn: this.state.search.msisdn,
          newSimNum: this.state.search.newSim,
        })
        .then((response) => {
          //API回傳成功
          if (
            response != null &&
            response != undefined &&
            response.resultMessage != null &&
            response.resultMessage != undefined &&
            response.resultMessage.success
          ) {
            if (response.resultMessage.message != 's') {
              // 處理回傳的擋件/提示/確認訊息
              this.setState({
                auth: response.auth,
                errMsg: response.resultMessage.message,
                isLoading: false,
              });
            } else {
              this.props.history.push(
                {
                  pathname: '/basicResultNoHeaderPage',
                  search: '?channelId=' + window.channelId,
                },
                {
                  type: 'success',
                  pageName: '更換SIM卡',
                  title: 'SIM卡更換成功',
                  description: '您已成功更換SIM卡，換卡費$300將於下期帳單收取。',
                }
              );
            }
          } else {
            //API回傳失敗
            this.toErrPage(response.resultMessage.message);
          }
        });
    }
  };

  // 導錯誤頁
  toErrPage = (errMsg) => {
    this.props.history.push(
      {
        pathname: '/basicResultNoHeaderPage',
      },
      {
        type: 'fail',
        pageName: '更換SIM卡',
        title: '提醒訊息',
        description: errMsg,
        showActions: false,
      }
    );
  };

  render() {
    return (
      <Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <div className='w-100 p-3'>
          {/* 查詢框 */}
          <div className='title mt-4'>
            更換SIM卡
            <div className='h5 mt-4 is-text-bold text-black'>
              ※換卡費$300將於下期帳單收取 <br />
              ※換卡完成後，10分鐘後即可通話
            </div>
          </div>

          {!this.state.searchMsisdn ? (
            <div className='w-70 p-3'>
              <Formsy>
                <Grid container spacing={6} className='my-2'>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      className='mx-0'
                      name='msisdn'
                      required={true}
                      value={this.state.search.msisdn}
                      placeholder='請輸入欲查詢門號'
                      label='門號'
                      failResultMsg={this.state.errMsg}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>
              </Formsy>
              <div className='two-buttons my-3'>
                <Button btnStyle='primary' size='large' onClick={this.searchByMsisdn} disabled={!this.validMsisdn()}>
                  查詢
                </Button>
                <Button btnStyle='secondary' size='large' onClick={this.backSearch}>
                  重填
                </Button>
              </div>
            </div>
          ) : null}
          {/* 查詢結果 */}
          {this.state.searchMsisdn ? (
            <Formsy>
              <div className='w-100 p-3'>
                <table className='w-100'>
                  <tr className='bg-white'>
                    <td className='w-30 text-center border-gray'>門號</td>
                    <td className='w-70 p-3 border-gray'>{this.state.search.msisdn}</td>
                  </tr>
                  <tr className='bg-white'>
                    <td className='w-30 text-center border-gray'>門號狀態</td>
                    <td className='w-70 p-3 border-gray'>{this.state.msisdnStatus}</td>
                  </tr>
                  <tr className='bg-white'>
                    <td rowspan='2' className='w-30 text-center border-gray'>
                      SIM卡號
                    </td>
                    <td className='w-70 p-3 border-gray'>{this.state.oriSimNum}</td>
                  </tr>
                  <tr className='bg-white'>
                    <td className='w-70  border-gray'>
                      <LabelInput
                        className='ml-3 mt-3'
                        name='newSim'
                        value={this.state.search.newSim}
                        required={false}
                        placeholder='請輸入更改的SIM卡號'
                        failResultMsg={this.state.errMsg}
                        onChange={this.onChange}
                        onBlur={this.validSim}
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div className='pt-5 two-buttons mb-5 pl-3'>
                <Button btnStyle='primary' size='large' disabled={!this.validSubmit()} onClick={this.submitSim}>
                  送出
                </Button>
                <Button btnStyle='secondary' size='large' onClick={this.backSearch}>
                  返回查詢
                </Button>
              </div>
            </Formsy>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ReplaceSimPage);
