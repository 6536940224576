import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import * as callApiUtil from '../../utils/callApiUtil';

class MaintainTrusteePage extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      docInfoList: [],
      isLoading: false,
    };
  }

  componentDidMount = () => {
    // 頁面初始化取得檔案資訊 & 說明
    this.setState({ isLoading: true });
    callApiUtil
      .callApi(
        '/ECPAP/API/DocumentController/docDownloadInit.action',
        {
          displayPath: window.location.pathname,
        },
        null,
        true //loading遮罩
      )
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          let docInfoList = [];
          if (
            response.docInfoBeanList != null &&
            response.docInfoBeanList.length > 0 &&
            response.docInfoBeanList[0].documentList != null &&
            response.docInfoBeanList[0].documentList.length > 0
          ) {
            docInfoList = response.docInfoBeanList;
          }
          this.setState({
            docInfoList: docInfoList,
            isLoading: false,
          });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultPage',
            },
            {
              type: 'fail',
              pageName: '受任人資料變更申請',
              title: '提示訊息',
              description: response.resultMessage.message,
              actions: [
                {
                  text: '返回受任人資料變更申請',
                  link: '/maintainPasswordPage',
                  btnStyle: 'primary',
                },
                {
                  text: '返回企業用戶專區',
                  link: '/index',
                  btnStyle: 'secondary',
                },
              ],
            }
          );
        }
      });
  };

  download(document) {
    if (document.uri != null) {
      window.open(document.uri, document.uri, 'height=auto,width=auto'); // 呼叫外部URI下載檔案
    } else {
      callApiUtil.postFile(
        '/ECPAP/API/DocumentController/downloadDoc.action',
        {
          documentId: document.documentId,
        },
        null, //成功後執行
        null, //錯誤處理
        true //loading遮罩
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>受任人資料變更申請</title>
        </Helmet>
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='帳號管理' history={this.props.history}></Sidebar>
              <div className='container bg-white col-9 shadow px-4 pt-5 pb-4'>
                <div className='title pt-2 ml-3'>受任人資料變更申請書下載</div>
                <div className='mb-4'>
                  {this.state.docInfoList == null || this.state.docInfoList.length < 1 ? (
                    <h2 className='my-5 ml-5'>
                      <span className='ml-3 sub-title'>查無受任人資料變更申請書</span>
                    </h2>
                  ) : (
                    this.state.docInfoList.map((title) => (
                      <React.Fragment key={'docInfoList' + title.name}>
                        <div className='sub-title mt-3 ml-5 font-weight-bold'>{title.name}</div>
                        <ul className='ml-5 pt-3 font-weight-bold'>
                          {title.documentList.map((doc, index) => (
                            <li className='h5' key={doc.documentId}>
                              <a className='pointer' href='#' onClick={() => this.download(doc)}>
                                {doc.displayName}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    ))
                  )}
                </div>
                <p className='ml-3 w-90'>
                  <ol>
                    <li>
                      因應企業受任人資料變更申請，需先經由企業客戶限定委任書完成異動後，才能依此異動後的資訊，進行企業用戶網路服務平台的受任人資料變更。
                    </li>
                    <li>
                      申請書填寫完畢後並完成用印公司大小章，請郵寄至『台北市114內湖區港墘路220號10樓』遠傳電信股份有限公司
                      訂單管理組 收。
                    </li>
                    <li>申請書填寫內容若有不清楚之處，還請洽所屬業務人員或洽客服電話:4499-365(手機撥打請加02)諮詢。</li>
                    <li>
                      遠傳電信股份有限公司(以下稱「遠傳電信」)／新世紀資通股份有限公司(以下稱「新世紀資通」)絕對尊重用戶的隱私權，為使用戶瞭解遠傳電信／新世紀資通(下稱：本公司)如何蒐集、應用及保護您所提供的個人資訊，請詳閱
                      <a
                        target='_blank'
                        href='https://www.fetnet.net/content/cbu/tw/information/fetprivacy.html'
                        rel='noreferrer'>
                        遠傳隱私權政策
                      </a>
                      (Privacy Policy)。
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MaintainTrusteePage);
