import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import Banner from '../../../components/partials/banner/Banner';
import CommonServiceSidebar from './CommonServiceSidebar';

class CloudServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>產品平台常用連結</title>
        </Helmet>
        <Banner title='企業用戶專區' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <CommonServiceSidebar
                className='col-3 font-weight-bold'
                title='常用服務'
                history={this.props.history}></CommonServiceSidebar>
              <div className='bg-white col-9 shadow p-2 pb-5'>
                <div className='title mt-5 ml-4'>產品平台常用連結</div>
                <ul className='ml-5 pt-3 h4 font-weight-bold'>
                  <li className='ml-2 h5'>
                    <a className='pointer' target='_blank' href={'https://console.aws.amazon.com'} rel='noreferrer'>
                      AWS Console
                    </a>
                  </li>
                  <li className='ml-2 h5'>
                    <a
                      className='pointer'
                      target='_blank'
                      href={'https://portal.taipei.fetazure.fetnet.net'}
                      rel='noreferrer'>
                      遠傳Azure Stack
                    </a>
                  </li>
                  <li className='ml-2 h5'>
                    <a
                      className='pointer'
                      target='_blank'
                      href={'https://fetazure.fetnet.net/#/login'}
                      rel='noreferrer'>
                      微軟AZURE平台
                    </a>
                  </li>
                  <li className='ml-2 h5'>
                    <a className='pointer' target='_blank' href={'https://cloud.google.com/'} rel='noreferrer'>
                      GCP Console
                    </a>
                  </li>
                  {/* 20220629 IT Johnny通知「Office 365 自助服務平台」服務已下線，故直接移除該資料*/}
                  <li className='ml-2 h5'>
                    <a className='pointer' target='_blank' href={'https://agp.line.biz/'} rel='noreferrer'>
                      Line官方帳號平台
                    </a>
                  </li>
                  {/* 20220705 IT Johnny通知移除「遠傳SMB服務快購平台」服務，故直接移除該資料*/}
                  {/* 20220906 IT Johnny通知新增「雲端多點視訊會議服務」、「FET Audio Conference」服務，故直接移除該資料*/}
                  <li className='ml-2 h5'>
                    <a
                      className='pointer'
                      target='_blank'
                      href={'http://122.147.110.246/usr/index3.php'}
                      rel='noreferrer'>
                      雲端多點視訊會議服務
                    </a>
                  </li>
                  <li className='ml-2 h5'>
                    <a
                      className='pointer'
                      target='_blank'
                      href={'https://cc.callinfo.com/cc/login?host=fet'}
                      rel='noreferrer'>
                      FET Audio Conference
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(CloudServicePage);
