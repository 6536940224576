import { addValidationRule } from 'formsy-react';

export const idNumberValid = (values, value) => {
  var reg = /^[A-Z]{1}[1-2]{1}[0-9]{8}$/; //身份證的正規表示式
  if (reg.test(value)) {
    var s = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; //把 A 取代成 10,把 B 取代成 11...的作法
    var ct = [
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '34',
      '18',
      '19',
      '20',
      '21',
      '22',
      '35',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '32',
      '30',
      '31',
      '33',
    ];

    var i = s.indexOf(value.charAt(0));
    var tempuserid = ct[i] + value.substr(1, 9); //若此身份證號若是A123456789=>10123456789
    var num = tempuserid.charAt(0) * 1;

    for (i = 1; i <= 9; i++) {
      num = num + tempuserid.charAt(i) * (10 - i);
    }

    num += tempuserid.charAt(10) * 1;

    if (num % 10 === 0) {
      return true;
    } else {
      return '請輸入正確的證照號碼';
    }
  } else {
    return '請輸入正確的證照號碼';
  }
};

addValidationRule('notEmpty', function (values, value) {
  return value != 1;
});
addValidationRule('isRequired', function (values, value) {
  return typeof value !== 'undefined' && value !== '' && value !== false;
});

addValidationRule('validId', function (values, value) {
  return twId(value) || foreignId(value) || comId(value);
});
// 驗證不為空值
export const notEmpty = (val) => {
  return typeof val !== 'undefined' && val != null && val.toString().trim() != '';
};

// 是否符合email格式
export const email = (str) => {
  if (isEmpty(str)) {
    return false;
  }
  var regEmail = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
  return validationRegexp(str, regEmail);
};

// 是否為正數
export const positive = (str) => {
  if (isEmpty(str)) return false;
  if (isNaN(str)) return false;
  return parseInt(str) > 0;
};

// 是否全為數字
export const digit = (str) => {
  if (isEmpty(str)) return false;
  if (typeof str !== 'String') str = str.toString();
  var regnDigit = /^[0-9]*$/;
  return validationRegexp(str, regnDigit);
};

// 是否可視為一個數字(包刮負數小數)
export const number = (str) => {
  if (isEmpty(str)) return true;
  return !isNaN(str);
};

// 是否為n碼數字, 或是為空
export const nDigit = (str, num) => {
  if (isEmpty(str)) return false;
  if (typeof str !== 'String') str = str.toString();
  if (str.length != num) return false;
  var regnDigit = /^[0-9]*$/;
  return validationRegexp(str, regnDigit);
};

// 是否符合電話&傳真號碼格式
export const phone = (str, phoneNumPart1, phoneNumPart2) => {
  if (isEmpty(str)) return true;
  var phoneNum;

  // 只有1參數
  if (!notEmpty(phoneNumPart1)) {
    phoneNum = str;
  }
  // 有3個參數
  else if (notEmpty(phoneNumPart1) && notEmpty(phoneNumPart2)) {
    phoneNum = str + phoneNumPart1 + phoneNumPart2;
  }
  // 有2個參數
  else {
    phoneNum = str + phoneNumPart1;
  }
  // 2021/08/13 參照舊ECP驗證規則
  phoneNum = phoneNum.replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, '').replace(/\#/g, '');
  return digit(phoneNum) && phoneNum.length >= 8 && phoneNum.length <= 17;
};

// 是否符合手機號碼格式
export const mobile = (str) => {
  if (isEmpty(str)) return false;
  var regMobile = /^09[0-9]{8}$/;
  return validationRegexp(str, regMobile);
};

// 驗公司電話
export const notCompany = (str) => {
  if (isEmpty(str)) return false;
  return /[^#0-9]/g.test(str);
};

// 是否符合身分證號碼格式
export const twId = (str) => {
  if (isEmpty(str)) return true;
  str = str.toString().toUpperCase();
  if (str.length != 10) return false;
  if (str[1] != 1 && str[1] != 2) {
    return false;
  }
  if (str[0] < 'A' || str[0] > 'Z') {
    return false;
  }
  var head = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
  var regionNum = head.indexOf(str[0]) + 10;
  regionNum = parseInt(regionNum / 10) + (regionNum % 10) * 9;
  var sum = 0;

  sum =
    str[1] * 8 +
    str[2] * 7 +
    str[3] * 6 +
    str[4] * 5 +
    str[5] * 4 +
    str[6] * 3 +
    str[7] * 2 +
    str[8] * 1 +
    str[9] * 1 +
    regionNum;

  // sum為最後檢查碼
  var result = sum % 10 == 0 ? true : false;
  return result;
};

// 是否為數字或英文
export const alphaNumeric = (str) => {
  return /^[a-zA-Z0-9]+$/.test(str);
};

// 是否符合公司編號格式
export const comId = (sTaxID) => {
  if (isEmpty(sTaxID)) return true;
  sTaxID = sTaxID.toString().toUpperCase();
  var a1, a2, a3, a4, a5, b1, b2, b3, b4;
  var c1, c2, c3, c4, d1, d2, d3, d4, d5, d6, d7, cd8;

  if (!nDigit(sTaxID, 8)) {
    return false;
  }

  d1 = parseInt(sTaxID.charAt(0));
  d2 = parseInt(sTaxID.charAt(1));
  d3 = parseInt(sTaxID.charAt(2));
  d4 = parseInt(sTaxID.charAt(3));
  d5 = parseInt(sTaxID.charAt(4));
  d6 = parseInt(sTaxID.charAt(5));
  d7 = parseInt(sTaxID.charAt(6));
  cd8 = parseInt(sTaxID.charAt(7));

  c1 = d1;
  c2 = d3;
  c3 = d5;
  c4 = cd8;
  a1 = parseInt((d2 * 2) / 10);
  b1 = (d2 * 2) % 10;
  a2 = parseInt((d4 * 2) / 10);
  b2 = (d4 * 2) % 10;
  a3 = parseInt((d6 * 2) / 10);
  b3 = (d6 * 2) % 10;
  a4 = parseInt((d7 * 4) / 10);
  b4 = (d7 * 4) % 10;
  a5 = parseInt((a4 + b4) / 10);

  if ((a1 + b1 + c1 + a2 + b2 + c2 + a3 + b3 + c3 + a4 + b4 + c4) % 5 == 0) return true;

  if (d7 == 7) {
    if ((a1 + b1 + c1 + a2 + b2 + c2 + a3 + b3 + c3 + a5 + c4) % 5 == 0) return true;
  }
  return false;
};

// 是否符合外籍人士編號格式
export const foreignId = (id) => {
  if (isEmpty(id)) return true;
  if (!/^[A-Za-z0-9]*$/.test(id)) return false;
  return true;
};

//
export const foreignIdForPrepaid = (id) => {
  if (isEmpty(id)) return true;
  if (!/^[A-Za-z0-9]*$/.test(id)) return false;
  return true;
};

// 是否為有效的手機條碼
export const phoneBarcode = (str) => {
  if (isEmpty(str)) return false;
  var regBarcode = /^[/]{1}[A-Z0-9+-.]{7,}$/;
  return validationRegexp(str, regBarcode);
};

// 是否為有效的自然人憑證
export const citizenDigitalCertificate = (str) => {
  if (isEmpty(str)) return false;
  var regCDC = /^[A-Z]{2}\d{14}$/;
  return validationRegexp(str, regCDC);
};

// 是否為有效的愛心碼
export const loveCode = (str) => {
  if (isEmpty(str)) return false;
  var regLoveCode = /^[0-9X]{3,7}$/;
  return validationRegexp(str, regLoveCode);
};

// 帳單年期別
export const billYearStage = (str) => {
  if (isEmpty(str)) return false;
  var regLoveCode = /^[0-9]{5}$/;
  return validationRegexp(str, regLoveCode);
};

// 帳單載具流水號
export const carrierSerialNumber = (str) => {
  if (isEmpty(str)) return false;
  var regLoveCode = /^[B]{2}[0-9]{8}$/;
  return validationRegexp(str, regLoveCode);
};

//帳單檢核碼
export const billCheckDigits = (str) => {
  if (isEmpty(str)) return false;
  var regLoveCode = /^[0-9]{5}$/;
  return validationRegexp(str, regLoveCode);
};

// 是否為有效的日期
export const validDate = (year, month) => {
  if (isEmpty(year) && isEmpty(month)) return true;
  if (nDigit(year, 4) && (nDigit(month, 1) || nDigit(month, 2))) {
    return year > 1900 && year < 2100 && month > 0 && month < 13;
  }
  return false;
};

// 是否為有效的證號
export const validId = (str) => {
  return twId(str) || foreignId(str) || comId(str);
};

// 使用工具
var validationRegexp = function (str, regexp) {
  if (typeof str !== 'String') str = str.toString();
  var result = false;
  if (regexp != null && regexp != undefined) {
    result = regexp.test(str);
  }
  return result;
};

export var isEmpty = function (val) {
  return !notEmpty(val);
};

//密碼是否符合規則 6~12碼 英數字限定
export const validPWD = (pwd) => {
  if (isEmpty(pwd)) return false;
  let regPWD = /^[a-zA-Z0-9]{6,12}$/;
  return validationRegexp(pwd, regPWD);
};
// 公司統一編號驗證
export const validTaxId = (taxId) => {
  if (isEmpty(taxId)) return false;
  let regTaxId = /^[0-9]{8}$/;
  return validationRegexp(taxId, regTaxId);
};
