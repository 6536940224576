
// 依照指定的長度補上符號 (符號預設為 0)
/* 
pad(10, 4);      // 0010
pad(9, 4);       // 0009
pad(123, 4);     // 0123
pad(10, 4, '-'); // --10
*/
export const pad = (value, width, spell)=> {
    spell = spell || '0';
    value = value + '';
    return value.length >= width ? value : new Array(width - value.length + 1).join(spell) + value;
}