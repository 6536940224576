import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';

import * as callApiUtil from '../../utils/callApiUtil';

class RedirectPTRMS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 初始化
  componentDidMount = () => {
    // 查詢IPv6反解url
    this.queryIpv6Url();
  };

  /*查詢IPv6反解url Action start*/
  queryIpv6Url = () => {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/ObstacleController/queryPTRMSUrl.action', {}).then((response) => {
      // 判斷回傳資料
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success
      ) {
        //API回傳成功
        window.open(response.url, '_blank', 'height=800, width=1000, resizable=0');
      } else {
        //API回傳失敗
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: 'IPv6反解申請頁面',
            title: '提示訊息',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回企業用戶專區',
                link: '/index',
                btnStyle: 'primary',
              },
            ],
          }
        );
      }
    });
  };
  /*查詢線上申告初始化資料 Action end*/

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>IPv6反解申請</title>
        </Helmet>
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='障礙申告及查詢' history={this.props.history}></Sidebar>
              <div className='col-9 container bg-white shadow pl-4 pr-4'></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(RedirectPTRMS);
