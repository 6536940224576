import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid } from '@material-ui/core';
import Dropdown from '../../components/Dropdown';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import * as callApiUtil from '../../utils/callApiUtil';
import * as validation from '../../utils/validation';
import Pagination from '../../components/Pagination';

class QueryObstacleRecordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopOut: false, //是否彈出搜尋結果
      searchBar: false, //是否彈出搜尋欄位
      searchSubmit: false, //送出查詢條件與否
      accountTypeList: [
        { value: 'ALL', text: '管理者及子帳號' },
        { value: '1', text: '管理者' },
      ],
      form: {
        startYear: { value: '', text: '' }, //查詢起始時間
        startMonth: { value: '', text: '' },
        startDay: { value: '', text: '' },

        endYear: { value: '', text: '' }, //結束時間
        endMonth: { value: '', text: '' },
        endDay: { value: '', text: '' },
        userType: { value: '', text: '' },
        formNumber: { value: '', text: '' }, //單號
      },
      accntType: '', //使用者帳號類別
      startYearList: [],
      startMonthList: [],
      startDayList: [],
      endYearList: [],
      endMonthList: [],
      endDayList: [],
      errmsg: '',
      isLoading: false,
      obstacleRecordList: [],
      obstaclesHistoryInfo: {},
      //分頁
      currentPage: 1,
      maxlength: 10, //一頁幾筆資料
      showMax: 6, //分頁欄顯示頁數
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    callApiUtil.callApi('/ECPAP/API/ObstacleController/queryObstacleInit.action', {}).then((response) => {
      // 判斷回傳資料
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success
      ) {
        //API回傳成功，將資料顯示在頁面上
        this.setState({ isLoading: false });
        //API回傳成功
        let startYear = new Date(response.startDate).getFullYear();
        let startMonth = new Date(response.startDate).getMonth() + 1;
        let startDay = new Date(response.startDate).getDate();
        let endYear = new Date(response.endDate).getFullYear();
        let endMonth = new Date(response.endDate).getMonth() + 1;
        let endDay = new Date(response.endDate).getDate();

        this.setState(() => ({
          accountType: response.accntType, //使用者帳號類別
          startYearList: this.getYearList(startYear),
          startMonthList: this.getMonthList(),
          startDayList: this.getDayList(startYear, startMonth),
          endYearList: this.getYearList(endYear),
          endMonthList: this.getMonthList(),
          endDayList: this.getDayList(endYear, endMonth),
          startYear: startYear, //查詢起始時間
          startMonth: startMonth,
          startDay: startDay,
          endYear: endYear, //結束時間
          endMonth: endMonth,
          endDay: endDay,
          accntType: response.accntType,
          form: {
            startYear: { value: startYear, text: startYear }, //查詢起始時間
            startMonth: { value: startMonth, text: startMonth },
            startDay: { value: startDay, text: startDay },
            endYear: { value: endYear, text: endYear }, //結束時間
            endMonth: { value: endMonth, text: endMonth },
            endDay: { value: endDay, text: endDay },
            userType: { value: '1', text: '管理員' }, //使用者帳號類別
            formNumber: { value: '', text: '' },
          },
        }));
      } else {
        //API回傳失敗
        this.setState({ isLoading: false });
        this.props.history.push(
          {
            pathname: '/basicResultPage',
          },
          {
            type: 'fail',
            pageName: '障礙申告查詢',
            title: '查詢失敗',
            description: response.resultMessage.message,
            actions: [
              {
                text: '返回企業用戶專區',
                link: '/index',
                btnStyle: 'primary',
              },
            ],
          }
        );
      }
    });
  };

  //依單號看詳細資料-彈出視窗
  clickToPop = (obstaclesHistoryId, e) => {
    e.preventDefault();
    this.queryObstaclesById(obstaclesHistoryId);
  };
  //依單號看詳細資料-關閉視窗
  handleClose = () => {
    this.setState({ isPopOut: false });
  };

  //是否彈出搜尋欄位
  searchBar = () => {
    this.setState(() => ({
      searchBar: !this.state.searchBar,
    }));
  };
  //分頁換頁
  handleChangePage = (newPage) => {
    this.setState({ currentPage: newPage });
  };
  //送出查詢條件(依條件抓回資料更新table)
  searchSubmit = () => {
    this.searchBar();
    this.setState(() => ({
      searchSubmit: true,
    }));

    // 驗證送出資料
    this.validatData();
  };

  /* 驗證送出資料*/
  validatData = () => {
    let sDay = new Date(
      this.state.form.startYear.value,
      this.state.form.startMonth.value,
      this.state.form.startDay.value
    );
    let eDay = new Date(this.state.form.endYear.value, this.state.form.endMonth.value, this.state.form.endDay.value);
    let startDate;
    let endDate;

    if (eDay >= sDay) {
      // 組合開始日期
      startDate =
        this.state.form.startYear.value +
        '/' +
        this.state.form.startMonth.value +
        '/' +
        this.state.form.startDay.value +
        ' 00:00:00';

      // 組合結束日期
      endDate =
        this.state.form.endYear.value +
        '/' +
        this.state.form.endMonth.value +
        '/' +
        this.state.form.endDay.value +
        ' 23:59:59';
    } else {
      this.setState({ obstacleRecordList: [] });
      return;
    }

    this.queryObstacleRecordData(startDate, endDate);
  };

  // 查詢障礙申告清單Action
  queryObstacleRecordData = (startDate, endDate) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ObstacleController/queryObstacle.action', {
        startDate: startDate,
        endDate: endDate,
        obstaclesHistoryId: validation.notEmpty(this.state.form.formNumber.value)
          ? this.state.form.formNumber.value
          : null,
        userType: this.state.form.userType.value,
      })
      .then((response) => {
        // 判斷回傳資料
        if (response.resultMessage != null && response.resultMessage != undefined && response.resultMessage.success) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({ isLoading: false });
          this.setState({ obstacleRecordList: response.obstaclesHistoryList });
        } else {
          // //API回傳失敗
          this.setState({ isLoading: false });
          this.setState({ obstacleRecordList: [] });
        }
      });
  };

  queryObstaclesById = (obstaclesHistoryId) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ObstacleController/queryObstacleRecordById.action', {
        obstaclesHistoryId: obstaclesHistoryId,
      })
      .then((response) => {
        // 判斷回傳資料
        if (response.resultMessage != null && response.resultMessage != undefined && response.resultMessage.success) {
          //API回傳成功，將資料顯示在頁面上
          this.setState({ isLoading: false, isPopOut: true, obstaclesHistoryInfo: response.obstaclesHistoryInfo });
        } else {
          // //API回傳失敗
          this.setState({ isLoading: false });
          this.setState({ obstacleRecordList: [] });
        }
      });
  };

  onChange = (name, data) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = data.value.trim();
    newForm[name].text = data.text.trim();
    this.setState({ form: newForm });

    /**
     * 判斷是否為更改月份
     */
    if (name == 'startMonth' || name == 'endMonth') {
      this.setState({ isLoading: true });
      // 判斷更改月份要一併修改日
      this.changeMonth(name, data);
    }
  };

  onChangeText = (name, value) => {
    let newForm = Object.assign(this.state.form);
    newForm[name].value = value.trim();
    this.setState({ form: newForm });
  };

  // 更改月分時要做的判斷
  changeMonth = (name, data) => {
    // 若為開始月份
    if (name == 'startMonth') {
      // 取出計算過的日清單
      let dayList = this.getDayList(this.state.form.startYear.value, data.value);
      // 將開始日期重置
      let newForm = Object.assign(this.state.form);
      newForm.startDay.value = '';
      newForm.startDay.text = '';

      // 將資料塞回去
      this.setState(() => ({
        form: newForm,
        startDayList: dayList,
        isLoading: false,
      }));

      // 若為結束月份
    } else if (name == 'endMonth') {
      // 取出計算過的日清單
      let dayList = this.getDayList(this.state.form.endYear.value, data.value);
      // 將開始日期重置
      let newForm = Object.assign(this.state.form);
      newForm.endDay.value = '';
      newForm.endDay.text = '';
      // 將資料塞回去
      this.setState(() => ({
        form: newForm,
        endDayList: dayList,
        isLoading: false,
      }));
    }
  };

  // 初始化表單資料
  resetData = () => {
    let newForm = {
      startYear: { value: '', text: '' }, //查詢起始時間
      startMonth: { value: '', text: '' },
      startDay: { value: '', text: '' },

      endYear: { value: '', text: '' }, //結束時間
      endMonth: { value: '', text: '' },
      endDay: { value: '', text: '' },

      formNumber: { value: '', text: '' }, //單號
      userType: { value: '', text: '' }, //使用者帳號類別
    };
    this.setState({ form: newForm });
  };

  //取得年份清單
  getYearList(year) {
    let yearList = [];

    for (let i = 0; i < 3; i++) {
      yearList.push({ value: (year - i).toString(), text: (year - i).toString() });
    }
    return yearList;
  }
  //取得月份清單
  getMonthList() {
    let monthList = [];
    for (let i = 1; i < 13; i++) {
      monthList.push({ value: i.toString(), text: i.toString() });
    }
    return monthList;
  }
  //取得日期清單
  getDayList(year, month) {
    let dayList = [];

    // 判斷日期天數
    let lastDay = new Date(year, month, 0);
    let day = lastDay.getDate();
    // 取出該年分該月的最後一天日期
    day = day < 10 ? '0' + day : day;

    // 將日期加進dayList中
    for (let i = 1; i < day + 1; i++) {
      dayList.push({ value: i.toString(), text: i.toString() });
    }

    return dayList;
  }

  render() {
    return (
      <React.Fragment>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <Helmet>
          <title>申告記錄查詢</title>
        </Helmet>
        <div className='bg-img'>
          <div className='container'>
            <div className='row'>
              <Sidebar className='col-3' title='障礙申告及查詢' history={this.props.history}></Sidebar>
              {/* 查詢框 */}
              <div className='col-9 container bg-white shadow pl-4 pr-4'>
                <div className='title pt-2 mt-5 ml-3'>
                  申告紀錄查詢
                  <div className='h5 mt-4 is-text-bold text-black'>※本服務之查詢資料僅保留24個月內之資料</div>
                </div>
                {this.state.searchBar ? (
                  <div style={{ position: 'absolute', top: '50%', left: '90%' }}>
                    <button className='is-text-bold bg-white title no-border' onClick={this.searchBar}>
                      +
                    </button>
                  </div>
                ) : null}

                {!this.state.searchBar ? (
                  <Formsy className='pl-3 ml-4'>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10} className='form-group'>
                        <label className='h5 ml-0 mb-3 is-text-bold is-required'>查詢起始時間</label>
                        <div className='row h5 is-text-bold align-items-center'>
                          <Dropdown
                            className='is-button col-3 pl-0'
                            list={this.state.startYearList}
                            label={this.state.form.startYear.value ? this.state.form.startYear.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('startYear', e)}
                          />
                          年
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.startMonthList}
                            label={this.state.form.startMonth.value ? this.state.form.startMonth.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('startMonth', e)}
                          />
                          月
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.startDayList}
                            label={this.state.form.startDay.value ? this.state.form.startDay.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('startDay', e)}
                          />
                          日
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10} className='form-group'>
                        <label className='h5 ml-0 mb-3 is-text-bold is-required'>結束時間</label>
                        <div className='row h5 is-text-bold text-align:center'>
                          <Dropdown
                            className='is-button col-3 pl-0'
                            list={this.state.endYearList}
                            label={this.state.form.endYear.value ? this.state.form.endYear.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('endYear', e)}
                          />
                          年
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.endMonthList}
                            label={this.state.form.endMonth.value ? this.state.form.endMonth.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('endMonth', e)}
                          />
                          月
                          <Dropdown
                            className='is-button col-3 pl-2'
                            list={this.state.endDayList}
                            label={this.state.form.endDay.value ? this.state.form.endDay.value : '--'}
                            arrow={true}
                            hasCheck={false}
                            onChange={(e) => this.onChange('endDay', e)}
                          />
                          日
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={10}>
                        <LabelInput
                          className='ml-0'
                          name='formNumber'
                          value={this.state.form.formNumber.value}
                          placeholder='請輸入單號'
                          label='查詢單號'
                          onChange={this.onChangeText}
                        />
                      </Grid>
                    </Grid>
                    {this.state.accntType == '1' || this.state.accntType == '5' ? (
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={10}>
                          <label className='h5 ml-0 mb-3 is-text-bold'>使用者帳號類別</label>
                          <div className='row  mb-5'>
                            <Dropdown
                              className='is-button col-5 pl-0'
                              list={this.state.accountTypeList}
                              label={this.state.form.userType.text ? this.state.form.userType.text : '<尚未確認新增>'}
                              arrow={true}
                              hasCheck={false}
                              onChange={(e) => this.onChange('userType', e)}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                    <div className='two-buttons mb-5'>
                      <Button btnStyle='primary' size='large' onClick={this.searchSubmit}>
                        查詢
                      </Button>
                      <Button btnStyle='secondary' size='large' onClick={this.resetData}>
                        重填
                      </Button>
                    </div>
                  </Formsy>
                ) : null}
              </div>

              {/* 查詢結果 */}
              {this.state.searchSubmit ? (
                <React.Fragment>
                  <div className='left-sidebar col-3'></div>
                  <div className='col-9 container bg-white shadow mt-5 pl-4 pr-5'>
                    <div className='title pt-2 mt-5 ml-3'>
                      查詢結果
                      {validation.isEmpty(this.state.obstacleRecordList) ? '-無符合的資料' : null}
                    </div>
                    <table className='w-100 mb-5 ml-3'>
                      {/* 欄位名稱 */}
                      <tr className='gray-block text-black'>
                        <td className='w-20 text-center border-gray'>使用者帳號</td>
                        <td className='w-15 text-center border-gray'>報修單號</td>
                        <td className='w-15 text-center border-gray'>線路編號</td>
                        <td className='w-15 text-center border-gray'>新增日期時間</td>
                        <td className='w-15 text-center border-gray'>產品類別</td>
                        <td className='w-15 text-center border-gray'>服務類別</td>
                      </tr>
                      {this.state.obstacleRecordList
                        .slice(
                          (this.state.currentPage - 1) * this.state.maxlength,
                          this.state.currentPage * this.state.maxlength
                        )
                        .map((record, i) => (
                          <tr className='bg-white' key={'obstacle' + i}>
                            <td className='w-20 p-3 text-center border-gray'>{record.loginId}</td>
                            <td className='w-15 p-3 text-center border-gray'>
                              <a href='' onClick={(e) => this.clickToPop(record.obstaclesHistoryId, e)}>
                                {record.obstaclesHistoryId}
                              </a>
                            </td>
                            <td className='w-15 p-3 text-center border-gray'> {record.userNo}</td>
                            <td className='w-15 p-3 text-center border-gray'>{record.createDate}</td>
                            <td className='w-15 p-3 text-center border-gray'>{record.productCategory}</td>
                            <td className='w-15 p-3 text-center border-gray'>{record.productService}</td>
                          </tr>
                        ))}
                    </table>
                    {this.state.obstacleRecordList.length > this.state.maxlength ? (
                      <div>
                        <hr></hr>
                        <Pagination
                          changePage={this.handleChangePage}
                          currentPage={this.state.currentPage}
                          totalPage={Math.ceil(this.state.obstacleRecordList.length / this.state.maxlength)}
                          showMax={this.state.showMax}
                        />
                      </div>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
        {/* 彈出視窗 */}
        <Dialog open={this.state.isPopOut} onClose={this.handleClose} fullWidth maxWidth={'md'}>
          <div className='text-right'>
            <IconButton className='bg-white' onClick={this.handleClose}>
              X
            </IconButton>
          </div>
          <div className='border-gray m-5'>
            <div className='p-5 m-auto'>
              <div className='title mb-5 is-text-bold'>障礙線上申告表</div>
              <table className='w-100 '>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>申告單號</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.obstaclesHistoryId}</td>
                </tr>
              </table>
              <div className='sub-title mt-5 mb-4 is-text-bold'>聯絡資料</div>
              <table className='w-100'>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>客戶申告送出時間</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.applyDate}</td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>姓名</td>
                  <td className='w-60 p-3 border-gray'>
                    {this.state.obstaclesHistoryInfo.accntName} {this.state.obstaclesHistoryInfo.title}
                  </td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>公司名稱</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.cmpName}</td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>電話</td>
                  <td className='w-60 p-3 border-gray'>
                    {this.state.obstaclesHistoryInfo.mainPhoneArea != null
                      ? this.state.obstaclesHistoryInfo.mainPhoneArea + '-'
                      : null}
                    {this.state.obstaclesHistoryInfo.mainPhoneLocal}
                    {this.state.obstaclesHistoryInfo.mainPhoneExt != null
                      ? '-' + this.state.obstaclesHistoryInfo.mainPhoneExt
                      : null}
                  </td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>行動電話</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.mainMobile}</td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>E-mail</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.mainEmail}</td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>希望聯絡方式</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.contactType}</td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>可聯絡時間</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.availableTime}</td>
                </tr>
                {/* 須加判斷是否有其他聯絡人*/}
                {this.state.obstaclesHistoryInfo.secondName != null &&
                this.state.obstaclesHistoryInfo.secondName.trim().length > 0 ? (
                  <tr className='bg-white'>
                    <td className='w-40 text-center border-gray'>聯絡其他人</td>
                    <td className='w-60 p-3 border-gray'>V</td>
                  </tr>
                ) : null}
              </table>
              {/* 須加判斷是否有其他聯絡人*/}

              {this.state.obstaclesHistoryInfo.secondName != null &&
              this.state.obstaclesHistoryInfo.secondName.trim().length > 0 ? (
                <div>
                  <div className='sub-title mt-5 mb-4 is-text-bold'>聯絡人1資料</div>
                  <table className='w-100'>
                    <tr className='bg-white'>
                      <td className='w-40 text-center border-gray'>姓名</td>
                      <td className='w-60 p-3 border-gray'>
                        {this.state.obstaclesHistoryInfo.secondName}
                        {this.state.obstaclesHistoryInfo.secondTitle}
                      </td>
                    </tr>
                    <tr className='bg-white'>
                      <td className='w-40 text-center border-gray'>電話</td>
                      <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.secondPhoneLocal}</td>
                    </tr>
                    <tr className='bg-white'>
                      <td className='w-40 text-center border-gray'>行動電話</td>
                      <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.secondMobile}</td>
                    </tr>
                    <tr className='bg-white'>
                      <td className='w-40 text-center border-gray'>Email</td>
                      <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.secondEmail}</td>
                    </tr>
                  </table>
                </div>
              ) : null}
              {/* 須加判斷是否有其他聯絡人2*/}
              {this.state.obstaclesHistoryInfo.thirdName != null &&
              this.state.obstaclesHistoryInfo.thirdName.trim().length > 0 ? (
                <div>
                  <div className='sub-title mt-5 mb-4 is-text-bold'>聯絡人2資料</div>
                  <table className='w-100'>
                    <tr className='bg-white'>
                      <td className='w-40 text-center border-gray'>姓名</td>
                      <td className='w-60 p-3 border-gray'>
                        {this.state.obstaclesHistoryInfo.thirdName}
                        {this.state.obstaclesHistoryInfo.thirdTitle}
                      </td>
                    </tr>
                    <tr className='bg-white'>
                      <td className='w-40 text-center border-gray'>電話</td>
                      <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.thirdPhoneLocal}</td>
                    </tr>
                    <tr className='bg-white'>
                      <td className='w-40 text-center border-gray'>行動電話</td>
                      <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.thirdMobile}</td>
                    </tr>
                    <tr className='bg-white'>
                      <td className='w-40 text-center border-gray'>Email</td>
                      <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.thirdEmail}</td>
                    </tr>
                  </table>
                </div>
              ) : null}
              <div className='sub-title mt-5 mb-4 is-text-bold'>申告內容</div>
              <table className='w-100'>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>產品類別</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.productCategory}</td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>服務類別</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.productService}</td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>問題類別</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.problemCategory}</td>
                </tr>
                <tr className='bg-white'>
                  <td className='w-40 text-center border-gray'>問題填寫內容</td>
                  <td className='w-60 p-3 border-gray'>{this.state.obstaclesHistoryInfo.problemContent}</td>
                </tr>
              </table>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withRouter(QueryObstacleRecordPage);
