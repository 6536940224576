import React from 'react';
import Collapse from './Collapse';

import PropTypes from 'prop-types';
import { notEmpty } from '../../../utils/validation';

class SectionCollapseInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      content: this.props.content,
    };
  }

  render() {
    return (
      <div>
        {notEmpty(this.props.content) || notEmpty(this.props.children) ? (
          <section className='fui-section-collapse'>
            <div className='fui-container'>
              <Collapse
                title={this.props.title}
                content={this.props.content}
                children={this.props.children}
                dataTrackingId={this.props.dataTrackingId}
                fetkey={this.props.fetkey}
              />
            </div>
          </section>
        ) : null}
      </div>
    );
  }
}

SectionCollapseInfo.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string,
};

export default SectionCollapseInfo;
