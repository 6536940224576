import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import Banner from '../../../components/partials/banner/Banner';
import Sidebar from './CommonProblemSideBar';
import { Collapse } from 'react-bootstrap';

class LoginProblemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapse: '',
      open: false,
    };
  }

  //是否彈出搜尋欄位
  openCollapse = (openCollapse) => {
    let curCollapse = this.state.openCollapse;
    this.setState(() => ({
      openCollapse: curCollapse === openCollapse ? '' : openCollapse,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>帳號申請常見問題</title>
        </Helmet>
        <Banner title='帳號申請常見問題' />
        <div className='bg-img'>
          <div className='bg-img container'>
            <div className='row'>
              <Sidebar history={this.props.history} />

              <div className='bg-white col-9 shadow p-2'>
                <div className='title mt-5 ml-4'>登入問題</div>
                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '1' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>我的帳號為何無法登入？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('1')}
                        aria-controls='collapse-top'
                        aria-expanded={this.state.openCollapse == '1'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '1'}>
                    <div className='ml-3 mt-3 h5'>
                      請再次確認您的帳號/密碼正確性，須注意密碼要區分大小寫。若仍持續無法登入，可使用忘記密碼功能嘗試更新密碼。
                    </div>
                  </Collapse>
                </div>

                <div
                  className={`gray-block p-5 mt-2 mb-2 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '2' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>
                      為什麼明明是在遠傳網站中，
                      <br />
                      在我登入之後有時還要再登入一次？
                    </div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('2')}
                        aria-controls='collapse-mid'
                        aria-expanded={this.state.openCollapse == '2'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '2'}>
                    <div className='ml-3 mt-4 h5'>
                      本網站重視服務安全性，即使您已經登入，在使用某些重要個人服務（如電子帳單），我們將要求您再驗證可登入使用或閱覽該功能/服務。
                    </div>
                  </Collapse>
                </div>

                <div
                  className={`gray-block p-5 mt-2 mb-5 ml-4 mr-4 font-weight-bold fui-collapse ${
                    this.state.openCollapse == '3' ? 'is-open' : '-'
                  }`}>
                  <div className='row sub-title'>
                    <div className='col-11'>如何確保我在登入帳號和密碼時是安全的？</div>

                    <div className='col-1'>
                      <button
                        className='mb-0 mt-2 is-text-bold border-0 collapse-header'
                        onClick={() => this.openCollapse('3')}
                        aria-controls='collapse-bot'
                        aria-expanded={this.state.openCollapse == '3'}></button>
                    </div>
                  </div>
                  <Collapse in={this.state.openCollapse == '3'}>
                    <div className='ml-3 mt-4 h5'>
                      遠傳會員登入機制採取SSO (single Sign On)
                      機制，由遠傳內部資料庫及主機做會員驗證及認證動作。與客戶或交易有關的頁面均採用https加密方式進行，防制任何資料竊取行為。遠傳網路均有多層防火牆，可實質保護主機及您的資料安全。
                      為了確保您的帳戶安全，請妥善保管您的帳號與密碼，並定期進行變更密碼。
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(LoginProblemPage);
