import React from 'react';
import { Navigation } from 'react-minimal-side-navigation';
class CommonServiceSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          title: '各式表單下載',
          itemId: '/commonServicePage',
        },
        {
          title: '各地區服務主機',
          itemId: '/areaServerPage',
        },
        {
          title: '線上測速',
          itemId: '/testSpeedPage',
        },
        {
          title: 'IP反查申請',
          itemId: '/ipSearch',
        },
        {
          title: '產品使用手冊',
          itemId: '/productManualPage',
        },
        {
          title: '產品平台常用連結',
          itemId: '/cloudServicePage',
        },
        {
          title: '流量查詢 (10帳號)',
          itemId: '/eServiceMrtgPage',
        },
        {
          title: '企業用戶免登入查發票',
          itemId: '/eInvoiceServicePage',
        },
        {
          title: 'Seednet用戶線上繳費',
          itemId: '/seednetPayBillPage',
        },
        {
          title: 'Seednet線上列印支票補繳',
          itemId: '/seednetPrintCheckPage',
        },
        {
          title: '常見問題',
          itemId: '/registerProblemPage',
        },
      ],
    };
  }
  toPage = (itemId) => {
    if (itemId == '/ipSearch') {
      window.open('https://eservice.seed.net.tw/ip_check.htm', '_blank', 'height=800, width=800, resizable=0');
    } else if (itemId == '/testSpeedPage') {
      window.open('https://service.seed.net.tw/home/speed/speed.htm', '_blank', 'height=800, width=800, resizable=0');
    } else if (itemId == '/eServiceMrtgPage') {
      window.open('https://ecp.fareastone.com.tw/MRTG/login', '_blank', 'height=800, width=800, resizable=0');
    } else if (itemId == '/seednetPayBillPage') {
      window.open(
        'https://service.seed.net.tw/home/bill/cdbill7_11.htm',
        '_blank',
        'height=800, width=800, resizable=0'
      );
    } else if (itemId == '/seednetPrintCheckPage') {
      window.open(
        'https://service.seed.net.tw/register-cgi/mschequepay',
        '_blank',
        'height=800, width=800, resizable=0'
      );
    } else {
      document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
      this.props.history.push(itemId);
    }
  };

  render() {
    return (
      <div className={`left-sidebar ${this.props.className}`}>
        <div className='nav-title side-navigation-panel-select-option'>{this.props.title}</div>
        <Navigation
          onSelect={({ itemId }) => {
            this.toPage(itemId);
          }}
          items={this.state.items}
        />
      </div>
    );
  }
}
export default CommonServiceSidebar;
