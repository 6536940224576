import React from 'react';
import * as callApiUtil from '../../utils/callApiUtil';

class DownloadFilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docList: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    let productId = new URLSearchParams(this.props.location.search).get('prodId');
    callApiUtil.callApi('/ECPAP/API/DownloadApplyController/docDownloadInit.action', { productId }).then((response) => {
      // 判斷回傳資料
      if (
        response != null &&
        response != undefined &&
        response.resultMessage != null &&
        response.resultMessage != undefined &&
        response.resultMessage.success
      ) {
        //API回傳成功，將資料顯示在頁面上
        // 單一頁面只會有一筆資料, 所以直接取第一筆
        let docList;
        if (
          response.docInfoBeanList != null &&
          response.docInfoBeanList != undefined &&
          response.docInfoBeanList.length > 0
        ) {
          docList = response.docInfoBeanList[0].documentList;
        } else {
          docList = null;
        }
        this.setState({ isLoading: false, docList: docList });

        // 指定該頁面背景顏色為白色
        document.body.style.backgroundColor = 'white';
        // 將頁面高度傳至iframe鑲嵌頁面
        let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
        window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
      } else {
        //API回傳失敗
        this.props.history.push(
          {
            pathname: '/basicResultNoHeaderPage',
          },
          {
            type: 'fail',
            pageName: '申請書下載',
            title: '提示訊息',
            description: response.resultMessage.message,
          }
        );
      }
    });
  }

  download(document) {
    if (document.uri != null) {
      window.open(document.uri, document.uri, 'height=auto,width=auto'); // 呼叫外部URI下載檔案
    } else {
      callApiUtil.postFile('/ECPAP/API/DownloadApplyController/downloadDoc.action', {
        documentId: document.documentId,
      });
    }
  }

  render() {
    return (
      <div className='w-100 p-3'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        {this.state.docList == null ? (
          <h2 className='ml-3 pt-3 pb-3'>查無申請書資料</h2>
        ) : (
          <React.Fragment>
            <div className='title mt-4 mb-5 h-20'>申請書下載</div>
            <div>
              <ul className='ml-3 font-weight-bold'>
                {this.state.docList.map((doc, index) => (
                  <li className='p-2 h5' key={doc.documentId}>
                    <a className='pointer' href='#' onClick={() => this.download(doc)}>
                      {doc.displayName}
                    </a>
                    <p className='w-90' dangerouslySetInnerHTML={{ __html: doc.desc }} />
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default DownloadFilePage;
