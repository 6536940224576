import React from 'react';
import Button from '../../components/Button';
import LabelInput from '../../components/form/LabelInput';
import Formsy from 'formsy-react';
import { Grid, Dialog, DialogActions, DialogContent, Checkbox } from '@material-ui/core';
import * as validation from '../../utils/validation';
import * as callApiUtil from '../../utils/callApiUtil';
class MisMonitorContactInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      RETURN_CODE_SUCCESS: '0000',
      NO_NOTICE_HOUR: '00', //不告警，監控時間的'時'設定為 00
      NOTICE_MIN: '00', // 若有選擇告警時間, 時間設為整點, 設 '分'為 00
      // 顯示頁面
      step: 1, //2:異動監控聯絡人 , 3:新增監控聯絡人, 4:異動監控設定
      subUserNo: null, // 用戶編號
      userNo: null, // 客戶統編/其他證號
      isTrustee: null, //登入帳號是否是受任人
      hasSelectContact: false, //有選擇資料才能click異動button
      hasSelectMonitor: false, //有選擇資料才能click異動button
      isPopOut: false, //確認是否刪除資料的提示視窗
      isValidate: false, //驗證資料, 控制送出按鈕開關
      nameErrMsg: '',
      telNoErrMsg: '',
      cellErrMsg: '', //手機電話錯誤提示訊息
      faxNoErrMsg: '',
      emailErrMsg: '',
      sendMsgFlagErrMsg: '',
      monitorIPErrMsg: '',
      noticeErrMsg: '',
      monitorTimeErrMsg: '',
      hrList: [],
      minList: [],
      contactList: [], //init.撈資料放這
      monitorList: [],
      selectedContact: {}, //select了哪筆資料
      selectedMonitor: {},
      returnCode: null,
      description: null,
      isResultPopOut: false, //結果視窗
      successPrefix: null, //成功小視窗 前綴字
      //新增或修改要填寫的資料
      newForm: {
        //監控聯絡人
        userNo: null, // 公司統一編號/其他證號
        name: null, //新姓名
        telNo: null, //新市內電話
        cell: null, //新手機電話
        faxNo: null,
        email: null, //新信箱
        sendMsgFlag: null, //是否簡訊傳送
        majar: null, //主要聯絡人

        //監控設定
        transNo: null, //交易序號
        lineNo: null, //線號
        monitorFlag: null, //告警註記
        noticeTel: null, //電話通知
        noticeMMS: null, //簡訊通知
        noticeEmail: null, //Email通知
        //周六
        sStartHour: null,
        sStartMin: null,
        sStopHour: null,
        sStopMin: null,
        //周日
        hStartHour: null,
        hStartMin: null,
        hStopHour: null,
        hStopMin: null,
        //非假日
        wStartHour: null,
        wStartMin: null,
        wStopHour: null,
        wStopMin: null,
        monitorIP: null, //監控IP
      },
      isLoading: false,
    };
  }
  onChange = (name, value) => {
    let setValue = true;
    let newForm = Object.assign(this.state.newForm);

    if (name === 'monitorFlag' && value === 'N') {
      // 修改監控設定時，設為不告警，告警通知設為 false
      this.state.newForm.monitorFlag = 'N';
      this.state.newForm.noticeTel = false;
      this.state.newForm.noticeMMS = false;
      this.state.newForm.noticeEmail = false;
      // 修改監控設定時，設為不告警，監控時段改為'00'
      this.state.newForm.sStartHour = this.state.NO_NOTICE_HOUR;
      this.state.newForm.sStartMin = this.state.NOTICE_MIN;
      this.state.newForm.sStopHour = this.state.NO_NOTICE_HOUR;
      this.state.newForm.sStopMin = this.state.NOTICE_MIN;
      this.state.newForm.hStartHour = this.state.NO_NOTICE_HOUR;
      this.state.newForm.hStartMin = this.state.NOTICE_MIN;
      this.state.newForm.hStopHour = this.state.NO_NOTICE_HOUR;
      this.state.newForm.hStopMin = this.state.NOTICE_MIN;
      this.state.newForm.wStartHour = this.state.NO_NOTICE_HOUR;
      this.state.newForm.wStartMin = this.state.NOTICE_MIN;
      this.state.newForm.wStopHour = this.state.NO_NOTICE_HOUR;
      this.state.newForm.wStopMin = this.state.NOTICE_MIN;

      setValue = false;
    } else if (name === 'monitorFlag' && value === 'Y') {
      this.state.newForm.monitorFlag = 'Y';
      this.state.newForm.noticeTel = false; //給初值
      this.state.newForm.noticeMMS = false;
      this.state.newForm.noticeEmail = false;

      this.state.newForm.sStartHour = null;
      this.state.newForm.sStartMin = null;
      this.state.newForm.sStopHour = null;
      this.state.newForm.sStopMin = null;
      this.state.newForm.hStartHour = null;
      this.state.newForm.hStartMin = null;
      this.state.newForm.hStopHour = null;
      this.state.newForm.hStopMin = null;
      this.state.newForm.wStartHour = null;
      this.state.newForm.wStartMin = null;
      this.state.newForm.wStopHour = null;
      this.state.newForm.wStopMin = null;

      setValue = false;
    }

    // 若將起始監控時間的'時'設為'--', reset 同時段的其他時間欄位
    if (this.state.newForm.sStartHour && name === 'sStartHour' && !value) {
      this.state.newForm.sStartMin = null;
      this.state.newForm.sStopHour = null;
      this.state.newForm.sStopMin = null;
    } else if (this.state.newForm.hStartHour && name === 'hStartHour' && !value) {
      this.state.newForm.hStartMin = null;
      this.state.newForm.hStopHour = null;
      this.state.newForm.hStopMin = null;
    } else if (this.state.newForm.wStartHour && name === 'wStartHour' && !value) {
      this.state.newForm.wStartMin = null;
      this.state.newForm.wStopHour = null;
      this.state.newForm.wStopMin = null;
    }

    // 選擇監控時間的時，自動帶入分
    if (name === 'sStartHour' && value) {
      this.state.newForm.sStartMin = this.state.NOTICE_MIN;
    } else if (name === 'sStopHour' && value) {
      this.state.newForm.sStopMin = this.state.NOTICE_MIN;
    } else if (name === 'hStartHour' && value) {
      this.state.newForm.hStartMin = this.state.NOTICE_MIN;
    } else if (name === 'hStopHour' && value) {
      this.state.newForm.hStopMin = this.state.NOTICE_MIN;
    } else if (name === 'wStartHour' && value) {
      this.state.newForm.wStartMin = this.state.NOTICE_MIN;
    } else if (name === 'wStopHour' && value) {
      this.state.newForm.wStopMin = this.state.NOTICE_MIN;
    }

    if (setValue) {
      newForm[name] = value.trim();
      this.setState({ newForm: newForm });
    }

    this.enableButton();
  };

  checkBoxChange = (name) => {
    let newForm = Object.assign(this.state.newForm);
    newForm[name] = !this.state.newForm[name];
    this.enableButton();
    this.setState({ form: newForm });
  };

  selectedContact = (contactInfo) => {
    this.setState({
      selectedContact: contactInfo,
      hasSelectContact: true,
    });
  };
  selecteMonitor = (monitorInfo) => {
    this.setState({
      selectedMonitor: monitorInfo,
      hasSelectMonitor: true,
    });
  };

  resetNewForm = () => {
    document.querySelector('body').scrollTo({ top: 0, behavior: 'smooth' });
    let newForm = {
      //監控聯絡人
      userNo: null, // 公司統一編號/其他證號
      name: null, //新姓名
      telNo: null, //新市內電話
      cell: null, //新手機電話
      faxNo: null,
      email: null, //新信箱
      sendMsgFlag: null, //是否簡訊傳送
      majar: null, //主要聯絡人

      //監控設定
      lineNo: null,
      monitorFlag: null,
      noticeTel: null,
      noticeMMS: null,
      noticeEmail: null,
      //周六
      sStartHour: null,
      sStartMin: null,
      sStopHour: null,
      sStopMin: null,
      //周日
      hStartHour: null,
      hStartMin: null,
      hStopHour: null,
      hStopMin: null,
      //非假日
      wStartHour: null,
      wStartMin: null,
      wStopHour: null,
      wStopMin: null,
      monitorIP: null,
    };

    this.setState({
      newForm: newForm,
      isValidate: false,
      nameErrMsg: '',
      telNoErrMsg: '',
      cellErrMsg: '',
      emailErrMsg: '',
      faxNoErrMsg: '',
      sendMsgFlagErrMsg: '',
      monitorIPErrMsg: '',
      noticeErrMsg: '',
      monitorTimeErrMsg: '',
    });
  };

  //將後端回應的監控設定轉會成前台需要的格式
  parseMonitorSettingList = (monitorSettingList) => {
    let monitorList = [];
    for (const key in monitorSettingList) {
      let monitor = monitorSettingList[key];

      let newMonitor = {
        subUserNo: monitor.subUserNo,
        lineNo: monitor.lineNo,
        monitorFlag: monitor.monitorFlag,
        noticeTel: monitor.noticeTel === 'Y' ? true : false,
        noticeMMS: monitor.noticeMMS === 'Y' ? true : false,
        noticeEmail: monitor.noticeEmail === 'Y' ? true : false,
        //將MIS回傳的監控時間四位數字拆開:時,分
        sStartHour: monitor.sStartHour.substring(0, 2),
        sStartMin: monitor.sStartHour.substring(2, 4),
        sStopHour: monitor.sStopHour.substring(0, 2),
        sStopMin: monitor.sStopHour.substring(2, 4),

        hStartHour: monitor.hStartHour.substring(0, 2),
        hStartMin: monitor.hStartHour.substring(2, 4),
        hStopHour: monitor.hStopHour.substring(0, 2),
        hStopMin: monitor.hStopHour.substring(2, 4),

        wStartHour: monitor.wStartHour.substring(0, 2),
        wStartMin: monitor.wStartHour.substring(2, 4),
        wStopHour: monitor.wStopHour.substring(0, 2),
        wStopMin: monitor.wStopHour.substring(2, 4),
        monitorIP: monitor.monitorIP,
      };
      monitorList.push(newMonitor);
    }
    this.setState({
      monitorList: monitorList,
    });
  };

  //查詢MIS監控設定
  queryMisMonitorSetting = (subUserNo) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/getMisMonitorSetting.action', {
        subUserNo: subUserNo,
        function: 'queryMonitorSetting',
      })
      .then((response) => {
        // console.log('queryMonitorSetting response', response);
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          //MIS returnCode 為成功,且有資料
          if (response.misReturnCode === this.state.RETURN_CODE_SUCCESS) {
            // 有資料,轉換值; 若無資料畫面則呈現查無資料
            if (response.misMonitorSettingList.length !== 0) {
              this.parseMonitorSettingList(response.misMonitorSettingList);
            }

            //MIS returnCode 不成功
          } else {
            this.setState({
              isResultPopOut: true,
              returnCode: response.misReturnCode,
              description: response.description,
            });
          }
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '監控聯絡人',
              title: '提示訊息',
              description: response.resultMessage.message,
            }
          );
        }
        this.setState({ isLoading: false });
        this.resetNewForm();
      });
  };
  //異動MIS監控設定
  updateMisMonitorSetting = () => {
    this.setState({ isLoading: true });

    callApiUtil
      .callApi('/ECPAP/API/ProductController/updateMisMonitorSetting.action', {
        function: 'updateMonitorSetting',
        subUserNo: this.state.subUserNo,
        transNo: this.state.selectedMonitor.transNo,
        lineNo: this.state.selectedMonitor.lineNo,
        monitorFlag: this.state.newForm.monitorFlag,
        // 若為null表示無修改，給null,若有有給值表示有修改，則轉換 MIS 需要的文字參數
        noticeTel: this.state.newForm.noticeTel === null ? null : this.state.newForm.noticeTel ? 'Y' : 'N',
        noticeMMS: this.state.newForm.noticeMMS === null ? null : this.state.newForm.noticeMMS ? 'Y' : 'N',
        noticeEmail: this.state.newForm.noticeEmail === null ? null : this.state.newForm.noticeEmail ? 'Y' : 'N',
        //轉換為 MIS 需要的時間格式,將時,分組合為四位數字
        sStartHour: this.state.newForm.sStartHour ? this.state.newForm.sStartHour + this.state.newForm.sStartMin : null,
        sStopHour: this.state.newForm.sStopHour ? this.state.newForm.sStopHour + this.state.newForm.sStopMin : null,
        hStartHour: this.state.newForm.hStartHour ? this.state.newForm.hStartHour + this.state.newForm.hStartMin : null,
        hStopHour: this.state.newForm.hStopHour ? this.state.newForm.hStopHour + this.state.newForm.hStopMin : null,
        wStartHour: this.state.newForm.wStartHour ? this.state.newForm.wStartHour + this.state.newForm.wStartMin : null,
        wStopHour: this.state.newForm.wStopHour ? this.state.newForm.wStopHour + this.state.newForm.wStopMin : null,
        monitorIP: this.state.newForm.monitorIP,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          let msg = response.description;

          if (response.misReturnCode === this.state.RETURN_CODE_SUCCESS) {
            // 重新取得監控設定
            this.queryMisMonitorSetting(this.state.subUserNo);

            msg = '更新成功';
            this.setState({ step: 1, hasSelectMonitor: false, successPrefix: '更新' });
          }
          this.setState({ isResultPopOut: true, returnCode: response.misReturnCode, description: msg });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '監控設定',
              title: '更新失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };

  //查詢MIS監控聯絡人
  queryMisMonitorContact = (subUserNo) => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/getMisMonitorContact.action', {
        subUserNo: subUserNo,
        function: 'query',
      })
      .then((response) => {
        // console.log('query response', response);
        if (
          response != null &&
          response !== undefined &&
          response.resultMessage != null &&
          response.resultMessage !== undefined &&
          response.resultMessage.success
        ) {
          // set 公司統編
          this.setState({
            userNo: response.userNo,
            isTrustee: response.isTrustee,
          });
          // 顯示結果的訊息
          //MIS returnCode 為成功,且有資料
          if (response.misReturnCode === this.state.RETURN_CODE_SUCCESS) {
            if (response.misMonitorContactList.length !== 0) {
              this.setState({
                contactList: response.misMonitorContactList,
              });
            }
          } else {
            // 若查無資料，顯示結果視窗
            this.setState({
              isResultPopOut: true,
              returnCode: response.misReturnCode,
              description: response.description,
            });
          }
        } else {
          //API回傳失敗
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '監控聯絡人',
              title: '提示訊息',
              description: response.resultMessage.message,
            }
          );
        }
        this.setState({ isLoading: false });
        this.resetNewForm();
      });
  };

  //新增MIS監控聯絡人
  insertContactInfo = () => {
    this.setState({ isLoading: true, clickInsertOrUpdate: 'insert' });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/insertMisMonitorContact.action', {
        function: 'insert',
        subUserNo: this.state.subUserNo,
        userNo: this.state.userNo,
        name: this.state.newForm.name,
        telNo: this.state.newForm.telNo,
        cell: this.state.newForm.cell,
        faxNo: this.state.newForm.faxNo,
        email: this.state.newForm.email,
        sendMsgFlag: this.state.newForm.sendMsgFlag,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          let msg = response.description;

          if (response.misReturnCode === this.state.RETURN_CODE_SUCCESS) {
            // 重新取得監控聯絡人
            this.queryMisMonitorContact(this.state.subUserNo);

            msg = '新增成功';
            this.setState({ step: 1, hasSelectContact: false, successPrefix: '新增' });
          }
          this.setState({ isResultPopOut: true, returnCode: response.misReturnCode, description: msg });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '監控聯絡人',
              title: '新增失敗',
              description: response.description,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };
  //更新MIS監控聯絡人
  updateContactInfo = () => {
    this.setState({ isLoading: true });

    callApiUtil
      .callApi('/ECPAP/API/ProductController/updateMisMonitorContact.action', {
        function: 'update',
        subUserNo: this.state.subUserNo,
        userNo: this.state.userNo,
        seqNo: this.state.selectedContact.seqNo,
        // 若非null或空字串表示要修改
        name: this.state.newForm.name ? this.state.newForm.name : null,
        telNo: this.state.newForm.telNo ? this.state.newForm.telNo : null,
        cell: this.state.newForm.cell ? this.state.newForm.cell : null,
        faxNo: this.state.newForm.faxNo ? this.state.newForm.faxNo : null,
        email: this.state.newForm.email ? this.state.newForm.email : null,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          let msg = response.description;

          if (response.misReturnCode === this.state.RETURN_CODE_SUCCESS) {
            // 重新取得監控聯絡人
            this.queryMisMonitorContact(this.state.subUserNo);

            msg = '更新成功';
            this.setState({ step: 1, hasShasSelectContactelect: false, successPrefix: '更新' });
          }
          this.setState({ isResultPopOut: true, returnCode: response.misReturnCode, description: msg });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '監控聯絡人',
              title: '更新失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };

  //刪除MIS監控聯絡人
  deleteContactInfo = () => {
    this.setState({ isLoading: true });
    callApiUtil
      .callApi('/ECPAP/API/ProductController/deleteMisMonitorContact.action', {
        function: 'delete',
        subUserNo: this.state.subUserNo,
        userNo: this.state.selectedContact.userNo,
        seqNo: this.state.selectedContact.seqNo,
      })
      .then((response) => {
        if (
          response != null &&
          response != undefined &&
          response.resultMessage != null &&
          response.resultMessage != undefined &&
          response.resultMessage.success
        ) {
          let msg = response.description;

          if (response.misReturnCode === this.state.RETURN_CODE_SUCCESS) {
            // 重新取得監控聯絡人
            this.queryMisMonitorContact(this.state.subUserNo);

            msg = '刪除成功';
            this.setState({ step: 1, hasSelectContact: false, successPrefix: '刪除' });
          }
          this.setState({ isResultPopOut: true, returnCode: response.misReturnCode, description: msg });
        } else {
          this.props.history.push(
            {
              pathname: '/basicResultNoHeaderPage',
            },
            {
              type: 'fail',
              pageName: '監控聯絡人',
              title: '刪除失敗',
              description: response.resultMessage.message,
            }
          );
        }
      });

    this.setState({ isLoading: false });
  };

  //驗證資料, 控制送出按鈕開關
  enableButton = () => {
    let isValid = true;

    let nameErrMsg = '';
    let telNoErrMsg = '';
    let cellErrMsg = '';
    let faxNoErrMsg = '';
    let emailErrMsg = '';
    let sendMsgFlagErrMsg = '';
    let monitorIPErrMsg = '';
    let noticeErrMsg = '';
    let monitorTimeErrMsg = '';

    if (this.state.step === 2) {
      // 驗證異動聯絡人格式
      //至少需異動一項資料，只驗證是否有填寫資料，資料格式交由MIS驗證
      if (
        validation.isEmpty(this.state.newForm.name) &&
        validation.isEmpty(this.state.newForm.telNo) &&
        validation.isEmpty(this.state.newForm.cell) &&
        validation.isEmpty(this.state.newForm.faxNo) &&
        validation.isEmpty(this.state.newForm.email)
      ) {
        nameErrMsg = '若要修改，請至少輸入一項資料';
        isValid = false;
      }
    }

    // 驗證新增聯絡人
    if (this.state.step === 3) {
      if (validation.isEmpty(this.state.newForm.name)) {
        nameErrMsg = '請輸入姓名';
        isValid = false;
      }
      if (validation.isEmpty(this.state.newForm.telNo)) {
        telNoErrMsg = '請輸入電話';
        isValid = false;
      }
      if (validation.isEmpty(this.state.newForm.email)) {
        emailErrMsg = '請輸入email';
        isValid = false;
      }
      if (validation.isEmpty(this.state.newForm.sendMsgFlag)) {
        sendMsgFlagErrMsg = '請選擇是否接受簡訊傳送';
        isValid = false;
      }
    }

    //更新監控設定
    if (this.state.step === 4) {
      //若有設定告警，至少選一種方式通知與監控時間
      if (this.state.newForm.monitorFlag === 'Y') {
        if (!this.state.newForm.noticeTel && !this.state.newForm.noticeMMS && !this.state.newForm.noticeEmail) {
          noticeErrMsg = '請至少選擇一種告警方式';
          isValid = false;
        }

        //若選擇監控，檢查是否有既有的監控時間，若無則檢查是否有設定監控時間
        if (
          !this.state.selectedMonitor.sStartHour &&
          !this.state.selectedMonitor.hStartHour &&
          !this.state.selectedMonitor.wStartHour &&
          !this.state.newForm.sStartHour &&
          !this.state.newForm.sStartMin &&
          !this.state.newForm.sStopHour &&
          !this.state.newForm.sStopMin &&
          !this.state.newForm.hStartHour &&
          !this.state.newForm.hStartMin &&
          !this.state.newForm.hStopHour &&
          !this.state.newForm.hStopMin &&
          !this.state.newForm.wStartHour &&
          !this.state.newForm.wStartMin &&
          !this.state.newForm.wStopHour &&
          !this.state.newForm.monitorIP
        ) {
          monitorTimeErrMsg = '請至少選擇一段監控時間';
          isValid = false;
        } else {
          //若有選擇時間，檢查是否填寫完整
          // 判斷若無選擇監控時間，則顯示提醒訊息
          if (
            !(
              this.state.newForm.sStartHour &&
              this.state.newForm.sStartMin &&
              this.state.newForm.sStopHour &&
              this.state.newForm.sStopMin
            ) &&
            !(
              this.state.newForm.hStartHour &&
              this.state.newForm.hStartMin &&
              this.state.newForm.hStopHour &&
              this.state.newForm.hStopMin
            ) &&
            !(
              this.state.newForm.wStartHour &&
              this.state.newForm.wStartMin &&
              this.state.newForm.wStopHour &&
              this.state.newForm.wStopMin
            )
          ) {
            monitorTimeErrMsg = '請設定告警時段，如要全天監控請依週六/假日/非假日設定在00:00~24:00';
            isValid = false;
          }

          if (
            (this.state.newForm.sStartHour ||
              this.state.newForm.sStartMin ||
              this.state.newForm.sStopHour ||
              this.state.newForm.sStopMin) &&
            !(
              this.state.newForm.sStartHour &&
              this.state.newForm.sStartMin &&
              this.state.newForm.sStopHour &&
              this.state.newForm.sStopMin
            )
          ) {
            monitorTimeErrMsg = '請完整填寫周六的監控時段';
            isValid = false;
          } else {
            let startHour = parseInt(this.state.newForm.sStartHour);
            let startMin = parseInt(this.state.newForm.sStartMin);
            let stopHour = parseInt(this.state.newForm.sStopHour);
            let stopMin = parseInt(this.state.newForm.sStopMin);

            if (startHour > stopHour || (startHour == stopHour && startMin >= stopMin)) {
              monitorTimeErrMsg = '周六監控的開始時間請不要晚於結束時間';
              isValid = false;
            }
          }
          if (
            (this.state.newForm.hStartHour ||
              this.state.newForm.hStartMin ||
              this.state.newForm.hStopHour ||
              this.state.newForm.hStopMin) &&
            !(
              this.state.newForm.hStartHour &&
              this.state.newForm.hStartMin &&
              this.state.newForm.hStopHour &&
              this.state.newForm.hStopMin
            )
          ) {
            monitorTimeErrMsg = '請完整填寫假日的監控時段';
            isValid = false;
          } else {
            let startHour = parseInt(this.state.newForm.hStartHour);
            let startMin = parseInt(this.state.newForm.hStartMin);
            let stopHour = parseInt(this.state.newForm.hStopHour);
            let stopMin = parseInt(this.state.newForm.hStopMin);

            if (startHour > stopHour || (startHour == stopHour && startMin >= stopMin)) {
              monitorTimeErrMsg = '周日監控的開始時間請不要晚於結束時間';
              isValid = false;
            }
          }
          if (
            (this.state.newForm.wStartHour ||
              this.state.newForm.wStartMin ||
              this.state.newForm.wStopHour ||
              this.state.newForm.wStopMin) &&
            !(
              this.state.newForm.wStartHour &&
              this.state.newForm.wStartMin &&
              this.state.newForm.wStopHour &&
              this.state.newForm.wStopMin
            )
          ) {
            monitorTimeErrMsg = '請完整填寫非假日的監控時段';
            isValid = false;
          } else {
            let startHour = parseInt(this.state.newForm.wStartHour);
            let startMin = parseInt(this.state.newForm.wStartMin);
            let stopHour = parseInt(this.state.newForm.wStopHour);
            let stopMin = parseInt(this.state.newForm.wStopMin);

            if (startHour > stopHour || (startHour == stopHour && startMin >= stopMin)) {
              monitorTimeErrMsg = '非假日監控的開始時間請不要晚於結束時間';
              isValid = false;
            }
          }
        }

        //若選擇有告警, 檢查是否有設定監控IP
        if (
          this.state.newForm.monitorFlag === 'Y' &&
          !this.state.selectedMonitor.monitorIP &&
          !this.state.newForm.monitorIP
        ) {
          monitorIPErrMsg = '已設定告警，請輸入監控 IP';
          isValid = false;
        }
      }
    }
    this.setState(() => ({
      nameErrMsg: nameErrMsg,
      telNoErrMsg: telNoErrMsg,
      cellErrMsg: cellErrMsg,
      faxNoErrMsg: faxNoErrMsg,
      emailErrMsg: emailErrMsg,
      sendMsgFlagErrMsg: sendMsgFlagErrMsg,
      monitorIPErrMsg: monitorIPErrMsg,
      noticeErrMsg: noticeErrMsg,
      monitorTimeErrMsg: monitorTimeErrMsg,
      isValidate: isValid,
    }));
  };
  componentDidUpdate() {
    // console.log('this.selectedContact', this.selectedContact);
    // console.log('this.state.monitorList', this.state.monitorList);
  }
  componentDidMount() {
    // 指定該頁面背景顏色為白色
    document.body.style.backgroundColor = 'white';
    // 將頁面高度傳至iframe鑲嵌頁面
    let bodyHigh = { type: 'high', bodyHight: document.body.scrollHeight };
    window.parent.postMessage(bodyHigh, process.env.REACT_APP_API_BASE_URL);
    //取得userNo
    let subUserNo = new URLSearchParams(this.props.location.search).get('userNo');
    this.setState({ subUserNo: subUserNo });
    this.queryMisMonitorContact(subUserNo);
    this.queryMisMonitorSetting(subUserNo);
    //監控時間option
    let hrList = [];
    hrList.push('--');
    for (let i = 0; i <= 24; i++) {
      i = i < 10 ? '0' + i : i;
      hrList.push(i);
    }
    let minList = ['--', '00'];

    this.setState({
      hrList: hrList,
      minList: minList,
    });
  }

  render() {
    return (
      <div className='w-100 p-3'>
        <div className={`${this.state.isLoading ? 'is-loading' : ''}`} />
        <React.Fragment>
          <div>
            {this.state.step === 1 ? (
              <div className='w-100 pl-3 pt-3'>
                <div>
                  {/* 監控聯絡人資料 */}
                  <div className='title mt-4 mb-5'>監控聯絡人資料</div>
                  {this.state.contactList && this.state.contactList.length !== 0 ? (
                    <div className='w-100 pl-3'>
                      <table className='w-100'>
                        <tbody>
                          <tr className='bg-white'>
                            {/* 只有受任人能做新增、修改、刪除等動作 */}
                            {this.state.isTrustee ? <td className='w-5 text-center border-gray'></td> : null}
                            <td className='w-13 p-2 text-center border-gray'>姓名</td>
                            <td className='w-18 p-2 text-center border-gray'>市內電話</td>
                            <td className='w-18 p-2 text-center border-gray'>手機號碼</td>
                            <td className='w-18 p-2 text-center border-gray'>傳真電話</td>
                            <td className='w-28 p-2 text-center border-gray'>Email</td>
                          </tr>
                          {this.state.contactList.map((info, index) => {
                            return (
                              <tr className='bg-white' key={'info' + index}>
                                {this.state.isTrustee ? (
                                  <td className='w-5 text-center border-gray'>
                                    <input
                                      type='radio'
                                      name='contact'
                                      id={info.seqNo}
                                      onClick={() => this.selectedContact(info)}
                                    />
                                  </td>
                                ) : null}
                                <td className='w-13 p-2 text-center border-gray'>
                                  {info.majar === 'Y' ? (
                                    <span className='red mr-1'>*</span>
                                  ) : (
                                    <span className='mr-2'></span>
                                  )}
                                  {info.name}
                                </td>
                                <td className='w-18 p-2 text-center border-gray'>{info.telNo}</td>
                                <td className='w-18 p-2 text-center border-gray'>{info.cell ? info.cell : 'N/A'}</td>
                                <td className='w-18 p-2 text-center border-gray'>{info.faxNo ? info.faxNo : 'N/A'}</td>
                                <td className='w-28 p-2 text-center border-gray'>{info.email}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className='mt-2 ml-2'>
                        <span className='red mr-1'>*</span>為主要聯絡人
                      </div>
                    </div>
                  ) : (
                    <div className='mt-3 pl-3 red font-weight-bold'>查無聯絡人資料</div>
                  )}
                  <div className='button-group mb-4 ml-3'>
                    {/* 只有受任人能做新增、修改、刪除等動作 */}
                    {this.state.isTrustee ? (
                      <div className='mt-5'>
                        <Button
                          btnStyle='primary'
                          size='large'
                          fetkey=''
                          onClick={() => {
                            this.resetNewForm();
                            this.setState({
                              step: 3,
                            });
                          }}>
                          新增
                        </Button>
                        <Button
                          btnStyle='primary'
                          size='large'
                          fetkey=''
                          disabled={!this.state.hasSelectContact}
                          onClick={() =>
                            this.setState({
                              isPopOut: true,
                            })
                          }>
                          刪除
                        </Button>
                        <Button
                          btnStyle='primary'
                          size='large'
                          fetkey=''
                          disabled={!this.state.hasSelectContact}
                          onClick={() =>
                            this.setState({
                              step: 2,
                            })
                          }>
                          異動
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* 監控設定清單 */}
                <div className='mt-5'>
                  <div className='title mb-5'>監控設定清單</div>
                  {this.state.monitorList != null && this.state.monitorList.length != 0 ? (
                    <div className='w-100 pl-3'>
                      <table className='w-100'>
                        <tbody>
                          <tr className='bg-white'>
                            {/* 受任人才能進行新增、刪除、更新 */}
                            {this.state.isTrustee ? <td className='w-5 text-center border-gray'></td> : null}
                            <td className='w-20 p-2 text-center border-gray'>線號</td>
                            <td className='w-20 p-2 text-center border-gray'>告警通知</td>
                            <td className='w-30 p-2 text-center border-gray'>監控時段</td>
                            <td className='w-25 p-2 text-center border-gray'>監控IP</td>
                          </tr>
                          {this.state.monitorList.map((info, index) => {
                            return (
                              <tr className='bg-white' key={'info' + index}>
                                {this.state.isTrustee ? (
                                  <td className='w-5 text-center border-gray'>
                                    <input
                                      type='radio'
                                      name='monitor'
                                      id={'monitor' + info.subUserNo}
                                      onClick={() => this.selecteMonitor(info)}
                                    />
                                  </td>
                                ) : null}
                                <td className='w-20 p-3 border-gray text-center'>
                                  {info.lineNo ? info.lineNo : 'N/A'}
                                </td>
                                <td className='w-20 p-3 border-gray text-center'>
                                  {info.monitorFlag === 'Y' ? (
                                    <div>
                                      {info.noticeTel ? <div className='mb-2'>電話通知</div> : null}
                                      {info.noticeMMS ? <div className='mb-2'>簡訊通知</div> : null}
                                      {info.noticeEmail ? <div className='mb-2'>E-Mail通知</div> : null}
                                    </div>
                                  ) : null}
                                  {info.monitorFlag === 'N' ? '不告警' : null}
                                  {!info.monitorFlag ? 'N/A' : null}
                                </td>
                                <td className='w-30 p-3 border-gray text-center'>
                                  <div>
                                    {info.sStartHour ? (
                                      <div className='mb-2'>
                                        週六:{info.sStartHour}:{info.sStartMin}~{info.sStopHour}:{info.sStopMin}
                                      </div>
                                    ) : null}
                                    {info.hStartHour ? (
                                      <div className='mb-2'>
                                        週日:{info.hStartHour}:{info.hStartMin}~{info.hStopHour}:{info.hStopMin}
                                      </div>
                                    ) : null}
                                    {info.wStartHour ? (
                                      <div>
                                        非假日:{info.wStartHour}:{info.wStartMin}~{info.wStopHour}:{info.wStopMin}
                                      </div>
                                    ) : null}
                                  </div>
                                </td>
                                <td className='w-25 p-3 border-gray text-center'>
                                  {info.monitorIP ? info.monitorIP : 'N/A'}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className='mt-3 pl-3 red font-weight-bold'>查無監控設定資料</div>
                  )}
                  <div className='button-group mb-4 ml-3'>
                    {/* 受任人才能進行新增、刪除、更新 */}
                    {this.state.isTrustee ? (
                      <div className='mt-5'>
                        <Button
                          btnStyle='primary'
                          size='large'
                          fetkey=''
                          disabled={!this.state.hasSelectMonitor}
                          onClick={() =>
                            this.setState({
                              step: 4,
                            })
                          }>
                          異動
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            {/* 2:異動監控聯絡人 */}
            {this.state.step === 2 ? (
              <div>
                <div className='title mb-5 ml-3 mt-5 is-text-bold'>異動聯絡人資料</div>
                <Formsy>
                  <div className='button-group p-3'>
                    <table className='w-100'>
                      <tbody>
                        <tr className='bg-white'>
                          <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                            姓名
                          </td>
                          <td className='w-60 p-3 border-gray'>{this.state.selectedContact.name}</td>
                        </tr>
                        <tr className='bg-white'>
                          <td className='w-40 border-gray'>
                            <LabelInput
                              className='ml-3 mt-3 is-required'
                              name='name'
                              value={this.state.newForm.name}
                              maxLength={20}
                              placeholder='請輸入欲更改的姓名'
                              label=''
                              failResultMsg={this.state.nameErrMsg}
                              onChange={this.onChange}
                            />
                          </td>
                        </tr>
                        <tr className='bg-white'>
                          <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                            市內電話(範例:xx-xxxxxxxx)
                          </td>
                          <td className='w-60 p-3 border-gray'>{this.state.selectedContact.telNo}</td>
                        </tr>
                        <tr className='bg-white'>
                          <td className='w-40 border-gray'>
                            <LabelInput
                              className='ml-3 mt-3'
                              name='telNo'
                              value={this.state.newForm.telNo}
                              maxLength={20}
                              placeholder='請輸入欲更改的市內電話'
                              label=''
                              onChange={this.onChange}
                              failResultMsg={this.state.telNoErrMsg}
                            />
                          </td>
                        </tr>
                        <tr className='bg-white'>
                          <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                            手機號碼
                          </td>
                          <td className='w-60 p-3 border-gray'>
                            {this.state.selectedContact.cell ? this.state.selectedContact.cell : 'N/A'}
                          </td>
                        </tr>
                        <tr className='bg-white'>
                          <td className='w-40 border-gray'>
                            <LabelInput
                              className='ml-3 mt-3'
                              name='cell'
                              maxLength={12}
                              value={this.state.newForm.cell}
                              placeholder='請輸入欲更改的手機號碼'
                              label=''
                              onChange={this.onChange}
                              failResultMsg={this.state.cellErrMsg}
                            />
                          </td>
                        </tr>
                        <tr className='bg-white'>
                          <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                            傳真號碼(範例:xx-xxxxxxxx)
                          </td>
                          <td className='w-60 p-3 border-gray'>
                            {this.state.selectedContact.faxNo ? this.state.selectedContact.faxNo : 'N/A'}
                          </td>
                        </tr>
                        <tr className='bg-white'>
                          <td className='w-40 border-gray'>
                            <LabelInput
                              className='ml-3 mt-3'
                              name='faxNo'
                              maxLength={12}
                              value={this.state.newForm.faxNo}
                              placeholder='請輸入欲更改的傳真號碼'
                              label=''
                              onChange={this.onChange}
                              failResultMsg={this.state.faxNoErrMsg}
                            />
                          </td>
                        </tr>
                        <tr className='bg-white'>
                          <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                            Email
                          </td>
                          <td className='w-60 p-3 border-gray'>{this.state.selectedContact.email}</td>
                        </tr>
                        <tr className='bg-white'>
                          <td className='w-40 border-gray'>
                            <LabelInput
                              className='ml-3 mt-3'
                              name='email'
                              maxLength={40}
                              value={this.state.newForm.email}
                              placeholder='請輸入欲更改的Email地址'
                              failResultMsg={this.state.emailErrMsg}
                              label=''
                              onChange={this.onChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Formsy>
                <div className='mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    fetkey=''
                    onClick={() => this.updateContactInfo()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' size='large' fetkey='' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            {/* 3:新增監控聯絡人 */}
            {this.state.step == 3 ? (
              <div>
                <div className='title mb-5 ml-3 mt-5 is-text-bold'>新增聯絡人資料</div>
                <Formsy>
                  <Grid container spacing={3} className='mt-md-3 ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='name'
                        value={this.state.newForm.name}
                        required={true}
                        maxLength={20}
                        placeholder='請輸入欲新增的聯絡人姓名'
                        label='姓名'
                        onChange={this.onChange}
                        failResultMsg={this.state.nameErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className=' ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='telNo'
                        value={this.state.newForm.telNo}
                        required={true}
                        maxLength={20}
                        placeholder='請輸入欲新增的市內電話'
                        label='市內電話(範例:xx-xxxxxxxx)'
                        onChange={this.onChange}
                        failResultMsg={this.state.telNoErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className=' ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='cell'
                        value={this.state.newForm.cell}
                        maxLength={12}
                        label='手機號碼'
                        placeholder='請輸入欲新增的手機號碼'
                        onChange={this.onChange}
                        failResultMsg={this.state.cellErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className=' ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='faxNo'
                        value={this.state.newForm.faxNo}
                        maxLength={12}
                        label='傳真電話(範例:xx-xxxxxxxx)'
                        placeholder='請輸入欲新增的傳真號碼'
                        onChange={this.onChange}
                        failResultMsg={this.state.faxNoErrMsg}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className=' ml-1'>
                    <Grid item xs={12} md={8}>
                      <LabelInput
                        className='ml-4'
                        name='email'
                        value={this.state.newForm.email}
                        required={true}
                        label='Email'
                        maxLength={40}
                        placeholder='請輸入欲新增的Email'
                        onChange={this.onChange}
                        failResultMsg={this.state.emailErrMsg}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} className='ml-1 form-group '>
                    <Grid item xs={12} md={8}>
                      <label className='h5 mb-3 ml-4 is-required'>簡訊傳送</label>
                      <input
                        type='radio'
                        className='radio-button ml-4'
                        name='sendMsgFlag'
                        id='sendMsg'
                        value='Y'
                        checked={this.state.newForm.sendMsgFlag === 'Y'}
                        onChange={(e) => this.onChange('sendMsgFlag', e.target.value)}></input>
                      <label htmlFor='Y' className='h5'>
                        是
                      </label>
                      <input
                        type='radio'
                        className='radio-button ml-4'
                        name='sendMsgFlag'
                        value='N'
                        id='notSendMsg'
                        checked={this.state.newForm.sendMsgFlag === 'N'}
                        onChange={(e) => this.onChange('sendMsgFlag', e.target.value)}></input>
                      <label htmlFor='N' className='h5'>
                        否
                      </label>
                      <div className='ml-4 error-message'>{this.state.sendMsgFlagErrMsg}</div>
                    </Grid>
                  </Grid>
                </Formsy>
                <div className='ml-4 mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    fetkey=''
                    onClick={() => this.insertContactInfo()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' fetkey='' size='large' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            {/* 4:異動監控設定 */}
            {this.state.step == 4 ? (
              <div>
                <div className='title mb-5 ml-3 mt-5 is-text-bold'>異動監控設定</div>
                <Formsy>
                  <div className='ml-2 button-group p-3'>
                    <Grid container spacing={3} className=' ml-1'>
                      <Grid item xs={12} md={8}>
                        <div className='sub-title mb-4  is-text-bold'>監控設定</div>
                        <table className='w-100'>
                          <tbody>
                            <tr className='bg-white'>
                              <td rowSpan='1' className='w-40 text-center border-gray backgroundColor-gray'>
                                用戶編號
                              </td>
                              <td className='w-60 p-3 border-gray'>{this.state.selectedMonitor.subUserNo}</td>
                            </tr>
                            <tr className='bg-white'>
                              <td rowSpan='1' className='w-40 text-center border-gray backgroundColor-gray'>
                                線號
                              </td>
                              <td className='w-60 p-3 border-gray'>
                                {this.state.selectedMonitor.lineNo ? this.state.selectedMonitor.lineNo : 'N/A'}
                              </td>
                            </tr>
                            <tr className='bg-white'>
                              <td rowSpan='2' className='w-20 text-center border-gray backgroundColor-gray'>
                                告警通知
                              </td>
                              <td className='w-60 p-3 border-gray'>
                                {this.state.selectedMonitor.monitorFlag === 'Y' ? '要告警' : '無告警'}
                                {this.state.selectedMonitor.monitorFlag === 'Y' ? (
                                  <div className='mt-3'>
                                    告警方式:
                                    <label className='h6 ml-2'>
                                      <input
                                        disabled={true}
                                        type='checkbox'
                                        className='ml-1'
                                        checked={this.state.selectedMonitor.noticeTel}
                                      />
                                      電話通知
                                    </label>
                                    <label className='h6'>
                                      <input
                                        disabled={true}
                                        type='checkbox'
                                        className='ml-2'
                                        checked={this.state.selectedMonitor.noticeMMS}
                                      />
                                      簡訊通知
                                    </label>
                                    <label className='h6'>
                                      <input
                                        disabled={true}
                                        type='checkbox'
                                        className='ml-2'
                                        checked={this.state.selectedMonitor.noticeEmail}
                                      />
                                      E-mail通知
                                    </label>
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                            <tr>
                              <td className='w-60 p-3 border-gray'>
                                請選擇欲修改的告警設定
                                <br />
                                <div className='ml-4 mt-3'>
                                  <input
                                    type='radio'
                                    className='radio-button'
                                    name='monitorFlag'
                                    id='NonAlarm'
                                    value='N'
                                    checked={this.state.newForm.monitorFlag === 'N'}
                                    onChange={(e) => this.onChange('monitorFlag', e.target.value)}
                                  />
                                  <label className='h6'>不告警 (點選後，不須再設定其他資訊)</label>
                                  <br></br>
                                  <input
                                    type='radio'
                                    className='radio-button'
                                    name='monitorFlag'
                                    id='alarm'
                                    value='Y'
                                    checked={this.state.newForm.monitorFlag === 'Y'}
                                    onChange={(e) => this.onChange('monitorFlag', e.target.value)}
                                  />
                                  <label className='h6'>
                                    要告警 設定告警方式<span className='red'>(可複選)</span>:{' '}
                                  </label>
                                  <div className='ml-4'>
                                    <label className='h6'>
                                      <input
                                        disabled={this.state.newForm.monitorFlag !== 'Y'}
                                        type='checkbox'
                                        name='noticeTel'
                                        className='ml-1'
                                        id='noticeTel'
                                        checked={this.state.newForm.noticeTel}
                                        onClick={() => this.checkBoxChange('noticeTel')}
                                      />
                                      電話通知
                                    </label>
                                    <label className='h6'>
                                      <input
                                        disabled={this.state.newForm.monitorFlag !== 'Y'}
                                        type='checkbox'
                                        name='noticeMMS'
                                        className='ml-2'
                                        id='noticeMMS'
                                        checked={this.state.newForm.noticeMMS}
                                        onClick={() => this.checkBoxChange('noticeMMS')}
                                      />
                                      簡訊通知
                                    </label>
                                    <label className='h6'>
                                      <input
                                        disabled={this.state.newForm.monitorFlag !== 'Y'}
                                        type='checkbox'
                                        name='noticeEmail'
                                        className='ml-2'
                                        id='noticeEmail'
                                        checked={this.state.newForm.noticeEmail}
                                        onClick={() => this.checkBoxChange('noticeEmail')}
                                      />
                                      E-mail通知
                                    </label>
                                    <div className='ml-1 error-message'>{this.state.noticeErrMsg}</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr className='bg-white'>
                              <td rowSpan='2' className='w-20 text-center border-gray backgroundColor-gray'>
                                監控時段
                                <div className='red'>(不告警免填)</div>
                              </td>
                              <td className='w-60 p-3 border-gray'>
                                <div className='mt-2 '>
                                  {/* selectedMonitor */}
                                  {this.state.selectedMonitor.sStartHour ? (
                                    <div className='mb-2'>
                                      週六:{this.state.selectedMonitor.sStartHour}:
                                      {this.state.selectedMonitor.sStartMin}~{this.state.selectedMonitor.sStopHour}:
                                      {this.state.selectedMonitor.sStopMin}
                                    </div>
                                  ) : null}
                                  {this.state.selectedMonitor.hStartHour ? (
                                    <div className='mb-2'>
                                      週日:{this.state.selectedMonitor.hStartHour}:
                                      {this.state.selectedMonitor.hStartMin}~{this.state.selectedMonitor.hStopHour}:
                                      {this.state.selectedMonitor.hStopMin}
                                    </div>
                                  ) : null}
                                  {this.state.selectedMonitor.wStartHour ? (
                                    <div className='mb-2'>
                                      非假日:{this.state.selectedMonitor.wStartHour}:
                                      {this.state.selectedMonitor.wStartMin}~{this.state.selectedMonitor.wStopHour}:
                                      {this.state.selectedMonitor.wStopMin}
                                    </div>
                                  ) : null}
                                  {/* 若皆無資料，則顯示 N/A */}
                                  {!this.state.selectedMonitor.sStartHour &&
                                  !this.state.selectedMonitor.hStartHour &&
                                  !this.state.selectedMonitor.wStartHour
                                    ? 'N/A'
                                    : null}
                                </div>
                              </td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-60 p-3 border-gray'>
                                請選擇欲修改的監控時段
                                <br />
                                <span className='red'>*全天監控請設定在00:00~24:00</span>
                                <div className='ml-4'>
                                  <div className='mt-3 d-flex'>
                                    <div className='col-2 mt-1'>週六</div>
                                    <div className='col-10'>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('sStartHour', e.target.value)}>
                                        {this.state.hrList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.sStartHour == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>時</span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('sStartMin', e.target.value)}>
                                        {this.state.minList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.sStartMin == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='ml-1'>分 ~ </span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('sStopHour', e.target.value)}>
                                        {' '}
                                        {this.state.hrList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.sStopHour == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>時</span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('sStopMin', e.target.value)}>
                                        {' '}
                                        {this.state.minList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.sStopMin == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>分</span>
                                    </div>
                                  </div>
                                  <div className='d-flex mt-3'>
                                    <div className='col-2 mt-1'>假日</div>
                                    <div className='col-10'>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('hStartHour', e.target.value)}>
                                        {this.state.hrList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.hStartHour == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>時</span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('hStartMin', e.target.value)}>
                                        {this.state.minList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.hStartMin == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='ml-1'>分 ~ </span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('hStopHour', e.target.value)}>
                                        {' '}
                                        {this.state.hrList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.hStopHour == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>時</span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('hStopMin', e.target.value)}>
                                        {' '}
                                        {this.state.minList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.hStopMin == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>分</span>
                                    </div>
                                  </div>
                                  <div className='d-flex mt-3'>
                                    <div className='col-2 mt-1'>非假日</div>
                                    <div className='col-10'>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('wStartHour', e.target.value)}>
                                        {this.state.hrList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.wStartHour == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>時</span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('wStartMin', e.target.value)}>
                                        {this.state.minList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.wStartMin == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='ml-1'>分 ~ </span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('wStopHour', e.target.value)}>
                                        {' '}
                                        {this.state.hrList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.wStopHour == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>時</span>
                                      <select
                                        disabled={this.state.newForm.monitorFlag != 'Y'}
                                        onClick={(e) => this.onChange('wStopMin', e.target.value)}>
                                        {' '}
                                        {this.state.minList.map((value, index) => (
                                          <option
                                            key={index}
                                            selected={this.state.newForm.wStopMin == value}
                                            value={value != '--' ? value : ''}>
                                            {value}
                                          </option>
                                        ))}
                                      </select>
                                      <span className='mr-1 ml-1'>分</span>
                                    </div>
                                  </div>
                                  <div className='ml-1 error-message'>{this.state.monitorTimeErrMsg}</div>
                                </div>
                              </td>
                            </tr>
                            <tr className='bg-white'>
                              <td rowSpan='2' className='w-40 text-center border-gray backgroundColor-gray'>
                                監控IP
                              </td>
                              <td className='w-60 p-3 border-gray'>
                                {this.state.selectedMonitor.monitorIP ? this.state.selectedMonitor.monitorIP : 'N/A'}
                              </td>
                            </tr>
                            <tr className='bg-white'>
                              <td className='w-40 border-gray'>
                                <LabelInput
                                  className='ml-3 mt-3 is-required'
                                  name='monitorIP'
                                  value={this.state.newForm.monitorIP}
                                  maxLength={15}
                                  label=''
                                  placeholder='請輸入欲修改的監控IP'
                                  onChange={this.onChange}
                                  failResultMsg={this.state.monitorIPErrMsg}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                  </div>
                </Formsy>
                <div className='ml-4 mt-4 two-buttons mb-5 pl-3'>
                  <Button
                    btnStyle='primary'
                    size='large'
                    fetkey=''
                    onClick={() => this.updateMisMonitorSetting()}
                    disabled={!this.state.isValidate}>
                    送出
                  </Button>
                  <Button btnStyle='secondary' fetkey='' size='large' onClick={() => this.resetNewForm()}>
                    重填
                  </Button>
                </div>
              </div>
            ) : null}
            <Dialog
              open={this.state.isPopOut}
              onClose={() => this.setState({ isPopOut: false })}
              fullWidth
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>貼心小叮嚀</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>請確認是否要刪除這筆聯絡人資料?</div>
              </DialogContent>
              <DialogActions>
                <Button
                  btnStyle='primary'
                  className='w5 my-2'
                  fetkey=''
                  onClick={() => {
                    this.deleteContactInfo();
                    this.setState({
                      isPopOut: false,
                    });
                  }}
                  autoFocus>
                  確認
                </Button>
                <Button
                  btnStyle='secondary'
                  className='w5 my-2'
                  fetkey=''
                  onClick={() =>
                    this.setState({
                      isPopOut: false,
                    })
                  }
                  autoFocus>
                  取消
                </Button>
              </DialogActions>
            </Dialog>
            {/* 結果視窗 */}
            <Dialog
              open={this.state.isResultPopOut}
              onClose={() => this.setState({ isResultPopOut: false })}
              fullWidth
              maxWidth={'xs'}>
              <div className='bg-header'>
                <div className='h5 ml-4 pt-3 pb-2 is-text-bold white'>提示訊息</div>
              </div>
              <DialogContent dividers={true}>
                <div className='h5 ml-3 my-4 is-text-bold'>
                  {this.state.returnCode === this.state.RETURN_CODE_SUCCESS
                    ? this.state.successPrefix + '成功'
                    : this.state.description}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  fetkey=''
                  btnStyle='primary'
                  className='w5 my-2'
                  onClick={() => {
                    this.setState({
                      isResultPopOut: false,
                    });
                  }}
                  autoFocus>
                  關閉
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default MisMonitorContactInfoPage;
